import React, { useEffect, useState } from 'react';
import {
    ActionButton,
    ContextualMenu,
    DefaultButton,
    Dialog,
    DialogType,
    FontSizes,
    MessageBar,
    Persona,
    PersonaSize,
    PrimaryButton,
    Text,
    TextField,
    ZIndexes,
    mergeStyleSets,
} from '@fluentui/react';
import { useViewport } from '../hooks/useViewport';

import mai from '../images/mai.png';
import { photoService } from '../services/photo.service';
import { useStateContext } from '../services/contextProvider';
import ChatBubble from './mai/ChatBubble';
import { useMaiWebSockets } from '../hooks/useMaiWebSockets';
import { Markdown } from '../scenes/Presentations/Markdown';
import { sorters } from '../data/sorters';

export function MissionMentorDialog(props: {
    hidden: boolean;
    onDismiss: () => void;
    userPrompts?: { key: string; text: string }[];
    content?: JSX.Element;
    // onPromptSelected?: (userPromptKey?: string) => void;
    actions?: { name: string; action: () => void }[];
    isStreaming?: boolean;
    isLoading?: boolean;
    allowChat?: boolean;
    onRegenerateClick?: () => void;
}) {
    const { content, actions, isStreaming, isLoading, allowChat } = props;

    const { sendThreadMessage, threadMessages } = useMaiThreadsChat();

    const { currentUserId, currentUserDisplayName } = useStateContext();

    const [showMaiInfo] = useState(true);

    const [userPrompt, setUserPrompt] = useState<string>();

    const { width } = useViewport();

    const isMobile = width < 620;

    const classNames = mergeStyleSets({
        titleContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        contentContainer: {
            height: 'min(50vh, 400px)',
            paddingRight: 20,
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column-reverse', // reverses content, allows scrolling down
            gap: 8,
            scrollBehavior: 'smooth',
            '& H1': {
                fontSize: FontSizes.large,
                marginBottom: 0,
            },
            '& H2': {
                fontSize: FontSizes.mediumPlus,
                marginBottom: 0,
            },
        },
    });

    return (
        <Dialog
            {...props}
            dialogContentProps={{
                type: DialogType.largeHeader,
                styles: {
                    title: {
                        paddingRight: 24,
                    },
                },
                title: (
                    <div className={classNames.titleContainer}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: 2,
                                alignItems: 'center',
                            }}
                        >
                            <span>MAI</span>
                            <sup
                                style={{
                                    alignSelf: 'start',
                                    fontSize: '0.6em',
                                }}
                            >
                                BETA
                            </sup>
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: 4,
                            }}
                        >
                            {!!content &&
                                !isStreaming &&
                                !!props.onRegenerateClick && (
                                    <ActionButton
                                        text="Regenerate"
                                        onClick={props.onRegenerateClick}
                                        iconProps={{
                                            iconName: 'Sync',
                                        }}
                                    />
                                )}

                            <ActionButton
                                onClick={props.onDismiss}
                                iconProps={{
                                    iconName: 'Cancel',
                                }}
                            />
                        </div>
                    </div>
                ),
                closeButtonAriaLabel: 'Close',
            }}
            modalProps={{
                isBlocking: false,
                dragOptions: {
                    moveMenuItemText: 'Move',
                    closeMenuItemText: 'Close',
                    menu: ContextualMenu,
                    keepInBounds: true,
                },
                styles: {
                    main: !isMobile
                        ? {
                              maxWidth: '800px !important',
                              minWidth: '500px !important',
                          }
                        : undefined,
                    layer: {
                        zIndex: ZIndexes.Layer + 1, // Keep this modal on top of any panels, etc
                    },
                },
                isModeless: true,
                forceFocusInsideTrap: false, // Required, otherwise the context menus behind the modal won't open.
                topOffsetFixed: true,
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 8,
                }}
            >
                <div className={classNames.contentContainer}>
                    {allowChat &&
                        threadMessages
                            .sort(sorters.sequenceSorter)
                            .reverse()
                            .map((tm) => {
                                if (tm.type === 'Mai') {
                                    return (
                                        <div
                                            key={tm.id}
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                gap: 8,
                                                justifyContent: 'start',
                                                paddingBottom: 8,
                                            }}
                                        >
                                            <ChatBubble
                                                content={
                                                    <div
                                                        style={{ padding: 10 }}
                                                    >
                                                        {tm.isStreaming &&
                                                        !tm.contentMd ? (
                                                            <p className="cursor">
                                                                ⬤
                                                            </p>
                                                        ) : (
                                                            <Markdown
                                                                source={
                                                                    !tm.isStreaming
                                                                        ? tm.contentMd
                                                                        : tm.contentMd +
                                                                          ' ⬤'
                                                                }
                                                            />
                                                        )}
                                                    </div>
                                                }
                                                tailPosition="topLeft"
                                                persona={
                                                    <Persona
                                                        imageUrl={mai}
                                                        size={
                                                            PersonaSize.size32
                                                        }
                                                        hidePersonaDetails
                                                    />
                                                }
                                            />
                                        </div>
                                    );
                                } else {
                                    return (
                                        <div
                                            key={tm.id}
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                gap: 8,
                                                justifyContent: 'end',
                                                paddingBottom: 8,
                                            }}
                                        >
                                            <ChatBubble
                                                content={
                                                    <div
                                                        style={{ padding: 10 }}
                                                    >
                                                        {tm.contentMd}
                                                    </div>
                                                }
                                                tailPosition="topRight"
                                                persona={
                                                    <Persona
                                                        imageUrl={photoService.getImageUrl(
                                                            currentUserId
                                                        )}
                                                        text={
                                                            currentUserDisplayName
                                                        }
                                                        size={
                                                            PersonaSize.size32
                                                        }
                                                        hidePersonaDetails
                                                    />
                                                }
                                            />
                                        </div>
                                    );
                                }
                            })}

                    {(isLoading || !!content) && (
                        <ChatBubble
                            isGenerating={isLoading}
                            content={content}
                            tailPosition="topLeft"
                            persona={
                                <Persona
                                    imageUrl={mai}
                                    size={PersonaSize.size32}
                                    hidePersonaDetails
                                />
                            }
                        />
                    )}

                    {!!props.userPrompts?.length && (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: 8,
                                justifyContent: 'end',
                            }}
                        >
                            {props.userPrompts?.map((up) => {
                                return (
                                    <ChatBubble
                                        key={up.key}
                                        content={
                                            <div style={{ padding: 10 }}>
                                                {up.text}
                                            </div>
                                        }
                                        tailPosition="topRight"
                                        persona={
                                            <Persona
                                                imageUrl={photoService.getImageUrl(
                                                    currentUserId
                                                )}
                                                text={currentUserDisplayName}
                                                size={PersonaSize.size32}
                                                hidePersonaDetails
                                            />
                                        }
                                    />
                                );
                            })}
                        </div>
                    )}
                </div>

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        gap: 8,
                    }}
                >
                    {actions?.map((a) => {
                        return (
                            <DefaultButton
                                key={a.name}
                                text={a.name}
                                onClick={() => {
                                    a.action();
                                    props.onDismiss();
                                }}
                            />
                        );
                    })}
                </div>

                {allowChat && (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: 8,
                            justifyContent: 'end',
                        }}
                    >
                        <TextField
                            placeholder="Ask anything about the mission or the advance dashboard..."
                            multiline
                            rows={3}
                            onChange={(_ev, newValue) =>
                                setUserPrompt(newValue || '')
                            }
                            onKeyDown={(ev) => {
                                if (
                                    ev.key === 'Enter' &&
                                    !ev.shiftKey &&
                                    userPrompt &&
                                    !isStreaming
                                ) {
                                    ev.preventDefault(); // Prevent adding a new line
                                    sendThreadMessage(userPrompt);
                                    setUserPrompt('');
                                }
                            }}
                            value={userPrompt}
                            disabled={isStreaming}
                            styles={{ root: { flexGrow: 1 } }}
                        />
                        <PrimaryButton
                            text="Send"
                            disabled={!userPrompt || isStreaming}
                            onClick={() => {
                                if (userPrompt) {
                                    sendThreadMessage(userPrompt);
                                    setUserPrompt('');
                                }
                            }}
                        />
                    </div>
                )}

                {showMaiInfo && (
                    <MessageBar
                        styles={{
                            root: {
                                marginTop: 16,
                            },
                        }}
                        //onDismiss={() => setShowMaiInfo(false)}
                    >
                        <Text variant="tiny">
                            The content and suggestions provided by MAI are
                            generated through artificial intelligence
                            algorithms. While we strive for accuracy, the
                            recommendations may not be suitable for all
                            situations and may contain inaccuracies or errors.
                            Please use your discretion and seek human
                            consultation when making critical decisions based on
                            this content.
                        </Text>
                    </MessageBar>
                )}
            </div>
        </Dialog>
    );
}

export function useMaiThreadsChat() {
    const { startThreadStreaming, isStreaming, contentMd } = useMaiWebSockets();
    const { currentTenantId, currentFinancialYearCode, currentMissionId } =
        useStateContext();

    const [currentThreadId, setCurrentThreadId] = useState<string>();

    const [threadMessages, setThreadMessages] = useState<
        {
            type: 'User' | 'Mai';
            contentMd: string;
            id: string;
            isStreaming?: boolean;
            sequence: number;
        }[]
    >([]);

    useEffect(() => {
        if (currentThreadId) {
            setThreadMessages((t) => {
                const currentThread = currentThreadId
                    ? t.find((c) => c.id === currentThreadId)
                    : null;

                if (currentThread) {
                    currentThread.contentMd = contentMd;
                    currentThread.isStreaming = isStreaming;
                    return [
                        ...t.filter((c) => c.id !== currentThreadId),
                        currentThread,
                    ];
                } else {
                    return t;
                }
            });
        }
    }, [isStreaming, contentMd, currentThreadId]);

    const sendThreadMessage = (message: string) => {
        const maiThreadId = crypto.randomUUID();

        setThreadMessages((t) => {
            const lastSequence = Math.max(...t.map((c) => c.sequence), 0);

            return [
                ...t,
                {
                    id: crypto.randomUUID(),
                    type: 'User',
                    contentMd: message,
                    sequence: lastSequence + 1,
                },
                {
                    id: maiThreadId,
                    type: 'Mai',
                    contentMd: '',
                    isStreaming: true,
                    sequence: lastSequence + 2,
                },
            ];
        });
        setCurrentThreadId(maiThreadId);
        startThreadStreaming(
            message,
            currentTenantId || '',
            currentFinancialYearCode,
            currentMissionId
        );
    };

    return {
        sendThreadMessage,
        threadMessages,
    };
}
