import React, { memo } from 'react';
import { Icon, mergeStyleSets } from '@fluentui/react';
import { MeasureStatusColours } from '../Colours';
import { useThemes } from '../hooks/useThemes';

export const MeasureArrowIcon = memo(function MeasureArrowIcon(props: {
    arrowColour: 'GREEN' | 'YELLOW' | 'RED' | 'NONE' | null | undefined;
    arrowDirection: 'UP' | 'DOWN' | 'SAME' | 'NONE' | null | undefined;
    previousPeriod?: string | null;
    iconFontSize?: string | number;
    outlineArrow?: boolean;
}): JSX.Element {
    let statusColour: string;
    const { currentTheme } = useThemes();

    if (props.arrowColour === 'GREEN') {
        statusColour = MeasureStatusColours.onTarget;
    } else if (props.arrowColour === 'RED') {
        statusColour = MeasureStatusColours.offTarget;
    } else if (props.arrowColour === 'YELLOW') {
        statusColour = MeasureStatusColours.nearTarget;
    } else {
        statusColour = currentTheme.palette.neutralQuaternary;
    }

    const classNames = mergeStyleSets({
        icon: {
            color: statusColour,
            fontSize: props.iconFontSize,
        },
    });

    const iconName =
        props.arrowDirection?.toUpperCase() === 'UP'
            ? props.outlineArrow
                ? 'TriangleUp12'
                : 'StockUp'
            : props.arrowDirection?.toUpperCase() === 'DOWN'
              ? props.outlineArrow
                  ? 'TriangleDown12'
                  : 'StockDown'
              : 'StatusCircleInner';

    let arrowTitle: string | undefined =
        props.arrowColour !== 'NONE'
            ? [props.arrowColour, props.arrowDirection].join(' / ')
            : undefined;

    if (arrowTitle && props.previousPeriod && props.arrowDirection !== 'NONE') {
        arrowTitle += ` (Since ${props.previousPeriod})`;
    }

    return (
        <Icon
            iconName={iconName}
            className={classNames.icon}
            title={arrowTitle}
        />
    );
});
