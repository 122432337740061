import dayjs from 'dayjs';
import {
    ArrowDirection,
    Arrows,
    MeasureTypes,
    StatusTypes,
} from '../data/types';
import { MeasureSeriesNames } from '../data/extendedTypes';
import { useCallback } from 'react';

type ValueType = {
    asOfDate: string;
    values:
        | {
              decimalValue: number | null;
              seriesType: {
                  name: string | null;
              } | null;
          }[]
        | null;
};

export const useMeasureStatusCalc = (): {
    decimalAchieveMore: (T: number, A: number) => number;
    decimalAchieveLess: (T: number, A: number) => number;
    decimalAchieveExact: (
        T: number,
        A: number,
        greenRange: number,
        yellowRange: number
    ) => number;
    getYtd: (
        lastAsOf: ValueType | null,
        valueHistory: ValueType[],
        getPrevious?: boolean
    ) => {
        targetYtd: number | null;
        actualYtd: number | null;
        forecastYtd: number | null;
    };
    calcStatus: (
        T: number | null,
        A: number | null,
        m: {
            measureType: MeasureTypes;
            statusType: StatusTypes;
            greenRange: number;
            yellowRange: number;
            yellowStart: number;
            isStatusLimited: boolean;
        },
        usePrevious?: number | null | undefined
    ) => {
        statusValue: number | null;
        arrowColour: Arrows;
        arrowDirection: ArrowDirection;
    };
    isCalcSupported: (measureType: MeasureTypes) => boolean;
} => {
    const decimalAchieveMore = (T: number, A: number) => {
        if (T == 0) {
            return (A + 1) / (T + 1);
        } else if (T < 0) {
            return -(A / T) + 2;
        } else {
            return A / T;
        }
    };

    const decimalAchieveLess = (T: number, A: number) => {
        if (T == 0) {
            return -(A + 1) / (T + 1) + 2;
        } else if (T > 0) {
            return -(A / T) + 2;
        } else {
            return A / T;
        }
    };

    const decimalAchieveExact = (
        T: number,
        A: number,
        greenRange: number,
        yellowRange: number
    ) => {
        if (yellowRange < greenRange)
            throw 'Yellow range must be larger than Green Range';

        const dx = yellowRange - greenRange;
        if (dx == 0) {
            return T == A ? 1.0 : 0;
        }

        const dy = 0.1; // Assume yellow is at 90% therefore: 1 - 0.90
        const m1 = dy / dx;
        const c1 = 1 - m1 * (T - greenRange);

        const m2 = -dy / dx;
        const c2 = 1 - m2 * (T + greenRange);

        if (
            (A >= T - greenRange && A < T + greenRange) ||
            A == T + greenRange
        ) {
            return 1.0;
        } else if (A < T) {
            return Math.max(m1 * A + c1, 0);
        } else if (A > T) {
            return Math.max(m2 * A + c2, 0);
        } else {
            return 0;
        }
    };

    const isCalcSupported = useCallback(
        (measureType: MeasureTypes) =>
            measureType == MeasureTypes.Currency ||
            measureType == MeasureTypes.Numeric ||
            measureType == MeasureTypes.Percentage,
        []
    );

    const getYtd = useCallback(
        (
            lastAsOf: ValueType | null,
            valueHistory: ValueType[],
            usePrevious = false // Used to get the previous values to calc status
        ): {
            targetYtd: number | null;
            actualYtd: number | null;
            forecastYtd: number | null;
        } => {
            const previousAsOfs =
                lastAsOf && valueHistory
                    ? valueHistory.filter((ao) =>
                          dayjs(ao.asOfDate).isBefore(lastAsOf.asOfDate)
                      )
                    : [];

            const previousAndCurrentAsOfs = lastAsOf
                ? [...previousAsOfs, lastAsOf]
                : [...previousAsOfs];

            const getPreviousValues = (
                array: ValueType[],
                series: MeasureSeriesNames
            ): ({ decimalValue: number | null } | undefined)[] => {
                return array.flatMap((ao) =>
                    ao.values?.find((v) => v.seriesType?.name === series)
                );
            };

            const reducer = (
                sum: number,
                current?: { decimalValue: number | null } | null
            ) => sum + (current?.decimalValue || 0);

            const ytdArray = usePrevious
                ? previousAsOfs
                : previousAndCurrentAsOfs;

            const hasTargets =
                ytdArray.length &&
                ytdArray.some((y) =>
                    y.values?.some(
                        (v) => v.seriesType?.name === MeasureSeriesNames.Target
                    )
                );

            const hasActuals =
                ytdArray.length &&
                ytdArray.some((y) =>
                    y.values?.some(
                        (v) => v.seriesType?.name === MeasureSeriesNames.Actual
                    )
                );

            const hasForecasts =
                ytdArray.length &&
                ytdArray.some((y) =>
                    y.values?.some(
                        (v) =>
                            v.seriesType?.name ===
                            MeasureSeriesNames.PhasedForecast
                    )
                );

            return {
                targetYtd: hasTargets
                    ? getPreviousValues(
                          ytdArray,
                          MeasureSeriesNames.Target
                      ).reduce(reducer, 0)
                    : null,
                actualYtd: hasActuals
                    ? getPreviousValues(
                          ytdArray,
                          MeasureSeriesNames.Actual
                      ).reduce(reducer, 0)
                    : null,
                forecastYtd: hasForecasts
                    ? getPreviousValues(
                          ytdArray,
                          MeasureSeriesNames.PhasedForecast
                      ).reduce(reducer, 0)
                    : null,
            };
        },
        []
    );

    const calcStatus = useCallback(
        (
            T: number | null,
            A: number | null,
            m: {
                measureType: MeasureTypes;
                statusType: StatusTypes;
                greenRange: number;
                yellowRange: number;
                yellowStart: number;
                isStatusLimited: boolean;
            },
            prevStatus?: number | null | undefined
        ) => {
            if (T === null || A === null) {
                return {
                    statusValue: null,
                    arrowColour: Arrows.None,
                    arrowDirection: ArrowDirection.None,
                };
            }
            if (isCalcSupported(m.measureType)) {
                let statusValue: number;
                switch (m.statusType) {
                    default:
                    case StatusTypes.MoreThanTarget:
                        statusValue = decimalAchieveMore(T, A);
                        break;
                    case StatusTypes.LessThanTarget:
                        statusValue = decimalAchieveLess(T, A);
                        break;
                    case StatusTypes.TargetExact:
                        statusValue = decimalAchieveExact(
                            T,
                            A,
                            m.greenRange,
                            m.yellowRange
                        );
                }

                const arrowDirection =
                    prevStatus === null || prevStatus === undefined
                        ? ArrowDirection.Up
                        : prevStatus.toFixed(4) < statusValue.toFixed(4)
                          ? ArrowDirection.Up
                          : prevStatus.toFixed(4) > statusValue.toFixed(4)
                            ? ArrowDirection.Down
                            : ArrowDirection.Up;

                const arrowColour =
                    statusValue >= 1
                        ? Arrows.Green
                        : statusValue >= m.yellowStart
                          ? Arrows.Yellow
                          : Arrows.Red;

                if (m.isStatusLimited && statusValue > 1) {
                    statusValue = 1;
                }

                if (m.isStatusLimited && statusValue < 0) {
                    statusValue = 0;
                }

                return {
                    statusValue: statusValue,
                    arrowColour: arrowColour,
                    arrowDirection: arrowDirection,
                };
            } else {
                throw `MeasureType '${m.measureType}' not supported`;
            }
        },
        [isCalcSupported]
    );

    return {
        decimalAchieveMore,
        decimalAchieveLess,
        decimalAchieveExact,
        getYtd,
        calcStatus,
        isCalcSupported,
    };
};
