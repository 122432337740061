import * as React from 'react';
import { generatePath, useNavigate } from 'react-router';
import { Pivot, PivotItem } from '@fluentui/react';
import { useStateContext } from '../../../services/contextProvider';
import { paths } from '../../../services/navigation';
import { ReportNames } from '../ReportNames';
import { ReportTasks } from './ReportTasks';
import { ReportMeasures } from './ReportMeasures';
import { ReportAlignment } from './ReportAlignment';
import { ReportBuilder } from './ReportBuilder';

export function ReportsPivot(props: {
    reportName: ReportNames;
    onTaskClick: (missionId: string, taskId: string) => void;
    onMeasureClick: (missionId: string, measureId: string) => void;
}): JSX.Element {
    const { currentTenantCode, currentFinancialYearCode } = useStateContext();

    const navigate = useNavigate();

    const handleLinkClick = (item?: PivotItem): void => {
        const reportName = (item?.props.itemKey as ReportNames) || 'tasks';
        const pathPattern = paths.report;
        const href = generatePath(pathPattern, {
            tenantCode: currentTenantCode || '',
            financialYearCode: currentFinancialYearCode,
            reportName: reportName,
        });
        navigate(href, { replace: true });
    };

    return (
        <Pivot
            selectedKey={props.reportName || 'tasks'}
            onLinkClick={handleLinkClick}
        >
            <PivotItem itemKey="tasks" headerText="Tasks">
                <ReportTasks {...props} />
            </PivotItem>
            <PivotItem itemKey="measures" headerText="Measures of Success">
                <ReportMeasures {...props} />
            </PivotItem>
            <PivotItem itemKey="alignment" headerText="Alignment & Activity">
                <ReportAlignment />
            </PivotItem>
            <PivotItem
                itemKey="report-builder"
                headerText="Report Export Builder"
            >
                <ReportBuilder />
            </PivotItem>
        </Pivot>
    );
}
