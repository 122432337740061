import * as React from 'react';
import {
    Pivot,
    PivotItem,
    Stack,
    DefaultButton,
    MessageBar,
    MessageBarType,
    ContextualMenuItemType,
    IContextualMenuItem,
    Separator,
} from '@fluentui/react';
import orderBy from 'lodash/orderBy';
import { FinancialYearList } from './components/FinancialYearList';
import { DivisionList } from './components/DivisionList';
import { DivisionEditPanel } from './components/DivisionEditPanel';
import {
    DivisionQl,
    FinancialYearQl,
    GetUsersQuery,
    MissionQl,
    ResourceQl,
    TagQl,
    TaskCategoryQl,
    TeamQl,
    useCacheResetMutation,
    useGetContributorsQuery,
    useGetFinancialYearsQuery,
    useGetMissionSearchQuery,
    useGetRightsQuery,
    useGetUsersQuery,
    useRecalculateAllMeasuresMutation,
    useRecalculateAllTasksMutation,
    UserQl,
} from '../../data/types';
import { FinancialYearEditPanel } from './components/FinancialYearEditPanel';
import { AreaContainer } from '../MissionBuilder/components/AreaContainer';
import { MissionList } from './components/MissionList';
import EditMissionPanel from '../../components/EditMissionPanel';
import NewMissionPanel from '../../components/NewMissionPanel';
import { TeamList } from './components/TeamList';
import EditTeamPanel from '../../components/EditTeamPanel';
import {
    useDispatchContext,
    useStateContext,
} from '../../services/contextProvider';
import { UserDetailList } from './components/UserDetailList';
import { UserEditPanel } from './components/UserEditPanel';
import { photoService } from '../../services/photo.service';
import { ResourceList } from './components/ResourceList';
import { ResourceEditPanel } from './components/ResourceEditPanel';
import { generatePath, useNavigate, useParams } from 'react-router';
import { TaskCategoryEditPanel } from './components/TaskCategoryEditPanel';
import { TaskCategoryList } from './components/TaskCategoryList';
import { AdvanceCard } from '../../components/AdvanceCard';
import { UserAccessPanel } from './components/UserAccessPanel';
import { TeamAccessPanel } from './components/TeamAccessPanel';
import { useActiveView } from '../../hooks/useActiveView';
import { TagList } from './components/TagList';
import { TagEditPanel } from './components/TagEditPanel';
import { CopyStructurePanel } from './CopyStructurePanel';
import { JsonUploadPanel } from './JsonUploadPanel';
import { useCallback, useEffect, useState } from 'react';
import { graphService } from '../../services/graph.service';
import dayjs from 'dayjs';
import { ContributorPanel } from './components/ContributorPanel';
import {
    DefaultUserFilters,
    UserFilterBar,
    UserFilters,
} from './components/UserFilterBar';
import { useUserFilters } from './hooks/useUserFilters';
import { ExtractQueryArrayType } from '../../data/extendedTypes';

export function Home(): JSX.Element {
    const {
        currentTenantCode,
        currentTenantId,
        currentRoles,
        userTenants,
        currentUserId,
        currentMissionId,
        currentFinancialYearCode,
    } = useStateContext();
    const dispatch = useDispatchContext();

    const navigate = useNavigate();
    const { sectionKey } = useParams();

    useActiveView('Setup', 'Setup');

    type SectionKeys =
        | 'financial-years'
        | 'divisions'
        | 'teams'
        | 'missions'
        | 'users'
        | 'resources'
        | 'task-categories';

    const { data: fyData } = useGetFinancialYearsQuery({
        skip: !currentTenantId,
        variables: {
            tenantId: currentTenantId || '',
        },
    });

    const [fyCodeFilter, setFyCodeFilter] = useState<string | null>(null);

    const setDefaultMissionAsync = useCallback(
        async (
            userId: string,
            tenantId: string,
            tenantCode: string,
            financialYearCode: string | null
        ) => {
            const defaultMissionData =
                await graphService.getDefaultMissionAsync(
                    userId,
                    tenantId,
                    financialYearCode
                );

            const defaultMission = defaultMissionData?.default_mission;
            const defaultContributor = defaultMissionData?.default_contributor;

            if (defaultMission) {
                const defaultTeam =
                    defaultMission?.team ||
                    defaultMission?.leaderOfTeams.find((x) => x !== undefined);

                dispatch({
                    type: 'SetupContext',
                    payload: {
                        currentTenantCode: tenantCode,
                        currentMissionId: defaultMission.id,
                        currentFinancialYearCode:
                            defaultTeam?.division?.financialYear?.code,
                        currentTeamCode: defaultTeam?.code || null,
                        themePrimaryColourHex: undefined,
                    },
                });
            } else if (defaultContributor) {
                dispatch({
                    type: 'SetupContext',
                    payload: {
                        currentTenantCode: tenantCode,
                        currentMissionId: undefined,
                        currentContributorId: defaultContributor?.id,
                        currentFinancialYearCode:
                            defaultContributor.financialYear?.code ||
                            financialYearCode,
                        currentTeamCode: defaultContributor.team?.code || null,
                        themePrimaryColourHex: undefined,
                    },
                });
            }
        },
        [dispatch]
    );

    useEffect(() => {
        if (
            !currentMissionId &&
            currentUserId &&
            currentTenantId &&
            currentTenantCode
        ) {
            setDefaultMissionAsync(
                currentUserId,
                currentTenantId,
                currentTenantCode,
                currentFinancialYearCode ?? null
            );
        }
    }, [
        currentUserId,
        currentMissionId,
        currentTenantId,
        currentTenantCode,
        currentFinancialYearCode,
        setDefaultMissionAsync,
    ]);

    const financialYearFilterOptions: IContextualMenuItem[] = orderBy(
        fyData?.financialYears || [],
        (fy) => dayjs(fy.startDate).toDate(),
        'desc'
    ).map((fy) => {
        return {
            key: `filter-fy-${fy.code}`,
            text:
                fy.code !== fy.name ? `${fy.code} - ${fy.name}` : fy.code || '',
            canCheck: true,
            checked: !!fyCodeFilter && fyCodeFilter === fy?.code,
            onClick: () => {
                setFyCodeFilter(fy?.code ?? null);
            },
            iconProps: {
                iconName: 'Calendar',
            },
        };
    });

    const hasAccess = currentRoles.some((r) =>
        ['GlobalAdmin', 'ClientAdmin'].includes(r)
    );

    if (!hasAccess) {
        return (
            <MessageBar
                messageBarType={MessageBarType.error}
                isMultiline={true}
                styles={{
                    root: {
                        width: 'auto',
                        margin: 16,
                    },
                }}
            >
                <span>You do not have access to the setup pages.</span>
            </MessageBar>
        );
    }

    const handleLinkClick = (item?: PivotItem): void => {
        const sectionKey =
            (item?.props.itemKey as SectionKeys) || 'financial-years';

        const pathPattern = '/:tenantCode/setup/:sectionKey';

        const href = generatePath(pathPattern, {
            tenantCode: currentTenantCode || '',
            sectionKey: sectionKey,
        });

        navigate(href, { replace: true });
    };

    if (fyCodeFilter) {
        financialYearFilterOptions.push(
            ...[
                {
                    key: 'clear-divider',
                    itemType: ContextualMenuItemType.Divider,
                },
                {
                    key: `filter-fy-none`,
                    text: 'Clear',
                    iconProps: {
                        iconName: 'Cancel',
                    },
                    onClick: () => {
                        setFyCodeFilter(null);
                    },
                },
            ]
        );
    }

    return (
        <AreaContainer
            title="Setup"
            isCollapsable={false}
            subTitle={
                userTenants.find((ut) => ut.tenant.code === currentTenantCode)
                    ?.tenant.description
            }
            commandBarButtons={[
                {
                    key: 'FYFilter',
                    text: fyCodeFilter ? fyCodeFilter : 'FY',
                    iconProps: {
                        iconName: fyCodeFilter ? 'FilterSolid' : 'Filter',
                    },
                    subMenuProps: {
                        items: financialYearFilterOptions,
                    },
                },
            ]}
        >
            <div style={{ padding: 8 }}>
                <AdvanceCard>
                    <AdvanceCard.Item fill>
                        <Pivot
                            selectedKey={sectionKey || 'financial-years'}
                            onLinkClick={handleLinkClick}
                            styles={{
                                link: {
                                    marginLeft: 8,
                                    marginBottom: 8,
                                },
                            }}
                            aria-label="Setup Tabs"
                            overflowBehavior={'menu'}
                            overflowAriaLabel="more items"
                        >
                            <PivotItem
                                itemKey="financial-years"
                                headerText="Financial Years"
                            >
                                <SetupFyPivot />
                            </PivotItem>
                            <PivotItem
                                itemKey="divisions"
                                headerText="Divisions"
                            >
                                <SetupDivisionPivot
                                    fyCodeFilter={fyCodeFilter}
                                />
                            </PivotItem>

                            <PivotItem itemKey="teams" headerText="Teams">
                                <SetupTeamsPivot fyCodeFilter={fyCodeFilter} />
                            </PivotItem>

                            <PivotItem itemKey="missions" headerText="Missions">
                                <SetupMissionsPivot
                                    fyCodeFilter={fyCodeFilter}
                                />
                            </PivotItem>
                            <PivotItem itemKey="users" headerText="Users">
                                <SetupUsersPivot fyCodeFilter={fyCodeFilter} />
                            </PivotItem>

                            <PivotItem
                                itemKey="resources"
                                headerText="Resources"
                            >
                                <SetupResourcesPivot
                                    fyCodeFilter={fyCodeFilter}
                                />
                            </PivotItem>

                            <PivotItem
                                itemKey="task-categories"
                                headerText="Task Categories"
                            >
                                <SetupCategoriesPivot
                                    fyCodeFilter={fyCodeFilter}
                                />
                            </PivotItem>
                            <PivotItem itemKey="tags" headerText="Tags">
                                <SetupTagsPivot fyCodeFilter={fyCodeFilter} />
                            </PivotItem>
                            <PivotItem itemKey="advanced" headerText="Advanced">
                                <SetupAdvancedPivot
                                    fyCodeFilter={fyCodeFilter}
                                />
                            </PivotItem>
                        </Pivot>
                    </AdvanceCard.Item>
                </AdvanceCard>
            </div>
        </AreaContainer>
    );
}

function SetupFyPivot(): JSX.Element {
    const [selectedFinancialYear, setSelectedFinancialYear] =
        React.useState<FinancialYearQl | null>(null);

    const [copyStructureFinancialYearCode, setCopyStructureFinancialYearCode] =
        React.useState<string | null>(null);

    return (
        <React.Fragment>
            <FinancialYearEditPanel
                key={`edit${selectedFinancialYear?.id}`}
                showPanel={!!selectedFinancialYear}
                financialYear={selectedFinancialYear}
                onCancel={(): void => setSelectedFinancialYear(null)}
                onSave={(): void => setSelectedFinancialYear(null)}
            />
            <CopyStructurePanel
                showPanel={!!copyStructureFinancialYearCode}
                financialYearCode={copyStructureFinancialYearCode}
                onDismiss={() => setCopyStructureFinancialYearCode(null)}
            />

            <Stack tokens={{ childrenGap: 16 }}>
                <Stack.Item
                    align="end"
                    styles={{
                        root: {
                            paddingRight: 16,
                        },
                    }}
                >
                    <DefaultButton
                        iconProps={{ iconName: 'Add' }}
                        text="FY"
                        onClick={(): void => {
                            setSelectedFinancialYear({} as FinancialYearQl);
                        }}
                    />
                </Stack.Item>

                <FinancialYearList
                    onItemEdit={(financialYear: FinancialYearQl): void =>
                        setSelectedFinancialYear(financialYear)
                    }
                    onCopyStructure={(financialYear: FinancialYearQl) =>
                        setCopyStructureFinancialYearCode(financialYear.code)
                    }
                />
            </Stack>
        </React.Fragment>
    );
}

function SetupDivisionPivot(props: {
    fyCodeFilter: string | null;
}): JSX.Element {
    const [selectedDivision, setSelectedDivision] =
        React.useState<DivisionQl | null>(null);

    const [jsonUploadDivisionId, setJsonUploadDivisionId] = React.useState<
        string | null
    >(null);

    return (
        <React.Fragment>
            <DivisionEditPanel
                key={`edit${selectedDivision?.id}`}
                showPanel={!!selectedDivision}
                division={selectedDivision}
                onCancel={(): void => setSelectedDivision(null)}
                onSave={(): void => setSelectedDivision(null)}
            />

            <JsonUploadPanel
                showPanel={!!jsonUploadDivisionId}
                divisionId={jsonUploadDivisionId}
                onDismiss={() => setJsonUploadDivisionId(null)}
            />

            <Stack tokens={{ childrenGap: 16 }}>
                <Stack.Item
                    align="end"
                    styles={{
                        root: {
                            paddingRight: 16,
                        },
                    }}
                >
                    <DefaultButton
                        iconProps={{ iconName: 'Add' }}
                        text="Division"
                        onClick={(): void => {
                            setSelectedDivision({} as DivisionQl);
                        }}
                    />
                </Stack.Item>

                <DivisionList
                    fyCodeFilter={props.fyCodeFilter}
                    onItemEdit={(division: DivisionQl): void =>
                        setSelectedDivision(division)
                    }
                    onJsonUpload={(division: DivisionQl) =>
                        setJsonUploadDivisionId(division.id)
                    }
                />
            </Stack>
        </React.Fragment>
    );
}

function SetupTeamsPivot(props: { fyCodeFilter: string | null }): JSX.Element {
    const { currentTenantId } = useStateContext();

    const { data: userData } = useGetUsersQuery({
        variables: {
            tenantId: currentTenantId || null,
            searchText: '',
            useCache: false,
        },
    });

    const [selectedTeam, setSelectedTeam] = React.useState<TeamQl | null>(null);
    const [showTeamAccessPanel, setShowTeamAccessPanel] =
        React.useState<TeamQl | null>(null);

    return (
        <React.Fragment>
            <EditTeamPanel
                key={`edit${selectedTeam?.id}`}
                showPanel={!!selectedTeam}
                team={selectedTeam || ({} as TeamQl)}
                onCancel={(): void => setSelectedTeam(null)}
                onSave={(): void => setSelectedTeam(null)}
                fyCodeFilter={props.fyCodeFilter}
            />
            <TeamAccessPanel
                showPanel={showTeamAccessPanel !== null}
                showGlobalRights={false}
                team={showTeamAccessPanel}
                users={userData?.userSearch || []}
                onUpdate={(): void => {
                    setShowTeamAccessPanel(null);
                }}
                onCancel={(): void => {
                    setShowTeamAccessPanel(null);
                }}
            />

            <Stack tokens={{ childrenGap: 16 }}>
                <Stack.Item
                    align="end"
                    styles={{
                        root: {
                            paddingRight: 16,
                        },
                    }}
                >
                    <DefaultButton
                        iconProps={{ iconName: 'Add' }}
                        text="Team"
                        onClick={(): void => {
                            setSelectedTeam({
                                id: null,
                            } as TeamQl);
                        }}
                    />
                </Stack.Item>

                <TeamList
                    fyCodeFilter={props.fyCodeFilter}
                    onItemEdit={(team: TeamQl): void => setSelectedTeam(team)}
                    onAccessEdit={(team: TeamQl): void =>
                        setShowTeamAccessPanel(team)
                    }
                />
            </Stack>
        </React.Fragment>
    );
}

function SetupMissionsPivot(props: {
    fyCodeFilter: string | null;
}): JSX.Element {
    const [selectedMission, setSelectedMission] =
        React.useState<MissionQl | null>(null);
    return (
        <React.Fragment>
            <NewMissionPanel
                onSave={(): void => {
                    setSelectedMission(null);
                }}
                onCancel={(): void => {
                    setSelectedMission(null);
                }}
                showPanel={!!selectedMission && !selectedMission.id}
                financialYearCode={props.fyCodeFilter}
                teamId={null}
            />
            <EditMissionPanel
                key={`edit${selectedMission?.id}`}
                showPanel={!!selectedMission?.id}
                missionId={selectedMission?.id || undefined}
                onCancel={(): void => setSelectedMission(null)}
                onSave={(): void => setSelectedMission(null)}
            />
            <Stack tokens={{ childrenGap: 16 }}>
                <Stack.Item
                    align="end"
                    styles={{
                        root: {
                            paddingRight: 16,
                        },
                    }}
                >
                    <DefaultButton
                        iconProps={{ iconName: 'Add' }}
                        text="Mission"
                        onClick={(): void => {
                            setSelectedMission({
                                id: null,
                            } as MissionQl);
                        }}
                    />
                </Stack.Item>

                <MissionList
                    fyCodeFilter={props.fyCodeFilter}
                    onItemEdit={(mission: MissionQl): void =>
                        setSelectedMission(mission)
                    }
                />
            </Stack>
        </React.Fragment>
    );
}

function SetupUsersPivot(props: { fyCodeFilter: string | null }): JSX.Element {
    const { currentTenantId } = useStateContext();

    const [userFilters, setUserFilters] =
        React.useState<UserFilters>(DefaultUserFilters);

    const {
        data: userData,
        loading: usersLoading,
        refetch: usersRefetch,
    } = useGetUsersQuery({
        skip: !currentTenantId,
        variables: {
            tenantId: currentTenantId || null,
            searchText: userFilters?.keyword || '',
            useCache: false,
        },
    });

    const { data: missionData } = useGetMissionSearchQuery({
        skip: !currentTenantId || !props.fyCodeFilter,
        variables: {
            tenantId: currentTenantId || '',
            searchText: '',
            isWritable: false,
            isImportable: false,
            includeInactive: true,
            includeDeleted: false,
            financialYearCode: props.fyCodeFilter || '',
            divisionId: null,
        },
    });

    const { data: contributorData } = useGetContributorsQuery({
        skip: !currentTenantId || !props.fyCodeFilter,
        variables: {
            tenantId: currentTenantId || '',
            financialYearCode: props.fyCodeFilter || '',
            teamCode: null,
        },
    });

    const { data: rightsData } = useGetRightsQuery({
        skip: !currentTenantId || !props.fyCodeFilter,
        variables: {
            userId: null,
            divisionId: null,
            teamId: null,
            missionId: null,
            tenantId: currentTenantId || '',
            financialYearCode: props.fyCodeFilter || 'NOTSET',
        },
    });

    const [activePanel, setActivePanel] = React.useState<
        | 'User'
        | 'UserAccess'
        | 'NewMission'
        | 'EditMission'
        | 'Contributor'
        | null
    >(null);

    const [filteredUsers, setFilteredUsers] = React.useState<
        ExtractQueryArrayType<GetUsersQuery, ['userSearch']>[]
    >([]);
    const [selectedUser, setSelectedUser] = React.useState<UserQl | null>(null);
    const [selectedContributorId, setSelectedContributorId] = React.useState<
        string | null
    >(null);
    const [selectedMissionId, setSelectedMissionId] = React.useState<
        string | null
    >(null);

    const selectUser = (userId: string) => {
        setSelectedUser(
            (userData?.userSearch || []).find((u) => u.id === userId) as UserQl
        );
    };

    const missions =
        props.fyCodeFilter && missionData ? missionData?.missions : null;

    const contributors =
        props.fyCodeFilter && contributorData
            ? contributorData?.contributors
            : null;

    const rights = props.fyCodeFilter && rightsData ? rightsData?.rights : null;

    const { getFilteredUsers } = useUserFilters(
        userData?.userSearch || null,
        missions,
        contributors,
        rights
    );

    useEffect(() => {
        setFilteredUsers(getFilteredUsers(userFilters) || []);
    }, [userFilters, getFilteredUsers, userData?.userSearch]);

    const closePanel = () => {
        setActivePanel(null);
        setSelectedUser(null);
        setSelectedContributorId(null);
    };

    return (
        <React.Fragment>
            <NewMissionPanel
                onSave={closePanel}
                onCancel={closePanel}
                showPanel={activePanel === 'NewMission'}
                teamId={null}
                financialYearCode={props.fyCodeFilter}
                user={
                    selectedUser?.id
                        ? {
                              userId: selectedUser.id,
                              displayName:
                                  selectedUser.displayName || selectedUser.id,
                          }
                        : undefined
                }
            />
            <EditMissionPanel
                onSave={closePanel}
                onCancel={closePanel}
                missionId={selectedMissionId || undefined}
                showPanel={activePanel === 'EditMission'}
            />
            <ContributorPanel
                onSave={closePanel}
                onCancel={closePanel}
                showPanel={activePanel === 'Contributor'}
                financialYearCode={props.fyCodeFilter}
                contributorId={selectedContributorId}
                user={
                    selectedUser?.id
                        ? {
                              userId: selectedUser.id,
                              displayName:
                                  selectedUser.displayName || selectedUser.id,
                          }
                        : undefined
                }
            />
            <Stack>
                <UserFilterBar
                    unfilteredUsers={userData?.userSearch || []}
                    onFiltersChanged={setUserFilters}
                    filters={userFilters}
                    missions={missions}
                    contributors={contributors}
                    rights={rights}
                    showFyFilters={!!props.fyCodeFilter}
                />
                <UserDetailList
                    users={filteredUsers || []}
                    missions={missions}
                    contributors={contributors}
                    rights={rights}
                    isDataLoaded={!usersLoading}
                    fyCodeFilter={props.fyCodeFilter}
                    hideDashboards={true}
                    onItemEdit={(selectedId: string): void => {
                        selectUser(selectedId);
                        setActivePanel('User');
                    }}
                    onAccessEdit={(selectedId: string): void => {
                        selectUser(selectedId);
                        setActivePanel('UserAccess');
                    }}
                    onSetupMissionClick={(
                        selectedId: string,
                        missionId: string | null
                    ): void => {
                        selectUser(selectedId);
                        setSelectedMissionId(missionId || null);
                        setActivePanel(
                            missionId ? 'EditMission' : 'NewMission'
                        );
                    }}
                    onSetupContributorClick={(
                        selectedId: string,
                        contributorId: string | null
                    ): void => {
                        selectUser(selectedId);
                        setSelectedContributorId(contributorId);
                        setActivePanel('Contributor');
                    }}
                />
            </Stack>
            <UserEditPanel
                onUpdate={closePanel}
                onCancel={closePanel}
                showPanel={activePanel === 'User'}
                showGlobalRights={false}
                user={selectedUser}
                key={`EditUserPanel_${selectedUser?.id || 'New'}`}
                onCreate={(): void => {
                    closePanel();
                    usersRefetch();
                }}
                onDelete={(): void => {
                    closePanel();
                    usersRefetch();
                }}
                onPhotoChanged={(): void => {
                    photoService.refreshImageUrl(selectedUser?.id);
                }}
            />
            <UserAccessPanel
                onUpdate={closePanel}
                onCancel={closePanel}
                showPanel={activePanel === 'UserAccess'}
                showGlobalRights={false}
                user={selectedUser}
                key={`AccessUserPanel_${selectedUser?.id || 'New'}`}
                fyCodeFilter={props.fyCodeFilter}
            />
        </React.Fragment>
    );
}

function SetupResourcesPivot(props: {
    fyCodeFilter: string | null;
}): JSX.Element {
    const [selectedResource, setSelectedResource] =
        React.useState<ResourceQl | null>(null);

    return (
        <React.Fragment>
            <Stack tokens={{ childrenGap: 16 }}>
                <Stack.Item
                    align="end"
                    styles={{
                        root: {
                            paddingRight: 16,
                        },
                    }}
                >
                    <DefaultButton
                        iconProps={{ iconName: 'Add' }}
                        text="Resource"
                        onClick={(): void => {
                            setSelectedResource({
                                id: null,
                            } as ResourceQl);
                        }}
                    />
                </Stack.Item>
                <ResourceList
                    fyCodeFilter={props.fyCodeFilter}
                    onItemEdit={(resource: ResourceQl): void =>
                        setSelectedResource(resource)
                    }
                />
            </Stack>
            <ResourceEditPanel
                fyCodeFilter={props.fyCodeFilter}
                showPanel={selectedResource !== null}
                resource={selectedResource}
                key={`EditResourcePanel_${selectedResource?.id || 'New'}`}
                onCancel={(): void => setSelectedResource(null)}
                onSave={(): void => setSelectedResource(null)}
            />
        </React.Fragment>
    );
}

function SetupCategoriesPivot(props: {
    fyCodeFilter: string | null;
}): JSX.Element {
    const [selectedCategory, setSelectedCategory] =
        React.useState<TaskCategoryQl | null>(null);

    return (
        <React.Fragment>
            <Stack tokens={{ childrenGap: 16 }}>
                <Stack.Item
                    align="end"
                    styles={{
                        root: {
                            paddingRight: 16,
                        },
                    }}
                >
                    <DefaultButton
                        iconProps={{ iconName: 'Add' }}
                        text="Category"
                        onClick={(): void => {
                            setSelectedCategory({
                                id: null,
                            } as TaskCategoryQl);
                        }}
                    />
                </Stack.Item>
                <TaskCategoryList
                    onItemEdit={(category: TaskCategoryQl): void =>
                        setSelectedCategory(category)
                    }
                    fyCodeFilter={props.fyCodeFilter}
                />
            </Stack>
            <TaskCategoryEditPanel
                showPanel={selectedCategory !== null}
                category={selectedCategory}
                key={`EditCategoryPanel_${selectedCategory?.id || 'New'}`}
                onCancel={(): void => setSelectedCategory(null)}
                onSave={(): void => setSelectedCategory(null)}
            />
        </React.Fragment>
    );
}

function SetupTagsPivot(props: { fyCodeFilter: string | null }): JSX.Element {
    const [selectedTag, setSelectedTag] = React.useState<TagQl | null>(null);

    return (
        <React.Fragment>
            <Stack tokens={{ childrenGap: 16 }}>
                <Stack.Item
                    align="end"
                    styles={{
                        root: {
                            paddingRight: 16,
                        },
                    }}
                >
                    <DefaultButton
                        iconProps={{ iconName: 'Add' }}
                        text="Tag"
                        onClick={(): void => {
                            setSelectedTag({
                                id: null,
                            } as TagQl);
                        }}
                    />
                </Stack.Item>
                <TagList
                    onItemEdit={(tag: TagQl): void => setSelectedTag(tag)}
                    fyCodeFilter={props.fyCodeFilter}
                />
            </Stack>
            <TagEditPanel
                showPanel={selectedTag !== null}
                tag={selectedTag}
                key={`EditCategoryPanel_${selectedTag?.id || 'New'}`}
                onCancel={(): void => setSelectedTag(null)}
                onSave={(): void => setSelectedTag(null)}
            />
        </React.Fragment>
    );
}

function SetupAdvancedPivot(props: {
    fyCodeFilter: string | null;
}): JSX.Element {
    const { currentTenantId } = useStateContext();

    const [cacheResetMutation, { data: resetData, loading: resettingCache }] =
        useCacheResetMutation({});

    const [
        measureRecalculateAllMutation,
        { data: measureCalcData, loading: recalculatingMeasures },
    ] = useRecalculateAllMeasuresMutation({
        variables: {
            tenantId: currentTenantId || '',
            financialYearCode: props.fyCodeFilter,
        },
    });

    const [
        taskRecalculateAllMutation,
        { data: taskCalcData, loading: recalculatingTasks },
    ] = useRecalculateAllTasksMutation({
        variables: {
            tenantId: currentTenantId || '',
            financialYearCode: props.fyCodeFilter,
        },
    });

    return (
        <React.Fragment>
            <Stack
                tokens={{ childrenGap: 16 }}
                style={{
                    marginLeft: '12px',
                }}
            >
                <div>
                    <h4>Caching</h4>
                    <p>
                        The following data will be refreshed across all tenants
                        on this instance:
                    </p>
                    <ul>
                        <li>GraphQL</li>
                        <li>WebHooks</li>
                    </ul>
                </div>
                <Stack.Item align="start">
                    <DefaultButton
                        iconProps={{ iconName: 'Refresh' }}
                        text="Clear Cache"
                        onClick={async (): Promise<void> => {
                            await cacheResetMutation();
                        }}
                    />
                </Stack.Item>

                {resettingCache && (
                    <MessageBar messageBarType={MessageBarType.info}>
                        Clearing cache!
                    </MessageBar>
                )}
                {resetData && (
                    <MessageBar messageBarType={MessageBarType.success}>
                        Cache reset!
                    </MessageBar>
                )}

                <Separator />

                <div>
                    <h4>Recalculate Measure Values</h4>
                    <p>
                        Recalulate the measure values following a back-end data
                        change.
                    </p>
                    <p>Selected FY: {props.fyCodeFilter || 'All'}</p>
                </div>

                <Stack.Item align="start">
                    <DefaultButton
                        disabled={!currentTenantId || recalculatingMeasures}
                        iconProps={{ iconName: 'SaveAll' }}
                        text="Recalculate"
                        onClick={async (): Promise<void> => {
                            await measureRecalculateAllMutation();
                        }}
                    />
                </Stack.Item>

                <div>
                    <h4>Recalculate Task Percentages</h4>
                    <p>
                        Recalulate the task percentages following a back-end
                        data change.
                    </p>
                    <p>Selected FY: {props.fyCodeFilter || 'All'}</p>
                </div>

                <Stack.Item align="start">
                    <DefaultButton
                        disabled={!currentTenantId || recalculatingTasks}
                        iconProps={{ iconName: 'SaveAll' }}
                        text="Recalculate"
                        onClick={async (): Promise<void> => {
                            await taskRecalculateAllMutation();
                        }}
                    />
                </Stack.Item>

                {(recalculatingMeasures || recalculatingTasks) && (
                    <MessageBar messageBarType={MessageBarType.info}>
                        Recalculating...
                    </MessageBar>
                )}

                {measureCalcData && (
                    <MessageBar messageBarType={MessageBarType.success}>
                        {`${measureCalcData.measureRecalculateAll.length} Measures Recalculated!`}
                    </MessageBar>
                )}

                {taskCalcData && (
                    <MessageBar messageBarType={MessageBarType.success}>
                        {`${taskCalcData.taskRecalculateAll.length} Task Recalculated!`}
                    </MessageBar>
                )}
            </Stack>
        </React.Fragment>
    );
}
