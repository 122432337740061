import { PanelType } from '@fluentui/react';
import React, { useCallback } from 'react';
import { EditPanel } from '../../../components/shared/EditPanel';
import {
    GetStatusReportQuery,
    StatusReport,
    StatusReportFact,
    useUpdateStatusReportMutation,
} from '../../../data/types';
import { StatusReportFactPicker } from './StatusReportFactPicker';
import orderBy from 'lodash/orderBy';

export function StatusReportAddFactPanel(props: {
    utcDataDate: string | null;
    utcCompletedDate: string | null;
    tenantId: string;
    missionId: string;
    statusReport: GetStatusReportQuery['statusReport'];
    showPanel: boolean;
    onDismiss: () => void;
    selectedFacts: {
        id: string | null;
        taskId: string | null;
        measureId: string | null;
        factText: string | null;
    }[];
}): JSX.Element {
    const [facts, setFacts] = React.useState<StatusReportFact[]>([]);
    const [hasSaved, setHasSaved] = React.useState(false);
    const canAddTaskFacts =
        props.statusReport?.mission?.team?.division?.canAddTaskFacts;

    const onFactsChanged = useCallback(
        (
            measures: { id: string; missionSequence?: number }[],
            tasks: { id: string; missionSequence?: number }[],
            customFactTexts: { id: string | null; text: string | null }[]
        ) => {
            const newFact: StatusReportFact = {
                id: null,
                measureId: null,
                taskId: null,
                factText: null,
                soWhatText: null,
                insightText: null,
                actionText: null,
                sequence: 0,
                isIncluded: true,
                actions: [],
                version: '',
            };

            const measureFacts = orderBy(measures, 'missionSequence').map(
                (m) => {
                    return {
                        ...newFact,
                        measureId: m.id,
                    };
                }
            );

            const taskFacts = orderBy(tasks, 'missionSequence').map((t) => {
                return {
                    ...newFact,
                    taskId: t.id,
                };
            });

            const customFacts = customFactTexts
                .filter((f) => f.id || f.text)
                .map((f) => {
                    return {
                        ...newFact,
                        id: f.id,
                        factText: f.text,
                    };
                });

            const allFacts = [...measureFacts, ...taskFacts, ...customFacts];
            allFacts.forEach((f, index) => (f.sequence = index));

            setFacts(allFacts);
        },
        []
    );

    const [updateStatusReport, { loading: isAdding, error: saveError }] =
        useUpdateStatusReportMutation();

    const updateFacts = async () => {
        if (!props.statusReport?.id) {
            return;
        }

        const r = props.statusReport;

        const updatedFacts = orderBy(r.facts, 'sequence').map((f) => {
            return {
                id: f.id,
                measureId: f.measureId,
                taskId: f.taskId,
                actionText: f.actionText,
                soWhatText: f.soWhatText,
                factText: f.factText,
                insightText: f.insightText,
                isIncluded: false,
                sequence: 999, // Resequence at the end
                version: f.version,
                actions: [],
            };
        });

        facts.forEach((f) => {
            if (f.measureId) {
                const existing = updatedFacts.find(
                    (uf) => uf.measureId === f.measureId
                );
                if (existing) {
                    existing.isIncluded = true;
                } else {
                    updatedFacts.push({
                        id: null,
                        measureId: f.measureId,
                        taskId: null,
                        factText: null,
                        soWhatText: '',
                        insightText: '',
                        actionText: '',
                        sequence: 999,
                        isIncluded: true,
                        version: '',
                        actions: [],
                    });
                }
            } else if (f.taskId) {
                const existing = updatedFacts.find(
                    (uf) => uf.taskId === f.taskId
                );
                if (existing) {
                    existing.isIncluded = true;
                } else {
                    updatedFacts.push({
                        id: null,
                        measureId: null,
                        taskId: f.taskId,
                        factText: null,
                        soWhatText: '',
                        insightText: '',
                        actionText: '',
                        sequence: 999,
                        isIncluded: true,
                        version: '',
                        actions: [],
                    });
                }
            } else {
                const existing = updatedFacts.find(
                    (uf) => f.id && uf.id === f.id
                );
                if (existing) {
                    // Don't touch existing for now.
                    //existing.factText = f.factText;
                    existing.isIncluded = true;
                } else if (f.factText) {
                    updatedFacts.push({
                        id: null,
                        measureId: null,
                        taskId: null,
                        factText: f.factText,
                        soWhatText: '',
                        insightText: '',
                        actionText: '',
                        sequence: 999,
                        isIncluded: true,
                        version: '',
                        actions: [],
                    });
                }
            }
        });

        updatedFacts
            .filter((f) => f.isIncluded)
            .forEach((f, index) => {
                f.sequence = index;
            });

        const input: StatusReport = {
            id: r.id,
            missionId: r.missionId,
            title: r.title,
            reportDate: r.reportDate,
            reportPeriod: r.reportPeriod,
            reportPeriodType: r.reportPeriodType,
            utcDataDate: r.utcDataDate,
            utcCompletedDate: r.utcCompletedDate,
            summaryText: r.summaryText,
            lastPeriodText: r.lastPeriodText,
            nextPeriodText: r.nextPeriodText,
            risksAndOpportunitiesText: r.risksAndOpportunitiesText,
            supportText: r.supportText,
            version: r.version,
            facts: updatedFacts,
        };

        await updateStatusReport({
            variables: {
                tenantId: props.tenantId,
                input: input,
                notifyUserIds: [],
            },
        });

        setHasSaved(true);

        await new Promise((res) => setTimeout(res, 1000));

        props.onDismiss();

        setHasSaved(false);
    };

    return (
        <EditPanel
            activeViewName="StatusReportFactNew"
            showPanel={props.showPanel}
            onDismiss={props.onDismiss}
            onUpdateClick={updateFacts}
            headerText="Add Fact"
            isSaving={isAdding}
            isValid={props.statusReport !== null}
            hasSaved={hasSaved}
            saveErrorMessage={saveError?.message}
            panelType={PanelType.medium}
        >
            <StatusReportFactPicker
                utcDataDate={props.utcDataDate}
                utcCompletedDate={props.utcCompletedDate}
                tenantId={props.tenantId}
                missionId={props.missionId}
                onFactsChanged={onFactsChanged}
                selectedFacts={props.selectedFacts}
                canAddTaskFacts={canAddTaskFacts}
            />
        </EditPanel>
    );
}
