import React from 'react';
import { Dropdown, IDropdownOption, Text } from '@fluentui/react';
import { useGetTeamsQuery } from '../../data/types';
import { useStateContext } from '../../services/contextProvider';
import { InputShimmer } from '.';
import orderBy from 'lodash/orderBy';
import pick from 'lodash/pick';

type TeamPickerProps = {
    label?: string;
    disabled?: boolean;
    selectedTeamId?: string | null;
    selectedTeamIds?: string[] | null;
    errorMessage?: string;
    financialYearCode?: string | null;
    multiSelect?: boolean;
    onChange?: (
        team: {
            id: string | null;
            code: string | null;
            name: string | null;
            divisionId: string | null;
            leaderMissionId: string | null;
            version: string | null;
        } | null,
        selected: boolean | undefined
    ) => void;
};

export function TeamPicker(props: TeamPickerProps): JSX.Element {
    const { currentTenantId, currentRoles } = useStateContext();

    const { data, loading } = useGetTeamsQuery({
        skip: !currentRoles?.some((r) => r === 'GlobalAdmin'),
        variables: {
            tenantId: currentTenantId || '',
            divisionId: null,
            financialYearCode: props.financialYearCode || null,
        },
    });

    const sortedTeams = orderBy(
        data?.teams || [],
        ['division.financialYear.code', 'division.code', 'code'],
        ['desc', 'asc', 'asc']
    );

    const teamOptions: IDropdownOption[] =
        sortedTeams.map((t) => {
            return {
                key: t.id || '',
                text: t.name || t.code || t.id || '',
                data: {
                    fyCode: t.division?.financialYear?.code,
                    divisionName: t.division?.name,
                    code: t.code,
                },
            };
        }) || [];

    const onRenderOption = (option?: IDropdownOption): JSX.Element | null => {
        if (!option) {
            return null;
        }

        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    paddingTop: 2,
                    paddingBottom: 2,
                }}
            >
                {option.data?.divisionName && (
                    <Text variant="tiny">
                        {option.data?.fyCode && !props.financialYearCode && (
                            <React.Fragment>
                                {option.data.fyCode} -&nbsp;
                            </React.Fragment>
                        )}
                        {option.data.divisionName}
                    </Text>
                )}
                <Text variant="smallPlus" style={{ fontWeight: 'bold' }}>
                    {option.text}
                </Text>
                {option.data?.code && (
                    <Text variant="tiny" style={{ fontFamily: 'Consolas' }}>
                        {option.data.code}
                    </Text>
                )}
            </div>
        );
    };

    return (
        <InputShimmer isDataLoaded={!loading}>
            <Dropdown
                label={props.label}
                disabled={props.disabled}
                errorMessage={props.errorMessage}
                selectedKey={props.selectedTeamId}
                selectedKeys={props.selectedTeamIds}
                multiSelect={props.multiSelect}
                styles={{
                    dropdownItem: {
                        lineHeight: '',
                        height: '',
                    },
                    dropdownItemSelected: {
                        lineHeight: '',
                        height: '',
                    },
                }}
                options={teamOptions}
                onRenderOption={onRenderOption}
                onChange={(_ev: React.FormEvent, option?): void => {
                    const selectedId = option?.key as string;
                    const selectedTeam = data?.teams?.find(
                        (t) => t.id === selectedId
                    );

                    if (props.onChange && selectedTeam) {
                        props.onChange(
                            {
                                ...pick(
                                    selectedTeam,
                                    'id',
                                    'code',
                                    'name',
                                    'version'
                                ),
                                divisionId: selectedTeam.division?.id || null,
                                leaderMissionId:
                                    selectedTeam.leaderMission?.id || null,
                            },
                            props.multiSelect ? option?.selected : true
                        );
                    } else if (props.onChange) {
                        props.onChange(
                            null,
                            props.multiSelect ? option?.selected : true
                        );
                    }
                }}
            />
        </InputShimmer>
    );
}
