import React, { useEffect, useState } from 'react';

import {
    Text,
    TextField,
    PrimaryButton,
    Stack,
    mergeStyleSets,
    ThemeProvider,
} from '@fluentui/react';

import { authService } from '../../services/auth.service';

import Footer from '../../components/shared/Footer';
import { defaultTheme } from '../../hooks/useThemes';
import { paths } from '../../services/navigation';
import { useMatch, useSearchParams } from 'react-router';
import { UnthemedLoading } from '../../components/Loading';
import { useActiveView } from '../../hooks/useActiveView';
import { useLanguage } from '../../services/i18n';
import { CookieConsentBanner } from '../../components/CookieConsentBanner';
import { CookieConsentModal } from '../../components/CookieConsentModal';

import background_lens from '../../images/background_lens.png';
import background_contour from '../../images/background_contour.png';
import logoDark from '../../images/advance-logo-colour-dark.svg';
import icon from '../../images/advance-icon-colour-dark.svg';

export function Home(): JSX.Element {
    const { t } = useLanguage();

    useActiveView('Home', t('login.title'));

    const [isProviderChecked, setIsProviderChecked] = useState<boolean>(false);

    const [isCookieConsentModalOpen, setIsCookieConsentModalOpen] =
        useState(false);
    const openCookieConsentModal = () => setIsCookieConsentModalOpen(true);
    const dismissCookieConsentModal = () => setIsCookieConsentModalOpen(false);

    const tenantRootRouteMatch = useMatch({
        path: paths.tenantRoot,
    });

    const [searchParams] = useSearchParams();

    useEffect(() => {
        async function providerRedirect() {
            if (tenantRootRouteMatch?.params.tenantCode) {
                await authService.redirectToSpecificLoginProviderAsync(
                    tenantRootRouteMatch.params.tenantCode,
                    searchParams.get('iss') // Used in OIDC to initiate login
                );
            }
            setIsProviderChecked(true);
        }
        providerRedirect();
    }, [tenantRootRouteMatch, searchParams]);

    const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});
    const [emailAddress, setEmailAddress] = useState<string>('');

    const formValidate = (): boolean => {
        let isValid = true;
        const formErrorMessages = {
            emailAddress: '',
        };

        if (!emailAddress) {
            isValid = false;
            formErrorMessages.emailAddress = t(
                'login.validation.emailAddressRequired'
            );
        } else if (!/\S+@\S+\.\S+/.test(emailAddress)) {
            isValid = false;
            formErrorMessages.emailAddress = t(
                'login.validation.emailAddressInvalid'
            );
        }

        setFormErrors(formErrorMessages);

        return isValid;
    };

    const handleSubmitAsync = async (
        event?: React.FormEvent
    ): Promise<void> => {
        event?.preventDefault();
        if (formValidate()) {
            await authService.redirectToLoginAsync(emailAddress);
        }
    };

    const form = (
        <form onSubmit={handleSubmitAsync} noValidate>
            <Stack tokens={{ padding: 32, childrenGap: 16 }}>
                <Text variant="mediumPlus" block>
                    {t('login.signIn')}
                </Text>

                <TextField
                    name="emailAddress"
                    type="email"
                    onChange={(
                        _event: React.FormEvent | undefined,
                        newValue?: string
                    ): void => {
                        setEmailAddress(newValue || '');
                    }}
                    errorMessage={formErrors && formErrors.emailAddress}
                    placeholder={t('login.emailAddress')}
                    autoComplete="email"
                    autoFocus
                />

                <PrimaryButton
                    text={t('login.next')}
                    type="submit"
                    styles={{
                        root: {
                            color: '#363636',
                            backgroundColor: '#ffbd59',
                            border: '#ffbd59',
                        },
                    }}
                />
            </Stack>
        </form>
    );

    const styles = mergeStyleSets({
        homeContainer: {
            backgroundColor: '#363636',
            height: '100%',
            backgroundImage: `url(${background_lens}) ,url(${background_contour})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain contain',
        },
        container: {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden',
        },
        formAndFooterContainer: {
            width: '100%',
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            justifyContent: 'space-between',
            overflowY: 'auto',
        },

        top: {
            alignSelf: 'start',
            paddingBottom: '4vh',
            '@media(max-width: 768px)': {
                flexGrow: 1,
                alignSelf: 'center',
                display: 'flex',
            },
        },
        middle: {
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'row',
            gap: 8,
            marginLeft: '10vw',
            marginRight: '10vw',
            alignItems: 'center',
            justifyContent: 'center',
            borderTop: 'solid 2px #fff',
            borderBottom: 'solid 2px #fff',
            '@media(max-width: 768px)': {
                border: 'none',
                margin: 0,
            },
        },
        bottom: {
            alignSelf: 'stretch',
            color: '#fff !important',
            '& a, button, span': {
                color: '#fff !important',
            },
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'end',

            paddingTop: '10vh',
            paddingLeft: '2vh',
            paddingRight: '2vh',

            '@media(max-width: 768px)': {
                alignSelf: 'center',
                paddingRight: 0,
            },
        },

        logoContainer: {
            alignSelf: 'start',
            minWidth: '30vw',
            padding: '2vw',
            '@media(max-width: 768px)': {
                alignSelf: 'center',
                minWidth: '80vw',
            },
        },

        iconContainer: {
            minWidth: '20vw',
            padding: '2vw',
            '@media(max-width: 768px)': {
                visibility: 'hidden',
                display: 'none',
            },
        },

        emailForm: {
            boxShadow: defaultTheme.effects.elevation8,
            backgroundColor: '#fff',
            minWidth: 'max(20vw, 400px)',
            borderRadius: 8,
        },
    });

    if (!isProviderChecked) {
        return <UnthemedLoading padding={0} />;
    }

    return (
        <ThemeProvider
            theme={defaultTheme}
            as="div"
            className={styles.homeContainer}
        >
            <CookieConsentModal
                isOpen={isCookieConsentModalOpen}
                onDismiss={dismissCookieConsentModal}
            />

            <div className={styles.container}>
                <CookieConsentBanner
                    onManageCookiesClick={openCookieConsentModal}
                />

                <div className={styles.formAndFooterContainer}>
                    <div className={styles.top}>
                        <div className={styles.logoContainer}>
                            <img src={logoDark} />
                        </div>
                    </div>

                    <div className={styles.middle}>
                        <div className={styles.iconContainer}>
                            <img src={icon} />
                        </div>
                        <div>
                            <div className={styles.emailForm}>{form}</div>
                        </div>
                    </div>

                    <div className={styles.bottom}>
                        <Footer onManageCookiesClick={openCookieConsentModal} />
                    </div>
                </div>
            </div>
        </ThemeProvider>
    );
}
