import React from 'react';
import { registerIcons } from '@fluentui/react/lib/Styling';
import {
    LightningBoltIcon,
    LightningBoltSolidIcon,
    createSvgIcon,
} from '@fluentui/react-icons-mdl2';
import mai from '../../images/mai.png';

const TagAdminIcon = createSvgIcon({
    svg: ({ classes }) => (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
            version="1.1"
            role="img"
            className={classes.svg}
        >
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <polygon
                    id="Tag"
                    fill="currentColor"
                    transform="translate(17.105927, 13.105927) rotate(45.000000) translate(-17.105927, -13.105927) "
                    points="8.55592748 27.3331482 25.6559265 27.3331482 25.6559265 7.53314938 17.105927 -1.12129354 8.55592748 7.53314938"
                ></polygon>
                <circle
                    id="Tag-hole"
                    fill="#FFFFFF"
                    cx="24"
                    cy="6"
                    r="1"
                ></circle>
                <g id="Cog-Group" transform="translate(5.000000, 7.400000)">
                    <polygon
                        id="Cog"
                        fill="#FFFFFF"
                        points="5.03896104 3.83870968 6.8961039 3 7.90909091 4.60175791 9.09090909 4.60175791 10.1038961 3 11.961039 3.75483871 11.6233766 5.6 12.3831169 6.43870968 14.2402597 5.93548387 15 7.78064516 13.4805195 8.95483871 13.4805195 10.1290323 15 11.1354839 14.2402597 12.9806452 12.3831169 12.6451613 11.6233766 13.4 11.961039 15.2451613 10.1038961 16 9.09090909 14.4903226 7.90909091 14.4903226 6.81168831 16 5.03896104 15.2451613 5.37662338 13.4 4.53246753 12.6451613 2.84415584 13.1483871 2 11.3032258 3.51948052 10.1290323 3.51948052 8.95483871 2 7.9483871 2.75974026 6.10322581 4.61688312 6.43870968 5.37662338 5.6"
                    ></polygon>
                    <path
                        d="M8.5,11.5 C9.6045695,11.5 10.5,10.6045695 10.5,9.5 C10.5,8.60578072 9.91314089,7.84862973 9.10355696,7.59268131 C8.9131158,7.5324737 8.71035022,7.5 8.5,7.5 C7.3954305,7.5 6.5,8.3954305 6.5,9.5 C6.5,10.6045695 7.3954305,11.5 8.5,11.5 Z"
                        id="Cog-inner-ring"
                        fill="currentColor"
                    ></path>
                </g>
            </g>
        </svg>
    ),
    displayName: 'TagAdminIcon',
});

// Use the registerIcons api from the styling package to register custom svg icons so that they
// can be used by the Icon component (or in anything that renders Icons, like Buttons).
registerIcons({
    icons: {
        Effort: <LightningBoltIcon />,
        EffortSolid: <LightningBoltSolidIcon />,
        TagAdmin: <TagAdminIcon />,
        Mai: (
            <img
                src={mai}
                style={{
                    width: 16,
                    height: 16,
                    display: 'inline-block',
                    border: 0,
                    borderRadius: '50%',
                    boxShadow: 'none',
                    verticalAlign: 'bottom',
                }}
            />
        ),
    },
});
