import React from 'react';
import icon from '../../images/advance-icon-charcoal.svg';
import logo from '../../images/advance-logo-colour-dark.svg';

import { DefaultButton } from '@fluentui/react';

export type EngagementTargetKeys =
    | 'HelpIcon'
    | 'CreateMeasureButton'
    | 'TaskRatingsVisibilityDropdown'
    | 'PresentButton'
    | 'TaskNotificationsTitle';

export type Engagements = {
    key: string;
    targetKey?: EngagementTargetKeys;
    type: 'Announcement' | 'Popup' | 'Beacon';
    headline?: string;
    headlineContent?: string | JSX.Element;
    content: string | JSX.Element;
    activeFrom: string;
    activeTo: string;
    sequence: number;
    backgroundColour?: string;
    headlineColour?: string;
};

function EngagementIcon() {
    return (
        <img
            src={icon}
            alt="Advance"
            style={{
                width: 50,
                filter: 'invert(100%) brightness(200%)',
                paddingBottom: 8,
            }}
        />
    );
}

function Logo() {
    return (
        <img
            src={logo}
            alt="Advance"
            style={{
                paddingTop: 8,
                maxWidth: 200,
            }}
        />
    );
}

export const engagements: Engagements[] = [
    {
        key: 'StatusReportInnovation2025',
        type: 'Popup',
        headline: 'ADVANCE > INNOVATION',
        backgroundColour: '#363636',
        headlineColour: '#ffbd59',
        headlineContent: (
            <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    alignItems: 'start',
                    gap: 8,
                    fontSize: 28,
                }}
            >
                <Logo />

                <span>INNOVATION</span>
            </div>
        ),
        content: (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 8,
                    textAlign: 'left',
                    color: '#fff',
                }}
            >
                <div>
                    <strong style={{ color: '#ffbd59' }}>
                        New Mission Status Reports Features
                    </strong>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            paddingTop: 8,
                            gap: 16,
                            alignItems: 'start',
                        }}
                    >
                        <EngagementIcon />
                        <div>
                            Selected report facts now display extended measure
                            values and support in-line task editing. Identified
                            actions are now isolated and tracked for completion
                            or can be promoted as specified/implied tasks.
                        </div>
                    </div>
                </div>
                <div>
                    <strong style={{ color: '#ffbd59' }}>
                        Mission Status Reports Are Now Enhanced with Mai
                        (Mission AI)
                    </strong>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            paddingTop: 8,
                            gap: 16,
                            alignItems: 'start',
                        }}
                    >
                        <EngagementIcon />
                        <div>
                            Mai can:
                            <ul>
                                <li>
                                    <strong>Generate a draft</strong> executive
                                    summary based on your situational analysis.
                                </li>
                                <li>
                                    <strong>Adjust the tone</strong> of your
                                    executive summary to be more professional
                                    and succinct.
                                </li>
                                <li>
                                    <strong>Simplify</strong> your executive
                                    summary to refine and remove excess content.
                                </li>
                                <li>
                                    <strong>Fact-check</strong> your summary to
                                    ensure it aligns with your mission&apos;s
                                    current status.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div>
                    <strong style={{ color: '#ffbd59' }}>
                        New ‘Mission Summary’ Presentation View
                    </strong>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            paddingTop: 8,
                            gap: 16,
                            alignItems: 'start',
                        }}
                    >
                        <EngagementIcon />
                        <div>
                            A streamlined 3-slide presentation capturing your
                            mission’s strategic intent, measures of success, and
                            a comprehensive summary of your plan:
                            <ol>
                                <li>
                                    Strategic Intent (1&2 levels up, Vision, and
                                    Purpose)
                                </li>
                                <li>Measures of Success</li>
                                <li>
                                    A condensed view of your mission statement,
                                    specified tasks, task categories, linked
                                    measures of success, and implied tasks
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>

                <div style={{ textAlign: 'center' }}>
                    <DefaultButton
                        styles={{
                            root: {
                                backgroundColor: '#ffbd59 ',
                                color: '#363636',
                            },
                            rootHovered: {
                                backgroundColor: '#ffbd59 ',
                                color: '#363636',
                            },
                        }}
                        text="Let's go!"
                    />
                </div>

                <div style={{ textAlign: 'center', paddingBottom: 8 }}>
                    Check out our knowledge base for more details or contact
                    Support for a demo.
                </div>
            </div>
        ),
        activeFrom: '2025-02-01',
        activeTo: '2025-02-28',
        sequence: 1,
    },

    // {
    //     key: 'TaskNotificationsAssignTo',
    //     targetKey: 'TaskNotificationsTitle',
    //     type: 'Announcement',
    //     headline: 'New ‘Replace Existing’ Option!',
    //     content:
    //         'You can now assign incoming resource requests to replace your manually written tasks.',
    //     activeFrom: '2024-05-09',
    //     activeTo: '2024-05-31',
    //     sequence: 1,
    // },
    // {
    //     key: 'NewPresentOptions',
    //     targetKey: 'PresentButton',
    //     type: 'Beacon',
    //     headline: 'Present live or download your mission.',
    //     content:
    //         'Redesigned presentation views, now including a new Mission Summary, with a concise overview of critical mission components.',
    //     activeFrom: '2024-05-09',
    //     activeTo: '2024-05-31',
    //     sequence: 2,
    // },
    // {
    //     key: 'NewHelp',
    //     targetKey: 'HelpIcon',
    //     type: 'Announcement',
    //     headline: 'Explore Our New Knowledge Base!',
    //     content:
    //         'This extensive resource is your one-stop destination for all queries and information about our platform.',
    //     activeFrom: '2024-03-27',
    //     activeTo: '2024-05-01',
    //     sequence: 1,
    // },
    // {
    //     key: 'NewMeasureImportFeature',
    //     targetKey: 'CreateMeasureButton',
    //     type: 'Beacon',
    //     headline: 'New Feature!',
    //     content:
    //         'You can now import measures of success from a mission you had last year.',
    //     activeFrom: '2024-03-27',
    //     activeTo: '2024-05-01',
    //     sequence: 2,
    // },
    // {
    //     key: 'NewRatingsFeature',
    //     targetKey: 'TaskRatingsVisibilityDropdown',
    //     type: 'Beacon',
    //     headline: 'Reinforce task impact with Ratings!',
    //     content:
    //         'Easily set effort and cost ratings on implied tasks.\n\nNote: tasks with a higher effort rating will have a weighted affect on the overall specified task percent complete.',
    //     activeFrom: '2024-03-27',
    //     activeTo: '2024-05-01',
    //     sequence: 3,
    // },
];
