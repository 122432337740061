class ConfigurationService {
    private configuration?: IConfiguration;

    private fetchPromise?: Promise<void>;

    public async getConfigurationAsync(): Promise<IConfiguration> {
        if (this.configuration) {
            return this.configuration;
        }

        if (!this.fetchPromise) {
            const configurationUrl = `/configuration`;
            this.fetchPromise = fetch(configurationUrl).then(async (result) => {
                this.configuration = await result.json();
            });
        }

        await this.fetchPromise;

        if (!this.configuration) {
            throw 'Configuration not loaded!';
        }

        return this.configuration;
    }

    // For testing
    public setConfiguration(config: IConfiguration): void {
        this.configuration = config;
    }
}

export interface IConfiguration {
    apiUrl: string;
    appInsightsConnectionString: string | null | undefined;
    whatfixUrl: string | null | undefined;
    storageFnUrl: string;
    exporterHost: string | null;
    exporterFnKey: string | null;
    importerHost: string | null;
    importerFnKey: string | null;
    importerEndpoint: string | null;
    idleTimeoutMinutes: number | null | undefined;
    localSignupSignInPolicy: string | null;
    externalSignupSignInPolicy: string | null;
    localPasswordResetPolicy: string | null;
    localPasswordChangePolicy: string | null;
}

export const configurationService = new ConfigurationService();
