import React from 'react';

import { MeasureArrowIcon } from '../../../components/MeasureArrowIcon';
import { ExtractQueryArrayType } from '../../../data/extendedTypes';
import { PresentationSlide } from './PresentationSlide';

import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';
import { sorters } from '../../../data/sorters';
import { useFormatters } from '../../../hooks/useFormatters';
import { GetMeasurePeriodDataQuery } from '../../../data/types';
import {
    IChartProps,
    ILineChartPoints,
    Sparkline,
} from '@fluentui/react-charting';
import { usePresentationColours } from '../hooks/usePresentationColours';

type PeriodData = ExtractQueryArrayType<
    GetMeasurePeriodDataQuery,
    ['periodData', 'measurePeriodData']
>;

export function MissionPresentationSlide(props: {
    mission:
        | {
              missionStatement: string | null;
          }
        | null
        | undefined;
    periodLabel?: string | null;
    periodData: PeriodData[];
    measures:
        | {
              id: string | null;
              name: string | null;
              shortName: string | null;
              isLinked: boolean | null;
              linkedFromMeasureId: string | null;
              utcDeleted: string | null;
              sequence: number;
              isStatusLimited: boolean;
              group: {
                  id: string | null;
                  name: string | null;
              } | null;
          }[]
        | null
        | undefined;
}) {
    const { measures, periodData, periodLabel } = props;

    const formatters = useFormatters();

    const measureGroups = orderBy(
        uniqBy(
            measures
                ?.filter((m) => m.utcDeleted === null)
                ?.flatMap((m) => m.group),
            (g) => g?.id
        ),
        'sequence'
    );

    const hasTrend = periodData.some((pd) => pd.history.length);

    return (
        <PresentationSlide key="mission" sectionKey="mission" leftSize="none">
            <PresentationSlide.ListContainer>
                <div
                    className="mintListHeader"
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}
                >
                    <h6>Measures of Success</h6>
                </div>
                <div style={{ paddingRight: 16 }} className="mintListBody">
                    <div style={{ textAlign: 'right' }}>
                        <h6
                            style={{
                                margin: 0,
                                padding: 0,
                                paddingBottom: 4,
                                fontSize: '0.8em',
                            }}
                        >
                            {periodLabel}
                        </h6>
                    </div>

                    <table>
                        <thead>
                            <tr>
                                <th>Measure of Success</th>
                                <th style={{ textAlign: 'right' }}>Period</th>
                                <th style={{ textAlign: 'right' }}>Target</th>
                                <th style={{ textAlign: 'right' }}>Actual</th>
                                {hasTrend && (
                                    <th style={{ textAlign: 'right' }}>
                                        Trend
                                    </th>
                                )}
                                <th style={{ textAlign: 'right' }}>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {measureGroups.map((mg) => {
                                const measuresForGroup = measures
                                    ?.filter((m) => m.utcDeleted === null)
                                    ?.filter((m) => m.group?.id === mg?.id);
                                return (
                                    <React.Fragment key={mg?.id || 'UNGROUPED'}>
                                        {measureGroups.length > 1 && (
                                            <tr>
                                                <td colSpan={hasTrend ? 6 : 5}>
                                                    <strong>
                                                        {mg?.name ??
                                                            'Ungrouped'}
                                                    </strong>
                                                </td>
                                            </tr>
                                        )}
                                        {measuresForGroup
                                            ?.sort(sorters.sequenceSorter)
                                            .map((m) => {
                                                const measurePeriodData =
                                                    periodData.find(
                                                        (pd) =>
                                                            pd.measureId ===
                                                                m?.id ||
                                                            (m?.isLinked &&
                                                                m.linkedFromMeasureId ===
                                                                    pd.measureId)
                                                    );

                                                const formattedPercentage =
                                                    measurePeriodData?.targetFormatted
                                                        ? formatters.formatMeasureStatus(
                                                              measurePeriodData?.statusValue,
                                                              m.isStatusLimited
                                                          )
                                                        : '';

                                                const formattedYtdPercentage =
                                                    measurePeriodData?.ytdTargetFormatted
                                                        ? formatters.formatMeasureStatus(
                                                              measurePeriodData?.ytdStatusValue,
                                                              m.isStatusLimited
                                                          )
                                                        : '';

                                                return (
                                                    <tr
                                                        key={m.id}
                                                        style={{
                                                            minHeight: '10em',
                                                        }}
                                                    >
                                                        <td
                                                            style={{
                                                                paddingLeft:
                                                                    measureGroups.length >
                                                                    1
                                                                        ? 16
                                                                        : undefined,
                                                                alignItems:
                                                                    'center',
                                                                justifyContent:
                                                                    'end',
                                                            }}
                                                        >
                                                            <span
                                                                title={
                                                                    m?.name ||
                                                                    ''
                                                                }
                                                            >
                                                                {m.shortName ||
                                                                    m.name}
                                                            </span>
                                                        </td>
                                                        <td
                                                            style={{
                                                                textAlign:
                                                                    'right',
                                                                whiteSpace:
                                                                    'nowrap',
                                                                alignItems:
                                                                    'center',
                                                                justifyContent:
                                                                    'end',
                                                            }}
                                                        >
                                                            {
                                                                measurePeriodData?.period
                                                            }
                                                        </td>
                                                        <td
                                                            style={{
                                                                textAlign:
                                                                    'right',
                                                                whiteSpace:
                                                                    'nowrap',
                                                                alignItems:
                                                                    'center',
                                                                justifyContent:
                                                                    'end',
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    display:
                                                                        'flex',
                                                                    flexDirection:
                                                                        'column',
                                                                    gap: 2,
                                                                }}
                                                            >
                                                                {
                                                                    measurePeriodData?.targetFormatted
                                                                }

                                                                {measurePeriodData?.ytdTargetFormatted !==
                                                                    null && (
                                                                    <span
                                                                        style={{
                                                                            fontSize:
                                                                                '0.5em',
                                                                        }}
                                                                    >
                                                                        YTD:{' '}
                                                                        {
                                                                            measurePeriodData?.ytdTargetFormatted
                                                                        }
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </td>
                                                        <td
                                                            style={{
                                                                textAlign:
                                                                    'right',
                                                                whiteSpace:
                                                                    'nowrap',
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    display:
                                                                        'flex',
                                                                    flexDirection:
                                                                        'column',
                                                                    gap: 2,
                                                                }}
                                                            >
                                                                {measurePeriodData?.actualFormatted !==
                                                                null
                                                                    ? measurePeriodData?.actualFormatted
                                                                    : 'None'}
                                                                <br />
                                                                {measurePeriodData?.ytdTargetFormatted !==
                                                                    null && (
                                                                    <span
                                                                        style={{
                                                                            fontSize:
                                                                                '0.5em',
                                                                        }}
                                                                    >
                                                                        YTD:{' '}
                                                                        {
                                                                            measurePeriodData?.ytdActualFormatted
                                                                        }
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </td>
                                                        {hasTrend && (
                                                            <td
                                                                style={{
                                                                    textAlign:
                                                                        'right',
                                                                    //width: 128,
                                                                }}
                                                            >
                                                                {!!measurePeriodData && (
                                                                    <PresentationMeasureSparkLine
                                                                        history={
                                                                            measurePeriodData
                                                                                ? [
                                                                                      ...measurePeriodData.history,
                                                                                      measurePeriodData,
                                                                                  ]
                                                                                : []
                                                                        }
                                                                    />
                                                                )}
                                                            </td>
                                                        )}
                                                        <td
                                                            style={{
                                                                textAlign:
                                                                    'right',
                                                                whiteSpace:
                                                                    'nowrap',
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    display:
                                                                        'flex',
                                                                    flexDirection:
                                                                        'column',
                                                                    gap: 2,
                                                                    minHeight:
                                                                        '2.4em',
                                                                    justifyContent:
                                                                        'center',
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        display:
                                                                            'flex',
                                                                        flexDirection:
                                                                            'row',
                                                                        alignItems:
                                                                            'center',
                                                                        justifyContent:
                                                                            'end',
                                                                    }}
                                                                >
                                                                    <span>
                                                                        {
                                                                            formattedPercentage
                                                                        }
                                                                    </span>
                                                                    <MeasureArrowIcon
                                                                        arrowColour={
                                                                            measurePeriodData?.arrowColour
                                                                        }
                                                                        arrowDirection={
                                                                            measurePeriodData?.arrowDirection
                                                                        }
                                                                        iconFontSize={
                                                                            '1em'
                                                                        }
                                                                    />
                                                                </div>
                                                                {measurePeriodData?.ytdTargetFormatted !==
                                                                    null &&
                                                                    !!measurePeriodData?.ytdArrowColow && (
                                                                        <div
                                                                            style={{
                                                                                display:
                                                                                    'flex',
                                                                                flexDirection:
                                                                                    'row',
                                                                                alignItems:
                                                                                    'center',
                                                                                justifyContent:
                                                                                    'end',
                                                                                fontSize:
                                                                                    '0.5em',
                                                                            }}
                                                                        >
                                                                            <span>
                                                                                YTD:{' '}
                                                                                {
                                                                                    formattedYtdPercentage
                                                                                }
                                                                            </span>
                                                                            <div
                                                                                style={{
                                                                                    width: '2em',
                                                                                    textAlign:
                                                                                        'center',
                                                                                }}
                                                                            >
                                                                                <MeasureArrowIcon
                                                                                    arrowColour={
                                                                                        measurePeriodData?.ytdArrowColow
                                                                                    }
                                                                                    arrowDirection={
                                                                                        measurePeriodData?.ytdArrowDirection
                                                                                    }
                                                                                    iconFontSize={
                                                                                        '0.8em'
                                                                                    }
                                                                                    outlineArrow
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                    </React.Fragment>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </PresentationSlide.ListContainer>
        </PresentationSlide>
    );
}

function PresentationMeasureSparkLine(props: {
    history:
        | {
              statusValue: number;
              actualFormatted: string | null;
          }[]
        | undefined
        | null;
}) {
    const { highlightColour } = usePresentationColours();

    const chartPoints: ILineChartPoints[] = [
        {
            legend: 'legend1',
            data:
                props.history
                    ?.filter((h) => h.actualFormatted)
                    ?.map((h, i) => {
                        return {
                            x: i,
                            y: h.statusValue || 0,
                        };
                    }) || [],
            color: highlightColour,
        },
    ];

    const chartData: IChartProps = {
        chartTitle: 'Measure History',
        lineChartData: chartPoints,
    };

    return <Sparkline data={chartData} width={100} />;
}
