import React, { useCallback, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router';

import {
    mergeStyleSets,
    Stack,
    ScrollablePane,
    ScrollbarVisibility,
    ThemeProvider,
} from '@fluentui/react';

import { NavigationProvider } from './navigationContext';

import { TopNav, Menu } from './components/navigation';
import { Team } from './scenes/Team';
import { MissionBuilder } from './scenes/MissionBuilder';
import {
    Present,
    MissionAnalysisPresentation,
    StatusReportPresentation,
} from './scenes/Presentations';
import { TemplateReportPresentation } from './scenes/Presentations/TemplateReportPresentation';
import { SwitchDashboard } from './scenes/SwitchDashboard';
import { StatusReport, StatusReports } from './scenes/StatusReports';
import SupportWidget from './components/SupportWidget';
import TelemetryProvider from './components/TelemetryProvider';
import { InitialState } from './services/context';
import { paths } from './services/navigation';
import { RouteContext } from './RouteContext';
import {
    Users,
    Tenants,
    Home,
    UsageStats,
    UsageOverview,
} from './scenes/Setup';
import { Importer } from './scenes/Importer';
import Footer from './components/shared/Footer';
import { useThemes } from './hooks/useThemes';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import minMax from 'dayjs/plugin/minMax';
import isBetween from 'dayjs/plugin/isBetween';
import duration from 'dayjs/plugin/duration';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';

import { useIdleTimer } from 'react-idle-timer';
import { authService } from './services/auth.service';
import { IConfiguration } from './services/configuration.service';
import { LastLoginDetails } from './components/shared/LastLoginDetails';
import { LoginHistoryModal } from './components/shared/LoginHistoryModal';
import RedirectToDefaultMission from './components/RedirectToDefaultMission';
import './components/icons/RegisterIcons';
import Reports from './scenes/Reports/Reports';
import TemplateReport from './scenes/TemplateReports/TemplateReport';
import { CookieConsentBanner } from './components/CookieConsentBanner';
import { CookieConsentModal } from './components/CookieConsentModal';
import { useApolloClient } from '@apollo/client';
import { Contributor } from './scenes/Contributor/Contributor';
import { EngagementProvider } from './components/engagement/EngagementContext';
import EngagementPortal from './components/engagement/EngagementPortal';
import { EngagementTarget } from './components/engagement/EngagementTarget';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
dayjs.extend(relativeTime);
dayjs.extend(isSameOrBefore);
dayjs.extend(minMax);
dayjs.extend(isBetween);
dayjs.extend(duration);
dayjs.extend(quarterOfYear);

export default function App(props: {
    configuration?: IConfiguration;
}): JSX.Element {
    const idleTimeoutMinutes = props.configuration?.idleTimeoutMinutes || 60;

    return (
        <RouteContext>
            <TelemetryProvider>
                <NavigationProvider>
                    <Routes>
                        <Route
                            path={paths.switchTenant}
                            element={<SwitchDashboard />}
                        />
                        <Route
                            path={paths.setupTenants}
                            element={<Tenants />}
                        />
                        <Route path={paths.setupUsers} element={<Users />} />
                        <Route
                            path={paths.usageOverview}
                            element={<UsageOverview />}
                        />
                        <Route
                            path={paths.usageStats}
                            element={<UsageStats />}
                        />
                        <Route
                            path={paths.missionAnalysisPresentation}
                            element={<MissionAnalysisPresentation />}
                        />
                        <Route
                            path={paths.missionSummaryPresentation}
                            element={<MissionAnalysisPresentation isSummary />}
                        />
                        <Route
                            path={paths.statusReportPresentation}
                            element={<StatusReportPresentation />}
                        />
                        <Route
                            path={paths.templateReportPresentation}
                            element={<TemplateReportPresentation />}
                        />
                        <Route
                            path={paths.templateReportPresentationView}
                            element={<TemplateReportPresentation />}
                        />
                        <Route path={paths.present} element={<Present />} />
                        <Route
                            path={paths.presentStatusReport}
                            element={<Present />}
                        />
                        <Route
                            path={paths.presentTemplateReport}
                            element={<Present />}
                        />
                        <Route
                            path={paths.presentTemplateReportView}
                            element={<Present />}
                        />
                        <Route
                            path={'*'}
                            element={
                                <React.Fragment>
                                    <PageLayout
                                        idleTimeoutMinutes={idleTimeoutMinutes}
                                    />
                                    <SupportWidget
                                        userEmailAddress={
                                            InitialState.currentUserEmail
                                        }
                                        userDisplayName={
                                            InitialState.currentUserDisplayName
                                        }
                                        themePrimaryColourHex={
                                            InitialState.themePrimaryColourHex
                                        }
                                    />
                                </React.Fragment>
                            }
                        ></Route>
                    </Routes>
                </NavigationProvider>
            </TelemetryProvider>
        </RouteContext>
    );
}

function LoggedInFooter(props: {
    onLoginHistoryDetailsClick: () => void;
    onManageCookiesClick: () => void;
}): JSX.Element {
    return (
        <Stack
            styles={{ root: { paddingTop: 32, paddingBottom: 16 } }}
            horizontalAlign="stretch"
        >
            <Stack.Item align="center">
                <LastLoginDetails
                    onDetailsClick={props.onLoginHistoryDetailsClick}
                />
            </Stack.Item>
            <Stack.Item align="stretch">
                <Footer {...props} alignment="center" />
            </Stack.Item>
        </Stack>
    );
}

function RootSwitch(): JSX.Element {
    return (
        <Routes>
            <Route path={paths.team} element={<Team />} />
            <Route
                path={paths.missionBuilderSpecifiedTask}
                element={<MissionBuilder />}
            />
            <Route
                path={paths.missionBuilderImpliedTask}
                element={<MissionBuilder />}
            />
            <Route path={paths.missionBuilder} element={<MissionBuilder />} />
            <Route
                path={paths.missionBuilderMeasure}
                element={<MissionBuilder />}
            />
            <Route
                path={paths.missionBuilderMeasureFormulaEditor}
                element={<MissionBuilder />}
            />
            <Route path={paths.statusReports} element={<StatusReports />} />
            <Route path={paths.statusReport} element={<StatusReport />} />
            <Route path={paths.templateReport} element={<TemplateReport />} />
            <Route path={paths.contributorWithTeam} element={<Contributor />} />
            <Route
                path={paths.contributorWithTeamTask}
                element={<Contributor />}
            />
            <Route path={paths.contributor} element={<Contributor />} />
            <Route path={paths.contributorTask} element={<Contributor />} />
            <Route path={paths.importerSection} element={<Importer />} />
            <Route path={paths.importer} element={<Importer />} />
            <Route path={paths.reports} element={<Reports />}>
                <Route path=":reportName" element={<Reports />} />
            </Route>
            <Route path={paths.setupSection} element={<Home />} />
            <Route path={paths.setupHome} element={<Home />} />
            <Route path={paths.fyRoot} element={<RedirectToDefaultMission />} />
            <Route
                path={paths.tenantRoot}
                element={<RedirectToDefaultMission />}
            />
            <Route path={paths.root} element={<RedirectToDefaultMission />} />
        </Routes>
    );
}

function PageLayout(props: { idleTimeoutMinutes: number }): JSX.Element {
    const apolloClient = useApolloClient();

    // This is the idle time before the user is logged out.
    const idleTimeoutMs = props.idleTimeoutMinutes * 60 * 1000;

    const [showIdleWarning, setShowIdleWarning] = useState(false);
    const [remainingSeconds, setRemainingSeconds] = useState(
        (idleTimeoutMs / 1000).toFixed(0)
    );

    const [showLoginHistoryModal, setShowLoginHistoryModal] = useState(false);

    const handleLoginHistoryModalDismiss = () => {
        setShowLoginHistoryModal(false);
    };

    const handleLoginHistoryDetailsClick = useCallback(() => {
        setShowLoginHistoryModal(true);
    }, []);

    const handleOnIdle = async () => {
        try {
            await apolloClient.cache.reset();
        } finally {
            await authService.logoutAsync();
        }
    };

    const { getRemainingTime, reset } = useIdleTimer({
        timeout: idleTimeoutMs,
        events: [
            'keydown',
            'mousedown',
            'mousewheel',
            'touchstart',
            'touchmove',
            'MSPointerDown',
            'wheel',
            'DOMMouseScroll',
        ],
        onIdle: handleOnIdle,
        debounce: 500,
        crossTab: true,
    });

    useEffect(() => {
        // This is the milliseconts until timeout that the user will see a warning.
        const idleWarningRemainingTimeMs = 1 * 60 * 1000;

        window.document.addEventListener('presentation:action', reset, false);
        setInterval(() => {
            if (getRemainingTime() < idleWarningRemainingTimeMs) {
                setShowIdleWarning(true);
                setRemainingSeconds((getRemainingTime() / 1000).toFixed(0));
            } else {
                setShowIdleWarning(false);
            }
        }, 1000);
    }, [getRemainingTime, reset]);

    const { currentTheme } = useThemes();

    const styles = mergeStyleSets({
        topNav: {
            boxShadow: currentTheme.effects.elevation8,
            zIndex: 10,
        },
        leftNav: {
            height: '100%',
            boxShadow: currentTheme.effects.elevation4,
            zIndex: 5,
        },
        content: {
            width: '100%',
            backgroundColor: currentTheme.palette.neutralLighter,
            '@media print': {
                backgroundColor: '#fff',
            },
        },
    });

    const [isCookieConsentModalOpen, setIsCookieConsentModalOpen] =
        useState(false);
    const openCookieConsentModal = () => setIsCookieConsentModalOpen(true);
    const dismissCookieConsentModal = () => setIsCookieConsentModalOpen(false);

    // ThemeProvider renders a div.
    // See: https://github.com/microsoft/fluentui/issues/16633
    return (
        <PageLayoutProviders>
            <Stack verticalFill={true}>
                <CookieConsentBanner
                    onManageCookiesClick={openCookieConsentModal}
                />

                <CookieConsentModal
                    isOpen={isCookieConsentModalOpen}
                    onDismiss={dismissCookieConsentModal}
                />

                <LoginHistoryModal
                    isOpen={showLoginHistoryModal}
                    onDismiss={handleLoginHistoryModalDismiss}
                />

                <Stack.Item className={styles.topNav}>
                    <TopNav
                        showBreadcrumbs={true}
                        showSearch={true}
                        showLoginHistoryMenuItemClick={
                            handleLoginHistoryDetailsClick
                        }
                    />
                </Stack.Item>
                <Stack.Item verticalFill={true}>
                    <Stack horizontal verticalFill={true}>
                        <Stack.Item
                            align="start"
                            verticalFill={true}
                            className={styles.leftNav}
                        >
                            <Menu
                                showIdleWarning={showIdleWarning}
                                idleWarningRemainingSeconds={remainingSeconds}
                            />
                        </Stack.Item>
                        <Stack.Item
                            align="stretch"
                            className={styles.content}
                            verticalFill={true}
                        >
                            <div className="content-container">
                                <ScrollablePane
                                    scrollbarVisibility={
                                        ScrollbarVisibility.always
                                    }
                                    styles={{
                                        root: {
                                            overflowX: 'hidden',
                                        },
                                        contentContainer: {
                                            overflowX: 'hidden',
                                        },
                                    }}
                                >
                                    <Stack verticalFill>
                                        <Stack.Item grow>
                                            <RootSwitch />
                                        </Stack.Item>
                                        <Stack.Item>
                                            <LoggedInFooter
                                                {...props}
                                                onManageCookiesClick={
                                                    openCookieConsentModal
                                                }
                                                onLoginHistoryDetailsClick={
                                                    handleLoginHistoryDetailsClick
                                                }
                                            />
                                        </Stack.Item>
                                    </Stack>
                                </ScrollablePane>
                            </div>
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
            </Stack>

            <EngagementPortal />
        </PageLayoutProviders>
    );
}

export function PageLayoutProviders(props: {
    children?: React.ReactNode;
}): JSX.Element {
    const { currentTheme } = useThemes();

    return (
        <ThemeProvider theme={currentTheme} style={{ height: '100%' }}>
            <EngagementProvider>
                {props.children}

                <EngagementTarget />
            </EngagementProvider>
        </ThemeProvider>
    );
}
