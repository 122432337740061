import { useState, useRef, useCallback } from 'react';
import { authService } from '../services/auth.service';
import { configurationService } from '../services/configuration.service';

export function useMaiFileUpload(): {
    isStreaming: boolean;
    contentMd: string;
    startStreaming: (
        promptName: string,
        prompt: string,
        maiPath: string,
        blob?: Blob | null
    ) => Promise<void>;
    cancelStreaming: () => void;
} {
    const [isStreaming, setIsStreaming] = useState(false);
    const [contentMd, setContentMd] = useState<string>('');
    const isCancelledRef = useRef(false);
    const readerRef = useRef<ReadableStreamDefaultReader<string> | null>(null);

    const startStreaming = useCallback(
        async (
            promptName: string,
            prompt: string,
            maiPath: string,
            blob?: Blob | null
        ) => {
            isCancelledRef.current = false;

            const configuration =
                await configurationService.getConfigurationAsync();
            const formData = new FormData();
            formData.append('prompt', prompt);
            formData.append('promptName', promptName);
            if (blob) {
                formData.append('files', blob);
            }

            const token = await authService.getTokenAsync();
            const response = await fetch(
                `${configuration.apiUrl}${maiPath || 'mai'}`,
                {
                    method: 'POST',
                    body: formData,
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            const reader = response?.body
                ?.pipeThrough(new TextDecoderStream())
                .getReader();

            readerRef.current = reader || null;
            isCancelledRef.current = false;

            let fullText = '';
            let accumulatedText = '';
            const updateInterval = 4; // 4 letters at a time

            while (reader && !isCancelledRef.current) {
                const { value, done } = await reader.read();

                if (done) {
                    // Append any remaining text
                    if (accumulatedText.length > 0) {
                        fullText += accumulatedText;
                        setContentMd(fullText);
                    }
                    setIsStreaming(false);
                    break;
                }

                // Slow down the content as the data from azure comes back in chunks due to content filtering.
                const chars = value.split('');
                for (const char of chars) {
                    if (isCancelledRef.current) {
                        break;
                    }
                    accumulatedText += char;
                    if (accumulatedText.length >= updateInterval) {
                        fullText += accumulatedText;
                        setContentMd(fullText);
                        accumulatedText = '';
                        await new Promise((resolve) => setTimeout(resolve, 50));
                    }
                }
            }

            if (isCancelledRef.current) {
                setContentMd('');
            }
        },
        []
    );

    const cancelStreaming = useCallback(() => {
        isCancelledRef.current = true;
        if (readerRef.current) {
            readerRef.current.cancel();
        }
    }, []);

    return { isStreaming, contentMd, startStreaming, cancelStreaming };
}
