import { useMemo } from 'react';
import {
    Link,
    mergeStyleSets,
    Shimmer,
    ShimmerElementType,
} from '@fluentui/react';
import { useGetMissionTasksQuery } from '../../../data/types';
import { useStateContext } from '../../../services/contextProvider';
import { sorters } from '../../../data/sorters';
import { AdvanceCard } from '../../../components/AdvanceCard';
import MeasureLinkedTasksDonut from '../../../components/MeasureLinkedTasksDonut';
import TaskProgressIndicator from '../../../components/TaskProgressIndicator';
import { useThemes } from '../../../hooks/useThemes';

export function MeasureLinkedTaskStatusCard(props: {
    missionId: string | null | undefined;
    measureId: string | null | undefined;
    isReadOnly: boolean;
    isLoading: boolean;
    onTaskClick: (taskId: string) => void;
}) {
    const { currentTenantId } = useStateContext();

    const { currentTheme } = useThemes();

    const { data, loading } = useGetMissionTasksQuery({
        skip: !props.missionId || !currentTenantId,
        variables: {
            tenantId: currentTenantId || '',
            missionId: props.missionId || '',
        },
    });

    const linkedTasks = useMemo(
        () =>
            (data?.tasks || [])
                .slice()
                .filter((t) => t.parentTaskId === null && !t.isDuplicate)
                .filter((t) =>
                    t.linkedMeasures.some(
                        (lm) => lm.measureId === props.measureId
                    )
                )
                .sort(sorters.sequenceSorter),
        [data?.tasks, props.measureId]
    );

    const impliedTasks = useMemo(
        () =>
            (data?.tasks || [])
                .slice()
                .sort(sorters.sequenceSorter)
                .filter(
                    (it) =>
                        linkedTasks.some((lt) => lt.id === it.parentTaskId) &&
                        !it.isDuplicate
                ),
        [data?.tasks, linkedTasks]
    );

    const classNames = mergeStyleSets({
        container: {
            height: 240,
            display: 'flex',
            flexDirection: 'row',
            paddingRight: 8,
            paddingLeft: 8,
            '> div': {
                flexGrow: 1,
                width: '50%',
            },
        },
        linkedTasksList: {
            maxHeight: 240,
            overflowY: 'auto',
            paddingRight: 16,
            paddingLeft: 8,
            //fontSize: FontSizes.xSmall,
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
        },
        linkedTaskItem: {
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
            backgroundColor: currentTheme.palette.neutralLighter,
            padding: 4,
            borderRadius: 4,
        },
    });

    if (!loading && !impliedTasks.length) {
        return null;
    }

    return (
        <AdvanceCard title={!loading ? 'Linked Tasks' : ' '}>
            <Shimmer
                isDataLoaded={!props.isLoading && !loading}
                shimmerElements={[
                    {
                        type: ShimmerElementType.gap,
                        width: 16,
                        height: 232,
                    },
                    {
                        type: ShimmerElementType.line,
                        height: 232,
                    },
                    {
                        type: ShimmerElementType.gap,
                        width: 16,
                        height: 232,
                    },
                ]}
            >
                <div className={classNames.container}>
                    <div>
                        <MeasureLinkedTasksDonut impliedTasks={impliedTasks} />
                    </div>
                    <div className={classNames.linkedTasksList}>
                        {linkedTasks.map((lt) => (
                            <div
                                key={lt.id}
                                className={classNames.linkedTaskItem}
                            >
                                <div>
                                    <Link
                                        onClick={() =>
                                            props.onTaskClick(lt.id || '')
                                        }
                                    >
                                        {lt.name}
                                    </Link>
                                </div>
                                <div>
                                    <TaskProgressIndicator
                                        showStatus
                                        task={lt}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </Shimmer>
        </AdvanceCard>
    );
}
