import React, { useEffect, useState } from 'react';
import max from 'lodash/max';

import {
    ChoiceGroup,
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    PrimaryButton,
} from '@fluentui/react';
import {
    GetMissionTasksQuery,
    refetchGetMissionTasksQuery,
    useGetMissionTasksQuery,
    useUpdateTaskMutation,
} from '../../../data/types';
import { useStateContext } from '../../../services/contextProvider';
import { TaskDropDown } from '../../../components/inputs/TaskDropDown';
import dayjs from 'dayjs';

type PromoteTypes = 'specified' | 'implied';

export function StatusReportActionPromoteDialog(props: {
    statusReportAction:
        | {
              name: string | null;
              due: string | null;
              done: string | null;
          }
        | null
        | undefined;
    missionId: string;
    hidden: boolean;
    onDismiss: () => void;
    onTaskPromoted: () => void;
    statusReportUtcCompletedDate: string | null;
}): JSX.Element {
    const { currentTenantId } = useStateContext();

    const [promoteType, setPromoteType] = useState<PromoteTypes>('implied');

    const [targetSpecifiedTaskId, setTargetSpecifiedTaskId] = useState<
        string | null
    >(null);

    useEffect(() => {
        if (!props.hidden) {
            setPromoteType('implied');
            setTargetSpecifiedTaskId(null);
        }
    }, [props.hidden]);

    const { data: tasksData, loading: tasksLoading } = useGetMissionTasksQuery({
        skip: !props.missionId || !currentTenantId,
        variables: {
            tenantId: currentTenantId || '',
            missionId: props.missionId || '',
        },
    });

    const [updateTask, { loading: isSaving }] = useUpdateTaskMutation();

    const onConfirmPromoteClick = async () => {
        if (!currentTenantId || !props.statusReportAction) {
            return;
        }

        const lastSequence = max(
            tasksData?.tasks
                ?.filter((t) => t.parentTaskId === targetSpecifiedTaskId)
                .filter((t) => !t.isDuplicate)
                .map((t) => t.sequence)
        );

        const newSequence = lastSequence !== undefined ? lastSequence + 1 : 0;

        const startDate = props.statusReportUtcCompletedDate
            ? dayjs(props.statusReportUtcCompletedDate).format('YYYY-MM-DD')
            : dayjs().format('YYYY-MM-DD');

        await updateTask({
            variables: {
                tenantId: currentTenantId,
                input: {
                    id: null,
                    costWeight: 0,
                    description: '',
                    done: props.statusReportAction.done,
                    due: props.statusReportAction.due,
                    effortResourceWeight: 0,
                    effortWeight: 0,
                    isDuplicate: false,
                    isPercentageCompleteFromResources: false,
                    isPercentageCompleteFromSubTasks: false,
                    linkedMeasures: [],
                    missionId: props.missionId,
                    name: props.statusReportAction.name,
                    parentTaskId: targetSpecifiedTaskId,
                    percentComplete: props.statusReportAction.done ? 1 : 0,
                    rejectedReason: null,
                    resource: null,
                    resourceId: null,
                    resourceIsPrimary: false,
                    resourcedFromTaskId: null,
                    review: null,
                    sequence: newSequence,
                    start: startDate,
                    taskCategoryId: null,
                    utcAccepted: null,
                    utcAtRisk: null,
                    utcCancelled: null,
                    utcChangesPending: null,
                    utcPostponed: null,
                    utcRejected: null,
                    utcResourceRemoved: null,
                    version: '',
                },
            },
            refetchQueries: [
                // Refetch so that the linked measures sections are updated. Not sure on the parameters in this component, so operation name will need to suffice.
                'GetTasksForStatusReport',
                // Needed to refresh the mission page tasks, only really needed if it's a specified task
                refetchGetMissionTasksQuery({
                    tenantId: currentTenantId,
                    missionId: props.missionId,
                }),
            ],
        });

        props.onTaskPromoted();
    };

    const handePromoteChoiceChange = (
        promoteType: PromoteTypes,
        taskId: string | null
    ) => {
        setPromoteType(promoteType);
        setTargetSpecifiedTaskId(taskId);
    };

    return (
        <Dialog
            hidden={props.hidden}
            dialogContentProps={{
                type: DialogType.largeHeader,
                title: 'Promote',
                showCloseButton: true,
                subText:
                    'This will promote this action item to a mission task.',
            }}
            modalProps={{
                isBlocking: true,
            }}
            onDismiss={props.onDismiss}
        >
            <PromoteChoiceGroup
                promoteType={promoteType}
                targetSpecifiedTaskId={targetSpecifiedTaskId}
                onChange={handePromoteChoiceChange}
                taskData={!tasksLoading && tasksData ? tasksData?.tasks : null}
            />

            <DialogFooter>
                <PrimaryButton
                    text="Promote"
                    onClick={onConfirmPromoteClick}
                    disabled={
                        isSaving ||
                        tasksLoading ||
                        (!targetSpecifiedTaskId && promoteType === 'implied')
                    }
                />
                <DefaultButton text="Cancel" onClick={props.onDismiss} />
            </DialogFooter>
        </Dialog>
    );
}

function PromoteChoiceGroup(props: {
    promoteType: PromoteTypes;
    targetSpecifiedTaskId: string | null;
    onChange: (
        promoteType: PromoteTypes,
        targetSpecifiedTaskId: string | null
    ) => void;
    taskData: GetMissionTasksQuery['tasks'] | null;
}): JSX.Element {
    const { promoteType, targetSpecifiedTaskId, onChange, taskData } = props;

    return (
        <ChoiceGroup
            defaultSelectedKey={promoteType}
            options={[
                {
                    key: 'implied',
                    text: 'Promote as an implied task',
                    disabled: !taskData?.length,
                    onRenderField: (props, render) => {
                        if (!render) {
                            return null;
                        }
                        return (
                            <div>
                                {render(props)}
                                <div
                                    style={{
                                        marginLeft: 26,
                                        width: 'calc(100% - 60px)',
                                        display: 'inline-block',
                                    }}
                                >
                                    <TaskDropDown
                                        canSelectSpecifiedTasks
                                        canSelectImpliedTasks={false}
                                        isDataLoaded={true} // no shimmer
                                        missionTasks={taskData}
                                        selectedKey={targetSpecifiedTaskId}
                                        onTaskChange={(
                                            taskId: string | null
                                        ) => {
                                            onChange(promoteType, taskId);
                                        }}
                                        placeholder="Select a target specified task"
                                        disabled={
                                            promoteType == 'specified' ||
                                            !taskData?.length
                                        }
                                    />
                                </div>
                            </div>
                        );
                    },
                },
                {
                    key: 'specified',
                    text: 'Promote as a specified task',
                },
            ]}
            onChange={(_ev, option) => {
                const selectedPromoteType = option?.key as PromoteTypes;

                onChange(
                    selectedPromoteType,
                    selectedPromoteType === 'implied'
                        ? targetSpecifiedTaskId
                        : null
                );
            }}
        />
    );
}
