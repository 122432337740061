import { IconButton, mergeStyleSets } from '@fluentui/react';
import { Editor } from '@tiptap/core';

export function MarkdownEditorToolbar(props: {
    editor: Editor;
    readOnly: boolean;
}) {
    const { editor, readOnly } = props;

    const handleUndo = () => editor.chain().focus().undo().run();
    const handleRedo = () => editor.chain().focus().redo().run();
    const handleBold = () => editor.chain().focus().toggleBold().run();
    const handleItalic = () => editor.chain().focus().toggleItalic().run();
    const handleBulletList = () =>
        editor.chain().focus().toggleBulletList().run();
    const handleOrderedList = () =>
        editor.chain().focus().toggleOrderedList().run();

    const classNames = mergeStyleSets({
        container: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingBottom: 4,
        },
        richTextButtons: {
            display: 'flex',
            flexDirection: 'row',
            gap: 4,
        },
    });

    return (
        <div className={classNames.container}>
            <div className={classNames.richTextButtons}>
                <IconButton
                    onClick={handleUndo}
                    disabled={readOnly}
                    text="Undo"
                    iconProps={{ iconName: 'Undo' }}
                />

                <IconButton
                    onClick={handleRedo}
                    disabled={readOnly}
                    text="Undo"
                    iconProps={{ iconName: 'Redo' }}
                />

                <IconButton
                    onClick={handleBold}
                    disabled={readOnly}
                    text="Bold"
                    iconProps={{ iconName: 'Bold' }}
                />

                <IconButton
                    onClick={handleItalic}
                    disabled={readOnly}
                    text="Italic"
                    iconProps={{ iconName: 'Italic' }}
                />

                <IconButton
                    onClick={handleBulletList}
                    disabled={readOnly}
                    text="Bullets"
                    iconProps={{ iconName: 'BulletedList' }}
                />

                <IconButton
                    onClick={handleOrderedList}
                    disabled={readOnly}
                    text="Italic"
                    iconProps={{ iconName: 'NumberedList' }}
                />
            </div>
        </div>
    );
}
