import React, { useEffect, useState } from 'react';
import { IconButton } from '@fluentui/react';
import Iframe from 'react-iframe';
import { useNavigate } from 'react-router';
import { usePresentationColours } from '../hooks/usePresentationColours';

export function PresentationFrame(props: {
    url: string;
    onBack?: () => void;
}): JSX.Element {
    const navigate = useNavigate();

    const { backgroundImage } = usePresentationColours();

    useEffect(() => {
        const iframe = document.getElementById('presentationIFrame');
        if (iframe) {
            iframe.focus();
        }
    });

    const defaultFontSize = 1;

    const [fontSize, setFontSize] = useState<number>(defaultFontSize);

    useEffect(() => {
        const iframe = document.getElementById(
            'presentationIFrame'
        ) as HTMLIFrameElement;

        iframe.contentDocument?.documentElement.style.setProperty(
            '--presentation-base-font-size',
            `${(fontSize + 0.4).toFixed(1)}vw`
        );
    }, [fontSize]);

    const fontSizes = [
        {
            name: 'Extra Small',
            fontSize: defaultFontSize - 0.4,
        },
        {
            name: 'Small',
            fontSize: defaultFontSize - 0.2,
        },
        {
            name: 'Medium',
            fontSize: defaultFontSize,
        },
        {
            name: 'Large',
            fontSize: defaultFontSize + 0.2,
        },
    ];

    return (
        <React.Fragment>
            <div className="presentationFrameContainer">
                <div
                    style={{
                        backgroundImage: `url(${backgroundImage})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        opacity: 0.1,
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                    }}
                />
                <div>
                    <IconButton
                        iconProps={{ iconName: 'More' }}
                        className="presentationOptions"
                        onRenderMenuIcon={() => null}
                        menuProps={{
                            className: 'presentationOptionsMenu',
                            items: [
                                {
                                    key: 'FontSize',
                                    iconProps: { iconName: 'FontSize' },
                                    text: 'Font Size',
                                    subMenuProps: {
                                        items: fontSizes.map((fs) => ({
                                            canCheck: true,
                                            checked:
                                                Math.round(
                                                    fs.fontSize * 100
                                                ) ===
                                                Math.round(fontSize * 100),
                                            key: fs.name,
                                            text: fs.name,
                                            onClick: () =>
                                                setFontSize(fs.fontSize),
                                        })),
                                    },
                                },
                                {
                                    key: 'Cancel',
                                    iconProps: { iconName: 'Cancel' },
                                    text: 'Close',
                                    secondaryText: '(Esc)',
                                    onClick: () => {
                                        if (props.onBack) {
                                            props.onBack();
                                        }
                                        navigate(-1);
                                    },
                                },
                            ],
                        }}
                    />
                </div>

                <Iframe
                    url={props.url}
                    id="presentationIFrame"
                    className="presentationFrameFullScreen"
                    allowFullScreen={true}
                />
            </div>
        </React.Fragment>
    );
}
