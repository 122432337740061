import React, { CSSProperties, useEffect, useState } from 'react';
import { useParams } from 'react-router';

import dayjs from 'dayjs';
import {
    useGetMeasuresForStatusReportQuery,
    useGetStatusReportQuery,
    useGetTasksForStatusReportQuery,
    GetTasksForStatusReportQuery,
    ReportPeriodTypes,
} from '../../data/types';
import { useStateContext } from '../../services/contextProvider';
import { StepContainer } from './components/StepContainer';
import { Markdown } from './Markdown';
import { sorters } from '../../data/sorters';
import { ExtractQueryArrayType } from '../../data/extendedTypes';
import { PresentationTitleSlide } from './components/PresentationTitleSlide';
import { PresentationSlide } from './components/PresentationSlide';
import { MissionPresentationSlide } from './components/MissionPresentationSlide';
import { useReportingMeasures } from '../../hooks/useReportingMeasures';
import { PresentationMeasureBox } from './components/PresentationMeasureBox';
import { useReportPeriods } from '../../hooks/useReportPeriods';
import { Icon } from '@fluentui/react';
import { usePresentationColours } from './hooks/usePresentationColours';

export function StatusReportPresentation(): JSX.Element {
    const handleMessage = () => {
        parent.document.dispatchEvent(new Event('presentation:action'));
    };

    const { highlightColour } = usePresentationColours();

    useEffect(() => {
        window.addEventListener('keydown', handleMessage, false);
        window.addEventListener('mousedown', handleMessage, false);
        window.addEventListener('touchstart', handleMessage, false);
    }, []);

    const [revealApi, setRevealApi] = useState<Reveal.Api>();
    const handleInitialized = setRevealApi;
    const handleSectionChanged = () => {
        if (revealApi?.sync) {
            revealApi.sync();
        }
    };

    const { currentTenantId } = useStateContext();

    const { missionId, statusReportId } = useParams();

    const { data } = useGetStatusReportQuery({
        skip: !statusReportId || !currentTenantId,
        variables: {
            tenantId: currentTenantId || '',
            statusReportId: statusReportId || '',
        },
        pollInterval: 10000,
    });

    const forDateTime =
        data?.statusReport?.utcDataDate ||
        data?.statusReport?.utcCompletedDate ||
        null;

    const { data: measureData } = useGetMeasuresForStatusReportQuery({
        skip: !data?.statusReport,
        variables: {
            tenantId: currentTenantId || '',
            missionId: missionId || '',
            forDateTime: forDateTime,
            includeDeleted: true,
        },
        fetchPolicy: 'no-cache', // We don't want the historic results polluting the cache
    });

    const { data: taskData } = useGetTasksForStatusReportQuery({
        skip: !data?.statusReport,
        variables: {
            tenantId: currentTenantId || '',
            missionId: missionId || '',
            forDateTime: forDateTime,
            includeDeleted: true,
        },
        fetchPolicy: 'no-cache', // We don't want the historic results polluting the cache
    });

    const { setSelectedMeasureIds, periodData } = useReportingMeasures({
        missionId: missionId || null,
        reportPeriod: data?.statusReport?.reportPeriod || null,
        reportPeriodType: data?.statusReport?.reportPeriodType || null,
        utcCompletedDate: data?.statusReport?.utcCompletedDate || null,
        utcDataDate: data?.statusReport?.utcDataDate || null,
    });

    const statusReport = data?.statusReport;
    const measures = measureData?.measures;
    const tasks = taskData?.tasks;
    const mission = statusReport?.mission;

    const fyStartDate = mission?.team?.division?.financialYear?.startDate;
    const fyEndDate = mission?.team?.division?.financialYear?.endDate;

    const { formatPeriod } = useReportPeriods(fyStartDate, fyEndDate);

    const reportPeriodLabel = formatPeriod(
        statusReport?.reportPeriodType,
        statusReport?.reportPeriod
    );

    useEffect(() => {
        if (statusReport?.facts) {
            // This may contain some deleted ones.
            const factMeasuresId = statusReport?.facts
                .filter((f) => f.isIncluded)
                .filter((f) => !!f.measureId)
                .map((t) => t.measureId || '');

            const allCurrent =
                measures
                    ?.filter((m) => factMeasuresId.indexOf(m.id || '') < 0)
                    .map((m) => m.id || '') || [];

            setSelectedMeasureIds([...factMeasuresId, ...allCurrent]);
        } else {
            setSelectedMeasureIds([]);
        }
    }, [statusReport?.facts, setSelectedMeasureIds, measures]);

    const includedFacts = statusReport?.facts
        ?.filter((f) => f.isIncluded)
        .sort(sorters.sequenceSorter);

    const isReady = !!mission && !!statusReport && !!measures && !!tasks;
    const steps: JSX.Element[] = [];

    const formattedReportDate = statusReport?.reportDate
        ? dayjs.utc(statusReport?.reportDate).format('DD MMM YYYY')
        : null;

    steps.push(
        <PresentationTitleSlide
            key="title"
            title={`${statusReport?.title || 'Status Report'}${formattedReportDate ? ` (${formattedReportDate})` : ''}${!data?.statusReport?.utcCompletedDate ? ' - Draft' : ''}`}
            mission={mission}
            onSectionChanged={handleSectionChanged}
        />
    );

    let periodName: string;
    switch (statusReport?.reportPeriodType) {
        case ReportPeriodTypes.Month:
            periodName = 'Month';
            break;
        case ReportPeriodTypes.Quarter:
            periodName = 'Quarter';
            break;
        case ReportPeriodTypes.Half:
            periodName = 'Half';
            break;
        default:
            periodName = 'Period';
    }

    const styles = {
        twoColumnLayoutContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'stretch',
            minWidth: 0,
            minHeight: 'auto',
            gap: 'min(0.8vw, 16px)',
        } as CSSProperties,

        twoColumnLayoutContainerLeft: {
            flexGrow: 2,
            flexBasis: 0,
            display: 'flex',
            flexDirection: 'column',
            gap: 'min(0.8vw, 16px)',
        } as CSSProperties,

        twoColumnLayoutContainerRight: {
            flexGrow: 1,
            flexBasis: 0,
            display: 'flex',
            flexDirection: 'column',
            gap: 'min(0.8vw, 16px)',
        } as CSSProperties,
    };

    if (statusReport?.summaryText?.trim()) {
        const keyMeasuresOfSuccess =
            periodData && measures
                ? statusReport?.facts
                      ?.slice()
                      .filter((r) => r.isIncluded)
                      .filter((r) => r.measureId)
                      .sort(sorters.sequenceSorter)
                      .map((f) => {
                          const measure = measures?.find(
                              (m) => m.id === f.measureId
                          );
                          return {
                              measure: measure,
                              periodData: periodData.find(
                                  (pd) =>
                                      pd.measureId === measure?.id ||
                                      (measure?.isLinked &&
                                          measure.linkedFromMeasureId ===
                                              pd.measureId)
                              ),
                          };
                      })
                : [];

        steps.push(
            <PresentationSlide
                key="executive-summary"
                sectionKey="executive-summary"
                leftSize="none"
            >
                <PresentationSlide.ContentScrollContainer>
                    <div className="mintListHeader">
                        <h6>Executive Summary</h6>
                    </div>
                    <div style={{ paddingRight: 16 }} className="mintListBody">
                        <div style={styles.twoColumnLayoutContainer}>
                            <div style={styles.twoColumnLayoutContainerLeft}>
                                <h6 style={{ marginBottom: 0 }}>&nbsp;</h6>
                                <div className="mintMarkdownFrame">
                                    <Markdown
                                        source={statusReport.summaryText?.trim()}
                                        className="mintMarkdownBoxNoScroll"
                                    />
                                </div>
                            </div>

                            {!!keyMeasuresOfSuccess.length && (
                                <div
                                    style={styles.twoColumnLayoutContainerRight}
                                >
                                    <h6 style={{ marginBottom: 0 }}>
                                        Key Measures of Success
                                    </h6>

                                    {keyMeasuresOfSuccess.map((km) => {
                                        const measure = km.measure;
                                        const measurePeriodData = km.periodData;

                                        if (!measure || !measurePeriodData) {
                                            return null;
                                        }

                                        return (
                                            <div key={measure.id}>
                                                <PresentationMeasureBox
                                                    measure={measure}
                                                    periodData={
                                                        measurePeriodData
                                                    }
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    </div>
                </PresentationSlide.ContentScrollContainer>
            </PresentationSlide>
        );
    }

    steps.push(
        <MissionPresentationSlide
            key="mission"
            mission={mission}
            measures={measures}
            periodData={periodData}
            periodLabel={reportPeriodLabel}
        />
    );

    if (statusReport?.lastPeriodText?.trim()) {
        steps.push(
            <PresentationSlide
                key="last-period"
                sectionKey="last-period"
                leftSize="none"
            >
                <PresentationSlide.ContentScrollContainer>
                    <div className="mintListHeader">
                        <h6>Last {periodName}</h6>
                    </div>
                    <div style={{ paddingRight: 16 }} className="mintListBody">
                        <div className="mintMarkdownFrame">
                            <Markdown
                                source={statusReport.lastPeriodText?.trim()}
                                className="mintMarkdownBoxNoScroll"
                            />
                        </div>
                    </div>
                </PresentationSlide.ContentScrollContainer>
            </PresentationSlide>
        );
    }

    includedFacts?.forEach((f) => {
        const measure = f.measureId
            ? measures?.find((m) => m.id === f.measureId)
            : null;

        const measurePeriodData = f.measureId
            ? periodData.find(
                  (pd) =>
                      pd.measureId === f.measureId ||
                      (measure?.isLinked &&
                          measure.linkedFromMeasureId === f.measureId)
              )
            : null;

        const task = f.taskId ? tasks?.find((t) => t.id === f.taskId) : null;

        const linkedMeasures = task?.linkedMeasures
            .map((lm) => {
                const measure = measures?.find((m) => m.id === lm.measureId);
                const measurePeriodData = periodData.find(
                    (pd) =>
                        pd.measureId === measure?.id ||
                        (measure?.isLinked &&
                            measure.linkedFromMeasureId === pd.measureId)
                );
                return {
                    measure: measure,
                    periodData: measurePeriodData,
                };
            })
            .filter((m) => !!m.measure && !!m.periodData);

        steps.push(
            <PresentationSlide key={f.id} sectionKey={`fact_${f.id}`}>
                <PresentationSlide.ContentScrollContainer>
                    <div className="mintListHeader">
                        {!task ||
                            (task.utcDeleted !== null && (
                                <h6 style={{ fontStyle: 'italic' }}>
                                    This task has been deleted
                                </h6>
                            ))}
                        {task && (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: 8,
                                }}
                            >
                                <div style={{ flexGrow: 2, flexBasis: 1 }}>
                                    <h6
                                        style={{
                                            whiteSpace: 'pre-wrap',
                                            textDecoration: task?.utcDeleted
                                                ? 'line-through'
                                                : undefined,
                                            display: 'flex',
                                            flexDirection: 'row',
                                            gap: 8,
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Icon
                                            iconName="TaskGroup"
                                            title="Specified Task"
                                            styles={{
                                                root: {
                                                    color: highlightColour,
                                                },
                                            }}
                                        />
                                        <span>{task?.name}</span>
                                    </h6>
                                </div>

                                <div
                                    style={{
                                        flexGrow: 1,
                                        flexBasis: 1,
                                        alignSelf: 'end',
                                    }}
                                >
                                    <StatusReportPresentationTaskBar
                                        task={task}
                                    />
                                </div>
                            </div>
                        )}
                        {!measure ||
                            (measure.utcDeleted !== null && (
                                <h6 style={{ fontStyle: 'italic' }}>
                                    This measure of success has been deleted
                                </h6>
                            ))}
                        {measure && (
                            <h6
                                style={{
                                    whiteSpace: 'pre-wrap',
                                    textDecoration: measure?.utcDeleted
                                        ? 'line-through'
                                        : undefined,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: 8,
                                    alignItems: 'center',
                                }}
                            >
                                <Icon
                                    iconName="Trending12"
                                    title="Measure of Success"
                                    styles={{
                                        root: {
                                            color: highlightColour,
                                        },
                                    }}
                                />
                                <span>{measure?.name}</span>
                            </h6>
                        )}
                        {!!f.factText && (
                            <h6
                                style={{
                                    whiteSpace: 'pre-wrap',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: 8,
                                    alignItems: 'center',
                                }}
                            >
                                <Icon
                                    iconName="TextDocument"
                                    title="Custom Fact"
                                    styles={{
                                        root: {
                                            color: highlightColour,
                                        },
                                    }}
                                />
                                <span>{f.factText}</span>
                            </h6>
                        )}
                    </div>

                    <div className="mintListBody">
                        <div style={styles.twoColumnLayoutContainer}>
                            <div style={styles.twoColumnLayoutContainerLeft}>
                                {!!f.soWhatText && (
                                    <>
                                        <h6 style={{ textAlign: 'left' }}>
                                            So What
                                        </h6>

                                        <div className="mintMarkdownFrame">
                                            <Markdown
                                                source={f?.soWhatText}
                                                className="mintMarkdownBox"
                                            />
                                        </div>
                                    </>
                                )}
                                {!!f.insightText && (
                                    <>
                                        <h6 style={{ textAlign: 'left' }}>
                                            Insight
                                        </h6>
                                        <div className="mintMarkdownFrame">
                                            <Markdown
                                                source={f?.insightText}
                                                className="mintMarkdownBox"
                                            />
                                        </div>
                                    </>
                                )}
                                {!!f.actionText && (
                                    <>
                                        <h6
                                            style={{
                                                textAlign: 'left',
                                            }}
                                        >
                                            Action
                                        </h6>
                                        <div className="mintMarkdownFrame">
                                            <Markdown
                                                source={f?.actionText}
                                                className="mintMarkdownBox"
                                            />
                                        </div>
                                    </>
                                )}

                                {!!f.actions.length && (
                                    <>
                                        <h6
                                            style={{
                                                textAlign: 'left',
                                            }}
                                        >
                                            Action
                                        </h6>
                                        <div className="mintMarkdownFrame">
                                            <div className="mintMarkdownBox">
                                                <ul>
                                                    {f.actions.map((a) => (
                                                        <li key={a.id}>
                                                            <div>{a.name}</div>
                                                            <div
                                                                style={{
                                                                    fontSize:
                                                                        '1vw',
                                                                }}
                                                            >
                                                                {a.due
                                                                    ? `Due: ${dayjs.utc(a.due).format('DD MMM YYYY')}`
                                                                    : ''}
                                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                                {a.done
                                                                    ? `Done: ${dayjs.utc(a.done).format('DD MMM YYYY')}`
                                                                    : ''}
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>

                            {!!linkedMeasures?.length && (
                                <div
                                    style={styles.twoColumnLayoutContainerRight}
                                >
                                    <h6>Linked Measures of Success</h6>
                                    {linkedMeasures.map((lm) => (
                                        <div key={lm.measure?.id}>
                                            {!!lm.measure && lm.periodData && (
                                                <PresentationMeasureBox
                                                    measure={lm.measure}
                                                    periodData={lm.periodData}
                                                />
                                            )}
                                        </div>
                                    ))}
                                </div>
                            )}

                            {!!measure && !!measurePeriodData && (
                                <div
                                    style={styles.twoColumnLayoutContainerRight}
                                >
                                    <h6>Measure Details</h6>

                                    <PresentationMeasureBox
                                        measure={measure}
                                        periodData={measurePeriodData}
                                        showChart
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </PresentationSlide.ContentScrollContainer>
            </PresentationSlide>
        );
    });

    const allActions =
        statusReport?.facts
            .slice()
            .filter((f) => f.isIncluded)
            .sort(sorters.sequenceSorter)
            .flatMap((f) => {
                return f.actions.slice().sort(sorters.sequenceSorter);
            }) || [];

    if (statusReport?.nextPeriodText?.trim() || allActions.length) {
        steps.push(
            <PresentationSlide
                key="next-period"
                sectionKey="next-period"
                leftSize="none"
            >
                <PresentationSlide.ContentScrollContainer>
                    <div className="mintListHeader">
                        <h6>Next {periodName} Actions</h6>
                    </div>
                    <div style={{ paddingRight: 16 }} className="mintListBody">
                        <div className="mintMarkdownFrame">
                            <Markdown
                                source={statusReport?.nextPeriodText?.trim()}
                                className="mintMarkdownBoxNoScroll"
                            />

                            {!!allActions.length && (
                                <div className="mintMarkdownBoxNoScroll">
                                    <p> </p>
                                    <h6>Fact Actions</h6>
                                    <ul>
                                        {allActions.map((a) => {
                                            return (
                                                <li key={a.id}>
                                                    {a.name}
                                                    <br />
                                                    <span
                                                        style={{
                                                            fontSize: '0.8em',
                                                            paddingTop: 16,
                                                        }}
                                                    >
                                                        Due:{' '}
                                                        {a.due
                                                            ? `${dayjs.utc(a.due).format('DD MMM YYYY')}`
                                                            : '-'}
                                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                                        {a.done
                                                            ? `Done: ${dayjs.utc(a.done).format('DD MMM YYYY')}`
                                                            : ''}
                                                    </span>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>
                </PresentationSlide.ContentScrollContainer>
            </PresentationSlide>
        );
    }

    if (statusReport?.risksAndOpportunitiesText?.trim()) {
        steps.push(
            <PresentationSlide
                key="risks-opportunitie"
                sectionKey="risks-opportunities"
            >
                <PresentationSlide.ContentScrollContainer>
                    <div
                        className="mintListBody"
                        style={{
                            padding: 0,
                        }}
                    >
                        {!!statusReport?.risksAndOpportunitiesText?.trim() && (
                            <div>
                                <div className="mintListHeader">
                                    <h6>Risks / Opportunities</h6>
                                </div>

                                <div style={{ padding: 16 }}>
                                    <div className="mintMarkdownFrame">
                                        <Markdown
                                            source={
                                                statusReport?.risksAndOpportunitiesText
                                            }
                                            className="mintMarkdownBoxNoScroll"
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </PresentationSlide.ContentScrollContainer>
            </PresentationSlide>
        );
    }

    if (statusReport?.supportText?.trim()) {
        steps.push(
            <PresentationSlide key="support" sectionKey="support">
                <PresentationSlide.ContentScrollContainer>
                    <div
                        className="mintListBody"
                        style={{
                            padding: 0,
                        }}
                    >
                        {!!statusReport?.supportText?.trim() && (
                            <div>
                                <div className="mintListHeader">
                                    <h6>Support</h6>
                                </div>
                                <div style={{ padding: 16 }}>
                                    <div className="mintMarkdownFrame">
                                        <Markdown
                                            source={statusReport?.supportText}
                                            className="mintMarkdownBoxNoScroll"
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </PresentationSlide.ContentScrollContainer>
            </PresentationSlide>
        );
    }

    return (
        <StepContainer
            isReady={isReady}
            steps={steps}
            onInitialized={handleInitialized}
        />
    );
}

// type MeasureType = ExtractQueryArrayType<
//     GetMeasuresForStatusReportQuery,
//     ['measures']
// >;

type TaskType = ExtractQueryArrayType<GetTasksForStatusReportQuery, ['tasks']>;

// function StatusReportPresentationMeasureBar(props: {
//     measure: MeasureType;
// }): JSX.Element {
//     const { measure } = props;

//     const lastAsOf = measure.lastAsOf;

//     const formatters = useFormatters();

//     const target = lastAsOf?.values?.find(
//         (v) => v.seriesType?.name === MeasureSeriesNames.Target
//     );

//     const actual = lastAsOf?.values?.find(
//         (v) => v.seriesType?.name === MeasureSeriesNames.Actual
//     );

//     const formattedPercentage =
//         target !== undefined
//             ? formatters.formatMeasureStatus(
//                   lastAsOf?.statusValue,
//                   measure.isStatusLimited
//               )
//             : '';

//     return (
//         <div
//             style={{
//                 display: 'flex',
//                 flexDirection: 'row',
//                 gap: 16,
//                 alignItems: 'center',
//                 justifyContent: 'end',
//                 paddingRight: 16,
//             }}
//         >
//             {target?.formatStr !== undefined && (
//                 <h6>Target: {target?.formatStr}</h6>
//             )}
//             {actual?.formatStr !== undefined && (
//                 <h6>Actual: {actual?.formatStr}</h6>
//             )}

//             {formattedPercentage !== '' && (
//                 <h6>Status: {formattedPercentage}</h6>
//             )}

//             {!!lastAsOf?.arrowColour &&
//                 !!lastAsOf?.arrowDirection &&
//                 target !== undefined && (
//                     <MeasureArrowIcon
//                         arrowColour={
//                             target !== undefined
//                                 ? lastAsOf?.arrowColour
//                                 : 'NONE'
//                         }
//                         arrowDirection={
//                             target !== undefined
//                                 ? lastAsOf?.arrowDirection
//                                 : 'NONE'
//                         }
//                         iconFontSize={24}
//                     />
//                 )}
//         </div>
//     );
// }

export function truncateToThreeWords(input: string | null | undefined): string {
    if (!input) {
        return '';
    }
    const words = input.split(/\s+/);
    if (words.length <= 3) return input;
    return words.slice(0, 3).join(' ') + '...';
}

function StatusReportPresentationTaskBar(props: {
    task: TaskType;
}): JSX.Element {
    const { task } = props;

    const formatter = new Intl.NumberFormat(undefined, {
        style: 'percent',
        maximumFractionDigits: 1,
    });

    const formattedPercentage = formatter.format(task?.percentComplete ?? 0);

    return (
        <div
            style={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'row',
                gap: 16,
                alignItems: 'end',
                justifyContent: 'space-between',
                fontSize: '0.8vw',
            }}
        >
            {task.taskCategory && (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'end',

                        gap: 4,
                    }}
                >
                    <span
                        style={{
                            color: `#${task.taskCategory?.colourHex ?? 'fff'}`,
                            fontSize: '1vw',
                        }}
                    >
                        &#x25A0;
                    </span>
                    <span>
                        <h6 title={task.taskCategory?.name || ''}>
                            {truncateToThreeWords(task.taskCategory?.name)}
                        </h6>
                    </span>
                </div>
            )}

            <h6
                style={{
                    whiteSpace: 'nowrap',
                }}
            >
                Percent Complete: {formattedPercentage}
            </h6>
        </div>
    );
}
