import React, { useEffect, useState } from 'react';
import orderBy from 'lodash/orderBy';
import {
    Checkbox,
    Link,
    Pivot,
    PivotItem,
    Stack,
    TextField,
} from '@fluentui/react';
import {
    useGetMeasuresForStatusReportQuery,
    useGetTasksForStatusReportQuery,
} from '../../../data/types';
import Loading from '../../../components/Loading';
import { MeasureCheckBoxList } from '../../../components/inputs/MeasureCheckBoxList';

export function StatusReportFactPicker(props: {
    utcDataDate: string | null;
    utcCompletedDate: string | null;
    tenantId: string;
    missionId: string;
    canAddTaskFacts?: boolean;
    onFactsChanged: (
        measures: { id: string; missionSequence?: number }[],
        tasks: { id: string; missionSequence?: number }[],
        customFactTexts: { id: string | null; text: string | null }[]
    ) => void;
    selectedFacts: {
        id: string | null;
        taskId: string | null;
        measureId: string | null;
        factText: string | null;
    }[];
}): JSX.Element {
    const { onFactsChanged } = props;

    const [selectedMeasures, setSelectedMeasures] = useState<
        { id: string; missionSequence?: number }[]
    >([]);
    const [selectedTasks, setSelectedTasks] = useState<
        { id: string; missionSequence?: number }[]
    >([]);
    const [customFacts, setCustomFacts] = useState<
        { id: string | null; text: string | null }[]
    >([]);

    useEffect(() => {
        onFactsChanged(selectedMeasures, selectedTasks, customFacts);
    }, [selectedMeasures, selectedTasks, customFacts, onFactsChanged]);

    useEffect(() => {
        setSelectedMeasures(
            props.selectedFacts
                .filter((f) => f.measureId)
                .map((f) => ({ id: f.measureId || '' }))
        );
        setSelectedTasks(
            props.selectedFacts
                .filter((f) => f.taskId)
                .map((f) => ({ id: f.taskId || '' }))
        );
        setCustomFacts([
            ...props.selectedFacts
                .filter((f) => !f.taskId && !f.measureId)
                .map((f) => ({ id: f.id, text: f.factText })),
            { id: null, text: '' },
        ]);
    }, [props.selectedFacts]);

    const forDateTime = props.utcDataDate || props.utcCompletedDate;

    const { data: measureData, loading: measuresLoading } =
        useGetMeasuresForStatusReportQuery({
            fetchPolicy: 'no-cache', // We don't want the historic results polluting the cache
            variables: {
                tenantId: props.tenantId,
                missionId: props.missionId,
                forDateTime: forDateTime,
                includeDeleted: true,
            },
        });

    const { data: taskData, loading: tasksLoading } =
        useGetTasksForStatusReportQuery({
            skip: !props.canAddTaskFacts,
            fetchPolicy: 'no-cache', // We don't want the historic results polluting the cache
            variables: {
                tenantId: props.tenantId,
                missionId: props.missionId,
                forDateTime: forDateTime,
                includeDeleted: true,
            },
        });

    const measures = orderBy(
        measureData?.measures?.filter(
            (m) =>
                m.utcDeleted === null ||
                selectedMeasures.some((sm) => sm.id === m.id)
        ),
        'sequence'
    );

    const specifiedTasks = orderBy(
        taskData?.tasks
            ?.filter((t) => !t.parentTaskId && !t.isDuplicate)
            .filter(
                (t) =>
                    t.utcDeleted === null ||
                    selectedTasks.some((sm) => sm.id === t.id)
            ),
        'sequence'
    );

    const selectAllTasks = () => {
        const selected =
            specifiedTasks?.map((t) => ({
                id: t.id || '',
                missionSequence: t.sequence,
            })) || [];

        setSelectedTasks(selected);
    };

    const handleCustomTextChange = (
        _ev: React.FormEvent,
        newValue?: string | undefined
    ) => {
        setCustomFacts([
            ...customFacts.filter((f) => f.id),
            {
                id: null,
                text: newValue || '',
            },
        ]);
    };

    return (
        <Pivot>
            <PivotItem headerText="Measures of Success">
                <Stack tokens={{ padding: 16 }}>
                    <Stack.Item>
                        <MeasureCheckBoxList
                            measures={measures.map((m) => {
                                return {
                                    ...m,
                                    measureGroupId: m.group?.id || null,
                                };
                            })}
                            isLoading={measuresLoading}
                            selectedMeasures={selectedMeasures}
                            onSelectedMeasuresChanged={setSelectedMeasures}
                        />
                    </Stack.Item>
                </Stack>
            </PivotItem>
            {props.canAddTaskFacts && (
                <PivotItem headerText="Specified Tasks">
                    <Stack tokens={{ childrenGap: 8, padding: 16 }}>
                        {specifiedTasks.length > 0 && (
                            <Stack.Item>
                                <Link
                                    onClick={selectAllTasks}
                                    disabled={
                                        selectedTasks.length ===
                                        specifiedTasks?.length
                                    }
                                >
                                    Select All
                                </Link>
                            </Stack.Item>
                        )}
                        {specifiedTasks?.map((t) => {
                            return (
                                <Checkbox
                                    key={t.id}
                                    label={t.name || t.id || ''}
                                    checked={selectedTasks.some(
                                        (st) => st.id === t.id
                                    )}
                                    onChange={(_ev, checked) => {
                                        const updated = selectedTasks.filter(
                                            (st) => st.id !== t.id
                                        );
                                        if (checked && t.id) {
                                            updated.push({
                                                id: t.id,
                                                missionSequence: t.sequence,
                                            });
                                        }
                                        setSelectedTasks(updated);
                                    }}
                                />
                            );
                        })}
                        {tasksLoading && <Loading />}
                    </Stack>
                </PivotItem>
            )}
            <PivotItem headerText="Custom">
                <Stack tokens={{ childrenGap: 8, padding: 16 }}>
                    <TextField
                        label="New Fact"
                        autoAdjustHeight
                        multiline
                        rows={3}
                        onChange={handleCustomTextChange}
                        placeholder="What are the facts? Not assumptions hearsay or otherwise."
                    />
                </Stack>
            </PivotItem>
        </Pivot>
    );
}
