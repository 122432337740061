import React from 'react';
import { Guid } from 'guid-typescript';
import orderBy from 'lodash/orderBy';

import {
    TextField,
    Stack,
    IRawStyle,
    mergeStyleSets,
    Text,
    Link,
    Label,
    CommandBar,
} from '@fluentui/react';
import useForm from '../../../hooks/useForm';
import {
    ResourceQl,
    useDeleteResourceMutation,
    useGetResourceQuery,
    useUpdateResourceMutation,
} from '../../../data/types';
import { EditPanel } from '../../../components/shared/EditPanel';
import { useStateContext } from '../../../services/contextProvider';
import { UserPicker } from '../../../components/UserPicker';
import DeleteModal from '../../../components/shared/DeleteModal';
import { useThemes } from '../../../hooks/useThemes';
import { navigation, PathParams } from '../../../services/navigation';
import { PanelCommandBarButton } from '../../../components/PanelCommandBarButton';
import { ResourceTransferDialog } from './ResourceTransferDialog';
import { Chip } from '../../../components/Chip';
import { TaskStatusColours } from '../../../Colours';

export type ResourceEditPanelProps = {
    showPanel: boolean;
    resource?: ResourceQl | null;
    onCancel: () => void;
    onSave: () => void;
    fyCodeFilter: string | null;
};

export function ResourceEditPanel(props: ResourceEditPanelProps): JSX.Element {
    const { currentTheme } = useThemes();

    const { currentTenantId, currentTenantCode } = useStateContext();

    const [isConfirmingDelete, setIsConfirmingDelete] = React.useState(false);
    const [isTransferringResourceOpen, setIsTransferringResourceOpen] =
        React.useState(false);
    const [isDeleting, setIsDeleting] = React.useState(false);
    const [deleteErrorResult, setDeleteErrorResult] = React.useState<{
        message: string | null;
    } | null>();

    const [updateResource, { loading: isSaving, error: saveError }] =
        useUpdateResourceMutation({
            onCompleted: props.onSave,
            refetchQueries: ['GetResources'],
        });

    const [deleteResource, { error: deleteError }] = useDeleteResourceMutation({
        variables: {
            id: props.resource?.id || '',
            tenantId: currentTenantId || '',
            restore: false,
        },
        refetchQueries: ['GetResources'],
    });

    const { data } = useGetResourceQuery({
        skip: !props.resource?.id || !props.showPanel,
        fetchPolicy: 'cache-and-network',
        variables: {
            id: props.resource?.id || '',
            tenantId: currentTenantId || '',
        },
    });

    type formValuesType = {
        name: string;
        userId: string | null;
    };

    const formDefaultValues: formValuesType = {
        name: props.resource?.name || '',
        userId: props.resource?.userId || '',
    };

    const formCallback = (values: formValuesType): void => {
        updateResource({
            variables: {
                tenantId: currentTenantId || '',
                input: {
                    id: props.resource?.id || Guid.createEmpty().toString(),
                    name: values.name,
                    userId: values.userId || null,
                    version: props.resource?.version || '',
                },
            },
        });
    };

    const formValidate = (
        values: formValuesType
    ): {
        [key: string]: string;
    } => {
        const formErrorMessages = {
            name: '',
            userId: '',
        };

        if (!values.name) {
            formErrorMessages.name = 'Enter a name';
        }

        return formErrorMessages;
    };

    const { errors, handleChange, handleSubmit, updateValue, values } = useForm(
        formDefaultValues,
        formCallback,
        formValidate
    );

    const onUserChange = (
        users: {
            userId: string;
            name: string;
        }[]
    ): void => {
        updateValue('name', users?.length > 0 ? users[0].name : null);
        updateValue('userId', users?.length > 0 ? users[0].userId : null);
    };

    const selectedUsers = props.resource?.userId
        ? [
              {
                  userId: props.resource?.userId,
                  displayName: props.resource?.displayName,
              },
          ]
        : [];

    const onDeleteClick =
        props.resource?.id && props.resource?.taskCount === 0
            ? (): void => {
                  setIsConfirmingDelete(true);
                  setIsDeleting(false);
                  setDeleteErrorResult(null);
              }
            : undefined;

    const deleteAction = async (): Promise<void> => {
        setIsDeleting(true);
        const result = await deleteResource();
        if (!result.data?.resourceDelete?.success) {
            setDeleteErrorResult(result.data?.resourceDelete || null);
        }
        setIsDeleting(false);

        if (props.onCancel) {
            props.onCancel();
        }
    };

    const listStyle: IRawStyle = {
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
    };

    const listItemStyle: IRawStyle = {
        padding: 8,
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
        border: `solid 1px ${currentTheme.palette.neutralLight}`,
        backgroundColor: currentTheme.palette.neutralLighterAlt,
    };

    const classNames = mergeStyleSets({
        list: listStyle,
        listItem: listItemStyle,
        fyAndText: {
            display: 'flex',
            flexDirection: 'row',
            gap: 8,
        },
    });

    const fyAndTasks =
        data?.resource?.tasks.map((t) => {
            const owningMission = t.mission ?? t.resourcedFromTask?.mission;
            const fy = owningMission?.team?.division?.financialYear;

            // navigate to the requester if the task hasn't been accepted
            const isOnMission = t.utcAccepted && t.mission !== null;
            const navTask = isOnMission
                ? t.isDuplicate
                    ? t.parentTask
                    : t
                : t.resourcedFromTask;

            const isNavTaskImplied = !!navTask?.parentTask;

            const pathParams: PathParams | null =
                owningMission?.id &&
                owningMission.team?.code &&
                fy?.code &&
                currentTenantCode
                    ? {
                          tenantCode: currentTenantCode,
                          financialYearCode: fy.code || undefined,
                          missionId: owningMission.id || undefined,
                          teamCode: owningMission.team.code || undefined,
                          impliedTaskId: isNavTaskImplied
                              ? navTask.id || undefined
                              : undefined,
                          specifiedTaskId: isNavTaskImplied
                              ? navTask.parentTask?.id || undefined
                              : navTask?.id || undefined,
                      }
                    : null;

            return {
                fyStartDate: fy?.startDate,
                fyCode: fy?.code,
                taskId: t.id,
                taskName: t.name,
                utcAccepted: t.utcAccepted,
                utcRejected: t.utcRejected,
                href: pathParams
                    ? navigation.getPathForParams(pathParams)
                    : null,
            };
        }) || [];

    const commandBar = (
        <div style={{ width: '100%' }}>
            <ResourceTransferDialog
                resource={data?.resource}
                onDismiss={() => setIsTransferringResourceOpen(false)}
                isOpen={isTransferringResourceOpen}
            />

            <CommandBar
                styles={{
                    root: {
                        height: 32,
                        paddingRight: 8, // Gap to save / close icons on right
                    },
                }}
                items={[
                    {
                        key: 'transfer',
                        text: 'Transfer Dependencies...',
                        onClick: () => setIsTransferringResourceOpen(true),
                        iconProps: { iconName: 'Send' },
                    },
                ]}
                buttonAs={PanelCommandBarButton}
                overflowButtonAs={PanelCommandBarButton}
            />
        </div>
    );

    return (
        <EditPanel
            activeViewName="ResourceEdit"
            onDismiss={props.onCancel}
            showPanel={props.showPanel}
            headerText={props.resource?.id ? 'Edit Resource' : 'New Resource'}
            isSaving={isSaving}
            isValid={true}
            saveErrorMessage={saveError?.message || null}
            onUpdateClick={handleSubmit}
            onDeleteClick={onDeleteClick}
            commandBar={commandBar}
        >
            <DeleteModal
                activeViewName="ResourceDelete"
                isOpen={isConfirmingDelete}
                onDismiss={(): void => setIsConfirmingDelete(false)}
                message={'Are you sure you want to delete this resource?'}
                deleteAction={deleteAction}
                isDeleting={isDeleting}
                error={deleteError || deleteErrorResult}
            />

            <Stack tokens={{ childrenGap: 8 }}>
                <TextField
                    label="Name"
                    required
                    name="name"
                    autoComplete="off"
                    value={values.name}
                    disabled={!!values.userId}
                    errorMessage={errors.name}
                    onChange={handleChange}
                />

                <UserPicker
                    label="Linked to User"
                    onChange={onUserChange}
                    itemLimit={1}
                    defaultSelectedUsers={selectedUsers}
                />

                <div className={classNames.list}>
                    {fyAndTasks.length > 0 && <Label>Tasks</Label>}

                    {orderBy(
                        fyAndTasks,
                        ['fyCode', 'taskName'],
                        ['desc', 'asc']
                    ).map((t) => {
                        return (
                            <div key={t.taskId} className={classNames.listItem}>
                                <div className={classNames.fyAndText}>
                                    <Text variant="tiny">{t.fyCode}</Text>
                                    <Text variant="tiny">
                                        {t.href ? (
                                            <Link href={t.href}>
                                                {t.taskName}
                                            </Link>
                                        ) : (
                                            t.taskName
                                        )}
                                    </Text>
                                </div>

                                {t.utcAccepted ? (
                                    <Chip
                                        iconName="CheckMark"
                                        text="Accepted"
                                        backgroundColor={
                                            TaskStatusColours.onTarget
                                        }
                                    />
                                ) : t.utcRejected ? (
                                    <Chip
                                        iconName="BlockContact"
                                        text="Rejected"
                                        backgroundColor={
                                            TaskStatusColours.rejected
                                        }
                                    />
                                ) : (
                                    <Chip
                                        iconName="TemporaryUser"
                                        text="Requested"
                                        backgroundColor={
                                            TaskStatusColours.notStarted
                                        }
                                    />
                                )}
                            </div>
                        );
                    })}
                </div>
            </Stack>
        </EditPanel>
    );
}
