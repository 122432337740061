import React from 'react';
import { mergeStyleSets, Text } from '@fluentui/react';
import dayjs from 'dayjs';
import { InlineDatePicker } from '../../../components/inputs/InlineDatePicker';

export function StatusReportAsOfDatePicker(props: {
    shimmer?: boolean;
    value: string | null;
    onSelectDate: (newValue: string | null | undefined) => void;
    mission: { utcCreated: string } | null | undefined;
}): JSX.Element {
    const dayjsValue = dayjs.utc(props.value);
    const dateValue = props.value
        ? new Date(dayjsValue.year(), dayjsValue.month(), dayjsValue.date())
        : new Date();

    const classNames = mergeStyleSets({
        container: {
            display: 'flex',
            flexDirection: 'row',
            gap: 4,
        },
    });

    return (
        <div className={classNames.container}>
            <Text style={{ fontWeight: 600 }}>Report Date: </Text>
            <InlineDatePicker
                styles={{
                    // Remove the little bit of margin that gets added at the bottom.
                    statusMessage: { marginTop: 0 },
                }}
                value={dateValue}
                onSelectDate={(date: Date | null | undefined) => {
                    if (date) {
                        const utcDate = dayjs
                            .utc(
                                `${date.getFullYear()}-${
                                    date.getMonth() + 1
                                }-${date.getDate()}`
                            )
                            .endOf('day')
                            .toISOString();

                        props.onSelectDate(utcDate);
                    }
                }}
                minDate={
                    props.mission?.utcCreated
                        ? dayjs.utc(props.mission?.utcCreated).toDate()
                        : undefined
                }
                maxDate={dayjs.utc().toDate()}
                formatDate={(date?: Date | null): string => {
                    return dayjs(date || undefined).format('DD MMM YYYY');
                }}
            />
        </div>
    );
}
