import {
    mergeStyles,
    ActionButton,
    Persona,
    PersonaSize,
    IContextualMenuItem,
} from '@fluentui/react';
import { MissionMentorDialog } from '../../../components/MissionMentorDialog';

import mai from '../../../images/mai.png';
import { InputTextName, useStatusReportMai } from '../hooks/useStatusReportMai';
import { GetStatusReportQuery } from '../../../data/types';

type StatusReportAskMaiButtonProps = {
    inputText: string;
    inputTextName: InputTextName;
    onInputTextChanged: (newInputText: string) => void;
    statusReport: GetStatusReportQuery['statusReport'];
};

export default function StatusReportAskMaiButton(
    props: StatusReportAskMaiButtonProps
) {
    const { inputText, onInputTextChanged, statusReport, inputTextName } =
        props;

    const {
        isEnabled,
        isDialogOpen,
        dismissDialog,
        maiContent,
        maiActions,
        generateDraft,
        factCheck,
        changeTone,
        simplify,
        isStreaming,
        isBuildingPrompt,
    } = useStatusReportMai(
        inputText,
        inputTextName,
        onInputTextChanged,
        statusReport
    );

    const buttonContainerStyles = mergeStyles({
        '& .ms-Image': {
            transition: 'transform 0.2s',
            transform: 'scale(0.9)',
            filter: 'grayscale(1) opacity(0.75)',
        },
        ':hover .ms-Image, .is-expanded .ms-Image': {
            transform: 'scale(1)',
            filter: 'grayscale(0%)',
        },
    });

    if (!isEnabled) {
        return null;
    }

    const menuItems: IContextualMenuItem[] =
        inputTextName === 'ExecutiveSummary'
            ? [
                  {
                      key: 'draft',
                      text: 'Generate Draft',
                      iconProps: { iconName: 'LightningBolt' },
                      onClick: () => {
                          generateDraft();
                      },
                  },

                  {
                      key: 'fact-check',
                      text: 'Fact Check',
                      iconProps: { iconName: 'ComplianceAudit' },
                      disabled: !props.inputText,
                      onClick: () => {
                          factCheck();
                      },
                  },
              ]
            : [];

    menuItems.push(
        ...[
            {
                key: 'adjust-tone',
                text: 'Adjust Tone',
                iconProps: { iconName: 'EmojiNeutral' },
                disabled: !props.inputText,
                subMenuProps: {
                    items: [
                        {
                            key: 'adjust-tone-formal',
                            text: 'Formal',
                            iconProps: {
                                iconName: 'BusinessCenterLogo',
                            },
                            onClick: () => {
                                changeTone('formal');
                            },
                            disabled: !props.inputText,
                        },
                        {
                            key: 'adjust-tone-friendly',
                            text: 'Friendly',
                            iconProps: { iconName: 'Emoji2' },
                            onClick: () => {
                                changeTone('friendly');
                            },
                            disabled: !props.inputText,
                        },
                        {
                            key: 'adjust-tone-positive',
                            text: 'Positive',
                            iconProps: { iconName: 'Like' },
                            onClick: () => {
                                changeTone('positive');
                            },
                            disabled: !props.inputText,
                        },
                        {
                            key: 'adjust-tone-inspirational',
                            text: 'Inspirational',
                            iconProps: { iconName: 'Lightbulb' },
                            onClick: () => {
                                changeTone('inspirational');
                            },
                            disabled: !props.inputText,
                        },
                        {
                            key: 'adjust-tone-urgent',
                            text: 'Urgent (Action-Oriented)',
                            iconProps: {
                                iconName: 'CommentUrgent',
                            },
                            onClick: () => {
                                changeTone('urgent');
                            },
                            disabled: !props.inputText,
                        },
                    ],
                },
            },
            {
                key: 'simplify',
                text: 'Simplify',
                iconProps: { iconName: 'OpenEnrollment' },
                disabled: !props.inputText,
                onClick: () => {
                    simplify();
                },
            },
        ]
    );

    return (
        <div className={buttonContainerStyles}>
            <ActionButton
                styles={{
                    root: { height: '' },
                }}
                menuProps={{
                    items: menuItems,
                }}
            >
                <Persona
                    imageUrl={mai}
                    text="MAI"
                    size={PersonaSize.size24}
                    showSecondaryText={false}
                    styles={{
                        root: { margin: 0 },
                    }}
                />
            </ActionButton>

            <MissionMentorDialog
                hidden={!isDialogOpen}
                onDismiss={dismissDialog}
                content={maiContent}
                actions={maiActions}
                isStreaming={isStreaming}
                isLoading={isBuildingPrompt}
            />
        </div>
    );
}
