import React from 'react';
import {
    IPersonaProps,
    ValidationState,
    Label,
    NormalPeoplePicker,
} from '@fluentui/react';
import {
    GetMissionSearchQuery,
    GetMissionSearchQueryVariables,
    GetMissionSearchDocument,
} from '../data/types';
import { useStateContext } from '../services/contextProvider';
import { useApolloClient } from '@apollo/client';
import { photoService } from '../services/photo.service';

export type MissionPickerProps = {
    financialYearCode?: string | null | undefined;
    divisionId?: string | null | undefined;
    selectedMissions: {
        id: string;
        owner: string | null;
        title: string | null;
        userId: string | null;
    }[];
    showOnlyWritable?: boolean;
    onChange: (missions: { id: string }[]) => void;
    itemLimit?: number | undefined;
    label: string;
    placeholder?: string;
    excludeMissionIds?: string[];
    disabled?: boolean;
};

export function MissionPicker(props: MissionPickerProps): JSX.Element {
    const { currentTenantId } = useStateContext();

    const client = useApolloClient();

    const updateSelectedMissions = (
        personas?: IPersonaProps[] | undefined
    ): void => {
        let missions: { id: string }[];
        if (personas) {
            missions = personas.map((persona) => {
                return {
                    id: persona.id || '',
                };
            });
        } else {
            missions = [];
        }
        if (props.onChange) {
            props.onChange(missions);
        }
    };

    const getDefaultSelectedItems = (): IPersonaProps[] => {
        const personas = props.selectedMissions.map((m) => {
            return {
                id: m?.id || '',
                text: `${m?.owner} - ${m?.title}`,
                secondaryText: m.title || '',
                imageUrl: photoService.getImageUrl(m?.userId),
            };
        });
        return personas;
    };

    const listContainsPersona = (
        persona: IPersonaProps,
        personas: IPersonaProps[]
    ): boolean => {
        if (!personas || !personas.length || personas.length === 0) {
            return false;
        }
        return personas.filter((item) => item.id === persona.id).length > 0;
    };

    const removeDuplicates = (
        personas: IPersonaProps[],
        possibleDupes: IPersonaProps[]
    ): IPersonaProps[] => {
        return personas.filter(
            (persona) => !listContainsPersona(persona, possibleDupes)
        );
    };

    const onResolveSuggestions = (
        filterText: string,
        currentPersonas: IPersonaProps[] | undefined,
        limitResults?: number
    ): IPersonaProps[] | Promise<IPersonaProps[]> => {
        if (filterText) {
            const query = client.query<
                GetMissionSearchQuery,
                GetMissionSearchQueryVariables
            >({
                query: GetMissionSearchDocument,
                variables: {
                    tenantId: currentTenantId || '',
                    searchText: filterText,
                    isWritable: props.showOnlyWritable || false,
                    isImportable: false,
                    includeInactive: false,
                    includeDeleted: false,
                    financialYearCode: props.financialYearCode || null,
                    divisionId: props.divisionId || null,
                },
            });

            return query.then((queryResult) => {
                const missions = queryResult.data.missions;

                if (missions) {
                    let filteredPersonas: IPersonaProps[] =
                        queryResult.data.missions.map((m) => {
                            return {
                                id: m?.id || '',
                                text: m?.owner || '',
                                secondaryText: m.title || '',
                                imageUrl: photoService.getImageUrl(m?.userId),
                            };
                        });

                    if (props.excludeMissionIds) {
                        filteredPersonas = filteredPersonas.filter(
                            (p) =>
                                !props.excludeMissionIds?.some((e) => e == p.id)
                        );
                    }

                    filteredPersonas = removeDuplicates(
                        filteredPersonas,
                        currentPersonas || []
                    );

                    filteredPersonas = limitResults
                        ? filteredPersonas.splice(0, limitResults)
                        : filteredPersonas;

                    return filteredPersonas;
                } else {
                    return [];
                }
            });
        } else {
            return [];
        }
    };

    const validateInput = (input: string): ValidationState => {
        if (input.indexOf('@') !== -1) {
            return ValidationState.valid;
        } else if (input.length > 1) {
            return ValidationState.warning;
        } else {
            return ValidationState.invalid;
        }
    };

    const getTextFromItem = (persona: IPersonaProps): string => {
        return persona.text as string;
    };

    const onChange = (items?: IPersonaProps[] | undefined): void => {
        updateSelectedMissions(items);
    };

    return (
        <div>
            <Label>{props.label}</Label>
            <NormalPeoplePicker
                itemLimit={props.itemLimit}
                disabled={props.disabled}
                onResolveSuggestions={onResolveSuggestions}
                getTextFromItem={getTextFromItem}
                className={'ms-PeoplePicker'}
                defaultSelectedItems={getDefaultSelectedItems()}
                key="missionPicker"
                onChange={onChange}
                onValidateInput={validateInput}
                inputProps={{
                    placeholder: props.placeholder,
                    'aria-label': 'Mission Picker',
                }}
            />
        </div>
    );
}
