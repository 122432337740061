import React, { useState } from 'react';
import {
    Text,
    IColumn,
    MessageBar,
    MessageBarType,
    Shimmer,
} from '@fluentui/react';
import { FlatList } from './FlatList';
import {
    GetTeamAlignmentQuery,
    GetTeamAlignmentQueryVariables,
    useGetAllTasksQuery,
    useGetTeamAlignmentQuery,
    useGetTeamUsageQuery,
} from '../data/types';
import orderBy from 'lodash/orderBy';
import dayjs from 'dayjs';
import { InternalLink } from './navigation';
import { ExtractQueryArrayType } from '../data/extendedTypes';
import { MissionFilters } from '../scenes/MissionBuilder/components/MissionFilterBar';
import { DetailsListCellItemContainer } from './shared/DetailsListCellItemContainer';
import { alignmentExport } from '../services/exporter';
import { useStateContext } from '../services/contextProvider';
import { useTaskWarnings } from '../hooks/useTaskWarning';
import { useRefetch } from '../hooks/useRefetch';
import { PeriodDropDown, PeriodDropDownPeriods } from './PeriodDropDown';

type TeamAlignmentMissionType = ExtractQueryArrayType<
    GetTeamAlignmentQuery,
    ['missions']
>;

type TeamAlignmentContributorType = ExtractQueryArrayType<
    GetTeamAlignmentQuery,
    ['contributors']
>;

export type AlignmentUsagePeriod = {
    start: string;
    end: string;
};

export type ResultType = {
    id: string | null;
    type: 'Mission' | 'Contributor';
    userId: string | null;
    userDisplayName: string | null;
    userEmailAddress: string | null;
    displaySequence: number;
    missionName: string;
    owner: string | null;
    title: string | null;
    missionStatement: string | null;
    teamCodeAndName?: string | null;
    teamCode?: string | null;
    teamName?: string | null;
    divisionName: string | null;
    specifiedTaskCount: number;
    specifiedTaskNotLinkedCount: number | string;
    specifiedTaskNoCategoryCount: number | string;
    specifiedTaskNoImpliedTasksCount: number | string;
    impliedTaskCount: number;
    impliedTaskNoStartDateCount: number | string;
    impliedTaskNoDueDateCount: number | string;
    resourceCount: number;
    resourcesAcceptedCount: number | string;
    resourcesRejectedCount: number | string;
    measureCount: number;
    behaviourCount: number;
    freedomCount: number;
    constraintCount: number;
    latestStatusReport: string;
    latestStatusReportId: string | null;
    latestIncDraftStatusReport: string;
    latestIncDraftStatusReportId: string | null;
    taskNotificationCount: number;
    sortSequence: number;
    lastSeen: string | null;
    lastSeenUtc: string | null;
    totalUsage: string | null;
    totalUsageInMinutes: number;
    totalDistinctDays: number;
    outstandingDependencyCount: number;
    source?: TeamAlignmentMissionType;
};

export type AlignmentListProps = {
    tenantId: string;
    tenantCode: string;
    financialYearCode: string;
    teamId?: string | undefined;
    defaultColumns?: (keyof ResultType)[];
    initialSort?: string[];
    initialSortOrders?: (boolean | 'asc' | 'desc')[];
    filters?: MissionFilters;
};

export function AlignmentList(props: AlignmentListProps): JSX.Element {
    const { configuration, currentRoles } = useStateContext();

    const isAdmin = currentRoles.some((r) =>
        ['GlobalAdmin', 'Developer'].includes(r)
    );

    const [columnsIncludeUsage, setColumnsIncludeUsage] = useState(false);

    const [usagePeriod, setUsagePeriod] = useState<AlignmentUsagePeriod>();

    const [selectedPeriod, setSelectedPeriod] =
        useState<PeriodDropDownPeriods>('Last90');

    const [selectedPeriodDisplayName, setSelectedPeriodDisplayName] =
        useState<string>('Last 90 Days');

    type FlatListColumn = {
        sortFieldName?: keyof ResultType;
        fieldName: keyof ResultType;
        name: string;
        isString: boolean;
        align?: 'left' | 'right' | 'center';
        isUsage?: boolean;
    } & Partial<IColumn>;

    const numberColumnMinWidth = 120;
    const numberColumnMaxWidth = 120;

    const availableColumns: FlatListColumn[] = [
        {
            fieldName: 'displaySequence',
            name: '#',
            isString: false,
            minWidth: 32,
            maxWidth: 32,
        },
        {
            fieldName: 'userDisplayName',
            name: 'User Name',
            isString: true,
            minWidth: 200,
            maxWidth: 400,
        },
        {
            fieldName: 'missionName',
            name: 'Mission',
            isString: true,
            minWidth: 200,
            maxWidth: 400,
        },
        {
            fieldName: 'owner',
            name: 'Mission Owner',
            isString: true,
            minWidth: 200,
            maxWidth: 400,
        },
        {
            fieldName: 'title',
            name: 'Mission Title',
            isString: true,
            minWidth: 200,
            maxWidth: 400,
        },
        {
            fieldName: 'missionStatement',
            name: 'Mission Statement',
            isString: true,
            minWidth: 200,
            maxWidth: 400,
        },
        {
            fieldName: 'measureCount',
            name: '# Measures of Success',
            isString: false,
            minWidth: numberColumnMinWidth,
            maxWidth: numberColumnMaxWidth,
            align: 'center',
        },
        {
            fieldName: 'specifiedTaskCount',
            name: '# Specified Tasks',
            isString: false,
            minWidth: numberColumnMinWidth,
            maxWidth: numberColumnMaxWidth,
            align: 'center',
        },
        {
            fieldName: 'specifiedTaskNotLinkedCount',
            name: '# Specified Tasks\n(Not Linked)',
            isString: false,
            minWidth: numberColumnMinWidth,
            maxWidth: numberColumnMaxWidth,
            align: 'center',
        },
        {
            fieldName: 'specifiedTaskNoCategoryCount',
            name: '# Specified Tasks\n(No Category)',
            isString: false,
            minWidth: numberColumnMinWidth,
            maxWidth: numberColumnMaxWidth,
            align: 'center',
        },
        {
            fieldName: 'specifiedTaskNoImpliedTasksCount',
            name: '# Specified Tasks\n(No Implied Tasks)',
            isString: false,
            minWidth: numberColumnMinWidth,
            maxWidth: numberColumnMaxWidth,
            align: 'center',
        },
        {
            fieldName: 'impliedTaskCount',
            name: '# Implied Tasks',
            isString: false,
            minWidth: numberColumnMinWidth,
            maxWidth: numberColumnMaxWidth,
            align: 'center',
        },
        {
            fieldName: 'impliedTaskNoStartDateCount',
            name: '# Implied Tasks\n(No Start Date)',
            isString: false,
            minWidth: numberColumnMinWidth,
            maxWidth: numberColumnMaxWidth,
            align: 'center',
        },
        {
            fieldName: 'impliedTaskNoDueDateCount',
            name: '# Implied Tasks\n(No Due Date)',
            isString: false,
            minWidth: numberColumnMinWidth,
            maxWidth: numberColumnMaxWidth,
            align: 'center',
        },
        {
            fieldName: 'resourceCount',
            name: '# Resources',
            isString: false,
            minWidth: numberColumnMinWidth,
            maxWidth: numberColumnMaxWidth,
            align: 'center',
        },
        {
            fieldName: 'resourcesAcceptedCount',
            name: '# Resources Accepted',
            isString: false,
            minWidth: numberColumnMinWidth,
            maxWidth: numberColumnMaxWidth,
            align: 'center',
        },
        {
            fieldName: 'resourcesRejectedCount',
            name: '# Resources Rejected',
            isString: false,
            minWidth: numberColumnMinWidth,
            maxWidth: numberColumnMaxWidth,
            align: 'center',
        },
        {
            fieldName: 'behaviourCount',
            name: '# Behaviours',
            isString: false,
            minWidth: numberColumnMinWidth,
            maxWidth: numberColumnMaxWidth,
            align: 'center',
        },
        {
            fieldName: 'freedomCount',
            name: '# Freedoms',
            isString: false,
            minWidth: numberColumnMinWidth,
            maxWidth: numberColumnMaxWidth,
            align: 'center',
        },
        {
            fieldName: 'constraintCount',
            name: '# Constraints',
            isString: false,
            minWidth: numberColumnMinWidth,
            maxWidth: numberColumnMaxWidth,
            align: 'center',
        },
        {
            fieldName: 'outstandingDependencyCount',
            name: '# Outstanding Dependencies',
            isString: false,
            minWidth: numberColumnMinWidth,
            maxWidth: numberColumnMaxWidth,
            align: 'center',
        },
        {
            fieldName: 'taskNotificationCount',
            name: '# Task Notifications',
            isString: false,
            minWidth: numberColumnMinWidth,
            maxWidth: numberColumnMaxWidth,
            align: 'center',
        },
        {
            fieldName: 'latestStatusReport',
            name: 'Latest Status Report',
            isString: false,
            minWidth: 200,
            maxWidth: 400,
            align: 'right',
        },
        {
            fieldName: 'latestIncDraftStatusReport',
            name: 'Latest Status Report (Inc. Draft)',
            isString: false,
            minWidth: 200,
            maxWidth: 400,
            align: 'right',
        },
        {
            fieldName: 'lastSeen',
            sortFieldName: 'lastSeenUtc',
            name: `Last Seen (${selectedPeriodDisplayName})`,
            isString: true,
            minWidth: 160,
            maxWidth: 160,
            align: 'center',
            isUsage: true,
        },
        {
            fieldName: 'totalUsage',
            sortFieldName: 'totalUsageInMinutes',
            name: `Online Time (${selectedPeriodDisplayName})`,
            isString: false,
            minWidth: 160,
            maxWidth: 160,
            align: 'center',
            isUsage: true,
        },
    ];

    if (isAdmin) {
        availableColumns.splice(
            availableColumns.findIndex(
                (c) => c.fieldName === 'userDisplayName'
            ),
            0,
            {
                fieldName: 'userEmailAddress',
                name: 'User Email',
                isString: true,
                minWidth: 200,
                maxWidth: 400,
            }
        );
        availableColumns.push({
            fieldName: 'totalDistinctDays',
            sortFieldName: 'totalDistinctDays',
            name: `Distinct Days Online (${selectedPeriodDisplayName})`,
            isString: false,
            minWidth: 160,
            maxWidth: 160,
            align: 'center',
            isUsage: true,
        });
    }

    if (!props.teamId) {
        availableColumns.splice(1, 0, {
            fieldName: 'teamName',
            sortFieldName: 'teamName',
            name: 'Team Name',
            isString: true,
            minWidth: 200,
            maxWidth: 400,
        });

        availableColumns.splice(1, 0, {
            fieldName: 'teamCode',
            sortFieldName: 'teamCode',
            name: 'Team Code',
            isString: true,
            minWidth: 200,
            maxWidth: 400,
        });

        availableColumns.splice(1, 0, {
            fieldName: 'teamCodeAndName',
            sortFieldName: 'teamCode',
            name: 'Team',
            isString: true,
            minWidth: 200,
            maxWidth: 400,
        });

        availableColumns.splice(1, 0, {
            fieldName: 'divisionName',
            name: 'Division',
            isString: true,
            minWidth: 200,
            maxWidth: 400,
        });
    }

    const {
        items,
        isRefetching,
        isRefetchingTasks,
        isRefetchingUsage,
        loading,
        tasksLoading,
        usageLoading,
        refreshData,
    } = useAlignmentItems(
        usagePeriod,
        props.tenantId,
        props.teamId,
        props.financialYearCode,
        columnsIncludeUsage
    );

    const defaultOrder = [...(props.initialSort ?? []), 'sortSequence'];

    orderBy(items || [], defaultOrder, props.initialSortOrders).forEach(
        (t, i) => {
            t.displaySequence = i + 1;
        }
    );

    const { filteredItems } = useMissionFilters(props.filters, items);

    const renderItemColumn = (
        item?: ResultType,
        _index?: number,
        column?: IColumn
    ) => {
        if (!item) {
            return null;
        }

        let fieldContent: JSX.Element | null = null;

        const text = item[column?.fieldName as keyof ResultType] as string;

        const isLoading =
            isRefetching ||
            (column?.fieldName &&
                column.fieldName.toLocaleUpperCase().indexOf('TASK') > -1 &&
                (tasksLoading || isRefetchingTasks));

        switch (column?.fieldName) {
            case 'missionName': {
                fieldContent = (
                    <InternalLink
                        scene={item.type}
                        tenantCode={props.tenantCode}
                        missionId={item.type === 'Mission' ? item.id : null}
                        contributorId={
                            item.type === 'Contributor' ? item.id : null
                        }
                        financialYearCode={props.financialYearCode}
                        teamCode={
                            item.teamCode ||
                            item.source?.team?.code ||
                            item.source?.leaderOfTeams.find(
                                (x) => x !== undefined
                            )?.code
                        }
                    >
                        <Text variant="smallPlus">{item.missionName}</Text>
                    </InternalLink>
                );
                break;
            }
            case 'specifiedTaskCount':
            case 'measureCount':
            case 'impliedTaskCount':
            case 'resourceCount':
            case 'resourcesAcceptedCount':
            case 'behaviourCount':
            case 'freedomCount':
            case 'constraintCount': {
                if (item.type === 'Mission') {
                    fieldContent = (
                        <MessageBar
                            styles={{
                                text: {
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    paddingRight: 16,
                                },
                            }}
                            messageBarIconProps={
                                isLoading
                                    ? { iconName: 'HourGlass' }
                                    : undefined
                            }
                            messageBarType={
                                isLoading
                                    ? MessageBarType.info
                                    : text
                                      ? MessageBarType.success
                                      : MessageBarType.severeWarning
                            }
                        >
                            {isLoading ? (
                                <Shimmer width={20} />
                            ) : (
                                <Text>{text}</Text>
                            )}
                        </MessageBar>
                    );
                }
                break;
            }

            case 'specifiedTaskNotLinkedCount':
            case 'specifiedTaskNoCategoryCount':
            case 'specifiedTaskNoImpliedTasksCount':
            case 'impliedTaskNoStartDateCount':
            case 'impliedTaskNoDueDateCount':
            case 'outstandingDependencyCount':
            case 'resourcesRejectedCount':
            case 'taskNotificationCount': {
                if (item.type === 'Mission') {
                    fieldContent = (
                        <MessageBar
                            styles={{
                                text: {
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    paddingRight: 16,
                                },
                            }}
                            messageBarIconProps={
                                isLoading
                                    ? { iconName: 'HourGlass' }
                                    : undefined
                            }
                            messageBarType={
                                isLoading
                                    ? MessageBarType.info
                                    : text
                                      ? MessageBarType.severeWarning
                                      : MessageBarType.success
                            }
                        >
                            {isLoading ? (
                                <Shimmer width={20} />
                            ) : (
                                <Text>{text}</Text>
                            )}
                        </MessageBar>
                    );
                }
                break;
            }

            case 'latestStatusReport': {
                fieldContent = item.latestStatusReportId ? (
                    <InternalLink
                        scene="StatusReport"
                        tenantCode={props.tenantCode}
                        missionId={item.id}
                        financialYearCode={props.financialYearCode}
                        teamCode={
                            item.teamCode ||
                            item.source?.team?.code ||
                            item.source?.leaderOfTeams.find(
                                (x) => x !== undefined
                            )?.code
                        }
                        statusReportId={item.latestStatusReportId}
                    >
                        <Text variant="smallPlus">{text}</Text>
                    </InternalLink>
                ) : null;
                break;
            }

            case 'latestIncDraftStatusReport': {
                fieldContent = item.latestIncDraftStatusReportId ? (
                    <InternalLink
                        scene="StatusReport"
                        tenantCode={props.tenantCode}
                        missionId={item.id}
                        financialYearCode={props.financialYearCode}
                        teamCode={
                            item.teamCode ||
                            item.source?.team?.code ||
                            item.source?.leaderOfTeams.find(
                                (x) => x !== undefined
                            )?.code
                        }
                        statusReportId={item.latestIncDraftStatusReportId}
                    >
                        <Text variant="smallPlus">{text}</Text>
                    </InternalLink>
                ) : null;
                break;
            }

            case 'lastSeen':
            case 'totalUsage':
            case 'totalDistinctDays': {
                const isUsageloading = usageLoading || isRefetchingUsage;

                let hasWarning = true;

                if (!isUsageloading) {
                    if (column.fieldName === 'lastSeen') {
                        hasWarning = !item.lastSeen;
                    } else if (column.fieldName === 'totalUsage') {
                        hasWarning = !item?.totalUsageInMinutes;
                    } else if (column.fieldName === 'totalDistinctDays') {
                        hasWarning = !item?.totalDistinctDays;
                    }
                }

                fieldContent = (
                    <MessageBar
                        styles={{
                            text: {
                                alignItems: 'center',
                                justifyContent: 'center',
                                paddingRight: 16,
                            },
                        }}
                        messageBarIconProps={
                            isUsageloading
                                ? { iconName: 'HourGlass' }
                                : undefined
                        }
                        messageBarType={
                            isUsageloading
                                ? MessageBarType.info
                                : hasWarning
                                  ? MessageBarType.severeWarning
                                  : MessageBarType.success
                        }
                    >
                        {isUsageloading ? (
                            <Shimmer width={20} />
                        ) : (
                            <Text>{text || '-'}</Text>
                        )}
                    </MessageBar>
                );
                break;
            }

            default: {
                fieldContent = <Text variant="small">{text}</Text>;
            }
        }

        return (
            <DetailsListCellItemContainer>
                {fieldContent}
            </DetailsListCellItemContainer>
        );
    };

    const [isExporting, setIsExporting] = React.useState(false);

    const handleExportClick = async (
        columns: IColumn[],
        sortedItems: ResultType[]
    ) => {
        try {
            setIsExporting(true);
            await alignmentExport(
                configuration,
                props.tenantId,
                columns,
                sortedItems
            );
        } catch (e) {
            console.log(e);
        } finally {
            setIsExporting(false);
        }
    };

    const handleColumnsChanged = (columns: (keyof ResultType)[]) => {
        const hasUsage = availableColumns
            .filter((ac) => ac.isUsage)
            .some((ac) => columns.some((c) => c === ac.fieldName));

        setColumnsIncludeUsage(hasUsage);
    };

    const handleRenderAdditionalFilters = (): JSX.Element => {
        return columnsIncludeUsage ? (
            <PeriodDropDown
                selectedPeriod={selectedPeriod}
                onPeriodChanged={function (
                    period: PeriodDropDownPeriods,
                    periodDisplayName: string,
                    startDate: string,
                    endDate: string
                ): void {
                    setSelectedPeriod(period);
                    setSelectedPeriodDisplayName(periodDisplayName);
                    setUsagePeriod({
                        start: startDate,
                        end: endDate,
                    });
                }}
            />
        ) : (
            <div />
        );
    };

    return (
        <FlatList<ResultType>
            {...props}
            listTypeName="Alignment"
            items={filteredItems}
            onRenderItemColumn={renderItemColumn}
            allowExport={!loading && !tasksLoading}
            isExporting={isExporting}
            onExportClick={handleExportClick}
            loading={loading}
            availableColumns={availableColumns}
            onRefreshClick={refreshData}
            isRefreshing={isRefetching || isRefetchingTasks}
            onColumnsChanged={handleColumnsChanged}
            onRenderAdditionalFilters={handleRenderAdditionalFilters}
        />
    );
}

function useMissionFilters<T extends ResultType>(
    filters: MissionFilters | undefined,
    items: T[]
): { filteredItems: T[] } {
    let filteredItems = items || [];

    if (filters?.keyword) {
        let regex: RegExp | null = null;
        try {
            // eslint-disable-next-line security/detect-non-literal-regexp
            regex = new RegExp(filters.keyword, 'gi');
        } catch {
            regex = null;
        }

        filteredItems = items.filter((m) => {
            if (regex) {
                return (
                    m.teamName?.match(regex)?.length ||
                    m.teamCode?.match(regex)?.length ||
                    m.userDisplayName?.match(regex)?.length ||
                    m.source?.team?.division?.name?.match(regex)?.length ||
                    m.source?.team?.code?.match(regex)?.length ||
                    m.source?.team?.name?.match(regex)?.length ||
                    m.source?.owner?.match(regex)?.length ||
                    m.source?.title?.match(regex)?.length
                );
            } else if (filters.keyword) {
                const keyword = filters.keyword.toLowerCase();

                return (
                    m.teamName?.toLowerCase().includes(keyword) ||
                    m.teamCode?.toLowerCase().includes(keyword) ||
                    m.userDisplayName?.toLowerCase().includes(keyword) ||
                    m.source?.team?.division?.name
                        ?.toLowerCase()
                        .includes(keyword) ||
                    m.source?.team?.code?.toLowerCase().includes(keyword) ||
                    m.source?.team?.name?.toLowerCase().includes(keyword) ||
                    m.source?.owner?.toLowerCase().includes(keyword) ||
                    m.source?.title?.toLowerCase().includes(keyword)
                );
            }
        });
    }

    return { filteredItems: filteredItems };
}

function useAlignmentItems(
    usagePeriod: AlignmentUsagePeriod | undefined,
    tenantId: string,
    teamId: string | null | undefined,
    financialYearCode: string,
    includeUsage: boolean
): {
    items: ResultType[];
    loading: boolean;
    tasksLoading: boolean;
    usageLoading: boolean;
    isRefetching: boolean;
    isRefetchingTasks: boolean;
    isRefetchingUsage: boolean;
    refreshData: () => void;
} {
    const queryVariables: GetTeamAlignmentQueryVariables = {
        tenantId: tenantId,
        teamId: teamId || null,
        financialYearCode: financialYearCode,
        includeDeleted: false,
        includeInactive: false,
        isWritable: false,
        searchText: '',
    };

    const {
        data,
        loading,
        refetch: _refetchAlignment,
    } = useGetTeamAlignmentQuery({
        variables: queryVariables,
        fetchPolicy: 'no-cache',
    });

    const {
        data: usageData,
        loading: usageLoading,
        refetch: _refetchUsage,
    } = useGetTeamUsageQuery({
        skip: !includeUsage,
        variables: {
            ...queryVariables,
            financialYearActiveDate: null,
            usagePeriodStart:
                usagePeriod?.start ||
                dayjs
                    .utc()
                    .subtract(90, 'day')
                    .startOf('day')
                    .format('YYYY-MM-DD'),
            usagePeriodEnd:
                usagePeriod?.end ||
                dayjs.utc().endOf('day').format('YYYY-MM-DD'),
            forDateTime: null,
        },
    });

    const {
        data: taskData,
        loading: tasksLoading,
        refetch: _refetchTasks,
    } = useGetAllTasksQuery({
        variables: {
            tenantId: tenantId,
            financialYearCode: financialYearCode,
            teamId: null,
            forDateTime: null,
        },
    });

    const mapUsage = (
        u: TeamAlignmentMissionType | TeamAlignmentContributorType
    ): {
        lastSeenUtc: string | null;
        lastSeen: string | null;
        totalUsageInMinutes: number;
        totalUsage: string | null;
        totalDistinctDays: number;
    } => {
        const usage =
            usageData?.missions.find((um) => um.userId === u.userId)
                ?.userUsage ||
            usageData?.contributors.find((um) => um.userId === u.userId)
                ?.userUsage;

        return {
            lastSeenUtc: usage?.lastSeen || null,
            lastSeen: usage?.lastSeen
                ? dayjs.utc(usage.lastSeen).format('DD MMM YYYY')
                : null,
            totalUsageInMinutes: usage?.totalUsageInMinutes || 0,
            totalUsage: dayjs
                .duration({
                    hours: Math.floor((usage?.totalUsageInMinutes || 0) / 60),
                    minutes: (usage?.totalUsageInMinutes || 0) % 60,
                })
                .format('H[h] m[m]'),
            totalDistinctDays: usage?.totalDistinctDays || 0,
        };
    };

    const [refetch, isRefetching] = useRefetch(_refetchAlignment);
    const [refetchTasks, isRefetchingTasks] = useRefetch(_refetchTasks);
    const [refetchUsage, isRefetchingUsage] = useRefetch(_refetchUsage);

    const refreshData = () => {
        refetch();
        refetchTasks();
        refetchUsage();
    };

    const taskWarnings = useTaskWarnings(taskData?.tasks || []);

    const items: ResultType[] =
        data?.missions.map((m) => {
            const missionTasks =
                taskData?.tasks?.filter((t) => t.missionId === m.id) || [];

            const warningsForTasks =
                taskWarnings.filter(
                    (w) =>
                        w.missionId === m.id &&
                        w.hasWarning &&
                        w.createNotification
                ) || [];

            const specifiedTasks = missionTasks
                .filter((t) => t.parentTaskId === null)
                .filter((t) => !t.resourcedFromTaskId || t.utcAccepted);

            const impliedTasks = missionTasks.filter(
                (t) =>
                    t.parentTaskId !== null &&
                    !t.isDuplicate &&
                    specifiedTasks.some((st) => st.id === t.parentTaskId)
            );

            const resourcedTasks = impliedTasks.flatMap(
                (it) => it.resourcedTasks
            );

            const teamSequence =
                m.team?.id === teamId
                    ? m.sequence
                    : (m.dottedTeams.find((dt) => dt.teamId === teamId)
                          ?.sequence ?? 99);

            const draftStatusReportDate =
                m.latestDraftStatusReport?.reportDate ||
                m.latestDraftStatusReport?.utcUpdated;

            const latestIncDraftStatusReport =
                !m.latestPublishedStatusReport ||
                dayjs(draftStatusReportDate).isAfter(
                    dayjs(m.latestPublishedStatusReport.utcCompletedDate)
                )
                    ? m.latestDraftStatusReport
                    : m.latestPublishedStatusReport;

            const result: ResultType = {
                ...mapUsage(m),
                source: m,
                id: m.id,
                type: 'Mission',
                userId: m.userId,
                userDisplayName: m.username,
                userEmailAddress: m.userEmailAddress,
                displaySequence: 0,
                sortSequence: m.leaderOfTeams.some((lot) => lot.id == teamId)
                    ? 0
                    : 10 + teamSequence,
                missionName: [m.owner, m.title].join(' - '),
                owner: m.owner,
                title: m.title,
                missionStatement: m.missionStatement,
                teamCodeAndName: `${m.team?.code} (${m.team?.name})`,
                teamName: m?.team?.name || null,
                teamCode: m?.team?.code || null,
                divisionName: m?.team?.division?.name || null,
                measureCount: m.measures.length,
                specifiedTaskCount: specifiedTasks.length,
                specifiedTaskNotLinkedCount: specifiedTasks.length
                    ? specifiedTasks.filter(
                          (st) => st.linkedMeasures.length === 0
                      ).length
                    : '-',
                specifiedTaskNoCategoryCount: specifiedTasks.length
                    ? specifiedTasks.filter((st) => st.taskCategory === null)
                          .length
                    : '-',
                specifiedTaskNoImpliedTasksCount: specifiedTasks.length
                    ? specifiedTasks.filter(
                          (st) =>
                              !impliedTasks.some(
                                  (it) => it.parentTaskId === st.id
                              )
                      ).length
                    : '-',
                impliedTaskCount: impliedTasks.length,
                impliedTaskNoStartDateCount: impliedTasks.length
                    ? impliedTasks.filter((it) => !it.start).length
                    : '-',
                impliedTaskNoDueDateCount: impliedTasks.length
                    ? impliedTasks.filter((it) => !it.due).length
                    : '-',
                resourceCount: resourcedTasks.length,
                resourcesAcceptedCount: resourcedTasks.length
                    ? resourcedTasks.filter((rt) => rt.utcAccepted).length
                    : '-',
                resourcesRejectedCount: resourcedTasks.filter(
                    (rt) => rt.utcRejected
                ).length,
                behaviourCount: m.behaviours.length,
                freedomCount: m.freedoms.length,
                constraintCount: m.constraints.length,
                latestStatusReportId: m.latestPublishedStatusReport?.id || null,
                latestStatusReport: m.latestPublishedStatusReport
                    ? `${
                          m.latestPublishedStatusReport?.title ||
                          'Status Report'
                      } - ${dayjs
                          .utc(m.latestPublishedStatusReport.reportDate)
                          .format('DD MMM YYYY')}`
                    : '',
                latestIncDraftStatusReportId:
                    latestIncDraftStatusReport?.id || null,
                latestIncDraftStatusReport: latestIncDraftStatusReport
                    ? `${
                          latestIncDraftStatusReport?.title || 'Status Report'
                      } - ${
                          latestIncDraftStatusReport.utcCompletedDate
                              ? dayjs
                                    .utc(latestIncDraftStatusReport.reportDate)
                                    .format('DD MMM YYYY')
                              : 'DRAFT'
                      }`
                    : '',
                taskNotificationCount:
                    warningsForTasks.length + m.outstandingDependencyCount,
                outstandingDependencyCount: m.outstandingDependencyCount,
            };

            return result;
        }) || [];

    data?.contributors.forEach((c) => {
        items.push({
            id: c.id,
            type: 'Contributor',
            userId: c.userId,
            userDisplayName: c.userDisplayName,
            userEmailAddress: c.userEmailAddress,
            teamCodeAndName: `${c.team?.code} (${c.team?.name})`,
            teamName: c?.team?.name || null,
            teamCode: c?.team?.code || null,
            ...mapUsage(c),
            displaySequence: 0,
            missionName: 'CONTRIBUTOR',
            owner: null,
            title: null,
            missionStatement: null,
            divisionName: null,
            specifiedTaskCount: 0,
            specifiedTaskNotLinkedCount: 0,
            specifiedTaskNoCategoryCount: 0,
            specifiedTaskNoImpliedTasksCount: 0,
            impliedTaskCount: 0,
            impliedTaskNoStartDateCount: 0,
            impliedTaskNoDueDateCount: 0,
            resourceCount: 0,
            resourcesAcceptedCount: 0,
            resourcesRejectedCount: 0,
            measureCount: 0,
            behaviourCount: 0,
            freedomCount: 0,
            constraintCount: 0,
            latestStatusReport: '',
            latestStatusReportId: null,
            latestIncDraftStatusReport: '',
            latestIncDraftStatusReportId: null,
            taskNotificationCount: 0,
            sortSequence: 0,
            outstandingDependencyCount: 0,
            source: undefined,
        });
    });

    return {
        items,
        loading,
        tasksLoading,
        usageLoading,
        isRefetching,
        isRefetchingTasks,
        isRefetchingUsage,
        refreshData,
    };
}
