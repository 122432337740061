import React, { useCallback, useEffect, useState } from 'react';
import {
    CommandBarButton,
    ContextualMenu,
    IDragOptions,
    IPanelProps,
    IPanelStyles,
    IRawStyle,
    IRenderFunction,
    IconButton,
    Modal,
    Panel,
    PanelType,
    mergeStyleSets,
    Text,
    ZIndexes,
} from '@fluentui/react';

import Iframe from 'react-iframe';
import { paths } from '../../services/navigation';
import { generatePath } from 'react-router';
import { useThemes } from '../../hooks/useThemes';
import { useStateContext } from '../../services/contextProvider';

type HelpPanelProps = {
    showPanel: boolean;
    onDismiss: () => void;
};

export default function HelpPanel(props: HelpPanelProps): JSX.Element {
    const { currentTheme } = useThemes();

    const { themePrimaryColourHex } = useStateContext();

    const [iFrameUrl, setIFrameUrl] = useState<string>();

    const [isPoppedOut, setIsPoppedOut] = useState(false);

    useEffect(() => {
        if (props.showPanel && !iFrameUrl) {
            const query = new URLSearchParams();
            if (themePrimaryColourHex) {
                query.append('themePrimaryColourHex', themePrimaryColourHex);
            }
            const helpHomeUrl = `${generatePath(paths.help)}?${query}`;
            setIFrameUrl(helpHomeUrl);
        }
    }, [props.showPanel, iFrameUrl, themePrimaryColourHex]);

    const classNames = mergeStyleSets({
        header: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
        } as IRawStyle,
        modalHeader: {
            display: 'flex',
            flexDirection: 'row',
            paddingLeft: 8,
            paddingRight: 8,
            paddingTop: 8,
            justifyContent: 'space-between',
            backgroundColor: currentTheme.palette.neutralLighter,
            borderTop: `4px solid ${currentTheme.palette.themePrimary}`,
        } as IRawStyle,
        modalContainer: {
            minHeight: 300,
            overflow: 'hidden',
            minWidth: 400,
            backgroundColor: currentTheme.palette.neutralLighter,
        } as IRawStyle,
        modalScrollableContent: {
            overflow: 'hidden',
        } as IRawStyle,
        modalTitle: {
            padding: 4,
        } as IRawStyle,
        modalButtons: {
            display: 'flex',
            flexDirection: 'row',
            gap: 4,
        },
    });

    const handleRenderHeader: IRenderFunction<IPanelProps> = useCallback(
        (props, defaultRender) => (
            <div className={classNames.header}>
                <div>{!!defaultRender && defaultRender(props)}</div>
                <CommandBarButton
                    iconProps={{ iconName: 'MiniExpand' }}
                    text="Pop Out"
                    onClick={() => setIsPoppedOut(true)}
                    styles={{
                        root: {
                            backgroundColor:
                                currentTheme.palette.neutralLighter,
                        },
                    }}
                />
            </div>
        ),
        [currentTheme, classNames.header]
    );

    const panelStyles: Partial<IPanelStyles> = {
        commands: {
            backgroundColor: currentTheme.palette.neutralLighter,
        },
        contentInner: {
            backgroundColor: currentTheme.palette.neutralLighter,
        },
        content: {
            padding: 0,
            overflow: 'hidden',
        },
        main: {
            overflow: 'hidden',
        },
    };

    const dragOptions: IDragOptions = {
        moveMenuItemText: 'Move',
        closeMenuItemText: 'Close',
        menu: ContextualMenu,
        dragHandleSelector: '.ms-Modal-scrollableContent > div:first-child',
        keepInBounds: true,
    };

    return (
        <>
            <Panel
                headerText="Help"
                isOpen={props.showPanel && !isPoppedOut}
                isBlocking={false}
                type={PanelType.smallFixedFar}
                onDismiss={props.onDismiss}
                closeButtonAriaLabel="Close Help"
                isFooterAtBottom
                onRenderHeader={handleRenderHeader}
                styles={panelStyles}
            >
                <HelpContentFrame iFrameUrl={iFrameUrl} />
            </Panel>

            <Modal
                isOpen={props.showPanel && isPoppedOut}
                dragOptions={dragOptions}
                onDismiss={() => {
                    setIsPoppedOut(false);
                    props.onDismiss();
                }}
                containerClassName={classNames.modalContainer}
                scrollableContentClassName={classNames.modalScrollableContent}
                styles={{
                    layer: {
                        zIndex: ZIndexes.Layer + 1, // Keep this modal on top of any panels, etc
                    },
                }}
                isModeless
                forceFocusInsideTrap={false} // Required, otherwise the context menus behind the modal won't open.
            >
                <div className={classNames.modalHeader}>
                    <Text variant="xLarge" className={classNames.modalTitle}>
                        Help
                    </Text>

                    <div>
                        <IconButton
                            iconProps={{ iconName: 'DockRight' }}
                            ariaLabel="Dock help right"
                            title="Dock Right"
                            onClick={() => {
                                setIsPoppedOut(false);
                            }}
                        />

                        <IconButton
                            iconProps={{ iconName: 'Cancel' }}
                            ariaLabel="Close help dialog"
                            title="Close"
                            onClick={() => {
                                setIsPoppedOut(false);
                                props.onDismiss();
                            }}
                        />
                    </div>
                </div>

                <HelpContentFrame iFrameUrl={iFrameUrl} />
            </Modal>
        </>
    );
}

function HelpContentFrame(props: { iFrameUrl: string | null | undefined }) {
    const { iFrameUrl } = props;

    const { currentTheme } = useThemes();

    const classNames = mergeStyleSets({
        iFrameContainer: {
            minHeight: 300,
            width: '100%',
            height: '100%',
            position: 'relative',
        },
        iFrame: {
            width: '100%',
            height: '100%',
            border: 0,
            position: 'absolute',
            backgroundColor: currentTheme.palette.neutralLighter,
        } as IRawStyle,
    });

    return (
        <div className={classNames.iFrameContainer}>
            {!!iFrameUrl && (
                <Iframe
                    id="helpIFrame"
                    url={iFrameUrl}
                    className={classNames.iFrame}
                    frameBorder={0}
                    height="100%"
                    width="100%"
                />
            )}
        </div>
    );
}
