import { useEditor, EditorContent, Editor, ReactRenderer } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Mention, { MentionOptions } from '@tiptap/extension-mention';
import Placeholder, { PlaceholderOptions } from '@tiptap/extension-placeholder';
import { SuggestionOptions } from '@tiptap/suggestion';
import { Node } from 'prosemirror-model';
import { Markdown } from 'tiptap-markdown';
import { MarkdownSerializerState } from 'prosemirror-markdown';
import Loading from '../Loading';
import {
    useGetTeamUsersLazyQuery,
    useGetUsersSearchLazyQuery,
} from '../../data/types';
import { useStateContext } from '../../services/contextProvider';
import MarkdownIt from 'markdown-it';
import {
    MarkdownEditorMentionList,
    MarkdownEditorMentionListProps,
    MarkdownEditorMentionListRef,
    MarkdownEditorMentionUser,
} from './MarkdownEditorMentionList';
import './MarkdownEditor.css';
import { MarkdownEditorToolbar } from './MarkdownEditorToolbar';
import { forwardRef, Ref, useImperativeHandle } from 'react';

type MarkdownEditorProps = {
    defaultValue?: string | null;
    onChange?: (markdown: string) => void;
    readOnly?: boolean;
    mentionSuggestionsTeamCode?: string | null;
    allowMentions?: boolean;
    placeholderText?: string;
};

export type MarkdownEditorRef = {
    setMarkdown: (markdown: string) => void;
    insertMarkdown: (markdown: string) => void;
    getMarkdown: () => string;
};

const MarkdownEditor = forwardRef(function MarkdownEditor(
    {
        defaultValue = '',
        onChange,
        readOnly = false,
        allowMentions = false,
        mentionSuggestionsTeamCode = undefined,
        placeholderText = undefined,
    }: MarkdownEditorProps,
    ref: Ref<MarkdownEditorRef>
) {
    const { currentTenantId, currentFinancialYearCode } = useStateContext();

    const [getUsersSearch] = useGetUsersSearchLazyQuery();
    const [getTeamMembers] = useGetTeamUsersLazyQuery();

    const queryUsers = async (
        query: string
    ): Promise<MarkdownEditorMentionUser[]> => {
        if (query) {
            const queryResults = await getUsersSearch({
                variables: {
                    tenantId: currentTenantId || '',
                    searchText: query.toLowerCase(),
                    useCache: false,
                },
            });
            return (
                queryResults.data?.userSearch
                    .filter((u) => u.accessEnabled)
                    .slice(0, 5) || []
            );
        } else if (mentionSuggestionsTeamCode && currentFinancialYearCode) {
            const queryResults = await getTeamMembers({
                variables: {
                    tenantId: currentTenantId || '',
                    teamCode: mentionSuggestionsTeamCode,
                    financialYearCode: currentFinancialYearCode,
                },
            });

            const results: MarkdownEditorMentionUser[] = [];
            queryResults?.data?.teams?.forEach((t) => {
                if (t.leaderMission?.userId) {
                    results.push({
                        id: t.leaderMission.userId,
                        displayName: t.leaderMission?.username,
                    });
                }
                t.missions.forEach((m) => {
                    if (m.userId && !results.find((r) => r.id === m.userId)) {
                        results.push({
                            id: m.userId,
                            displayName: m.username,
                        });
                    }
                });
            });

            return results;
        }

        return [];
    };

    const CustomMention = Mention.extend<MentionOptions>({
        addOptions() {
            return {
                ...this.parent?.(),
                suggestion: {
                    char: '@',
                    allowSpaces: true,
                    items: (props) => {
                        return queryUsers(props.query);
                    },
                    render: () => {
                        let component: ReactRenderer<
                            MarkdownEditorMentionListRef,
                            MarkdownEditorMentionListProps
                        > | null = null;
                        return {
                            onStart: (props) => {
                                const rect = props.clientRect
                                    ? props.clientRect()
                                    : null;

                                if (rect) {
                                    const mentionListProps: MarkdownEditorMentionListProps =
                                        {
                                            ...props,
                                            target: {
                                                getBoundingClientRect: () => ({
                                                    top: rect.top,
                                                    bottom: rect.bottom,
                                                    left: rect.left,
                                                    right: rect.right,
                                                    width: 0,
                                                    height: 0,
                                                }),
                                            } as HTMLElement,
                                        };

                                    component = new ReactRenderer<
                                        MarkdownEditorMentionListRef,
                                        MarkdownEditorMentionListProps
                                    >(MarkdownEditorMentionList, {
                                        props: mentionListProps,
                                        editor: props.editor,
                                    });
                                }
                            },
                            onUpdate: (props) => {
                                const rect = props.clientRect
                                    ? props.clientRect()
                                    : null;

                                if (rect && component) {
                                    component.updateProps({
                                        ...props,
                                        target: {
                                            getBoundingClientRect: () => ({
                                                top: rect.top,
                                                bottom: rect.bottom,
                                                left: rect.left,
                                                right: rect.right,
                                                width: 0,
                                                height: 0,
                                            }),
                                        } as HTMLElement,
                                    });
                                }
                            },
                            onKeyDown(props) {
                                if (props.event.key === 'Escape') {
                                    component?.destroy();
                                    component = null;
                                    return true;
                                }
                                return component?.ref?.onKeyDown(props);
                            },
                            onExit: () => {
                                component?.destroy();
                                component = null;
                            },
                        };
                    },
                    command: ({
                        editor,
                        range,
                        props,
                    }: {
                        editor: Editor;
                        range: { from: number; to: number };
                        props: MarkdownEditorMentionUser;
                    }) => {
                        editor
                            .chain()
                            .focus()
                            .deleteRange(range)
                            .insertContent([
                                {
                                    type: 'mention',
                                    attrs: {
                                        id: props.id,
                                        label: props.displayName,
                                    },
                                },
                                { type: 'text', text: ' ' },
                            ])
                            .run();
                    },
                } as Partial<SuggestionOptions<MarkdownEditorMentionUser>>, // Cast to partial to avoid strict mismatch
            };
        },
        addStorage() {
            return {
                markdown: {
                    serialize(state: MarkdownSerializerState, node: Node) {
                        const { id, label } = node.attrs;
                        state.write(`@[${label || id}](${id})`);
                    },
                    parse: {
                        setup(markdownit: MarkdownIt) {
                            markdownit.inline.ruler.before(
                                'emphasis', // place our rule before "emphasis"
                                'mention', // name of our rule
                                function mentionTokenize(state, silent) {
                                    const src = state.src.slice(state.pos);
                                    if (!src.startsWith('@[')) {
                                        return false;
                                    }
                                    const mentionRegex = /@\[(.*?)\]\((.*?)\)/g;
                                    const match = mentionRegex.exec(src);
                                    if (!match) {
                                        return false;
                                    }

                                    const fullMention = match[0];
                                    const label = match[1];
                                    const id = match[2];

                                    if (!silent) {
                                        const token = state.push(
                                            'mention',
                                            '',
                                            0
                                        );
                                        token.meta = { id, label };
                                        token.content = fullMention;
                                    }

                                    state.pos += fullMention.length;
                                    return true;
                                }
                            );
                            markdownit.renderer.rules.mention = (
                                tokens,
                                idx
                            ) => {
                                const token = tokens[idx as number];
                                const { id, label } = token.meta || {};
                                return `<span data-mention data-id="${id}" data-label="${label}"></span>`;
                            };
                        },
                        updateDOM() {
                            // Not needed
                        },
                    },
                },
            };
        },
        parseHTML() {
            return [
                {
                    tag: 'span[data-mention]',
                    getAttrs: (el) => {
                        return {
                            id: el.getAttribute('data-id'),
                            label: el.getAttribute('data-label'),
                        };
                    },
                },
            ];
        },
        renderHTML({ node, HTMLAttributes }) {
            return [
                'span',
                { ...HTMLAttributes, class: 'mention' },
                `@${node.attrs.label || node.attrs.id}`,
            ];
        },
    });

    const CustomPlaceholder = Placeholder.configure({
        placeholder: placeholderText || '',
    } as PlaceholderOptions);

    const editor = useEditor({
        extensions: allowMentions
            ? [StarterKit, CustomPlaceholder, Markdown, CustomMention]
            : [StarterKit, CustomPlaceholder, Markdown],
        content: defaultValue ?? '',
        editable: !readOnly,
        onUpdate({ editor }) {
            const md = editor.storage.markdown?.getMarkdown?.();
            if (onChange && typeof md === 'string') {
                onChange(md);
            }
        },
    });

    useImperativeHandle(ref, () => ({
        setMarkdown(markdown: string) {
            if (!editor) return;
            editor.chain().focus().setContent(markdown).run();
            if (onChange) {
                onChange(editor.storage.markdown?.getMarkdown?.() || '');
            }
        },
        insertMarkdown(markdown: string) {
            if (!editor) return;
            editor.chain().focus().insertContent(markdown).run();
            if (onChange) {
                onChange(editor.storage.markdown?.getMarkdown?.() || '');
            }
        },
        getMarkdown() {
            if (!editor) return '';
            return editor.storage.markdown?.getMarkdown?.() || '';
        },
    }));

    if (!editor) {
        return <Loading />;
    }

    return (
        <div>
            <div>
                <MarkdownEditorToolbar editor={editor} readOnly={readOnly} />
            </div>
            <div>
                <EditorContent editor={editor} />
            </div>
        </div>
    );
});

export default MarkdownEditor;
