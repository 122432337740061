import React from 'react';
import { TenantQl, UserTenantQl, UserRoleQl } from '../data/types';
import { IConfiguration } from './configuration.service';

const SetupContexType = 'SetupContext';
const SetCurrentTeamAndMissionType = 'SetCurrentTeamAndMission';
const SetCurrentTenantType = 'SetCurrentTenant';
const SetUserTenantsType = 'SetUserTenants';
const SetCurrentThemeType = 'SetCurrentTheme';
const SetThemePrimaryColourType = 'SetThemePrimaryColour';
const ResetType = 'Reset';

class SetupContext {
    readonly type = SetupContexType;
    constructor(
        public payload: {
            currentTenantCode: string;
            currentFinancialYearCode: string | null | undefined;
            currentTeamCode: string | null | undefined;
            currentMissionId: string | null | undefined;
            currentContributorId?: string | null | undefined;
            themePrimaryColourHex: string | null | undefined;
        }
    ) {}
}

class SetCurrentTeamAndMission {
    readonly type = SetCurrentTeamAndMissionType;
    constructor(
        public payload: {
            currentTenantCode: string;
            currentTeamCode: string | null | undefined;
            currentMissionId: string | null | undefined;
            themePrimaryColourHex?: string | null | undefined;
        }
    ) {}
}

class SetCurrentTenant {
    readonly type = SetCurrentTenantType;
    constructor(public payload: TenantQl) {}
}

class SetUserTenants {
    readonly type = SetUserTenantsType;
    constructor(public payload: UserTenantQl[]) {}
}

class SetCurrentTheme {
    readonly type = SetCurrentThemeType;
    constructor(public payload: string) {}
}

class SetThemePrimaryColour {
    readonly type = SetThemePrimaryColourType;
    constructor(
        public payload: {
            themePrimaryColourHex: string | null | undefined;
        }
    ) {}
}

class Reset {
    readonly type = ResetType;
}

type Actions =
    | SetupContext
    | SetCurrentTeamAndMission
    | SetCurrentTenant
    | SetUserTenants
    | SetCurrentTheme
    | SetThemePrimaryColour
    | Reset;

export type Dispatch = (action: Actions) => void;

export type DefaultState = {
    initialised: boolean;
    configuration: IConfiguration;
    currentUserId: string | undefined;
    currentUserEmail: string | undefined;
    currentUserDisplayName: string | undefined;
    currentUserIsSelfHelpEnabled: boolean | undefined;
    currentUserIsNew: boolean | undefined;
    userTenants: UserTenantQl[];
    userRoles: UserRoleQl[];
    currentRoles: string[];
    currentTeamCode: string | undefined;
    currentMissionId: string | undefined;
    currentContributorId: string | undefined;
    currentTenantId: string | undefined;
    currentTenantCode: string | undefined;
    currentFinancialYearCode: string | undefined;
    currentThemeName: string | undefined;
    themePrimaryColourHex: string | undefined;
    taskContentState: 'expanded' | 'collapsed' | 'partial';
};

export const InitialState: DefaultState = {
    initialised: false,
    configuration: {
        apiUrl: '/',
        appInsightsConnectionString: null,
        storageFnUrl: '',
        exporterHost: null,
        exporterFnKey: null,
        importerHost: null,
        importerFnKey: null,
        importerEndpoint: null,
        idleTimeoutMinutes: null,
        localSignupSignInPolicy: null,
        externalSignupSignInPolicy: null,
        localPasswordResetPolicy: null,
        localPasswordChangePolicy: null,
        whatfixUrl: null,
    },
    currentUserId: undefined,
    currentUserEmail: undefined,
    currentUserDisplayName: undefined,
    currentUserIsSelfHelpEnabled: undefined,
    currentUserIsNew: undefined,
    userTenants: [],
    userRoles: [],
    currentRoles: [],
    currentTeamCode: undefined,
    currentMissionId: undefined,
    currentContributorId: undefined,
    currentTenantId: undefined,
    currentTenantCode: undefined,
    currentFinancialYearCode: undefined,
    currentThemeName: undefined,
    taskContentState: 'expanded',
    themePrimaryColourHex: undefined,
};

export const Reducer = (state: DefaultState, action: Actions): DefaultState => {
    switch (action.type) {
        case SetupContexType: {
            if (!action.payload) {
                throw new Error('Payload is null');
            }

            const tenant = state.userTenants?.find(
                (t) =>
                    t &&
                    t.tenant.code?.toUpperCase() ===
                        action.payload.currentTenantCode.toUpperCase()
            )?.tenant;

            const currentRoles = state.userRoles
                .filter(
                    (ur) => ur.tenantId == null || ur.tenantId === tenant?.id
                )
                .map((ur) => ur.name || '');

            const updatedState = {
                ...state,
                currentTenantId: tenant?.id ? tenant.id : undefined,
                currentTenantCode:
                    action.payload.currentTenantCode?.toLowerCase(),
                currentRoles: currentRoles,
            };

            if (action.payload.currentFinancialYearCode !== undefined) {
                updatedState.currentFinancialYearCode =
                    action.payload.currentFinancialYearCode || undefined;
            }

            if (action.payload.currentTeamCode !== undefined) {
                updatedState.currentTeamCode =
                    action.payload.currentTeamCode || undefined;
            }

            if (action.payload.currentMissionId !== undefined) {
                updatedState.currentMissionId =
                    action.payload.currentMissionId || undefined;
                updatedState.currentContributorId = undefined;
            }

            if (action.payload.currentContributorId !== undefined) {
                updatedState.currentContributorId =
                    action.payload.currentContributorId || undefined;
            }

            if (action.payload.themePrimaryColourHex !== undefined) {
                updatedState.themePrimaryColourHex =
                    action.payload.themePrimaryColourHex || undefined;
            }

            return updatedState;
        }

        case SetUserTenantsType: {
            if (!action.payload) {
                throw new Error('Payload is null');
            }

            const updatedState = {
                ...state,
                userTenants: action.payload,
            };

            return updatedState;
        }

        case SetCurrentThemeType: {
            if (!action.payload) {
                throw new Error('Payload is null');
            }

            const updatedState = {
                ...state,
                setCurrentTheme: action.payload,
            };

            return updatedState;
        }

        case SetCurrentTeamAndMissionType: {
            if (!action.payload) {
                throw new Error('Payload is null');
            }

            const tenant = state.userTenants?.find(
                (t) =>
                    t &&
                    t.tenant.code?.toUpperCase() ===
                        action.payload.currentTenantCode.toUpperCase()
            )?.tenant;

            const updatedState = {
                ...state,
                currentTenantId: tenant?.id ? tenant.id : undefined,
                currentTenantCode:
                    action.payload.currentTenantCode?.toLowerCase(),
            };

            if (action.payload.currentMissionId !== undefined) {
                updatedState.currentMissionId =
                    action.payload.currentMissionId || undefined;
                updatedState.currentContributorId = undefined;
            }

            if (action.payload.currentTeamCode !== undefined) {
                updatedState.currentTeamCode =
                    action.payload.currentTeamCode?.toLowerCase();
            }

            if (action.payload.themePrimaryColourHex !== undefined) {
                updatedState.themePrimaryColourHex =
                    action.payload.themePrimaryColourHex || undefined;
            }

            return updatedState;
        }
        case SetThemePrimaryColourType: {
            if (!action.payload) {
                throw new Error('Payload is null');
            }

            const updatedState = {
                ...state,
                themePrimaryColourHex:
                    action.payload.themePrimaryColourHex || undefined,
            };

            return updatedState;
        }
        case ResetType:
            return InitialState;

        default:
            throw new Error();
    }
};

export const DispatchContext = React.createContext<Dispatch | undefined>(
    undefined
);

export const Context = React.createContext<DefaultState | undefined>(undefined);

export default Context;
