import dayjs from 'dayjs';
import { ReportPeriodTypes } from '../data/types';
import { getHalf, getQuarter } from '../scenes/Measure/utils/measureUtils';
import { DatePickerStrings } from '../services/i18n';
import { ReportType } from '../scenes/StatusReports/StatusReports';
import { useCallback } from 'react';

export function useReportPeriods(
    fyStartDate: string | null | undefined,
    fyEndDate: string | null | undefined
): {
    getPeriods(
        reportPeriodType: ReportPeriodTypes | null | undefined
    ): { text: string; period: number }[];
    formatPeriod(
        reportPeriodType: ReportPeriodTypes | null | undefined,
        reportPeriod: number | null | undefined
    ): string;
    getAllowedPeriodTypes: (
        reportType: ReportType,
        templateOrReport?: {
            reportPeriodType: ReportPeriodTypes | null;
        }
    ) => ReportPeriodTypes[];
} {
    // The month number in the current fy
    // For example: If the FY starts 1 Apr, May would be 2.
    const getMonthNumber = (
        fyStart: string | dayjs.Dayjs,
        current: string | dayjs.Dayjs
    ): number => {
        const startYear = dayjs(fyStart).year();
        const currentYear = dayjs(current).year();
        const startMonth = dayjs(fyStart).month();
        const currentMonth = dayjs(current).month();

        let monthNumber: number;

        if (currentYear > startYear) {
            monthNumber = currentMonth + 12 - startMonth + 1;
        } else {
            monthNumber = currentMonth - startMonth + 1;
        }

        return Math.floor(monthNumber);
    };

    const getMonthNumberName = (
        fyStart: string | dayjs.Dayjs,
        monthNumber: number
    ): string => {
        const periodDate = dayjs(fyStart).add(monthNumber - 1, 'M');

        return `${DatePickerStrings.months[periodDate.month()]} ${periodDate.year()}`;
    };

    return {
        formatPeriod: (
            reportPeriodType: ReportPeriodTypes | null | undefined,
            reportPeriod: number | null | undefined
        ) => {
            if (reportPeriodType === ReportPeriodTypes.None) {
                return '';
            }
            if (reportPeriodType === ReportPeriodTypes.Ytd) {
                return 'YTD';
            }
            const prefix =
                reportPeriodType === ReportPeriodTypes.Quarter
                    ? 'Q'
                    : reportPeriodType === ReportPeriodTypes.Half
                      ? 'H'
                      : '';
            return reportPeriodType === ReportPeriodTypes.Month &&
                fyStartDate &&
                reportPeriod
                ? getMonthNumberName(fyStartDate, reportPeriod)
                : `${prefix}${reportPeriod || ''}`;
        },
        getPeriods: (reportPeriodType: ReportPeriodTypes | null) => {
            if (!fyStartDate || !reportPeriodType) {
                return [];
            }

            const periods: { text: string; period: number }[] = [];

            let currentDate = dayjs().isAfter(fyEndDate)
                ? dayjs(fyEndDate)
                : dayjs();

            if (dayjs(currentDate).isBefore(fyStartDate)) {
                currentDate = dayjs(fyStartDate);
            }

            switch (reportPeriodType) {
                case ReportPeriodTypes.Month: {
                    const currentMonth = getMonthNumber(
                        fyStartDate,
                        currentDate
                    );

                    for (let m = currentMonth; m > 0; m--) {
                        const lastMonthNumberName = getMonthNumberName(
                            fyStartDate,
                            m
                        );
                        periods.push({
                            period: m,
                            text: `${lastMonthNumberName}`,
                        });
                    }

                    break;
                }
                case ReportPeriodTypes.Quarter: {
                    const quarter = getQuarter(fyStartDate, currentDate);
                    if (quarter) {
                        for (let q = quarter; q > 0; q--) {
                            periods.push({
                                period: q,
                                text: `Q${q}`,
                            });
                        }
                    }
                    break;
                }
                case ReportPeriodTypes.Half: {
                    const half = getHalf(fyStartDate, currentDate);
                    if (half) {
                        for (let h = half; h > 0; h--) {
                            periods.push({
                                period: h,
                                text: `H${h}`,
                            });
                        }
                    }
                    break;
                }
                default:
                    break;
            }

            return periods;
        },
        getAllowedPeriodTypes: useCallback(
            (
                reportType: ReportType,
                reportOrTemplate?: {
                    reportPeriodType: ReportPeriodTypes | null;
                }
            ) => {
                const allowedTypes: ReportPeriodTypes[] = [];

                if (reportType === ReportType.StatusReport) {
                    allowedTypes.push(ReportPeriodTypes.Month);
                    allowedTypes.push(ReportPeriodTypes.Quarter);
                    if (
                        reportOrTemplate?.reportPeriodType ===
                        ReportPeriodTypes.None
                    ) {
                        allowedTypes.push(ReportPeriodTypes.None);
                    }
                } else if (reportOrTemplate?.reportPeriodType) {
                    allowedTypes.push(reportOrTemplate?.reportPeriodType);
                } else {
                    allowedTypes.push(ReportPeriodTypes.Month);
                    allowedTypes.push(ReportPeriodTypes.Quarter);
                }

                return allowedTypes;
            },
            []
        ),
    };
}
