import { ArgumentTypes } from '../../../data/types';

export type Variable = {
    __typename: 'ValueVariableQL';
    name: string | null;
    value: {
        __typename: 'ValueArgumentQL';
        argType: ArgumentTypes;
        decimalValue: number | null;
        measureId: string | null;
        functionName: string | null;
        variableName: string | null;
    };
};

export type MeasureLink = {
    __typename: 'MeasureQL';
    id: string | null;
    name: string | null;
    frequencyPeriod: string | null;
    mission: {
        __typename: 'MissionQL';
        id: string | null;
        title: string | null;
        owner: string | null;
    } | null;
};

export class FormulaManager {
    formula = '';
    variables: Variable[] = [];
    measures: MeasureLink[] = [];

    setFormula(formula_string: string | undefined | null) {
        this.formula = formula_string || '';
    }

    setVariables(variables?: Variable[]) {
        this.variables = variables ?? [];
    }

    setMeasures(measures?: MeasureLink[]) {
        this.measures = measures ?? [];
    }

    lineCount(): number {
        return this.formulaToLines().length;
    }

    addVariableDeclaration(variableName: string, measureId: string) {
        const declaration = `${variableName}="${measureId}"`;
        if (this.formula.length == 0) {
            this.formula = `${declaration}`;
        } else {
            this.formula = `${this.formula};\n${declaration}`;
        }

        this.substituteValueWithVariable(variableName, measureId);

        this.variables = [
            ...this.variables,
            {
                __typename: 'ValueVariableQL',
                name: variableName,
                value: {
                    __typename: 'ValueArgumentQL',
                    argType: ArgumentTypes.MeasureIds,
                    decimalValue: null,
                    measureId: measureId,
                    variableName: null,
                    functionName: null,
                },
            },
        ];
    }

    substituteValueWithVariable(variableName: string, measureId: string) {
        const lines = this.formulaToLines();
        const output = [];

        for (const line of lines) {
            if (line.startsWith('@')) {
                output.push(line);
            } else {
                output.push(
                    line
                        .replaceAll(`'${measureId}'`, variableName)
                        .replaceAll(`"${measureId}"`, variableName)
                );
            }
        }

        this.linesToFormula(output);
    }

    renameVariable(oldName: string, newName: string) {
        this.formula = this.formula.replaceAll(oldName, newName);

        this.variables = this.variables.map((v) => {
            return {
                ...v,
                name: v.name === oldName ? newName : v.name,
            };
        });
    }

    linesToFormula(lines: string[]) {
        this.formula = lines.join('\n');
    }

    formulaToLines() {
        return this.formula
            .replace(/;\n/g, '\n')
            .replace(/;/g, '\n')
            .split('\n')
            .map((s) => s.trim());
    }
}
