import {
    DeleteTaskMutation,
    DeleteTaskMutationVariables,
    GetTaskQuery,
    Task,
    useDeleteTaskMutation,
    useGetMissionLazyQuery,
    useGetMissionTasksLazyQuery,
    useUpdateTaskMutation,
} from '../data/types';
import { ExtractQueryArrayType } from '../data/extendedTypes';
import {
    ApolloCache,
    ApolloError,
    DefaultContext,
    MutationUpdaterFunction,
} from '@apollo/client';
import { UpdatedResourceType, useResourceUpdater } from './useResourceUpdater';
import { useIsTaskImplied } from './useIsTaskImplied';
import { navigation } from '../services/navigation';
import { useNavigate } from 'react-router';
import { useCallback, useState } from 'react';
import { ChecklistTask } from '../components/TaskChecklist';
import { useInputMappers } from './useInputMappers';

export const useTaskUpdater = (
    currentTenantId: string | undefined,
    currentTenantCode: string | undefined,
    currentFinancialYearCode: string | undefined,
    currentTeamCode: string | undefined,
    task: ExtractQueryArrayType<GetTaskQuery, ['task']> | null,
    selectedResources: UpdatedResourceType[],
    checklistTasks: ChecklistTask[]
): {
    updateTaskAsync: () => Promise<void>;
    hasSaved: boolean;
    isSaving: boolean;
    saveError: ApolloError | undefined;
    reset: () => void;
} => {
    const navigate = useNavigate();

    const isImpliedTask = useIsTaskImplied(task);

    const [hasSaved, setHasSaved] = useState(false);

    const resourceUpdater = useResourceUpdater(
        currentTenantId || null,
        currentFinancialYearCode || null,
        task?.mission?.userId || null
    );

    const [
        updateTaskMutation,
        { loading: isSaving, error: saveError, reset: resetMutation },
    ] = useUpdateTaskMutation();

    const { getDateForInput, getTaskInput } = useInputMappers();

    const cacheUpdater: MutationUpdaterFunction<
        DeleteTaskMutation,
        DeleteTaskMutationVariables,
        DefaultContext,
        ApolloCache<unknown>
    > = (cache, mutationResult) => {
        const deletedObject = mutationResult.data?.taskDelete?.deletedObject;
        if (deletedObject?.utcDeleted) {
            cache.evict({ id: cache.identify(deletedObject) });
            deletedObject.subTasks
                ?.filter((st) => st.utcDeleted)
                .forEach((st) => {
                    cache.evict({ id: cache.identify(st) });
                });
            cache.gc();
        }
    };

    const [deleteTaskMutation, { loading: isDeleting }] = useDeleteTaskMutation(
        {
            update: cacheUpdater,
        }
    );

    const [refetchMissionTasksQuery] = useGetMissionTasksLazyQuery({
        fetchPolicy: 'network-only',
    });
    const [refetchMissionQuery] = useGetMissionLazyQuery({
        fetchPolicy: 'network-only',
    });

    const updateTaskAsync = async (): Promise<void> => {
        setHasSaved(false);

        if (!task || !currentTenantId) {
            return;
        }

        const inputTask = getTaskInput(task);

        // Clear changes pending on this task.
        inputTask.utcChangesPending = null;

        const result = await updateTaskMutation({
            variables: {
                tenantId: currentTenantId,
                input: inputTask,
            },
        });

        if (isImpliedTask) {
            await resourceUpdater.updateResourcedTasks(task, selectedResources);
            await updateChecklistTasks(task, checklistTasks);
        }

        setHasSaved(true);

        if (inputTask.missionId) {
            await Promise.all([
                refetchMissionTasksQuery({
                    variables: {
                        tenantId: currentTenantId,
                        missionId: inputTask.missionId,
                    },
                }),
                refetchMissionQuery({
                    variables: {
                        tenantId: currentTenantId,
                        missionId: inputTask.missionId,
                    },
                }),
                // Delay so that the user can see the saved message.
                new Promise((res) => setTimeout(res, 1000)),
            ]);
        }

        setHasSaved(false);

        if (
            !inputTask.id &&
            result.data?.taskUpdate?.id &&
            result.data?.taskUpdate?.missionId
        ) {
            // Navigate to the new task.
            const href = navigation.getPathForParams({
                tenantCode: currentTenantCode,
                financialYearCode: currentFinancialYearCode,
                teamCode: currentTeamCode,
                missionId: result.data?.taskUpdate?.missionId,
                specifiedTaskId:
                    result.data?.taskUpdate?.parentTaskId ||
                    result.data?.taskUpdate?.id,
            });

            navigate(href);
        }
    };

    const updateChecklistTasks = async (
        task: ExtractQueryArrayType<GetTaskQuery, ['task']>,
        checklistTasks: ChecklistTask[]
    ): Promise<void> => {
        const existingChecklistTasks = task.subTasks.filter(
            (t) => !t.isDuplicate
        );

        for (const existing of existingChecklistTasks) {
            const removed = checklistTasks.some(
                (t) => t.id === existing.id && t.isDeleted
            );
            if (removed) {
                await deleteTaskMutation({
                    variables: {
                        tenantId: currentTenantId || '',
                        id: existing.id || '',
                        isTaskResource: false,
                        restore: false,
                    },
                });
            }
        }

        const checklistTasksToUpdate = checklistTasks.filter(
            (checklistTask) => !checklistTask.isDeleted
        );

        for (const checklistTask of checklistTasksToUpdate) {
            const existing = task.subTasks.find(
                (t) => t.id === checklistTask.id
            );

            let hasChanged = false;

            if (existing) {
                if (
                    existing.sequence !== checklistTask.sequence ||
                    existing.name !== checklistTask.name ||
                    existing.due !== checklistTask.due ||
                    existing.done !== checklistTask.done
                ) {
                    hasChanged = true;
                }
            }

            // Only add new tasks with a name, or existing tasks that have changed.
            if ((!existing && checklistTask.name) || hasChanged) {
                const inputChecklistTask: Task = {
                    id: checklistTask.id,
                    missionId: task.missionId,
                    name: checklistTask.name,
                    description: '',
                    percentComplete: checklistTask.done ? 1 : 0,
                    parentTaskId: task.id,
                    resourcedFromTaskId: null,
                    start: null,
                    due: getDateForInput(checklistTask.due),
                    done: getDateForInput(checklistTask.done),
                    review: null,
                    utcAccepted: null,
                    utcRejected: null,
                    rejectedReason: null,
                    taskCategoryId: null,
                    resourceId: null,
                    utcResourceRemoved: null,
                    utcChangesPending: null,
                    utcCancelled: null,
                    utcPostponed: null,
                    utcAtRisk: null,
                    isPercentageCompleteFromResources: false,
                    isPercentageCompleteFromSubTasks: false,
                    sequence: checklistTask.sequence,
                    resource: null,
                    linkedMeasures: [],
                    isDuplicate: false,
                    resourceIsPrimary: false,
                    effortWeight: 1,
                    effortResourceWeight: 1,
                    costWeight: 1,
                    version: '',
                };

                await updateTaskMutation({
                    variables: {
                        tenantId: currentTenantId || '',
                        input: inputChecklistTask,
                    },
                });
            }
        }
    };

    const resetResources = resourceUpdater.reset;
    const reset = useCallback(() => {
        resetMutation();
        resetResources();
    }, [resetMutation, resetResources]);

    return {
        updateTaskAsync,
        hasSaved,
        isSaving: isDeleting || isSaving || resourceUpdater.isBusy,
        saveError: saveError || resourceUpdater.saveError,
        reset,
    };
};
