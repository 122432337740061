import React from 'react';
import { Stack, Text, mergeStyleSets, Separator, Icon } from '@fluentui/react';
import { GetMissionQuery } from '../../../data/types';
import { sorters } from '../../../data/sorters';
import Loading from '../../../components/Loading';
import { AdvanceCard } from '../../../components/AdvanceCard';
import { useStrategicDirection } from '../../../hooks/useStrategicDirection';
import { useLanguage } from '../../../services/i18n';

export type MissionCardProps = {
    missionData: GetMissionQuery['mission'];
    teamCode?: string;
};

function MissionCard(props: MissionCardProps): JSX.Element {
    const mission = props.missionData;

    const { t } = useLanguage();

    const {
        onLevelUpStatement,
        twoLevelsUpStatement,
        vision,
        purpose,
        values,
    } = useStrategicDirection(mission, props.teamCode);

    if (!mission) {
        return <Loading />;
    }

    const gapStack = {
        childrenGap: 8,
        padding: 0,
    };

    const serparatorStyles = {
        root: {
            padding: 0,
        },
    };

    const classNames = mergeStyleSets({
        gridItem: {
            marginBottom: '16px',
        },
        cardContainer: {
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            '@media(max-width: 600px)': {
                flexDirection: 'column',
            },
        },
        card: {
            flex: 1,
            padding: 8,
        },
    });

    function sortPhrases<T extends { sequence: number }>(phrases: T[]): T[] {
        return phrases.slice().sort(sorters.sequenceSorter) || [];
    }

    return (
        <div className={classNames.cardContainer}>
            <div className={classNames.card}>
                <AdvanceCard style={{ height: '100%' }}>
                    <AdvanceCard.Item>
                        <Stack
                            horizontal
                            tokens={{ childrenGap: 8 }}
                            verticalAlign={'center'}
                        >
                            <Icon iconName="ArrowTallUpRight" />
                            <Text variant={'mediumPlus'} block>
                                Strategic Direction
                            </Text>
                        </Stack>
                    </AdvanceCard.Item>
                    <AdvanceCard.Item fill>
                        <Separator styles={serparatorStyles} />
                    </AdvanceCard.Item>
                    <AdvanceCard.Item>
                        <Stack tokens={gapStack} verticalFill={true}>
                            {!!onLevelUpStatement && (
                                <Stack.Item>
                                    <Text variant={'mediumPlus'} block>
                                        One Level Up
                                    </Text>
                                    <Text variant={'small'} block>
                                        {onLevelUpStatement}
                                    </Text>
                                </Stack.Item>
                            )}

                            {!!twoLevelsUpStatement && (
                                <Stack.Item>
                                    <Text variant={'mediumPlus'} block>
                                        Two Levels Up
                                    </Text>
                                    <Text variant={'small'} block>
                                        {twoLevelsUpStatement}
                                    </Text>
                                </Stack.Item>
                            )}

                            {!!vision && (
                                <Stack.Item>
                                    <Text variant={'mediumPlus'} block>
                                        Vision
                                    </Text>
                                    <Text variant={'small'} block>
                                        {vision}
                                    </Text>
                                </Stack.Item>
                            )}

                            {!!purpose && (
                                <Stack.Item>
                                    <Text variant={'mediumPlus'} block>
                                        Purpose
                                    </Text>
                                    <Text variant={'small'} block>
                                        {purpose}
                                    </Text>
                                </Stack.Item>
                            )}

                            {!!values && (
                                <Stack.Item>
                                    <Text variant={'mediumPlus'} block>
                                        Values
                                    </Text>
                                    <Text
                                        variant="small"
                                        block
                                        style={{ whiteSpace: 'pre-wrap' }}
                                    >
                                        {values
                                            .split('|')
                                            .map((v) => v.trim())
                                            .join('\n')}
                                    </Text>
                                </Stack.Item>
                            )}
                        </Stack>
                    </AdvanceCard.Item>
                </AdvanceCard>
            </div>

            <div className={classNames.card}>
                <AdvanceCard style={{ height: '100%' }}>
                    <AdvanceCard.Item>
                        <Stack
                            horizontal
                            tokens={{ childrenGap: 8 }}
                            verticalAlign={'center'}
                        >
                            <Icon iconName="BorderDot" />
                            <Text variant={'mediumPlus'} block>
                                {t('mission.boundaries')}
                            </Text>
                        </Stack>
                    </AdvanceCard.Item>

                    <AdvanceCard.Item fill>
                        <Separator styles={serparatorStyles} />
                    </AdvanceCard.Item>

                    <AdvanceCard.Item>
                        <Stack tokens={gapStack}>
                            <Text variant={'mediumPlus'} block>
                                {t('mission.freedom', {
                                    count: mission.freedoms.length,
                                })}
                            </Text>

                            {!mission?.freedoms?.length && (
                                <Text variant="small" block>
                                    None entered.
                                </Text>
                            )}

                            {sortPhrases(mission.freedoms || []).map(
                                (p, index) => (
                                    <Text
                                        key={index}
                                        variant="small"
                                        block
                                        style={{ whiteSpace: 'pre-line' }}
                                    >
                                        {p.text?.trim()}
                                    </Text>
                                )
                            )}
                        </Stack>
                    </AdvanceCard.Item>

                    <AdvanceCard.Item fill>
                        <Separator styles={serparatorStyles} />
                    </AdvanceCard.Item>

                    <AdvanceCard.Item>
                        <Stack tokens={gapStack}>
                            <Text variant={'mediumPlus'} block>
                                {t('mission.constraint', {
                                    count: mission.constraints.length,
                                })}
                            </Text>
                            {!mission?.constraints?.length && (
                                <Text variant="small" block>
                                    None entered.
                                </Text>
                            )}
                            {sortPhrases(mission.constraints || []).map(
                                (p, index) => (
                                    <Text
                                        key={index}
                                        variant="small"
                                        block
                                        style={{ whiteSpace: 'pre-line' }}
                                    >
                                        {p.text?.trim()}
                                    </Text>
                                )
                            )}
                        </Stack>
                    </AdvanceCard.Item>
                </AdvanceCard>
            </div>

            <div className={classNames.card}>
                <AdvanceCard style={{ height: '100%' }}>
                    <AdvanceCard.Item>
                        <Stack
                            horizontal
                            tokens={{ childrenGap: 8 }}
                            verticalAlign={'center'}
                        >
                            <Icon iconName="Commitments" />
                            <Text variant={'mediumPlus'} block>
                                {t('mission.behaviour', {
                                    count: mission.behaviours.length,
                                })}
                            </Text>
                        </Stack>
                    </AdvanceCard.Item>

                    <AdvanceCard.Item fill>
                        <Separator styles={serparatorStyles} />
                    </AdvanceCard.Item>

                    <AdvanceCard.Item>
                        <Stack tokens={gapStack} verticalFill={true}>
                            {!mission?.behaviours?.length && (
                                <Text variant="small" block>
                                    None entered.
                                </Text>
                            )}
                            {sortPhrases(mission.behaviours || []).map(
                                (p, index) => (
                                    <Text
                                        key={index}
                                        variant="small"
                                        block
                                        style={{ whiteSpace: 'pre-line' }}
                                    >
                                        {p.text?.trim()}
                                    </Text>
                                )
                            )}
                        </Stack>
                    </AdvanceCard.Item>
                </AdvanceCard>
            </div>
        </div>
    );
}

export default React.memo(MissionCard);
