import React, { useState } from 'react';
import {
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    MessageBar,
    MessageBarType,
    PrimaryButton,
    Spinner,
    SpinnerSize,
    Toggle,
} from '@fluentui/react';
import {
    refetchGetMeasureValueHistoryQuery,
    refetchGetMissionMeasuresQuery,
    useGetMeasureQuery,
    useUnlinkMeasureMutation,
} from '../data/types';
import Loading from './Loading';

export type UnlinkMeasureDialogProps = {
    tenantId: string;
    measureId: string | null | undefined;
    hidden: boolean;
    onDismiss: () => void;
    onMeasureUnlinked: () => void;
};

export function UnlinkMeasureDialog(
    props: UnlinkMeasureDialogProps
): JSX.Element {
    const [keepValuesOption, setKeepValuesOption] = useState<boolean>(true);
    const [showUnlinkOptions, setShowUnlinkOptions] = useState<boolean>(false);

    const { data, loading, error } = useGetMeasureQuery({
        skip: props.hidden || !props.measureId,
        variables: {
            tenantId: props.tenantId,
            id: props.measureId || '',
        },
        onCompleted: () => {
            setShowUnlinkOptions(false);
            setKeepValuesOption(true);
        },
    });

    const [unlinkMeasure, { loading: isUnlinking, error: unlinkError }] =
        useUnlinkMeasureMutation();

    const measure = !loading ? data?.measure : null;

    const subText =
        measure?.isLinked && measure.linkedFromMeasure?.id
            ? `This measure of success is linked to another mission: ${measure.linkedFromMeasure.mission?.owner} - ${measure.linkedFromMeasure.mission?.title}`
            : undefined;

    const onKeepValuesCheckboxChange = (
        _ev?: React.FormEvent,
        checked?: boolean | undefined
    ) => {
        setKeepValuesOption(checked || false);
    };

    const onConfirmUnlinkClick = async () => {
        await unlinkMeasure({
            variables: {
                tenantId: props.tenantId,
                measureId: measure?.id || props.measureId || '',
                keepValues: keepValuesOption,
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                refetchGetMissionMeasuresQuery({
                    tenantId: props.tenantId,
                    missionId: measure?.missionId || '',
                }),
                refetchGetMeasureValueHistoryQuery({
                    tenantId: props.tenantId,
                    id: measure?.id || props.measureId || '',
                    historyHasActual: false,
                    historySkip: 0,
                    historyTake: 12,
                }),
            ],
        });

        if (!unlinkError) {
            props.onDismiss();
        }
    };

    return (
        <Dialog
            hidden={props.hidden}
            dialogContentProps={{
                type: DialogType.largeHeader,
                title: 'Linked Measure',
                showCloseButton: true,
                subText: subText,
            }}
            modalProps={{
                isBlocking: true,
            }}
            onDismiss={props.onDismiss}
        >
            {loading && <Loading />}

            {(error || unlinkError) && (
                <MessageBar
                    messageBarType={MessageBarType.error}
                    isMultiline={true}
                >
                    <span>{(error || unlinkError)?.message}</span>
                </MessageBar>
            )}

            {isUnlinking && (
                <Spinner
                    size={SpinnerSize.small}
                    label="Removing link..."
                    labelPosition="right"
                />
            )}

            {showUnlinkOptions && !isUnlinking && (
                <Toggle
                    label="Keep Values"
                    inlineLabel
                    checked={keepValuesOption}
                    onChange={onKeepValuesCheckboxChange}
                    disabled={isUnlinking}
                />
            )}

            {!loading && !showUnlinkOptions && (
                <DialogFooter>
                    {measure?.mission?.rights.write && (
                        <DefaultButton
                            text="Remove Link..."
                            onClick={() => {
                                setShowUnlinkOptions(true);
                            }}
                        />
                    )}
                    <DefaultButton text="Close" onClick={props.onDismiss} />
                </DialogFooter>
            )}

            {!loading && showUnlinkOptions && !isUnlinking && (
                <DialogFooter>
                    <PrimaryButton
                        text="Confirm Remove Link"
                        onClick={onConfirmUnlinkClick}
                        disabled={isUnlinking}
                    />
                    <DefaultButton
                        text="Cancel"
                        onClick={() => {
                            setShowUnlinkOptions(false);
                        }}
                    />
                </DialogFooter>
            )}
        </Dialog>
    );
}
