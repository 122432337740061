import React, { useEffect } from 'react';

import { Stack, TextField, PanelType } from '@fluentui/react';

import { EditPanel } from './shared/EditPanel';
import { useUpdateMissionMutation, MissionPhrase } from '../data/types';

import { useStateContext } from '../services/contextProvider';
import { UserPicker } from './UserPicker';
import useForm from '../hooks/useForm';
import { TeamPicker } from './inputs/TeamPicker';

type NewMissionPanelProps = {
    financialYearCode?: string | undefined | null;
    teamId?: string | undefined | null;
    user?: {
        userId: string;
        displayName: string;
    } | null;
    showPanel: boolean;
    onCancel: () => void;
    onSave: () => void;
};

export default function NewMissionPanel(
    props: NewMissionPanelProps
): JSX.Element {
    const { currentTenantId } = useStateContext();

    const [updateMission, { loading: isSaving, error: saveError }] =
        useUpdateMissionMutation({
            refetchQueries: ['GetTeamMissions', 'GetMissionSearch'],
            onCompleted: props.onSave,
        });

    type formValuesType = {
        owner: string;
        title: string;
        missionStatement: string;
        userId: string | null;
        teamId: string | null;
    };

    const formDefaultValues: formValuesType = {
        owner: '',
        title: '',
        missionStatement: '',
        userId: null,
        teamId: null,
    };

    const formValidate = (
        values: formValuesType
    ): {
        [key: string]: string;
    } => {
        const formErrorMessages = {
            owner: !values?.owner ? 'Please enter a value.' : '',
            title: !values?.title ? 'Please enter a value.' : '',
            teamId: '',
        };

        return formErrorMessages;
    };

    const formCallback = async (values: formValuesType): Promise<void> => {
        await updateMission({
            variables: {
                tenantId: currentTenantId || '',
                input: {
                    id: null,
                    teamId: props.teamId || values.teamId,
                    owner: values.owner,
                    title: values.title,
                    missionStatement: values.missionStatement,
                    missionDate: null,
                    userId: values.userId,
                    customMissionStatement1Up: null,
                    customMissionStatement2Up: null,
                    utcInactive: null,
                    freedoms: [] as MissionPhrase[],
                    constraints: [] as MissionPhrase[],
                    behaviours: [] as MissionPhrase[],
                    dottedLines: [],
                    leaderOfTeams: [],
                    version: '',
                },
            },
        });
    };

    const { errors, handleChange, handleSubmit, values, updateValue } = useForm(
        formDefaultValues,
        formCallback,
        formValidate,
        true
    );

    useEffect(() => {
        if (props.user) {
            updateValue('userId', props.user.userId);
            updateValue('owner', props.user.displayName);
        }
    }, [props.user, updateValue]);

    const onUserPickerChange = (
        users: {
            userId: string;
            name: string;
        }[]
    ): void => {
        if (users?.length > 0) {
            const selectedUserId = users[0].userId;
            const selectedUserName = users[0].name;

            updateValue('userId', selectedUserId);

            if (values.owner?.trim().length === 0) {
                updateValue('owner', selectedUserName);
            }
        }
    };

    const form = (
        <Stack tokens={{ childrenGap: 8 }}>
            <TeamPicker
                label="Team"
                disabled={!!props.teamId}
                selectedTeamId={props.teamId || values.teamId}
                errorMessage={errors.teamId}
                onChange={(team): void => {
                    updateValue('teamId', team?.id);
                }}
                financialYearCode={props.financialYearCode}
            />

            <UserPicker
                onChange={onUserPickerChange}
                itemLimit={1}
                defaultSelectedUsers={props.user ? [props.user] : []}
            />

            <TextField
                label="Owner"
                required
                name="owner"
                value={values?.owner || ''}
                errorMessage={errors.owner}
                onChange={handleChange}
            />

            <TextField
                label="Title"
                required
                name="title"
                value={values?.title || ''}
                errorMessage={errors.title}
                onChange={handleChange}
            />

            <TextField
                label="Mission Statement"
                multiline
                autoAdjustHeight
                name="missionStatement"
                defaultValue={''}
                onChange={handleChange}
            />
        </Stack>
    );

    return (
        <EditPanel
            activeViewName="MissionNew"
            onDismiss={(): void => props.onCancel()}
            panelType={PanelType.smallFixedFar}
            showPanel={props.showPanel}
            headerText="New Mission"
            isSaving={isSaving}
            isValid={true}
            saveErrorMessage={saveError ? saveError.message : null}
            onUpdateClick={handleSubmit}
        >
            {form}
        </EditPanel>
    );
}
