# What is the Advance Dashboard?

Advance is designed to drive alignment and accountability across teams, clarifying the interdependencies and interactions needed to achieve results. Click for a **[guide to the key components](/help/advance-dashboard-overview)**.

# Advance Innovations

[New Mission Status Reports features](/help/status-report-create)

[Mission Status Reports are now enabled with Mai](/help/status-report-create)

[New 'Mission Summary' Presentation View](/help/mission-present)

[Dynamic comments](/help/dynamic-comments)

[Mai - Your AI-powered Mission Leadership Companion](/help/mission-mai-interact)

# Mission

[How to update your mission statement and title](/help/mission-statement-edit)

[How to define your operating environment](/help/mission-operating-environment-add)

[How to present your mission](/help/mission-present)

[How to improve your mission](/help/mission-mai-analysis)

# Measures of Success

[How to create a measure of success](/help/measures-of-success-create)

[How to link from an existing measure](/help/measures-of-success-link-existing)

[How to send a linked measure](/help/measures-of-success-link-send)

[How to copy measures from last year](/help/measures-of-success-last-year-copy)

[How to to create calculated measures](/help/measures-of-success-calculated)

[How to unlink measures](/help/measures-of-success-unlink)

[How to transfer measures to mission owner](/help/measures-of-success-ownership-transfer)

[How to update measures of success](/help/measures-of-success-update)

[How to create groups for measures](/help/measures-of-success-group-create)

[How to group measures](/help/measures-of-success-grouping)

[How to re-arrange order of measure tiles](/help/measures-of-success-sequencing)

[How to change the measure view](/help/measures-of-success-view-change)

[How to add comments to measure](/help/measures-of-success-comment-add)

# Specified Tasks

[Actioning task notifications](/help/task-notification-update)

[Actioning resource requests](/help/task-notification-update-assignto)

[How to create specified tasks](/help/specified-task-create)

[How to link specified tasks to a measure](/help/specified-task-measure-link)

[How to set a task type](/help/specified-task-type-set)

[How to update specified task categories](/help/specified-task-categories-update)

[How to update specified tasks](/help/specified-task-update)

[How to re-arrange order of specified tasks](/help/specified-task-sequencing)

[How to expand and collapse task views](/help/task-view-expand-collapse)

[How to change the task view](/help/task-view-change)

[How to add comments to a specified task](/help/specified-task-comment-add)

[How to add attachments to a specified task](/help/specified-task-attachment-add)

[How to delete/unaccept a specified task](/help/specified-task-delete-unaccept)

# Implied Tasks

[How to create implied tasks](/help/implied-task-create)

[How to update implied tasks](/help/implied-task-update)

[How to add a resource](/help/implied-task-resource-add)

[How to add task ratings](/help/implied-task-ratings)

[How to create a task checklist](/help/implied-task-checklist-create)

[How to update a task checklist](/help/implied-task-checklist-update)

[How to promote task checklist item to an implied task](/help/implied-task-checklist-promote)

[How to re-arrange implied task order](/help/implied-task-sequencing)

[How to expand and collapse task views](/help/task-view-expand-collapse)

[How to change the task view](/help/task-view-change)

[How to add comments to implied task](/help/implied-task-comment-add)

[How to add attachments to implied task](/help/implied-task-attachment-add)

[How to remove a resource](/help/implied-task-resource-remove)

[How to delete/unaccept an implied task](/help/implied-task-delete-unaccept)

# Team

[How to view your team performance](/help/team-performance-view)

[How to check team alignment](/help/team-alignment-view)

[How to navigate to team member mission](/help/team-mission-navigation)

[How to create team groups](/help/team-group-create)

[How to edit team groups](/help/team-group-edit)

# Status Reports

[How to create a monthly status report](/help/status-report-create)

[How to promote mission status review action items](/help/status-report-action-promote)

[How to create a quarterly business review](/help/quarterly-report-create)

[How to publish a report](/help/status-report-publish)

[How to unpublish a report](/help/status-report-unpublish)

[How to present a report](/help/status-report-present)
