import React, { CSSProperties, PropsWithChildren, useState } from 'react';
import {
    Link,
    Text,
    mergeStyleSets,
    IconButton,
    IButtonStyles,
    Facepile,
    PersonaSize,
    IPersonaSharedProps,
    FontIcon,
} from '@fluentui/react';
import {
    FrequencyPeriods,
    GetMeasureQuery,
    ValueTypes,
} from '../../../data/types';
import {
    ExtractQueryType,
    MeasureSeriesNames,
} from '../../../data/extendedTypes';
import { useMeasureValueFormatter } from '../../../hooks/useMeasureValueFormatter';
import { useThemes } from '../../../hooks/useThemes';
import { MeasureFullYearTargetPanel } from './MeasureFullYearTargetPanel';
import { FrequencyTypes, getFrequencyLabels } from '../utils/measureUtils';
import { MeasureAsOfEditPanel } from './MeasureAsOfEditPanel';
import { AdvanceCard } from '../../../components/AdvanceCard';
import { MeasureChartColours, MeasureStatusColours } from '../../../Colours';
import { useFormatters } from '../../../hooks/useFormatters';
import { useStateContext } from '../../../services/contextProvider';
import { useMeasureStatusCalc } from '../../../hooks/useMeasureStatusCalc';
import { navigation } from '../../../services/navigation';
import { photoService } from '../../../services/photo.service';
import MeasureArrow from '../../../components/MeasureArrow';
import MeasureCalcTooltip from '../../MeasureFormulaEditor/components/MeasureCalcTooltip';
import { useLanguage } from '../../../services/i18n';

export function MeasureTargetSection(props: {
    measure: ExtractQueryType<GetMeasureQuery, ['measure']>;
    isReadOnly: boolean;
    onChangeFrequencyClick: () => void;
    fyStartDate: string;
}) {
    const { measure } = props;

    const { currentTenantCode, currentFinancialYearCode } = useStateContext();

    const measureFormatter = useMeasureValueFormatter(props.measure);
    const formatters = useFormatters();

    const { currentTheme } = useThemes();

    const { t } = useLanguage();

    const [
        isMeasureFullYearTargetPanelOpen,
        setIsMeasureFullYearTargetPanelOpen,
    ] = useState(false);

    const [editAsOfPanelAsOf, setEditAsOfPanelAsOf] = useState<{
        asOf: ExtractQueryType<GetMeasureQuery, ['measure', 'lastAsOf']>;
        seriesName: MeasureSeriesNames | null;
    } | null>(null);

    const targetValueFormatted =
        measureFormatter.isNumeric && props.measure.fullYearTarget !== null
            ? measureFormatter.formatDecimalValue(props.measure.fullYearTarget)
            : props.measure.fullYearString;

    const frequencyString = measure.isCustom
        ? 'Custom'
        : measure.frequencyPeriod === FrequencyPeriods.Month
          ? 'Monthly'
          : measure.frequencyPeriod === FrequencyPeriods.Quarter
            ? 'Quarterly'
            : measure.frequencyPeriod === FrequencyPeriods.Year
              ? 'Yearly'
              : measure.frequencyPeriod === FrequencyPeriods.None
                ? 'Intermittent'
                : 'Unknown';

    let frequencyType: FrequencyTypes = FrequencyTypes.Custom;

    if (measure.isCustom) {
        frequencyType = FrequencyTypes.Custom;
    } else if (measure.frequencyPeriod === FrequencyPeriods.Month) {
        frequencyType = FrequencyTypes.Monthly;
    } else if (measure.frequencyPeriod === FrequencyPeriods.Quarter) {
        frequencyType = FrequencyTypes.Quarterly;
    } else if (measure.frequencyPeriod === FrequencyPeriods.Year) {
        frequencyType = FrequencyTypes.Yearly;
    }

    const lastAsOf = measure.lastAsOf;

    const lastAsOfLabel = getFrequencyLabels(
        props.fyStartDate,
        frequencyType,
        lastAsOf?.asOfDate
    ).standard;

    const lastAsOfTargetFormatted = lastAsOf?.values?.find(
        (v) => v.seriesType?.name === MeasureSeriesNames.Target
    )?.formatStr;

    const lastAsOfActualFormatted = lastAsOf?.values?.find(
        (v) => v.seriesType?.name === MeasureSeriesNames.Actual
    )?.formatStr;

    const lastAsOfForecastFormatted = lastAsOf?.values?.find(
        (v) => v.seriesType?.name === MeasureSeriesNames.PhasedForecast
    )?.formatStr;

    let ytdStatus: number | null = null;

    const showYtd =
        measureFormatter.isNumeric &&
        measure.valueType === ValueTypes.Incremental;

    const { calcStatus, getYtd } = useMeasureStatusCalc();

    const { targetYtd, actualYtd, forecastYtd } = getYtd(
        measure.lastAsOf,
        measure.valueHistory || []
    );

    const { actualYtd: previousActualYtd, targetYtd: previousTargetYtd } =
        getYtd(measure.lastAsOf, measure.valueHistory || [], true);

    if (showYtd && measure.lastAsOf) {
        const previousYtdStatus = calcStatus(
            previousTargetYtd,
            previousActualYtd,
            measure
        );

        ytdStatus = calcStatus(
            targetYtd,
            actualYtd,
            measure,
            previousYtdStatus.statusValue
        )?.statusValue;
    }

    const classNames = mergeStyleSets({
        container: {
            display: 'flex',
            flexDirection: 'column',
        },
        cardContainer: {
            flexGrow: 1,
            rowGap: 16,
            columnGap: 16,
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(240px, 1fr))',
            gridAutoRows: showYtd ? 'minmax(90px, auto)' : 'minmax(68px, auto)',
            gridAutoFlow: 'dense',
        },
        detailsCardContainer: {
            flexGrow: 1,
        },
        detailsArea: {
            display: 'flex',
            gap: 32,
            marginBottom: 16,
            flexDirection: 'row',
            alignItems: 'baseline',
            '@media (max-width: 680px)': {
                flexDirection: 'column',
                gap: 8,
            },
        },
        detail: {
            whiteSpace: 'no-wrap',
            display: 'flex',
            flexDirection: 'row',
            gap: 4,
            '&span': {
                paddingTop: 2,
            },
        },
        detailTitle: {
            fontWeight: 600,
        },
        persona: {
            display: 'flex',
            flexDirection: 'row',
            gap: 4,
        },
    });

    let statusColour: string;
    if (lastAsOf?.arrowColour === 'GREEN') {
        statusColour = MeasureStatusColours.onTarget;
    } else if (lastAsOf?.arrowColour === 'RED') {
        statusColour = MeasureStatusColours.offTarget;
    } else if (lastAsOf?.arrowColour === 'YELLOW') {
        statusColour = MeasureStatusColours.nearTarget;
    } else {
        statusColour = currentTheme.palette.neutralQuaternary;
    }

    const formattedStatus =
        lastAsOfTargetFormatted !== null && lastAsOfActualFormatted !== null
            ? formatters.formatMeasureStatus(
                  lastAsOf?.statusValue,
                  measure.isStatusLimited
              )
            : null;

    const formattedYtdStatus =
        ytdStatus !== null
            ? formatters.formatMeasureStatus(ytdStatus, measure.isStatusLimited)
            : null;

    const fullYearTargetText =
        targetValueFormatted !== null
            ? targetValueFormatted
            : props.isReadOnly
              ? 'None'
              : 'Add a target';

    const fullYearTargetClick = () => setIsMeasureFullYearTargetPanelOpen(true);

    const linkedFromTitle =
        measure.isLinked && measure?.linkedFromMeasure?.mission
            ? measure.linkedFromMeasure?.mission.owner ||
              measure.linkedFromMeasure?.mission.title
            : null;

    const linkedFromHref =
        measure.isLinked &&
        measure?.linkedFromMeasure?.mission?.id &&
        measure.linkedFromMeasure?.mission?.team?.code &&
        measure?.linkedFromMeasure?.mission.rights.read
            ? navigation.getPathForParams({
                  tenantCode: currentTenantCode,
                  financialYearCode: currentFinancialYearCode,
                  missionId: measure.linkedFromMeasure.mission.id,
                  teamCode: measure.linkedFromMeasure.mission.team.code,
              })
            : null;

    const linkedFromFormulaEditor =
        measure.isLinked &&
        measure?.linkedFromMeasure?.mission?.id &&
        measure.linkedFromMeasure?.mission?.team?.code &&
        measure?.linkedFromMeasure?.mission.rights.read
            ? navigation.getPathForParams({
                  tenantCode: currentTenantCode,
                  financialYearCode: currentFinancialYearCode,
                  missionId: measure.linkedFromMeasure.mission.id,
                  teamCode: measure.linkedFromMeasure.mission.team.code,
                  measureId: measure.linkedFromMeasure.id || undefined,
                  formulaEdit: true,
              })
            : null;

    const getPersonaProps = (): IPersonaSharedProps => ({
        imageShouldFadeIn: true,
        hidePersonaDetails: true,
    });

    return (
        <>
            <div className={classNames.container}>
                <div className={classNames.detailsArea}>
                    <div className={classNames.detail}>
                        <span className={classNames.detailTitle}>
                            Measure Target
                            {!!targetValueFormatted &&
                                ` (${
                                    measure.isFullYearTarget
                                        ? 'Full Year'
                                        : 'Reoccurring'
                                })`}
                            :{' '}
                        </span>
                        <span>
                            {!props.isReadOnly ? (
                                <Link onClick={fullYearTargetClick}>
                                    {fullYearTargetText}
                                </Link>
                            ) : (
                                <span>{fullYearTargetText}</span>
                            )}
                        </span>
                    </div>
                    <div className={classNames.detail}>
                        <span className={classNames.detailTitle}>
                            Update Frequency:{' '}
                        </span>
                        <span>
                            {!props.isReadOnly ? (
                                <Link onClick={props.onChangeFrequencyClick}>
                                    {frequencyString}
                                </Link>
                            ) : (
                                <span>{frequencyString}</span>
                            )}
                        </span>
                    </div>

                    {measure.isLinked && linkedFromTitle && (
                        <div className={classNames.detail}>
                            <span className={classNames.detailTitle}>
                                Linked From:
                            </span>
                            <span>
                                {linkedFromHref ? (
                                    <Link href={linkedFromHref}>
                                        {linkedFromTitle}
                                    </Link>
                                ) : (
                                    <span>{linkedFromTitle}</span>
                                )}
                            </span>
                            {measure?.linkedFromMeasure?.valueFormulaData !=
                                null && (
                                <MeasureCalcTooltip
                                    measureId={measure?.linkedFromMeasure.id}
                                    valueFormula={
                                        measure?.linkedFromMeasure
                                            .valueFormulaData.formula
                                    }
                                    style={{ display: 'inline' }}
                                >
                                    <Link href={linkedFromFormulaEditor || ''}>
                                        <FontIcon iconName="Calculator" />
                                    </Link>
                                </MeasureCalcTooltip>
                            )}
                        </div>
                    )}

                    {!!measure.linkedMeasures.length && (
                        <div className={classNames.detail}>
                            <span className={classNames.detailTitle}>
                                Linked to:
                            </span>
                            <Facepile
                                personaSize={PersonaSize.size16}
                                personas={measure.linkedMeasures.map((lm) => ({
                                    personaName:
                                        lm.mission?.owner ||
                                        lm.mission?.title ||
                                        '',
                                    imageUrl: photoService.getImageUrl(
                                        lm.mission?.userId
                                    ),
                                }))}
                                getPersonaProps={getPersonaProps}
                            />
                        </div>
                    )}
                </div>

                {!!lastAsOf && (
                    <div className={classNames.cardContainer}>
                        <SectionCard
                            title={`Target ${
                                lastAsOfLabel ? `(${lastAsOfLabel})` : ''
                            }`}
                            highlightColour={currentTheme.palette.themeLight}
                            showYtd={showYtd}
                        >
                            <EditableElement
                                isReadOnly={props.isReadOnly}
                                onEditClick={() => {
                                    setEditAsOfPanelAsOf({
                                        asOf: lastAsOf,
                                        seriesName: MeasureSeriesNames.Target,
                                    });
                                }}
                            >
                                <Text
                                    variant="xLarge"
                                    nowrap
                                    style={{
                                        color:
                                            lastAsOfTargetFormatted === null
                                                ? currentTheme.palette
                                                      .neutralSecondary
                                                : undefined,
                                    }}
                                >
                                    {lastAsOfTargetFormatted !== null
                                        ? lastAsOfTargetFormatted
                                        : 'Not Set'}
                                </Text>
                            </EditableElement>

                            {showYtd && (
                                <Text variant="small">
                                    YTD:{' '}
                                    {measureFormatter.formatDecimalValue(
                                        targetYtd || 0
                                    )}
                                </Text>
                            )}
                        </SectionCard>

                        <SectionCard
                            title={`Actual ${
                                lastAsOfLabel ? `(${lastAsOfLabel})` : ''
                            }`}
                            highlightColour={currentTheme.palette.themePrimary}
                            showYtd={showYtd}
                        >
                            <EditableElement
                                isReadOnly={props.isReadOnly}
                                onEditClick={() => {
                                    setEditAsOfPanelAsOf({
                                        asOf: lastAsOf,
                                        seriesName: MeasureSeriesNames.Actual,
                                    });
                                }}
                            >
                                <Text
                                    variant="xLarge"
                                    nowrap
                                    style={{
                                        color:
                                            lastAsOfActualFormatted === null
                                                ? currentTheme.palette
                                                      .neutralSecondary
                                                : undefined,
                                    }}
                                >
                                    {lastAsOfActualFormatted !== null
                                        ? lastAsOfActualFormatted
                                        : 'Not Set'}
                                </Text>
                            </EditableElement>
                            {showYtd && (
                                <Text variant="small">
                                    YTD:{' '}
                                    {measureFormatter.formatDecimalValue(
                                        actualYtd || 0
                                    )}
                                </Text>
                            )}
                        </SectionCard>

                        {measure.showForecast && (
                            <SectionCard
                                title={`${t('measure-of-success.forecast')} ${
                                    lastAsOfLabel ? `(${lastAsOfLabel})` : ''
                                }`}
                                highlightColour={MeasureChartColours.forecast}
                                showYtd={showYtd}
                            >
                                <EditableElement
                                    isReadOnly={props.isReadOnly}
                                    onEditClick={() => {
                                        setEditAsOfPanelAsOf({
                                            asOf: lastAsOf,
                                            seriesName:
                                                MeasureSeriesNames.PhasedForecast,
                                        });
                                    }}
                                >
                                    <Text
                                        variant="xLarge"
                                        nowrap
                                        style={{
                                            color:
                                                lastAsOfForecastFormatted ===
                                                null
                                                    ? currentTheme.palette
                                                          .neutralSecondary
                                                    : undefined,
                                        }}
                                    >
                                        {lastAsOfForecastFormatted !== null
                                            ? lastAsOfForecastFormatted
                                            : 'Not Set'}
                                    </Text>
                                </EditableElement>

                                {showYtd && (
                                    <Text variant="small">
                                        YTD:{' '}
                                        {measureFormatter.formatDecimalValue(
                                            forecastYtd || 0
                                        )}
                                    </Text>
                                )}
                            </SectionCard>
                        )}

                        {lastAsOf?.statusValue !== undefined && (
                            <SectionCard
                                title={`Status ${
                                    lastAsOfLabel ? `(${lastAsOfLabel})` : ''
                                }`}
                                highlightColour={statusColour}
                                showYtd={showYtd}
                            >
                                <EditableElement
                                    isReadOnly
                                    onEditClick={() => {
                                        // TODO
                                    }}
                                >
                                    <Text
                                        variant="xLarge"
                                        nowrap
                                        style={{
                                            color:
                                                formattedStatus === null
                                                    ? currentTheme.palette
                                                          .neutralSecondary
                                                    : undefined,
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                gap: 4,
                                            }}
                                        >
                                            {formattedStatus}

                                            <MeasureArrow
                                                {...lastAsOf}
                                                isStatusLimited={
                                                    measure.isStatusLimited
                                                }
                                                iconFontSize={24}
                                            />
                                        </div>
                                    </Text>
                                </EditableElement>
                                {showYtd && (
                                    <Text variant="small">
                                        YTD: {formattedYtdStatus}
                                    </Text>
                                )}
                            </SectionCard>
                        )}
                    </div>
                )}
            </div>
            <MeasureFullYearTargetPanel
                measure={measure}
                showPanel={isMeasureFullYearTargetPanelOpen}
                onDismiss={() => setIsMeasureFullYearTargetPanelOpen(false)}
            />

            <MeasureAsOfEditPanel
                asOfId={editAsOfPanelAsOf?.asOf?.id ?? null}
                seriesName={editAsOfPanelAsOf?.seriesName ?? null}
                showPanel={!!editAsOfPanelAsOf}
                onDismiss={() => setEditAsOfPanelAsOf(null)}
                measure={measure}
                frequencyType={frequencyType}
                fyStartDate={props.fyStartDate}
            />
        </>
    );
}

const SectionCard = (
    props: PropsWithChildren<{
        title: string;
        highlightColour: string;
        showYtd: boolean;
    }>
): JSX.Element => {
    const { currentTheme } = useThemes();

    const cardStyle: CSSProperties = {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'start',
        gap: 8,
        paddingLeft: 16,
        paddingRight: 16,
        minHeight: props.showYtd ? 70 : 46,
        minWidth: 240,
        backgroundColor: currentTheme.isInverted
            ? `${props.highlightColour}60`
            : `${props.highlightColour}20`,
        borderLeft: `solid 4px ${props.highlightColour}`,
    };

    return (
        <div>
            <AdvanceCard style={cardStyle}>
                <div>
                    <Text
                        block
                        variant="medium"
                        style={{
                            paddingTop: 0,
                        }}
                    >
                        {props.title}
                    </Text>
                    {props.children}
                </div>
            </AdvanceCard>
        </div>
    );
};

function EditableElement(
    props: PropsWithChildren<{
        isReadOnly: boolean;
        onEditClick: () => void;
    }>
) {
    const { currentTheme } = useThemes();

    const [isEditIconShown, setIsEditIconShown] = useState(false);

    const handleMouseEnter = !props.isReadOnly
        ? () => setIsEditIconShown(true)
        : undefined;

    const handleMouseLeave = !props.isReadOnly
        ? () => setIsEditIconShown(false)
        : undefined;

    const classNames = mergeStyleSets({
        container: {
            display: 'flex',
            flexDirection: 'row',
            gap: 4,
            minHeight: 32,
            alignItems: 'end',
        },
        iconContainer: {
            minHeight: 32,
            minWidth: 32,
        },
    });

    const buttonStyle: IButtonStyles = {
        root: {
            color: currentTheme.palette.themeLighter,
            selectors: {
                '&:hover': {
                    color: currentTheme.palette.themePrimary,
                },
            },
        },
    };

    return (
        <div
            className={classNames.container}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {props.children}
            {!props.isReadOnly && (
                <div className={classNames.iconContainer}>
                    {isEditIconShown && (
                        <IconButton
                            iconProps={{ iconName: 'Edit' }}
                            onClick={props.onEditClick}
                            styles={buttonStyle}
                            title="Edit"
                        />
                    )}
                </div>
            )}
        </div>
    );
}
