import { NodeSpec } from 'prosemirror-model';

export const MentionNodeSpec: NodeSpec = {
    group: 'inline',
    inline: true,
    atom: true,
    attrs: {
        id: {},
        label: {},
    },
    selectable: false,
    toDOM: (node) => [
        'span',
        {
            'data-mention-id': node.attrs.id,
            class: 'mention',
            style: 'font-weight: bold;',
        },
        `@${node.attrs.label}`,
    ],
    parseDOM: [
        {
            tag: 'span[data-mention-id]',
            getAttrs: (dom: HTMLElement) => ({
                id: dom.getAttribute('data-mention-id'),
                label: dom.textContent?.slice(1), // maybe dom.textContent?.replace(/^@/, '') ?
            }),
        },
    ],
};
