import React, { CSSProperties, PropsWithChildren } from 'react';
import { usePresentationColours } from '../hooks/usePresentationColours';

function PresentationSlideContainer(
    props: PropsWithChildren<{
        sectionKey: string;
        leftSize?: 'small' | 'large' | 'none';
    }>
) {
    const { backgroundColour, backgroundImage, logoSrc, darkLogoSrc } =
        usePresentationColours();

    const backgroundGradient =
        props.leftSize && props.leftSize !== 'none'
            ? `linear-gradient(to right, var(--presentationHighlight) ${props.leftSize === 'large' ? 35 : 2}%, ${backgroundColour} ${props.leftSize === 'large' ? 35 : 2}% 100%)`
            : undefined;

    return (
        <section
            key={props.sectionKey}
            data-background-image={backgroundImage}
            data-background-color={backgroundColour}
            data-background-opacity={0.1}
            data-background-gradient={backgroundGradient}
        >
            <div
                className={`mintSections ${props.leftSize === 'large' ? 'largeLeft' : 'smallLeft'}`}
            >
                {props.children}

                {!!logoSrc && props.leftSize !== 'large' && (
                    <div
                        style={{
                            height: 60,
                            position: 'absolute',
                            bottom: 8,
                            left:
                                props.leftSize === 'small'
                                    ? 'calc(2vw + 8px)'
                                    : 8,
                        }}
                    >
                        <img
                            src={logoSrc}
                            style={{
                                border: 0,
                                maxHeight: '100%',
                                maxWidth: '100%',
                                width: 'auto',
                                margin: 0,
                            }}
                        />
                    </div>
                )}

                {props.leftSize === 'large' && !!darkLogoSrc && (
                    <div
                        style={{
                            height: 60,
                            position: 'absolute',
                            bottom: 8,
                            left: 8,
                        }}
                    >
                        <img
                            src={darkLogoSrc}
                            style={{
                                border: 0,
                                maxHeight: '100%',
                                maxWidth: '100%',
                                width: 'auto',
                                margin: 0,
                            }}
                        />
                    </div>
                )}
            </div>
        </section>
    );
}

function PresentationSlideLeftSection(props: PropsWithChildren) {
    return <div className="mintLeftSection">{props.children}</div>;
}

function PresentationSlideRightSection(
    props: PropsWithChildren<{
        fullHeight?: boolean;
    }>
) {
    const style: CSSProperties = props.fullHeight
        ? { height: '100vh' }
        : {
              height: '100vh',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
          };

    return (
        <div className="mintRightSection" style={style}>
            {props.children}
        </div>
    );
}

function PresentationSlideListContainer(props: PropsWithChildren) {
    return (
        <div style={{ height: '100vh', width: '100vw' }}>
            <div className="mintListContainer">{props.children}</div>
        </div>
    );
}

function PresentationSlideContentContainer(props: PropsWithChildren) {
    return <div className="mintContentContainer">{props.children}</div>;
}

function PresentationSlideContentScrollContainer(props: PropsWithChildren) {
    return (
        <div className="mintContentContainer">
            <div className="mintContentScrollContainer">{props.children}</div>
        </div>
    );
}

export const PresentationSlide = Object.assign(PresentationSlideContainer, {
    LeftSection: PresentationSlideLeftSection,
    RightSection: PresentationSlideRightSection,
    ListContainer: PresentationSlideListContainer,
    ContentContainer: PresentationSlideContentContainer,
    ContentScrollContainer: PresentationSlideContentScrollContainer,
});
