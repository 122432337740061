import React, { useEffect, useState } from 'react';
import { ContributorTaskList } from '../../components/ContributorTaskList';
import { useContributorTasks } from './hooks/useContributorTasks';
import { mergeStyleSets } from '@fluentui/react';
import TaskPanel, { TaskPanelSections } from '../../components/TaskPanel';
import AttachmentsPanel from '../../components/AttachmentsPanel';
import CommentsPanel from '../../components/CommentsPanel';
import { AreaContainer } from '../MissionBuilder/components/AreaContainer';
import HeaderSummaryBar from '../MissionBuilder/components/HeaderSummaryBar';
import { useTaskSummary } from '../../hooks/useTaskSummary';
import {
    DefaultTaskFilters,
    TaskFilterBar,
    TaskFilters,
} from '../MissionBuilder/components/TaskFilterBar';
import {
    useDispatchContext,
    useStateContext,
} from '../../services/contextProvider';
import { useMatch, useNavigate, useParams } from 'react-router';
import { useGetContributorQuery } from '../../data/types';
import TaskHistoryPanel from '../../components/TaskHistoryPanel';
import { useActiveView } from '../../hooks/useActiveView';
import { navigation, paths } from '../../services/navigation';

export function Contributor(): JSX.Element {
    const { currentTenantId, currentUserId, themePrimaryColourHex } =
        useStateContext();

    const params = useParams();
    const { tenantCode, financialYearCode, contributorId, teamCode, taskId } =
        params;

    const contributorTaskMatch = useMatch({
        path: paths.contributorTask,
        end: true,
    });

    const contributorWithTeamTaskMatch = useMatch({
        path: paths.contributorWithTeamTask,
        end: true,
    });

    const dispatch = useDispatchContext();

    const navigate = useNavigate();

    useActiveView('Contributor');

    const { data: contributorData } = useGetContributorQuery({
        skip: !currentTenantId,
        variables: {
            tenantId: currentTenantId || '',
            userId: null,
            contributorId: contributorId || '',
            financialYearCode: financialYearCode || null,
        },
    });

    useEffect(() => {
        if (tenantCode) {
            dispatch({
                type: 'SetupContext',
                payload: {
                    currentTenantCode: tenantCode || '',
                    currentFinancialYearCode: financialYearCode,
                    currentMissionId: null,
                    currentContributorId: contributorId,
                    currentTeamCode: teamCode,
                    themePrimaryColourHex: themePrimaryColourHex,
                },
            });
        }
    }, [
        dispatch,
        tenantCode,
        financialYearCode,
        contributorId,
        teamCode,
        themePrimaryColourHex,
    ]);

    useEffect(() => {
        if (taskId) {
            setSelectedTask((s) =>
                s?.id !== taskId ? { id: taskId, mode: 'view' } : s
            );
        }
    }, [taskId]);

    const setActiveTaskId = (id: string | null, mode?: 'view' | 'edit') => {
        setSelectedTask(id && mode ? { id: id, mode: mode } : null);

        if (id) {
            // If a task has been selected update the url.
            navigate(
                navigation.getPathForParams({
                    ...params,
                    taskId: id,
                })
            );
        } else if (contributorTaskMatch || contributorWithTeamTaskMatch) {
            // If a task has closed and the url is still a task one, clear it.
            navigate(
                navigation.getPathForParams({
                    ...params,
                    taskId: undefined,
                })
            );
        }
    };

    const contributor = contributorData?.contributor;
    const contributorTasks = useContributorTasks(contributor?.userId);

    const [selectedTask, setSelectedTask] = useState<{
        id: string;
        mode: 'edit' | 'view' | 'attachments' | 'comments' | 'history';
    } | null>();

    const taskSummary = useTaskSummary(
        contributorTasks.map((t) => {
            return { ...t, resourcedFromTask: null }; // We don't care about accepted/rejected here as they won't be in the mission
        })
    );

    const taskSummaryItems = taskSummary.map((ts) => {
        return {
            count: ts.count,
            text: ts.name.toLocaleUpperCase(),
            iconName: 'SquareShapeSolid',
            iconColour: ts.colour,
        };
    });

    const classNames = mergeStyleSets({
        tableContainer: {
            padding: 8,
        },
        filterContainer: {
            padding: 8,
        },
    });

    const [filters, setFilters] = useState<TaskFilters>(DefaultTaskFilters);

    const handleFiltersChanged = (f: TaskFilters) => {
        setFilters(f);
    };

    const title =
        contributor?.userId === currentUserId
            ? 'My Tasks'
            : contributor
              ? `${contributor?.userDisplayName} -  Tasks`
              : '';

    return (
        <React.Fragment>
            <AreaContainer
                title={title}
                isCollapsable={false}
                initialState="expanded"
                subTitle={<HeaderSummaryBar items={taskSummaryItems} />}
            >
                <div className={classNames.filterContainer}>
                    <TaskFilterBar
                        filters={filters}
                        tasks={contributorTasks}
                        onFiltersChanged={handleFiltersChanged}
                        includeResourcedTasks
                        hideResourceFilter
                        hideCategoryFilter
                    />
                </div>

                <div className={classNames.tableContainer}>
                    <ContributorTaskList
                        contributorTasks={contributorTasks}
                        onTaskClick={(taskId: string) =>
                            setActiveTaskId(taskId, 'view')
                        }
                        onTaskEditClick={(taskId: string) =>
                            setActiveTaskId(taskId, 'edit')
                        }
                        onCommentsClick={(taskId: string) =>
                            setSelectedTask({ id: taskId, mode: 'comments' })
                        }
                        onTaskHistoryClick={(taskId: string) =>
                            setSelectedTask({ id: taskId, mode: 'history' })
                        }
                        filters={filters}
                        missionAccess={{
                            read: false,
                            write: false,
                            export: false,
                            import: false,
                        }}
                    />
                </div>
            </AreaContainer>

            <AttachmentsPanel
                showPanel={selectedTask?.mode === 'attachments'}
                taskId={selectedTask?.id}
                onDismiss={() => {
                    setActiveTaskId(null);
                }}
            />
            <CommentsPanel
                showPanel={selectedTask?.mode === 'comments'}
                taskId={selectedTask?.id}
                onDismiss={() => {
                    setActiveTaskId(null);
                }}
            />
            <TaskHistoryPanel
                showPanel={selectedTask?.mode === 'history'}
                taskId={selectedTask?.id}
                onDismiss={() => {
                    setActiveTaskId(null);
                }}
            />
            <TaskPanel
                showPanel={
                    selectedTask?.mode === 'view' ||
                    selectedTask?.mode === 'edit'
                }
                sectionHint={
                    selectedTask?.mode === 'view'
                        ? TaskPanelSections.Alignment
                        : TaskPanelSections.Edit
                }
                taskId={selectedTask?.id}
                newImpliedSpecifiedTaskId={null}
                missionId={null}
                onDismiss={() => {
                    setActiveTaskId(null);
                }}
                onAttachmentsButtonClick={() => {
                    if (selectedTask) {
                        setSelectedTask({
                            ...selectedTask,
                            mode: 'attachments',
                        });
                    }
                }}
                onCommentsButtonClick={() => {
                    if (selectedTask) {
                        setSelectedTask({
                            ...selectedTask,
                            mode: 'comments',
                        });
                    }
                }}
                onSwapTask={function (taskId: string | null): void {
                    if (taskId) {
                        setActiveTaskId(taskId, 'view');
                    } else {
                        setActiveTaskId(null);
                    }
                }}
            />
        </React.Fragment>
    );
}
