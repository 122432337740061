import React, { useState } from 'react';
import {
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    PrimaryButton,
} from '@fluentui/react';
import { ReportPeriodTypes } from '../../data/types';
import { ReportPeriodSelection } from './ReportPeriodSelection';
import { ReportType } from '../../scenes/StatusReports/StatusReports';

export function ReportPeriodChangeDialog(props: {
    hidden: boolean;
    fyStartDate: string | null | undefined;
    fyEndDate: string | null | undefined;
    reportPeriodType: ReportPeriodTypes | null;
    reportPeriod: number | null;
    onPeriodSaveClick: (
        reportPeriodType: ReportPeriodTypes,
        reportPeriod: number
    ) => void;
    onDismiss: () => void;
    reportType: ReportType;
}): JSX.Element {
    const [reportPeriodType, setReportingPeriodType] = useState(
        props.reportPeriodType
    );
    const [reportPeriod, setReportingPeriod] = useState(props.reportPeriod);

    const handlePeriodChanged = (
        periodType: ReportPeriodTypes,
        period: number
    ) => {
        setReportingPeriodType(periodType);
        setReportingPeriod(period);
    };

    const handleSave = () => {
        props.onPeriodSaveClick(
            reportPeriodType || ReportPeriodTypes.None,
            reportPeriod || 0
        );
    };

    return (
        <Dialog
            hidden={props.hidden}
            onDismiss={props.onDismiss}
            dialogContentProps={{
                type: DialogType.largeHeader,
                title: 'Change Reporting Period',
                closeButtonAriaLabel: 'Close',
            }}
        >
            <ReportPeriodSelection
                fyStartDate={props.fyStartDate}
                fyEndDate={props.fyEndDate}
                reportPeriodType={reportPeriodType}
                reportPeriod={reportPeriod}
                onPeriodChanged={handlePeriodChanged}
                reportType={props.reportType}
                templateOrReport={{
                    reportPeriodType: props.reportPeriodType,
                }}
            />
            <DialogFooter>
                <PrimaryButton onClick={handleSave} text="Save" />
                <DefaultButton onClick={props.onDismiss} text="Cancel" />
            </DialogFooter>
        </Dialog>
    );
}
