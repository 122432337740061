import { mergeStyles } from '@fluentui/react';
import { useState, useEffect } from 'react';
import { useMissionMentor } from '../../../hooks/useMissionMentor';
import { useThemes } from '../../../hooks/useThemes';
import { Markdown } from '../../Presentations/Markdown';
import {
    Arrows,
    GetMeasuresForStatusReportQuery,
    GetStatusReportQuery,
    GetTasksForStatusReportQuery,
    ReportPeriodTypes,
    useGetMeasurePeriodDataLazyQuery,
    useGetMeasuresForStatusReportLazyQuery,
    useGetMissionQuery,
    useGetTasksForStatusReportLazyQuery,
} from '../../../data/types';
import { useReportPeriods } from '../../../hooks/useReportPeriods';
import { useStateContext } from '../../../services/contextProvider';
import { sorters } from '../../../data/sorters';
import dayjs from 'dayjs';
import { useFormatters } from '../../../hooks/useFormatters';
import { taskStatusUtil } from '../../../data/extendedTypes';
import { PromptBuilder, useMai } from '../../../hooks/useMai';

export type InputTextName =
    | 'ExecutiveSummary'
    | 'LastPeriod'
    | 'NextPeriod'
    | 'RisksAndOpportunities'
    | 'Support'
    | undefined;

export function useStatusReportMai(
    inputText: string,
    inputTextName: InputTextName,
    onInputTextChanged: (newInputText: string) => void,
    statusReport: GetStatusReportQuery['statusReport']
): {
    isEnabled: boolean;
    isDialogOpen: boolean;
    dismissDialog: () => void;
    maiContent: JSX.Element | undefined;
    maiActions: { name: string; action: () => void }[];
    generateDraft: () => void;
    factCheck: () => void;
    changeTone: (toneType: string) => void;
    simplify: () => void;
    isStreaming: boolean;
    isBuildingPrompt: boolean;
} {
    const { currentTenantId } = useStateContext();

    const { currentTheme } = useThemes();

    const {
        isStreaming,
        contentMd,
        executePrompt,
        addTeamToPrompt,
        cancel,
        isEnabled,
    } = useMissionMentor();

    const { getPromptBuilder } = useMai();

    const { data: missionData } = useGetMissionQuery({
        skip: !isEnabled || !statusReport?.missionId || !currentTenantId,
        variables: {
            tenantId: currentTenantId || '',
            missionId: statusReport?.missionId || '',
        },
    });

    const [isBuildingPrompt, setIsBuildingPrompt] = useState(false);

    const { formatTaskPercentage } = useFormatters();

    const [getMeasures] = useGetMeasuresForStatusReportLazyQuery();
    const [getTasks] = useGetTasksForStatusReportLazyQuery();
    const [getPeriodData] = useGetMeasurePeriodDataLazyQuery();

    const mission = missionData?.mission;
    const fyStartDate = mission?.team?.division?.financialYear?.startDate;
    const fyEndDate = mission?.team?.division?.financialYear?.endDate;

    const { formatPeriod } = useReportPeriods(fyStartDate, fyEndDate);

    const markdownContainerStyles = mergeStyles({
        '& BLOCKQUOTE': {
            marginLeft: 0,
            marginRight: 8,
            paddingLeft: 12,
            paddingRight: 16,
            paddingTop: 1,
            paddingBottom: 1,
            borderLeft: `solid 4px ${currentTheme.palette.themeLight}`,
        },
    });

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [maiContent, setMaiContent] = useState<JSX.Element>();
    const [maiActions, setMaiActions] =
        useState<{ name: string; action: () => void }[]>();

    const addTaskDetailsToPrompt = (
        promptBuilder: PromptBuilder,
        tasks: GetTasksForStatusReportQuery['tasks'],
        measures: GetMeasuresForStatusReportQuery['measures']
    ) => {
        promptBuilder.writeln(`# Specified Tasks`);
        promptBuilder.writeln();

        if (!tasks?.length) {
            promptBuilder.writeln('There are no specified tasks.');
        } else {
            const specifiedTasks = tasks
                .slice()
                .filter((t) => t.parentTaskId === null)
                .sort(sorters.sequenceSorter);

            specifiedTasks?.forEach(async (st) => {
                promptBuilder.writeln(`## ${st.name}`);
                promptBuilder.writeln();

                if (st.tags.find((t) => t.name === 'Main Effort')) {
                    promptBuilder.writeln('This is the main effort.');
                    promptBuilder.writeln();
                }

                if (st.linkedMeasures.length) {
                    promptBuilder.writeln(
                        'This task is linked to the following measures of success:'
                    );
                    for (const lm of st.linkedMeasures) {
                        const measure = measures.find(
                            (m) => m.id === lm.measureId
                        );
                        if (measure?.name) {
                            promptBuilder.writeln(` - ${measure?.name}`);
                        }
                    }
                    promptBuilder.writeln();
                }

                const fact = statusReport?.facts.find(
                    (f) => f.taskId === st.id && f.isIncluded
                );

                if (fact) {
                    promptBuilder.writeln(
                        'This task has been selected as a fact for this status report.'
                    );
                    promptBuilder.writeln();
                }

                if (st.start) {
                    promptBuilder.writeln(
                        `Start: ${dayjs(st.start).format('DD MMM YYYY')}`
                    );
                }
                if (st.due) {
                    promptBuilder.writeln(
                        `Due: ${dayjs(st.due).format('DD MMM YYYY')}`
                    );
                }

                promptBuilder.writeln();

                const impliedTasks = tasks
                    .slice()
                    .filter((it) => it.parentTaskId === st.id)
                    .filter((it) => !it.isDuplicate)
                    .sort(sorters.sequenceSorter);

                const headers = [
                    'Implied Task',
                    'Start Date',
                    'Due Date',
                    'Done Date',
                    'Percent Complete',
                    'Status',
                ];

                const rows: string[][] = [];

                impliedTasks.forEach((it) => {
                    const start = it.start
                        ? dayjs(it.start).format('DD MMM YYYY')
                        : 'NONE';
                    const due = it.due
                        ? dayjs(it.due).format('DD MMM YYYY')
                        : 'NONE';
                    const done = it.done
                        ? dayjs(it.done).format('DD MMM YYYY')
                        : 'NONE';
                    const percentComplete = formatTaskPercentage(
                        it.done ? 1 : it.percentComplete
                    );

                    const status =
                        taskStatusUtil.GetTaskStatus(
                            {
                                ...it,
                                resourcedFromTask: null, // This isn't needed for status reports.
                            },
                            mission?.team?.division?.financialYear?.code ||
                                undefined,
                            statusReport?.utcDataDate ||
                                statusReport?.utcCompletedDate
                        ) || '';

                    rows.push([
                        it.name || 'NO NAME',
                        start,
                        due,
                        done,
                        percentComplete,
                        status.match(/($[a-z])|[A-Z][^A-Z]+/g)?.join(' ') || '',
                    ]);
                });

                if (rows.length) {
                    promptBuilder.writeln(
                        'The actions required to complete this specified task:'
                    );
                    promptBuilder.writeMarkdownTable(headers, rows);
                } else {
                    promptBuilder.writeln('No implied tasks.');
                }
                promptBuilder.writeln();
            });
        }
    };

    const addMeasureDetailsToPrompt = async (
        promptBuilder: PromptBuilder,
        measures: GetMeasuresForStatusReportQuery['measures']
    ) => {
        promptBuilder.writeln('# Measures of Success\n\n');

        if (!measures?.length) {
            promptBuilder.writeln('There are no measures of success.');
        }

        const headers = [
            'Name',
            'Group',
            'Period',
            'Target',
            'Actual',
            'Next Period',
            'Next Period Target',
            'YTD Target',
            'YTD Actual',
            'Status',
            'Other Information',
        ];

        const rows: string[][] = [];

        for (const m of measures) {
            const periodData = await getPeriodData({
                variables: {
                    forDateTime:
                        statusReport?.utcDataDate ||
                        statusReport?.utcCompletedDate ||
                        null,
                    measureId:
                        m?.isLinked && m.linkedFromMeasureId
                            ? m.linkedFromMeasureId
                            : m?.id || '',
                    tenantId: currentTenantId || '',
                    reportPeriod: statusReport?.reportPeriod || null,
                    reportPeriodType:
                        statusReport?.reportPeriodType ||
                        ReportPeriodTypes.None,
                },
            });

            const mpd = periodData.data?.periodData?.measurePeriodData;

            const arrowColour =
                mpd?.ytdArrowColow && mpd.ytdArrowColow !== Arrows.None
                    ? mpd.ytdArrowColow
                    : mpd?.arrowColour;

            let status = '';
            switch (arrowColour) {
                case Arrows.Green:
                    status = 'ON MISSION';
                    break;
                case Arrows.Red:
                    status = 'OFF MISSION';
                    break;
                case Arrows.Yellow:
                    status = 'NEAR MISSION';
                    break;
                default:
                    status = 'UNKNOWN';
                    break;
            }

            let otherInformation = '';

            const fact = statusReport?.facts.find(
                (f) => f.measureId === m.id && f.isIncluded
            );

            if (fact) {
                otherInformation +=
                    'This measure of success has been selected as a fact for this status report.';
            }

            rows.push([
                m.name || 'No name',
                m.group?.name || '',
                mpd?.period || '',
                mpd?.targetFormatted || 'N/A',
                mpd?.actualFormatted || 'N/A',
                mpd?.nextPeriod || '',
                mpd?.nextTargetFormatted || 'N/A',
                mpd?.ytdTargetFormatted || 'N/A',
                mpd?.ytdActualFormatted || 'N/A',
                status,
                otherInformation,
            ]);
        }

        promptBuilder.writeMarkdownTable(headers, rows);
    };

    const getMissionDetailsMd = async (): Promise<string> => {
        const promptBuilder = new PromptBuilder();

        const measuresData = await getMeasures({
            variables: {
                tenantId: currentTenantId || '',
                forDateTime:
                    statusReport?.utcDataDate ||
                    statusReport?.utcCompletedDate ||
                    null,
                missionId: statusReport?.missionId || '',
                includeDeleted: false,
            },
        });

        const tasksData = await getTasks({
            variables: {
                tenantId: currentTenantId || '',
                forDateTime:
                    statusReport?.utcDataDate ||
                    statusReport?.utcCompletedDate ||
                    null,
                missionId: statusReport?.missionId || '',
                includeDeleted: false,
            },
        });

        const measures =
            measuresData.data?.measures
                ?.slice()
                ?.sort(sorters.sequenceSorter) || [];

        const tasks =
            tasksData.data?.tasks?.slice()?.sort(sorters.sequenceSorter) || [];

        if (statusReport?.reportPeriodType && statusReport.reportPeriod) {
            const period = formatPeriod(
                statusReport?.reportPeriodType,
                statusReport.reportPeriod
            );

            if (period) {
                promptBuilder.writeln('# Report Period');
                promptBuilder.writeln(period);
                promptBuilder.writeln();
            }
        }

        addFactsToPrompt(promptBuilder, measures, tasks);

        promptBuilder.writeln('# Mission Details\n\n');

        if (mission?.title) {
            promptBuilder.writeln('# Mission Title');
            promptBuilder.writeln(mission?.title);
            promptBuilder.writeln();
        }

        if (mission?.owner) {
            promptBuilder.writeln('# Mission Owner');
            promptBuilder.writeln(mission?.owner);
            promptBuilder.writeln();
        }

        if (mission?.missionStatement) {
            promptBuilder.writeln('# Mission Statement');
            promptBuilder.writeln(mission?.missionStatement);
            promptBuilder.writeln();
        }

        const team =
            mission?.leaderOfTeams.find((t) => t.code) || mission?.team;

        promptBuilder.writeln('# Team');
        await addTeamToPrompt(
            promptBuilder,
            team?.code,
            mission?.team?.division?.financialYear?.code,
            mission?.userId
        );

        for (const t of mission?.leaderOfTeams || []) {
            await addTeamToPrompt(
                promptBuilder,
                t?.code,
                mission?.team?.division?.financialYear?.code,
                mission?.userId
            );
        }

        await addMeasureDetailsToPrompt(promptBuilder, measures);
        addTaskDetailsToPrompt(promptBuilder, tasks, measures);

        return promptBuilder.toString();
    };

    const addFactsToPrompt = (
        promptBuilder: PromptBuilder,
        measures: GetMeasuresForStatusReportQuery['measures'],
        tasks: GetTasksForStatusReportQuery['tasks']
    ): void => {
        const facts = statusReport?.facts
            .slice()
            .filter((f) => f.isIncluded)
            .sort(sorters.sequenceSorter);

        promptBuilder.writeln('# Report Selected Facts');

        if (!facts?.length) {
            promptBuilder.writeln(
                'The user has not selected any facts for this report.'
            );
            return;
        }

        promptBuilder.writeln(
            'The user has selected the following facts for the focus of this report.'
        );
        promptBuilder.writeln();

        facts.forEach((f) => {
            if (f.measureId) {
                const measure = measures.find((m) => m.id === f.measureId);
                promptBuilder.writeln(
                    `## Measure of Success: ${measure?.name}`
                );
            }
            if (f.taskId) {
                const task = tasks.find((t) => t.id === f.taskId);
                promptBuilder.writeln(`## Specified Task: ${task?.name}`);
            } else if (f.factText) {
                promptBuilder.writeln(`## Custom Fact: ${f.factText}`);
            }

            promptBuilder.writeln();

            promptBuilder.writeln(
                `### So What?\n\n${f.soWhatText || 'No text entered.'}\n\n`
            );

            promptBuilder.writeln(
                `### Insight\n\n${f.insightText || 'No text entered.'}\n\n`
            );

            promptBuilder.writeln('### Actions');
            promptBuilder.writeln();

            if (f.actions.length) {
                promptBuilder.writeln(
                    `${f.actions.map((a) => `- ${a.name}`).join('\n')}`
                );
            } else if (f.actionText) {
                promptBuilder.writeln(`${f.actionText}\n\n`);
            } else {
                promptBuilder.writeln('No actions entered.');
            }

            promptBuilder.writeln();
            promptBuilder.writeln();
        });
    };

    const processMarkdownContent = (
        markdown: string,
        isStreaming: boolean
    ): string => {
        const placeholderStart = '[//]: UPDATED TEXT START';
        const placeholderEnd = '[//]: UPDATED TEXT END';
        let isInPlaceholderBlock = false;

        // Process markdown line by line
        let processedMarkdown = markdown
            .split('\n')
            .map((line) => {
                if (line.trim() === placeholderStart) {
                    isInPlaceholderBlock = true;
                    return ''; // Remove the placeholder start line
                }

                if (line.trim() === placeholderEnd) {
                    isInPlaceholderBlock = false;
                    return ''; // Remove the placeholder end line
                }

                if (line.trim().startsWith('[//]:')) {
                    return ''; // Remove other markdown comments
                }

                if (isInPlaceholderBlock) {
                    return `> ${line}`; // Add blockquote prefix
                }

                return line; // Keep the rest of the content
            })
            .join('\n');

        if (isStreaming) {
            processedMarkdown += ' ⬤';
        }

        return processedMarkdown;
    };

    useEffect(() => {
        let markdown = contentMd;

        if (markdown.indexOf('```markdown') > -1) {
            markdown = markdown.replaceAll('```markdown', '');
            markdown = markdown.replaceAll('```', '');
        }

        markdown = processMarkdownContent(markdown, isStreaming);

        if (markdown) {
            setMaiContent(
                <Markdown
                    source={markdown}
                    className={markdownContainerStyles}
                />
            );
        } else if (isStreaming) {
            setMaiContent(<p className="cursor">⬤</p>);
        } else {
            setMaiContent(undefined);
        }

        if (!isStreaming && contentMd && onInputTextChanged) {
            const regex =
                /\[\/\/\]: UPDATED TEXT START([\s\S]*?)\[\/\/\]: UPDATED TEXT END/;
            const match = contentMd.match(regex);
            const updatedText = match ? match[1] : null;
            if (updatedText && onInputTextChanged) {
                setMaiActions([
                    {
                        name: 'Use Suggestion',
                        action: () => {
                            onInputTextChanged(updatedText);
                            setIsDialogOpen(false);
                        },
                    },
                ]);
            }
        }
    }, [contentMd, isStreaming, markdownContainerStyles, onInputTextChanged]);

    const openDialog = () => setIsDialogOpen(true);
    const dismissDialog = () => {
        cancel();
        setIsDialogOpen(false);
    };

    const generateDraft = async () => {
        setIsBuildingPrompt(true);

        setMaiActions([]);
        setMaiContent(undefined);
        openDialog();

        const promptBuilder = await getPromptBuilder(
            `statusreport-${inputTextName?.toLowerCase()}-draft.md`
        );

        if (statusReport?.lastPeriodText) {
            promptBuilder.writeln(
                '# The user has said they were working on this in the previous period:'
            );
            promptBuilder.writeln(statusReport?.lastPeriodText);
            promptBuilder.writeln();
        }

        if (statusReport?.nextPeriodText) {
            promptBuilder.writeln(
                '# The user has said they will be working on this in the next period:'
            );
            promptBuilder.writeln(statusReport?.nextPeriodText);
            promptBuilder.writeln();
        }

        if (statusReport?.risksAndOpportunitiesText) {
            promptBuilder.writeln(
                '# The user has identified these risks and opportunities:'
            );
            promptBuilder.writeln(statusReport?.risksAndOpportunitiesText);
            promptBuilder.writeln();
        }

        if (statusReport?.supportText) {
            promptBuilder.writeln('# The user has asked for support:');
            promptBuilder.writeln(statusReport?.supportText);
            promptBuilder.writeln();
        }

        const missionDetailsMd = await getMissionDetailsMd();

        const prompt = promptBuilder
            .toString()
            .replace('[[MISSION DETAILS]]', missionDetailsMd);

        setIsBuildingPrompt(false);

        executePrompt(`generateDraft${inputTextName}`, prompt);
    };

    const factCheck = async () => {
        setIsBuildingPrompt(true);

        setMaiActions([]);
        setMaiContent(undefined);
        openDialog();

        const promptBuilder = await getPromptBuilder(
            `statusreport-${inputTextName?.toLowerCase()}-factcheck.md`
        );
        const missionDetailsMd = await getMissionDetailsMd();
        const prompt = promptBuilder
            .toString()
            .replace('[[PROVIDED TEXT]]', inputText)
            .replace('[[MISSION DETAILS]]', missionDetailsMd);

        setIsBuildingPrompt(false);

        executePrompt(`factCheck${inputTextName}`, prompt);
    };

    const changeTone = async (toneType: string) => {
        setIsBuildingPrompt(true);

        setMaiActions([]);
        setMaiContent(undefined);
        openDialog();

        const promptBuilder = await getPromptBuilder(
            `tone-change-${toneType}.md`
        );
        const prompt = promptBuilder
            .toString()
            .replace('[[PROVIDED TEXT]]', inputText);

        setIsBuildingPrompt(false);

        executePrompt(`${toneType}Tone`, prompt);
    };

    const simplify = async () => {
        setIsBuildingPrompt(true);

        setMaiActions([]);
        setMaiContent(undefined);
        openDialog();

        const promptBuilder = await getPromptBuilder(`simplify.md`);
        const prompt = promptBuilder
            .toString()
            .replace('[[PROVIDED TEXT]]', inputText);

        setIsBuildingPrompt(false);

        executePrompt(`simplify`, prompt);
    };

    return {
        isEnabled,
        isDialogOpen,
        dismissDialog,
        maiContent,
        maiActions: maiActions || [],
        generateDraft,
        factCheck,
        changeTone,
        simplify,
        isStreaming,
        isBuildingPrompt,
    };
}
