import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
    Stack,
    MessageBar,
    MessageBarType,
    Spinner,
    SpinnerSize,
    IIconProps,
} from '@fluentui/react';

import {
    useDispatchContext,
    useStateContext,
} from '../../services/contextProvider';
import {
    useGetAllMeasuresQuery,
    useGetAllTasksQuery,
    useGetMissionGroupsQuery,
    useGetTaskCategoriesQuery,
    useGetTeamMissionsQuery,
} from '../../data/types';
import { EditPanels, PanelTypes } from '../../components/EditPanels';
import {
    AreaContainer,
    AreaContainerButton,
} from '../MissionBuilder/components/AreaContainer';
import EditTeamPanel from '../../components/EditTeamPanel';
import TeamMissions, { TeamMissionsProps } from './components/TeamMissions';
import EditMissionGroupsPanel from '../../components/EditMissionGroupsPanel';
import { useActiveView } from '../../hooks/useActiveView';
import compact from 'lodash/compact';
import { TaskFlatList } from '../../components/TaskFlatList';
import { AdvanceCard } from '../../components/AdvanceCard';
import { MeasureFlatList } from '../../components/MeasureFlatList';
import { AlignmentList } from '../../components/AlignmentList';
import {
    DefaultMissionFilters,
    MissionFilterBar,
    MissionFilters,
} from '../MissionBuilder/components/MissionFilterBar';
import {
    DefaultTaskFilters,
    TaskFilterBar,
    TaskFilters,
} from '../MissionBuilder/components/TaskFilterBar';
import { useLanguage } from '../../services/i18n';
import { PathParams } from '../../services/navigation';

export type TeamView = 'standard' | 'compact' | 'list' | 'alignment';
export type TeamViewModes = 'measures' | 'tasks' | 'mission';

export function Team(): JSX.Element {
    const { tenantCode, teamCode, financialYearCode } = useParams<PathParams>();

    const [docTitle, setDocTitle] = useState<string | undefined>();
    useActiveView('Team', docTitle);
    const { t } = useLanguage();

    const [activePanel, setActivePanel] = useState<PanelTypes>(null);
    const [activeMissionId, setActiveMissionId] = useState<string | null>();
    const [activeMeasureId, setActiveMeasureId] = useState<string | null>();
    const [activeTaskId, setActiveTaskId] = useState<string | null>();
    const [showMissionGroupingPanel, setShowMissionGroupingPanel] =
        useState(false);
    const [teamViewMode, setTeamViewMode] = useState<TeamViewModes>('measures');

    const dispatch = useDispatchContext();

    const {
        currentUserId,
        currentTenantId,
        currentMissionId,
        currentTenantCode,
        currentRoles,
    } = useStateContext();

    const { loading, data, error, refetch } = useGetTeamMissionsQuery({
        skip: !teamCode || !currentTenantId,
        variables: {
            tenantId: currentTenantId || '',
            teamCode: teamCode || '',
            financialYearCode: financialYearCode || null,
        },
    });

    const team =
        !error && !loading && data && data.teams ? data.teams[0] : null;

    const leaderMission = team?.leaderMission;
    const teamMissions = team?.missions;
    const dottedMissions = team?.dottedMissions;

    const teamViewKey = 'ADVANCE_TEAMVIEW';

    // Legacy
    const teamViewCompactKey = 'ADVANCE_TEAMVIEWCOMPACT';

    // Alignment report visible to admins, team leaders, team members or those with write access to all missions.
    const viewsToShow =
        currentRoles.some((r) => ['GlobalAdmin', 'ClientAdmin'].includes(r)) ||
        leaderMission?.userId === currentUserId ||
        teamMissions?.some((m) => m.userId === currentUserId) ||
        (leaderMission?.rights.write &&
            teamMissions?.every((m) => m.rights.write))
            ? ['standard', 'compact', 'list', 'alignment']
            : ['standard', 'compact', 'list'];

    const getSavedViewMode = (): TeamView => {
        if (
            localStorage.getItem(teamViewKey) === 'alignment' &&
            viewsToShow.some((v) => v === 'alignment')
        ) {
            return 'alignment';
        }
        if (localStorage.getItem(teamViewKey) === 'list') {
            return 'list';
        } else if (
            localStorage.getItem(teamViewKey) === 'compact' ||
            localStorage.getItem(teamViewCompactKey) === 'true'
        ) {
            return 'compact';
        } else {
            return 'standard';
        }
    };

    const [teamView, setTeamView] = useState<TeamView>(getSavedViewMode());

    const changeTeamView = (mode: TeamView): void => {
        localStorage.setItem(teamViewKey, mode);
        localStorage.removeItem(teamViewCompactKey);

        setTeamView(mode);
    };

    const {
        data: groupData,
        loading: groupsLoading,
        refetch: refetchGroups,
    } = useGetMissionGroupsQuery({
        skip: !team?.id || !currentTenantId,
        variables: {
            tenantId: currentTenantId || '',
            teamId: team?.id || '',
        },
    });

    const handleMeasureClick = React.useCallback(
        (missionId: string, measureId: string): void => {
            setActiveMissionId(missionId);
            setActiveMeasureId(measureId);
            setActiveTaskId(null);
            setActivePanel('measure-overview');
        },
        []
    );

    const handleTaskClick = React.useCallback(
        (missionId: string, taskId: string): void => {
            setActiveMissionId(missionId);
            setActiveTaskId(taskId);
            setActiveMeasureId(null);
            setActivePanel('task-alignment');
        },
        []
    );

    const onActivePanelChanged = React.useCallback(
        (panelType: PanelTypes): void => {
            setActivePanel(panelType);
        },
        []
    );

    const onActiveTaskChanged = React.useCallback(() => {
        setActivePanel(null);
    }, []);

    const closeActivePanel = React.useCallback((): void => {
        setActivePanel(null);
    }, []);

    const onEditTeamClick = (): void => {
        setActivePanel('edit-team');
    };

    const handleGroupingButtonClick = () => {
        setShowMissionGroupingPanel(true);
    };

    const changeTeamViewMode = (mode: TeamViewModes): void => {
        setTeamViewMode(mode);

        if (mode === 'mission' && teamView === 'list') {
            setTeamView('standard');
        }
    };

    const canSort = currentRoles.some((r) =>
        ['GlobalAdmin', 'ClientAdmin'].includes(r)
    );

    const teamMissionsProps: TeamMissionsProps = {
        tenantCode: tenantCode || '',
        tenantId: currentTenantId || '',
        financialYearCode: financialYearCode || '',
        fyStartDate: team?.division?.financialYear?.startDate || '',
        teamId: team?.id || null,
        teamCode: team?.code || '',
        teamName: team?.name || '',
        leaderMission: leaderMission || null,
        missions: [],
        dottedMissions: [],
        onMeasureClick: handleMeasureClick,
        onTaskClick: handleTaskClick,
        teamCardMode: teamViewMode,
        loading: groupsLoading,
        missionGroups: groupData?.missionGroups || null,
        canSort: canSort,
        compact: teamView === 'compact',
    };

    if (teamMissions) {
        teamMissionsProps.missions = teamMissions.filter(
            (m) => m && m.id !== leaderMission?.id
        );
    }

    if (dottedMissions) {
        teamMissionsProps.dottedMissions = compact(
            dottedMissions
                .filter((dm) => dm && dm.id !== leaderMission?.id)
                .map((dm) => {
                    if (dm.mission) {
                        return {
                            ...dm.mission,
                            dottedMissionId: dm.id || null,
                            id: dm.mission?.id || null,
                            userId: dm.mission?.id || null,
                            missionGroupId: dm.missionGroupId,
                            sequence: dm.sequence,
                        };
                    }
                })
        );
    }

    useEffect(() => {
        if (team) {
            setDocTitle(`${team?.name}`);
        }
    }, [team]);

    useEffect(() => {
        if (leaderMission) {
            const isCurrentMissionInTeam = teamMissions?.some(
                (m) => m?.id === currentMissionId
            );

            if (
                !isCurrentMissionInTeam &&
                leaderMission?.id &&
                leaderMission?.id !== currentMissionId
            ) {
                dispatch({
                    type: 'SetCurrentTeamAndMission',
                    payload: {
                        currentTenantCode: tenantCode || '',
                        currentMissionId: leaderMission.id,
                        currentTeamCode: teamCode,
                    },
                });
            }
        }
    }, [
        leaderMission,
        teamMissions,
        currentMissionId,
        teamCode,
        tenantCode,
        dispatch,
    ]);

    const tokens = {
        gapStack: {
            padding: 0,
            marginBottom: 32,
            childrenGap: 8,
        },
    };

    const canEdit = currentRoles?.some((r) => r === 'GlobalAdmin');

    const getCommandBarButtons = (): AreaContainerButton[] => {
        const buttons: AreaContainerButton[] = [];

        if (canEdit) {
            buttons.push({
                key: 'Edit',
                iconProps: {
                    iconName: 'Edit',
                },
                text: 'Edit',
                onClick: onEditTeamClick,
                allowOverflow: true,
            });

            buttons.push({
                key: 'Grouping',
                iconProps: {
                    iconName: 'FabricFolder',
                },
                text: 'Grouping',
                onClick: handleGroupingButtonClick,
                allowOverflow: true,
            });
        }

        const viewModes: {
            [key in TeamViewModes]: {
                text: string;
                iconProps: IIconProps;
            };
        } = {
            ['mission']: {
                text: 'Mission Only',
                iconProps: { iconName: 'LargeGrid' },
            },
            ['measures']: {
                text: t('measure-of-success.measure-of-success', {
                    count: 99,
                }),
                iconProps: { iconName: 'Trending12' },
            },
            ['tasks']: {
                text: t('specified-task.specified-task', {
                    count: 99,
                }),
                iconProps: { iconName: 'TaskGroup' },
            },
        };

        const views: {
            [key in TeamView]: {
                text: string;
                iconProps: IIconProps;
                disabled?: boolean;
            };
        } = {
            ['standard']: {
                text: 'Standard view',
                iconProps: { iconName: 'Table' },
            },
            ['compact']: {
                text: 'Compact view',
                iconProps: { iconName: 'TransitionPush' },
            },
            ['list']: {
                text: 'List view',
                iconProps: { iconName: 'ViewList' },
                disabled: teamViewMode === 'mission',
            },
            ['alignment']: {
                text: 'Alignment & Activity',
                iconProps: { iconName: 'QuadColumn' },
            },
        };

        if (teamView !== 'alignment') {
            buttons.push({
                key: 'ViewMode',
                iconProps: viewModes[teamViewMode as TeamViewModes].iconProps,
                text: viewModes[teamViewMode as TeamViewModes].text,
                subMenuProps: {
                    items: ['measures', 'tasks', 'mission'].map((mode) => {
                        const vm = viewModes[mode as TeamViewModes];
                        return {
                            key: mode,
                            text: vm.text,
                            iconProps: vm.iconProps,
                            onClick: () =>
                                changeTeamViewMode(mode as TeamViewModes),
                        };
                    }),
                },
            });
        }

        buttons.push({
            key: 'Views',
            iconProps: views[teamView as TeamView].iconProps,
            text: views[teamView as TeamView].text,
            subMenuProps: {
                items: viewsToShow.map((mode) => {
                    const vm = views[mode as TeamView];
                    return {
                        key: mode,
                        text: vm.text,
                        iconProps: vm.iconProps,
                        disabled: vm.disabled,
                        onClick: () => changeTeamView(mode as TeamView),
                    };
                }),
            },
        });

        return buttons;
    };

    if (error) {
        return (
            <MessageBar messageBarType={MessageBarType.error}>
                {error.message}
            </MessageBar>
        );
    } else if (!team) {
        return (
            <div id="loader">
                <Spinner size={SpinnerSize.large} />
            </div>
        );
    }

    return (
        <React.Fragment>
            <EditPanels
                activePanel={activePanel}
                missionId={activeMissionId}
                measureId={activeMeasureId}
                taskId={activeTaskId}
                teamId={team.id}
                onActivePanelChanged={onActivePanelChanged}
                onActiveTaskChanged={onActiveTaskChanged}
            />

            <EditTeamPanel
                key={`editTeamPanel${team.id}`}
                team={team}
                showPanel={activePanel === 'edit-team'}
                onCancel={closeActivePanel}
                onSave={closeActivePanel}
                fyCodeFilter={null}
            />

            {team?.leaderMission?.rights && (
                <EditMissionGroupsPanel
                    teamId={team?.id}
                    missionAccess={team?.leaderMission?.rights}
                    showPanel={showMissionGroupingPanel}
                    onCancel={() => {
                        setShowMissionGroupingPanel(false);
                    }}
                    onSave={async () => {
                        await refetchGroups();
                        await refetch();
                        setShowMissionGroupingPanel(false);
                    }}
                />
            )}

            <Stack tokens={tokens.gapStack}>
                <Stack.Item align="stretch" grow>
                    <AreaContainer
                        title={team.name || ''}
                        isCollapsable={false}
                        commandBarButtons={getCommandBarButtons()}
                    >
                        {teamView === 'list' &&
                            teamViewMode === 'tasks' &&
                            !!currentTenantId &&
                            !!financialYearCode &&
                            !!team?.id && (
                                <TeamMissionsTaskList
                                    tenantId={currentTenantId}
                                    financialYearCode={financialYearCode}
                                    teamId={team?.id}
                                    onTaskClick={handleTaskClick}
                                    allowExport={
                                        !!team?.leaderMission?.rights?.export
                                    }
                                />
                            )}

                        {teamView === 'list' &&
                            teamViewMode === 'measures' &&
                            !!currentTenantId &&
                            !!financialYearCode &&
                            !!team?.id && (
                                <TeamMissionsMeasureList
                                    tenantId={currentTenantId}
                                    financialYearCode={financialYearCode}
                                    teamId={team?.id}
                                    onMeasureClick={handleMeasureClick}
                                />
                            )}

                        {teamView === 'alignment' &&
                            !!currentTenantCode &&
                            !!currentTenantId &&
                            !!financialYearCode &&
                            !!team?.id && (
                                <TeamAlignment
                                    tenantId={currentTenantId}
                                    tenantCode={currentTenantCode}
                                    financialYearCode={financialYearCode}
                                    teamId={team?.id}
                                />
                            )}

                        {teamView !== 'list' && teamView !== 'alignment' && (
                            <TeamMissions {...teamMissionsProps} />
                        )}
                    </AreaContainer>
                </Stack.Item>
            </Stack>
        </React.Fragment>
    );
}

function TeamMissionsTaskList(props: {
    tenantId: string;
    financialYearCode: string;
    teamId: string;
    onTaskClick: (missionId: string, taskId: string) => void;
    allowExport: boolean;
}): JSX.Element {
    const { data, loading } = useGetAllTasksQuery({
        variables: {
            tenantId: props.tenantId,
            teamId: props.teamId,
            financialYearCode: props.financialYearCode,
            forDateTime: null,
        },
        fetchPolicy: 'network-only',
    });

    const [filters, setFilters] =
        React.useState<TaskFilters>(DefaultTaskFilters);

    const handleFiltersChanged = (f: TaskFilters) => setFilters(f);

    const handleTaskClick = (taskId: string) => {
        const missionId = data?.tasks?.find((t) => t.id === taskId)?.missionId;
        if (missionId) {
            props.onTaskClick(missionId, taskId);
        }
    };

    return (
        <Stack tokens={{ padding: 8 }} styles={{ root: { height: '100%' } }}>
            <AdvanceCard>
                <AdvanceCard.Item>
                    {!!data?.tasks && (
                        <TaskFilterBar
                            tasks={data?.tasks}
                            filters={filters}
                            onFiltersChanged={handleFiltersChanged}
                            searchBoxPlaceholder={
                                'Filter by mission or keyword'
                            }
                            hideResourcedFromFilter
                        />
                    )}
                </AdvanceCard.Item>
                <AdvanceCard.Item>
                    <TaskFlatList
                        teamId={props.teamId}
                        tasks={data?.tasks}
                        tasksLoading={loading}
                        onTaskClick={handleTaskClick}
                        initialSort={['isLeader', 'mission.sequence']}
                        initialSortOrders={['desc', 'asc']}
                        defaultColumns={[
                            'displaySequence',
                            'missionName',
                            'specifiedTaskName',
                            'name',
                            'start',
                            'due',
                            'done',
                            'percentComplete',
                        ]}
                        allowExport={props.allowExport}
                        filters={filters}
                    />
                </AdvanceCard.Item>
            </AdvanceCard>
        </Stack>
    );
}

function TeamMissionsMeasureList(props: {
    tenantId: string;
    financialYearCode: string;
    teamId: string;
    onMeasureClick: (missionId: string, measureId: string) => void;
}): JSX.Element {
    const [filters, setFilters] = useState<MissionFilters>(
        DefaultMissionFilters
    );

    const { data, loading } = useGetAllMeasuresQuery({
        variables: {
            tenantId: props.tenantId,
            teamId: props.teamId,
            financialYearCode: props.financialYearCode,
            financialYearActiveDate: null,
            taskCategoryIds: filters.taskCategoryIds,
            forDateTime: null,
        },
        fetchPolicy: 'network-only',
    });

    const { data: categoryData } = useGetTaskCategoriesQuery({
        variables: {
            tenantId: props.tenantId || '',
            divisionId: null,
            financialYearCode: props.financialYearCode,
        },
    });

    const handleFiltersChanged = (f: MissionFilters) => setFilters(f);

    return (
        <Stack tokens={{ padding: 8 }} styles={{ root: { height: '100%' } }}>
            <AdvanceCard>
                <AdvanceCard.Item>
                    <MissionFilterBar
                        taskCategories={categoryData?.taskCategories || []}
                        filters={filters}
                        onFiltersChanged={handleFiltersChanged}
                        searchBoxPlaceholder={'Filter by mission or keyword'}
                    />
                </AdvanceCard.Item>
                <AdvanceCard.Item>
                    <MeasureFlatList
                        {...props}
                        measures={data?.measures}
                        measuresLoading={loading}
                        defaultColumns={[
                            'displaySequence',
                            'missionName',
                            'name',
                            'target',
                            'actual',
                            'status',
                        ]}
                        initialSort={['isLeader', 'mission.sequence']}
                        initialSortOrders={['desc', 'asc']}
                        filters={filters}
                    />
                </AdvanceCard.Item>
            </AdvanceCard>
        </Stack>
    );
}

function TeamAlignment(props: {
    tenantId: string;
    tenantCode: string;
    financialYearCode: string;
    teamId: string;
}): JSX.Element {
    const [filters, setFilters] = React.useState<MissionFilters>(
        DefaultMissionFilters
    );

    const handleFiltersChanged = (f: MissionFilters) => setFilters(f);

    return (
        <Stack tokens={{ padding: 8 }} styles={{ root: { height: '100%' } }}>
            <AdvanceCard>
                <AdvanceCard.Item>
                    <MissionFilterBar
                        filters={filters}
                        onFiltersChanged={handleFiltersChanged}
                        searchBoxPlaceholder={'Filter by mission or keyword'}
                    />
                </AdvanceCard.Item>
                <AdvanceCard.Item>
                    <AlignmentList
                        {...props}
                        defaultColumns={[
                            'displaySequence',
                            'missionName',
                            'measureCount',
                            'specifiedTaskCount',
                            'outstandingDependencyCount',
                            'latestStatusReport',
                        ]}
                        filters={filters}
                    />
                </AdvanceCard.Item>
            </AdvanceCard>
        </Stack>
    );
}
