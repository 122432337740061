import React, { useEffect, useState } from 'react';
import {
    mergeStyleSets,
    MessageBar,
    MessageBarType,
    Spinner,
    SpinnerSize,
} from '@fluentui/react';
import { useStateContext } from '../services/contextProvider';
import { PathParams, navigation, paths } from '../services/navigation';
import { generatePath, useNavigate } from 'react-router';
import { useGetDefaultMissionQuery } from '../data/types';
import dayjs from 'dayjs';

export default function RedirectToDefaultMission(): JSX.Element {
    const navigate = useNavigate();

    const { loading, navigateTo } = useDefaultLocation();

    const { currentFinancialYearCode, currentTenantCode, currentRoles } =
        useStateContext();

    const isAdmin = currentRoles.some((r) =>
        ['GlobalAdmin', 'ClientAdmin'].includes(r)
    );

    useEffect(() => {
        if (loading || !currentTenantCode) {
            return;
        }

        if (navigateTo?.contributorId || navigateTo?.missionId) {
            const path = navigation.getPathForParams(navigateTo);
            navigate(path);
        } else if (isAdmin) {
            // Admins wit no default mission (probably a new tenant) can go to the setup screen.
            const setupPath = generatePath(paths.setupHome, {
                tenantCode: currentTenantCode?.toLowerCase(),
            });
            navigate(setupPath);
        }
    }, [navigateTo, loading, currentTenantCode, isAdmin, navigate]);

    const styles = mergeStyleSets({
        loadingContainer: {
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 16,
        },
    });

    if (
        !loading &&
        !navigateTo?.contributorId &&
        !navigateTo?.missionId &&
        !isAdmin
    ) {
        return (
            <MessageBar
                messageBarType={MessageBarType.warning}
                isMultiline={true}
                styles={{
                    root: {
                        width: 'auto',
                        margin: 16,
                    },
                }}
            >
                {currentFinancialYearCode ? (
                    <span>
                        There were no missions found for this financial year or
                        you have not been given access. Please contact support
                        if this is unexpected.
                    </span>
                ) : (
                    <span>
                        There were no missions found for this tenant or you have
                        not been given access. Please contact support if this is
                        unexpected.
                    </span>
                )}
            </MessageBar>
        );
    } else {
        return (
            <div className={styles.loadingContainer}>
                <Spinner size={SpinnerSize.large} />
            </div>
        );
    }
}

function useDefaultLocation(): {
    navigateTo: PathParams | null;
    loading: boolean;
} {
    const {
        currentUserId,
        currentFinancialYearCode,
        currentTenantId,
        currentTenantCode,
    } = useStateContext();

    const [navigateTo, setNavigateTo] = useState<PathParams | null>(null);
    const [isReady, setIsReady] = useState(false);

    const { loading: dataLoading, data } = useGetDefaultMissionQuery({
        skip: !currentUserId || !currentTenantId,
        variables: {
            tenantId: currentTenantId || '',
            financialYearCode: currentFinancialYearCode || null,
        },
    });

    useEffect(() => {
        setIsReady(false);

        if (dataLoading || !data) {
            return;
        }

        const mission = data?.default_mission;
        const contributor = data?.default_contributor;

        const missionTeam =
            mission?.team && mission?.team?.leaderMission?.rights.read
                ? mission?.team
                : mission?.leaderOfTeams != null &&
                    mission?.leaderOfTeams.length
                  ? mission?.leaderOfTeams[0]
                  : mission?.team;

        const missionFy = mission?.team?.division?.financialYear;
        const contributorFy = contributor?.financialYear;

        let locationType: 'Mission' | 'Contributor' | null = null;

        // If there is a mission and a contributor returned, but the years are different.
        if (mission?.id && contributor?.id) {
            if (mission.userId !== currentUserId) {
                // If the default mission is somebody elses, use the contributor page
                locationType = 'Contributor';
            } else if (
                dayjs().isBetween(missionFy?.startDate, missionFy?.endDate)
            ) {
                // Favour the mission for the current year
                locationType = 'Mission';
            } else if (
                dayjs().isBetween(
                    contributorFy?.startDate,
                    contributorFy?.endDate
                )
            ) {
                // Then the contributor for the current year
                locationType = 'Contributor';
            } else if (
                dayjs(contributorFy?.startDate).isAfter(missionFy?.startDate)
            ) {
                // If the contributor fy start date is after the mission one, land on the contributor page.
                locationType = 'Contributor';
            } else {
                locationType = 'Mission';
            }
        } else if (mission?.id) {
            locationType = 'Mission';
        } else if (contributor?.id) {
            locationType = 'Contributor';
        }

        if (
            locationType === 'Mission' &&
            mission?.id &&
            missionTeam?.code &&
            missionFy?.code
        ) {
            setNavigateTo({
                tenantCode: currentTenantCode,
                missionId: mission.id,
                teamCode: missionTeam.code,
                financialYearCode: missionFy.code,
            });
        } else if (
            locationType === 'Contributor' &&
            contributor?.id &&
            contributorFy?.code
        ) {
            setNavigateTo({
                tenantCode: currentTenantCode,
                contributorId: contributor.id,
                teamCode: contributor.team?.code || undefined,
                financialYearCode: contributorFy.code,
            });
        }
        setIsReady(true);
    }, [data, dataLoading, currentTenantCode, currentUserId]);

    return {
        navigateTo: navigateTo,
        loading: dataLoading || !isReady,
    };
}
