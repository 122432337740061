import React, { useMemo } from 'react';
import {
    GetMeasurePeriodDataQuery,
    GetMeasuresForStatusReportQuery,
} from '../../../data/types';
import { ExtractQueryArrayType } from '../../../data/extendedTypes';
import { MeasureArrowIcon } from '../../../components/MeasureArrowIcon';
import { usePresentationColours } from '../hooks/usePresentationColours';
import { ResponsiveContainer } from '@fluentui/react-charting';
import {
    Bar,
    BarChart,
    LabelList,
    Tooltip,
    TooltipProps,
    XAxis,
} from 'recharts';
import { useThemes } from '../../../hooks/useThemes';
import {
    NameType,
    ValueType,
} from 'recharts/types/component/DefaultTooltipContent';

export function PresentationMeasureBox(props: {
    measure: ExtractQueryArrayType<
        GetMeasuresForStatusReportQuery,
        ['measures']
    >;
    periodData: ExtractQueryArrayType<
        GetMeasurePeriodDataQuery,
        ['periodData', 'measurePeriodData']
    >;
    showChart?: boolean;
}): JSX.Element {
    const { measure, periodData } = props;
    //const formatters = useFormatters();
    // const formattedPercentage =
    //     periodData?.targetFormatted !== undefined
    //         ? formatters.formatMeasureStatus(
    //               periodData.statusValue,
    //               measure?.isStatusLimited || false
    //           )
    //         : '';

    return (
        <div
            className="contentAreaRaised"
            style={{
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <div
                style={{
                    fontSize: '1em',
                    textAlign: 'left',
                    paddingBottom: 4,
                }}
                title={
                    measure?.shortName && measure?.name
                        ? measure?.name
                        : undefined
                }
            >
                <span className="line-clamp1" title={measure?.name || ''}>
                    {measure?.shortName || measure?.name}
                </span>
            </div>
            <div
                style={{
                    paddingTop: '1vh',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'start',
                }}
            >
                {/* {!!periodData && (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'end',
                            minWidth: '3.8vw',
                        }}
                    >
                        <MeasureArrowIcon
                            {...periodData}
                            iconFontSize="1.2em"
                        />
                        {(periodData?.actualFormatted !== null ||
                            periodData?.targetFormatted !== null) && (
                            <span
                                style={{
                                    fontSize: '0.6em',
                                }}
                            >
                                {formattedPercentage}
                            </span>
                        )}
                    </div>
                )} */}

                <div
                    style={{
                        alignSelf: 'end',
                        textAlign: 'left',
                        display: 'flex',
                        fontSize: '1em',
                        flexDirection: 'row',
                        gap: 'min(0.8vw, 16px)',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'start',
                            justifyContent: 'start',
                            gap: '0.4vh',
                        }}
                    >
                        <div style={{ fontSize: '0.6em' }}>
                            Actual{' '}
                            {periodData?.period
                                ? `(${periodData?.period})`
                                : null}
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                gap: '0.2vw',
                            }}
                        >
                            <div
                                style={{
                                    fontWeight: 'bold',
                                    fontSize: '1em',
                                }}
                            >
                                {periodData?.actualFormatted
                                    ? periodData?.actualFormatted
                                    : 'None'}
                            </div>
                            <div>
                                <MeasureArrowIcon
                                    {...periodData}
                                    iconFontSize="1em"
                                />
                            </div>
                        </div>

                        {!!periodData?.ytdActualFormatted && (
                            <div
                                style={{
                                    fontSize: '0.6em',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '0.6vw',
                                }}
                            >
                                <span>Actual (YTD)</span>
                                <span>·</span>
                                <span style={{ whiteSpace: 'nowrap' }}>
                                    {periodData?.ytdActualFormatted}
                                </span>

                                <span>
                                    <MeasureArrowIcon
                                        arrowColour={periodData.ytdArrowColow}
                                        arrowDirection={
                                            periodData.ytdArrowDirection
                                        }
                                        previousPeriod={
                                            periodData.previousPeriod
                                        }
                                        iconFontSize="0.8em"
                                        outlineArrow
                                    />
                                </span>
                            </div>
                        )}
                    </div>
                </div>

                <div
                    style={{
                        flexGrow: 1,
                        flexBasis: 0,
                        alignSelf: 'end',

                        // display: 'flex',
                        // flexDirection: 'column',
                        // textAlign: 'right',
                        //gap: '0.6vh',

                        display: 'grid',
                        gridTemplateColumns: '1fr auto auto',
                        fontSize: '0.6em',
                        alignItems: 'end',
                        justifyItems: 'end',
                        rowGap: '0.6vh',
                        columnGap: '0.6vw',
                    }}
                >
                    {!!periodData?.targetFormatted && (
                        <React.Fragment>
                            <span>Target ({periodData?.period})</span>
                            <span>·</span>
                            <span style={{ whiteSpace: 'nowrap' }}>
                                {periodData?.targetFormatted}
                            </span>
                        </React.Fragment>
                    )}

                    {!!periodData?.ytdTargetFormatted && (
                        <React.Fragment>
                            <span>Target (YTD)</span>
                            <span>·</span>
                            <span style={{ whiteSpace: 'nowrap' }}>
                                {periodData?.ytdTargetFormatted}
                            </span>
                        </React.Fragment>
                    )}

                    {!!periodData?.nextTargetFormatted &&
                        !!periodData?.nextPeriod && (
                            <React.Fragment>
                                <span>
                                    Next Target ({periodData?.nextPeriod})
                                </span>
                                <span>·</span>
                                <span>{periodData?.nextTargetFormatted}</span>
                            </React.Fragment>
                        )}
                </div>
            </div>

            {props.showChart && (
                <div>
                    <PresentationMeasureChart
                        history={
                            periodData
                                ? [...periodData.history, periodData]
                                : []
                        }
                    />
                </div>
            )}
        </div>
    );
}

function PresentationMeasureChart(props: {
    history:
        | {
              statusValue: number;
              actualFormatted: string | null;
              targetFormatted: string | null;
              period: string | null;
          }[]
        | undefined
        | null;
}) {
    const { highlightColour, backgroundColour } = usePresentationColours();

    const { getContrastColourHex } = useThemes();

    const textColour = useMemo(() => {
        return getContrastColourHex(backgroundColour || '#363636', [
            '#fff',
            '#363636', // GP Charcoal
        ]);
    }, [backgroundColour, getContrastColourHex]);

    const data =
        props.history
            ?.filter((h) => h.actualFormatted)
            ?.map((h) => {
                return {
                    name: h.period,
                    s: h.statusValue,
                    a: h.actualFormatted,
                    t: h.targetFormatted,
                };
            }) || [];

    if (data?.length < 2) {
        return null;
    }

    return (
        <ResponsiveContainer width="100%" height="200px">
            <BarChart
                height={200}
                data={data}
                margin={{ top: 40, right: 0, left: 0, bottom: 0 }}
            >
                <Bar dataKey="s" fill={highlightColour}>
                    <LabelList
                        dataKey="a"
                        position="top"
                        fill={textColour}
                        fontSize="0.5em"
                    />
                </Bar>
                <Tooltip
                    content={<PresentationMeasureChartTooltip />}
                    cursor={false}
                />
                <XAxis
                    dataKey="name"
                    stroke={textColour}
                    fontSize="0.6em"
                    tickLine={false}
                    axisLine={false}
                />
            </BarChart>
        </ResponsiveContainer>
    );
}

const PresentationMeasureChartTooltip = (
    props: TooltipProps<ValueType, NameType>
) => {
    const { backgroundColour } = usePresentationColours();

    const { active, payload, label } = props;
    if (active && payload && payload.length) {
        return (
            <div
                style={{
                    fontSize: '0.6em',
                    backgroundColor: backgroundColour,
                    padding: 16,
                    minWidth: 100,
                }}
            >
                <div style={{ fontWeight: 600 }}>{label}</div>
                <div>{payload[0].payload['a']}</div>
                <div style={{ fontSize: '0.8em' }}>
                    Target: {payload[0].payload['t'] || '-'}
                </div>
            </div>
        );
    }

    return null;
};
