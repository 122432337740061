import { PropsWithChildren } from 'react';
import { ITooltipHostProps, TooltipHost } from '@fluentui/react';
import { useThemes } from '../../../hooks/useThemes';

export function PresentationTooltipHost(
    props: PropsWithChildren<ITooltipHostProps & { hideTooltip?: boolean }>
) {
    const { darkTheme } = useThemes();

    if (props.hideTooltip) {
        return props.children;
    }

    return (
        <TooltipHost
            className="revealTooltip"
            tooltipProps={{ theme: darkTheme }}
            calloutProps={{ theme: darkTheme }}
            {...props}
        >
            {props.children}
        </TooltipHost>
    );
}
