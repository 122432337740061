import * as React from 'react';

import {
    Icon,
    mergeStyleSets,
    MessageBar,
    MessageBarButton,
    MessageBarType,
    Separator,
    Stack,
    Text,
} from '@fluentui/react';

import { NavigationContext } from '../../navigationContext';
import {
    useDispatchContext,
    useStateContext,
} from '../../services/contextProvider';
import {
    useGetContributorQuery,
    useGetMissionNavQuery,
} from '../../data/types';
import MenuPersona from './MenuPersona';
import MenuNav from './MenuNav';
import { useThemes } from '../../hooks/useThemes';
import { useViewport } from '../../hooks/useViewport';
import { useContext, useEffect } from 'react';
import { useSupportWidget } from '../SupportWidget';
import { navigation } from '../../services/navigation';
import { NavLink } from 'react-router';

export function Menu(props: {
    showIdleWarning: boolean;
    idleWarningRemainingSeconds: string;
}): JSX.Element {
    const { toggleNavBar, isNavBarExpanded } = useContext(NavigationContext);

    const [isMobileMode, setIsMobileMode] = React.useState(false);
    const { width } = useViewport();

    const { setThemeColour: setSupportThemeColour } = useSupportWidget();

    useEffect(() => {
        if (isMobileMode && isNavBarExpanded) {
            toggleNavBar();
        }
        if (!isMobileMode && !isNavBarExpanded) {
            toggleNavBar();
        }
    }, [isMobileMode, isNavBarExpanded, toggleNavBar]);

    useEffect(() => {
        setIsMobileMode(width > 0 && width < 800);
    }, [width]);

    const { currentTheme } = useThemes();

    const {
        currentTenantCode: tenantCode,
        currentTenantId: tenantId,
        currentFinancialYearCode: financialYearCode,
        currentTeamCode: teamCode,
        currentMissionId: missionId,
        currentContributorId: contributorId,
        currentRoles,
        currentUserId,
    } = useStateContext();

    const dispatch = useDispatchContext();

    const { data: missionQueryData, loading } = useGetMissionNavQuery({
        skip: !tenantId || !missionId,
        variables: {
            tenantId: tenantId || '',
            missionId: missionId || '',
        },
    });

    const { data: currentContributorData, loading: contributorLoading } =
        useGetContributorQuery({
            skip: !tenantId || !!missionId || !contributorId,
            variables: {
                tenantId: tenantId || '',
                userId: null,
                contributorId: contributorId || '',
                financialYearCode: financialYearCode || null,
            },
        });

    const { data: userContributorData, loading: userContributorLoading } =
        useGetContributorQuery({
            skip: !tenantId || !currentUserId || !financialYearCode,
            variables: {
                tenantId: tenantId || '',
                userId: currentUserId || null,
                contributorId: null,
                financialYearCode: financialYearCode || null,
            },
        });

    const styles = mergeStyleSets({
        root: {
            width: isNavBarExpanded ? '110px' : '56px',
            backgroundColor: currentTheme.palette.white,
        },
    });

    const mission = missionQueryData?.mission;
    const currentContributor = currentContributorData?.contributor;

    useEffect(() => {
        const currentTeam =
            teamCode &&
            mission?.team?.code &&
            mission?.team?.code.toUpperCase() === teamCode.toUpperCase()
                ? mission?.team
                : mission?.leaderOfTeams.find(
                      (t) => t.code?.toUpperCase() === teamCode?.toUpperCase()
                  );

        if (currentTeam?.division) {
            const divisionThemeColourHex =
                currentTeam?.division?.primaryColourHex;

            setSupportThemeColour(divisionThemeColourHex);

            dispatch({
                type: 'SetThemePrimaryColour',
                payload: {
                    themePrimaryColourHex: divisionThemeColourHex,
                },
            });
        }
    }, [
        teamCode,
        mission?.team,
        mission?.team?.code,
        mission?.leaderOfTeams,
        mission?.team?.division,
        dispatch,
        setSupportThemeColour,
    ]);

    const isContributorScene = !!currentContributor;
    const isCurrentUserAContributor = !!userContributorData?.contributor;

    return (
        <Stack
            verticalFill={true}
            className={styles.root + ' no-print'}
            tokens={{ childrenGap: 8, padding: 8 }}
        >
            {!!financialYearCode &&
                !!tenantCode &&
                !loading &&
                !contributorLoading &&
                !userContributorLoading &&
                (contributorId || missionId) &&
                isCurrentUserAContributor &&
                currentContributorData?.contributor?.id !==
                    userContributorData?.contributor?.id && (
                    <BackToMyTasksButton
                        tenantCode={tenantCode}
                        financialYearCode={financialYearCode}
                        compact={!isNavBarExpanded}
                    />
                )}

            {loading || contributorLoading || mission ? (
                <MenuPersona
                    isDataLoaded={!loading}
                    missionOwner={mission?.owner}
                    missionTitle={mission?.title}
                    missionUserId={mission?.userId || ''}
                    hidePersonaDetails={!isNavBarExpanded}
                />
            ) : (
                <MenuPersona
                    isDataLoaded={true}
                    missionOwner={currentContributor?.userDisplayName}
                    missionTitle={isContributorScene ? 'Contributor' : ''}
                    missionUserId={currentContributor?.userId || ''}
                    hidePersonaDetails={!isNavBarExpanded}
                />
            )}
            <Stack.Item grow>
                {!!tenantCode && !!tenantId && (
                    <MenuNav
                        tenantId={tenantId}
                        tenantCode={tenantCode}
                        teamCode={teamCode}
                        missionId={missionId}
                        financialYearCode={financialYearCode}
                        roles={currentRoles}
                        hideText={!isNavBarExpanded}
                        contributorId={currentContributor?.id}
                    />
                )}
            </Stack.Item>
            {props.showIdleWarning && (
                <MessageBar
                    messageBarType={MessageBarType.warning}
                    styles={{
                        iconContainer: { display: 'none' },
                        text: { margin: '4px 2px', paddingLeft: 4 },
                        actions: {
                            margin: 6,
                        },
                    }}
                    messageBarIconProps={{
                        iconName: '',
                    }}
                    actions={
                        <div>
                            <MessageBarButton
                                styles={{
                                    root: {
                                        padding: '4px 0',
                                        height: '',
                                    },
                                }}
                            >
                                Stay signed in
                            </MessageBarButton>
                        </div>
                    }
                    isMultiline
                >
                    You will be logged out in{' '}
                    {props.idleWarningRemainingSeconds} seconds.
                </MessageBar>
            )}
        </Stack>
    );
}

function BackToMyTasksButton(props: {
    tenantCode: string;
    financialYearCode: string;
    compact: boolean;
}): JSX.Element | null {
    const { currentTheme } = useThemes();

    const classNames = mergeStyleSets({
        container: {
            backgroundColor: currentTheme.palette.themeDark,
            color: currentTheme.palette.white,
            padding: 4,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 4,
            borderRadius: 2,
            boxShadow: currentTheme.effects.elevation4,
            textDecoration: 'none',
            transition: 'box-shadow 0.5s ease',
            ':hover': {
                boxShadow: currentTheme.effects.elevation8,
                color: currentTheme.palette.themeLighter,
                textDecoration: 'none',
            },
        },
        icon: {
            paddingTop: 2,
        },
        text: {
            color: currentTheme.palette.white,
        },
    });

    const href = navigation.getPathForParams({
        tenantCode: props.tenantCode,
        financialYearCode: props.financialYearCode,
    });

    return props.compact ? null : (
        <div>
            <NavLink className={classNames.container} to={href}>
                <Icon className={classNames.icon} iconName="NavigateBack" />
                <Text className={classNames.text} variant="smallPlus">
                    My Tasks
                </Text>
            </NavLink>
            <Separator />
        </div>
    );
}
