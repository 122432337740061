import { authService } from '../services/auth.service';
import {
    GetCurrentUserDocument,
    GetCurrentUserQuery,
    UserQl,
    GetDefaultMissionDocument,
    GetDefaultMissionQuery,
    UserTenantQl,
    UserRoleQl,
} from '../data/types';

import {
    ApolloClient,
    ApolloLink,
    HttpLink,
    InMemoryCache,
    Operation,
} from '@apollo/client';

import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { RetryLink } from '@apollo/client/link/retry';
import { onError } from '@apollo/client/link/error';

import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';

import { IConfiguration, configurationService } from './configuration.service';
import { Kind, OperationDefinitionNode } from 'graphql';
import dayjs from 'dayjs';

type SetupConfiguration = {
    configuration: IConfiguration;
    client: ApolloClient<unknown>;
    currentUserId: string | undefined;
    currentUserDisplayName: string | null | undefined;
    currentUserIsSelfHelpEnabled: boolean | undefined;
    currentUserIsNew: boolean | undefined;
    userTenants: UserTenantQl[];
    userRoles: UserRoleQl[];
};

class GraphService {
    apolloClient?: ApolloClient<unknown> = undefined;

    public async initialiseAsync(): Promise<SetupConfiguration> {
        const configuration =
            await configurationService.getConfigurationAsync();

        this.apolloClient = await this.getClientAsync(configuration);

        const currentUser = await this.getCurrentUserAsync();

        const isSelfHelpEnabled = currentUser?.utcSelfHelpEnabledUntil
            ? dayjs.utc(currentUser?.utcSelfHelpEnabledUntil) >= dayjs()
            : false;

        return {
            currentUserId: currentUser?.id ? currentUser.id : undefined,
            currentUserDisplayName: currentUser?.displayName,
            currentUserIsSelfHelpEnabled: isSelfHelpEnabled,
            currentUserIsNew: currentUser?.isNewUser,
            configuration: configuration,
            client: this.apolloClient,
            userTenants: currentUser?.userTenants || [],
            userRoles: currentUser?.userRoles || [],
        };
    }

    public async getDefaultMissionAsync(
        userId: string,
        tenantId: string,
        financialYearCode: string | null
    ): Promise<GetDefaultMissionQuery | null> {
        if (!this.apolloClient) {
            throw new Error('The apollo client is not initialised.');
        }
        try {
            const result =
                await this.apolloClient.query<GetDefaultMissionQuery>({
                    query: GetDefaultMissionDocument,
                    variables: {
                        tenantId: tenantId,
                        userId: userId,
                        financialYearCode: financialYearCode,
                    },
                });
            return result.data;
        } catch (error) {
            console.error('Error fetching default mission:', error);
            return null;
        }
    }

    omitDeep(
        obj: Record<string, unknown>,
        key: string
    ): Record<string, unknown> {
        return Object.entries(obj).reduce(
            (acc, [k, v]) => {
                if (k !== key) {
                    acc[k as string] = Array.isArray(v)
                        ? v.map((item) =>
                              typeof item === 'object' && item !== null
                                  ? this.omitDeep(item, key)
                                  : item
                          )
                        : typeof v === 'object' && v !== null
                          ? this.omitDeep(v as Record<string, unknown>, key)
                          : v;
                }
                return acc;
            },
            {} as Record<string, unknown>
        );
    }

    async getClientAsync(
        configuration: IConfiguration
    ): Promise<ApolloClient<unknown>> {
        const cleanTypenameLink = new ApolloLink((operation, forward) => {
            if (operation.variables) {
                operation.variables = this.omitDeep(
                    operation.variables,
                    '__typename'
                );
            }
            return forward(operation).map((data) => {
                return data;
            });
        });

        const cache = new InMemoryCache({
            typePolicies: {
                CurrencyQL: {
                    keyFields: ['code'],
                },
                // This is a grouping of queries so can be merged
                Reports: {
                    merge: true,
                },
            },
        });

        const customFetch = async (
            uri: RequestInfo | URL,
            options?: RequestInit
        ) => {
            const token = await authService.getTokenAsync();
            if (token) {
                const headers = options?.headers as Record<string, string>;
                headers['Authorization'] = `Bearer ${token}`;
            }
            return fetch(uri, options);
        };

        const httpLinkOptions: BatchHttpLink.Options = {
            uri: `${configuration.apiUrl}graphql`,
            fetch: customFetch,
        };

        const onErrorLink = onError(
            ({ graphQLErrors, networkError, operation }) => {
                graphQLErrors?.forEach(({ message, locations, path }) => {
                    console.error(
                        `[GraphQL error] Message: ${message}, Location: ${locations}, Path: ${path}`
                    );
                });
                if (networkError) {
                    // Don't error on aborted search queries.
                    if (
                        !(
                            networkError.name === 'AbortError' &&
                            operation.operationName.endsWith('Search')
                        )
                    ) {
                        console.error(
                            `[Network error] ${networkError.message}`,
                            networkError
                        );
                    }
                }
            }
        );

        const retryLink = new RetryLink();

        const isOperationMutation = (operation: Operation): boolean => {
            return operation.query.definitions.some(
                (d) =>
                    d.kind === Kind.OPERATION_DEFINITION &&
                    (d as OperationDefinitionNode).operation === 'mutation'
            );
        };

        const isOperationSubscription = (operation: Operation): boolean => {
            return operation.query.definitions.some(
                (d) =>
                    d.kind === Kind.OPERATION_DEFINITION &&
                    (d as OperationDefinitionNode).operation === 'subscription'
            );
        };

        const wsLink = new GraphQLWsLink(
            createClient({
                url: `${configuration.apiUrl.replace('http', 'ws')}graphql`,
                connectionParams: async () => {
                    const token = await authService.getTokenAsync();
                    return {
                        authToken: token,
                    };
                },
            })
        );

        return new ApolloClient({
            connectToDevTools: process.env.NODE_ENV === 'development',
            link: ApolloLink.from([
                retryLink,
                onErrorLink,
                cleanTypenameLink.split(
                    (operation) => isOperationSubscription(operation),
                    wsLink,
                    cleanTypenameLink.split(
                        (operation) => isOperationMutation(operation),
                        new BatchHttpLink(httpLinkOptions),
                        new HttpLink(httpLinkOptions)
                    )
                ),
            ]),
            cache,
        });
    }

    async getCurrentUserAsync(): Promise<UserQl | null> {
        if (!this.apolloClient) {
            throw new Error('The apollo client is not initialised.');
        }
        return this.apolloClient
            .query<GetCurrentUserQuery>({
                query: GetCurrentUserDocument,
            })
            .then((result) => {
                return result.data?.current_user as UserQl;
            });
    }
}

export const graphService = new GraphService();
