import React, { useEffect, useMemo, useState } from 'react';

import { ExtractQueryArrayType } from '../../../data/extendedTypes';
import {
    ConstrainMode,
    DetailsListLayoutMode,
    IColumn,
    Link,
    mergeStyleSets,
    PrimaryButton,
    SelectionMode,
    ShimmeredDetailsList,
    Text,
} from '@fluentui/react';
import { AdvanceCard } from '../../../components/AdvanceCard';
import {
    GetTasksForStatusReportQuery,
    GetMeasuresForStatusReportQuery,
    GetMeasurePeriodDataQuery,
    ReportPeriodTypes,
} from '../../../data/types';
import { DetailsListCellItemContainer } from '../../../components/shared/DetailsListCellItemContainer';
import { TemplateReportElementType } from '../TemplateReport';
import { TaskPickerPanel } from './TaskPickerPanel';
import { sorters } from '../../../data/sorters';
import { useThemes } from '../../../hooks/useThemes';
import { Chip } from '../../../components/Chip';
import dayjs from 'dayjs';
import { useFormatters } from '../../../hooks/useFormatters';
import { DragHandlerButtonMemo } from '../../../components/DragHandlerButton';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { SortableContainer } from '../../../components/SortableContainer';
import { Guid } from 'guid-typescript';
import { useTemplateReportTasks } from '../hooks/useTemplateReportTasks';

type AvailableColumnsNames =
    | 'ImpliedTask_Name'
    | 'ImpliedTask_Start'
    | 'ImpliedTask_Due'
    | 'ImpliedTask_Done'
    | 'ImpliedTask_PercentComplete'
    | 'LinkedMeasure_Name'
    | 'LinkedMeasure_Status'
    | 'LinkedMeasure_Target'
    | 'LinkedMeasure_Actual'
    | 'LinkedMeasure_NextTarget';

type PeriodData = ExtractQueryArrayType<
    GetMeasurePeriodDataQuery,
    ['periodData', 'measurePeriodData']
>;

export function TemplateReportTaskTableElement(props: {
    missionId: string;
    utcDataDate: string | null;
    utcCompletedDate: string | null;
    element: TemplateReportElementType;
    onElementChanged: (
        updated: TemplateReportElementType,
        forceImmediateSave: boolean
    ) => void;
    isReadOnly: boolean;
    onTaskNavigate: ((taskId: string) => void) | null;
    reportPeriodType: ReportPeriodTypes | null;
    reportPeriod: number | null;
}): JSX.Element {
    const [customFacts, setCustomFacts] = useState<
        {
            id: string;
            text: string;
        }[]
    >([]);

    const {
        selectedTaskIds,
        setSelectedTaskIds,
        isLoading,
        specifiedTasks,
        impliedTasks,
        measures,
        periodData,
    } = useTemplateReportTasks(props);

    const DefaultColumns: AvailableColumnsNames[] = useMemo(
        () => [
            'ImpliedTask_Name',
            'ImpliedTask_Due',
            'ImpliedTask_PercentComplete',
            'LinkedMeasure_Name',
            'LinkedMeasure_Status',
        ],
        []
    );

    const [isPicking, setIsPicking] = useState(false);
    const togglePickTasks = () => setIsPicking(!isPicking);

    const [activeDragItemId, setActiveDragItemId] = useState<string | null>();

    const handleDragging = (id: string | null) => setActiveDragItemId(id);

    const handleTaskDropped = (id: string | null, newIndex: number) => {
        if (!id) {
            return;
        }

        const movedItem = selectedTaskIds.find((m) => m === id);
        const remainingItems = selectedTaskIds.filter((r) => r !== id);

        const reorderedItems = movedItem
            ? [
                  ...remainingItems.slice(0, newIndex),
                  movedItem,
                  ...remainingItems.slice(newIndex),
              ]
            : remainingItems;

        const updatedLinks: {
            id: string | null;
            sequence: number;
            taskId: string;
        }[] = [];

        let sequence = 0;
        reorderedItems.forEach((taskId) => {
            updatedLinks.push({
                id:
                    props.element.taskLinks.find((tl) => tl.taskId === taskId)
                        ?.id || null,
                taskId: taskId,
                sequence: sequence++,
            });
        });

        setSelectedTaskIds(reorderedItems);

        props.onElementChanged(
            {
                ...props.element,
                taskLinks: updatedLinks,
            },
            true
        );
    };

    const handleCustomFactDropped = (id: string | null, newIndex: number) => {
        if (!id) {
            return;
        }

        const movedItem = customFacts.find((cf) => cf.id === id);
        const remainingItems = customFacts.filter((cf) => cf.id !== id);

        const reorderedItems = movedItem
            ? [
                  ...remainingItems.slice(0, newIndex),
                  movedItem,
                  ...remainingItems.slice(newIndex),
              ]
            : remainingItems;

        setCustomFacts(reorderedItems);

        props.onElementChanged(
            {
                ...props.element,
                textContent: reorderedItems.map((r) => r.text).join('|'),
            },
            true
        );
    };

    const handleTaskPickerUpdateClick = (
        selectedTaskIds: string[],
        customFacts: string[]
    ) => {
        props.onElementChanged(
            {
                ...props.element,
                textContent: customFacts.join('|'),
                taskLinks: [
                    ...props.element.taskLinks
                        .filter((tl) => selectedTaskIds.indexOf(tl.taskId) > -1)
                        .map((tl) => ({
                            ...tl,
                            sequence: selectedTaskIds.indexOf(tl.taskId),
                        })),
                    ...selectedTaskIds
                        .filter(
                            (id) =>
                                !props.element.taskLinks.some(
                                    (tl) => tl.taskId === id
                                )
                        )
                        .map((id) => ({
                            id: null,
                            taskId: id,
                            sequence: selectedTaskIds.indexOf(id),
                        })),
                ],
            },
            true
        );

        setIsPicking(false);
    };

    useEffect(() => {
        setSelectedTaskIds(
            props.element.taskLinks
                .slice()
                .sort(sorters.sequenceSorter)
                .map((sm) => sm.taskId)
        );
    }, [props.element.taskLinks, setSelectedTaskIds]);

    useEffect(() => {
        setCustomFacts(
            props.element.textContent
                ? props.element.textContent?.split('|').map((cf) => ({
                      id: Guid.create().toString(),
                      text: cf,
                  }))
                : []
        );
    }, [props.element.textContent]);

    const elementColumns = props.element.columns;

    const columnNames: AvailableColumnsNames[] = elementColumns.length
        ? elementColumns
              .slice()
              .sort(sorters.sequenceSorter)
              .map((c) => (c.columnName as AvailableColumnsNames) || '')
        : DefaultColumns;

    return (
        <div
            style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: 8,
            }}
        >
            {!props.isReadOnly && (
                <div
                    className="no-print"
                    style={{
                        alignSelf: 'end',
                    }}
                >
                    <PrimaryButton
                        text="Pick tasks..."
                        onClick={togglePickTasks}
                        disabled={isLoading}
                    />
                </div>
            )}

            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 16,
                }}
            >
                {!isLoading && (
                    <SortableContainer
                        ids={selectedTaskIds || []}
                        onDragging={handleDragging}
                        onDropped={handleTaskDropped}
                    >
                        {specifiedTasks.map((st) => {
                            const linkedMeasures =
                                measures
                                    ?.slice()
                                    .sort(sorters.sequenceSorter)
                                    .filter(
                                        (m) =>
                                            st.linkedMeasures
                                                .map((lm) => lm.measureId)
                                                .indexOf(m.id || '') > -1
                                    ) || [];

                            return st ? (
                                <div key={st?.id}>
                                    <TemplateReportTaskTableElementTask
                                        specifiedTask={st}
                                        impliedTasks={
                                            impliedTasks.filter(
                                                (it) =>
                                                    it?.parentTaskId === st.id
                                            ) || []
                                        }
                                        linkedMeasures={linkedMeasures}
                                        onTaskNavigate={props.onTaskNavigate}
                                        hideImplieds={!impliedTasks.length}
                                        isLoading={isLoading}
                                        isReadOnly={props.isReadOnly}
                                        isActive={activeDragItemId === st.id}
                                        columnNames={columnNames}
                                        periodData={periodData}
                                    />
                                </div>
                            ) : null;
                        })}
                    </SortableContainer>
                )}

                {!isLoading && (
                    <SortableContainer
                        ids={customFacts.map((cf) => cf.id)}
                        onDragging={handleDragging}
                        onDropped={handleCustomFactDropped}
                    >
                        {customFacts.map((cf) => (
                            <TemplateReportTaskTableElementCustomFact
                                key={cf.id}
                                customFact={cf}
                                isReadOnly={props.isReadOnly}
                                isActive={activeDragItemId === cf.id}
                            />
                        ))}
                    </SortableContainer>
                )}
            </div>

            <TaskPickerPanel
                isOpen={isPicking}
                onDismiss={togglePickTasks}
                onUpdateClick={handleTaskPickerUpdateClick}
                missionId={props.missionId}
                selectedTaskIds={selectedTaskIds}
                customFacts={customFacts.map((cf) => cf.text)}
            />
        </div>
    );
}

function TemplateReportTaskTableElementCustomFact(props: {
    customFact: {
        id: string;
        text: string;
    };
    isReadOnly: boolean;
    isActive: boolean;
}) {
    const { attributes, listeners, setNodeRef, transform, transition } =
        useSortable({ id: props.customFact.id });

    return (
        <div
            ref={setNodeRef}
            style={{
                transformOrigin: '0 0',
                opacity: props.isActive ? 0.4 : 1,
                transform: CSS.Translate.toString(transform),
                transition: transition || undefined,
            }}
        >
            <AdvanceCard
                style={{
                    width: '100%',
                    alignItems: 'start',
                    padding: 8,
                }}
            >
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'start',
                    }}
                >
                    <Text
                        variant="mediumPlus"
                        block
                        style={{ whiteSpace: 'pre-line' }}
                    >
                        {props.customFact.text}
                    </Text>

                    <DragHandlerButtonMemo
                        hidden={props.isReadOnly}
                        handleListeners={listeners}
                        handleAttributes={attributes}
                        iconName="GripperDotsVertical"
                    />
                </div>
            </AdvanceCard>
        </div>
    );
}

function TemplateReportTaskTableElementTask(props: {
    specifiedTask: ExtractQueryArrayType<
        GetTasksForStatusReportQuery,
        ['tasks']
    >;
    impliedTasks: GetTasksForStatusReportQuery['tasks'];
    linkedMeasures: GetMeasuresForStatusReportQuery['measures'];
    onTaskNavigate: ((taskId: string) => void) | null;
    hideImplieds: boolean;
    isLoading: boolean;
    isReadOnly: boolean;
    isActive: boolean;
    columnNames: AvailableColumnsNames[];
    periodData: PeriodData[];
}) {
    const { specifiedTask, onTaskNavigate, isReadOnly } = props;

    const { currentTheme } = useThemes();

    const { attributes, listeners, setNodeRef, transform, transition } =
        useSortable({ id: specifiedTask.id || '' });

    const classNames = mergeStyleSets({
        tables: {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            gap: 8,
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            '@media print': {
                flexDirection: 'column',
            },
        },
        tableContainer: {
            flexGrow: 1,
            flexBasis: 0,
            minWidth: 300, // prevent overflow
            maxWidth: 632, // The print view doesn't have time to redraw, so fix the size here to prevent overflow.
            '@media print': {
                width: '100%',
            },
        },
    });

    return (
        <div
            ref={setNodeRef}
            style={{
                breakInside: 'avoid',
                transformOrigin: '0 0',
                opacity: props.isActive ? 0.4 : 1,
                transform: CSS.Translate.toString(transform),
                transition: transition || undefined,
            }}
        >
            <AdvanceCard
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 8,
                    alignItems: 'start',
                    padding: 8,
                }}
            >
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 8,
                            alignItems: 'start',
                        }}
                    >
                        {!!specifiedTask.taskCategory?.name && (
                            <Chip
                                text={specifiedTask.taskCategory?.name}
                                iconName={
                                    specifiedTask.taskCategory
                                        ? 'TagSolid'
                                        : 'Tag'
                                }
                                backgroundColor={
                                    specifiedTask.taskCategory?.colourHex
                                        ? '#' +
                                          specifiedTask.taskCategory?.colourHex
                                        : currentTheme.palette.neutralDark
                                }
                            />
                        )}
                        <Text variant="mediumPlus" block>
                            <Link
                                onClick={() =>
                                    onTaskNavigate && specifiedTask.id
                                        ? onTaskNavigate(specifiedTask.id)
                                        : undefined
                                }
                                style={{ whiteSpace: 'pre-wrap' }}
                            >
                                {specifiedTask.name}
                            </Link>
                        </Text>
                    </div>

                    <DragHandlerButtonMemo
                        hidden={isReadOnly}
                        handleListeners={listeners}
                        handleAttributes={attributes}
                        iconName="GripperDotsVertical"
                    />
                </div>
                <div className={classNames.tables}>
                    {!props.hideImplieds && (
                        <div className={classNames.tableContainer}>
                            <TemplateReportTaskTableElementTaskLinkedImpliedTasks
                                {...props}
                            />
                        </div>
                    )}
                    <div className={classNames.tableContainer}>
                        <TemplateReportTaskTableElementTaskLinkedMeasures
                            {...props}
                        />
                    </div>
                </div>
            </AdvanceCard>
        </div>
    );
}

type ReportMeasure = ExtractQueryArrayType<
    GetMeasuresForStatusReportQuery,
    ['measures']
>;

function TemplateReportTaskTableElementTaskLinkedMeasures(props: {
    linkedMeasures: GetMeasuresForStatusReportQuery['measures'];
    isLoading: boolean;
    columnNames: AvailableColumnsNames[];
    periodData: PeriodData[];
}): JSX.Element {
    const { columnNames, periodData } = props;

    const columns = useMemo(() => {
        const cols: IColumn[] = [];

        columnNames.forEach((columnName) => {
            switch (columnName) {
                case 'LinkedMeasure_Name':
                    cols.push({
                        key: 'name',
                        fieldName: 'name',
                        name: 'Linked Measures of Success',
                        minWidth: 0,
                        isRowHeader: true,
                        onRender: (measure: ReportMeasure) => {
                            return (
                                <DetailsListCellItemContainer>
                                    {measure.name}
                                </DetailsListCellItemContainer>
                            );
                        },
                    });
                    break;
                case 'LinkedMeasure_Target':
                    cols.push({
                        key: 'target',
                        name: 'Target',
                        minWidth: 100,
                        onRender: (measure: ReportMeasure) => (
                            <DetailsListCellItemContainer>
                                {
                                    periodData.find(
                                        (p) =>
                                            p.measureId === measure.id ||
                                            (measure.isLinked &&
                                                measure.linkedFromMeasureId ===
                                                    measure.id)
                                    )?.targetFormatted
                                }
                            </DetailsListCellItemContainer>
                        ),
                    });
                    break;
                case 'LinkedMeasure_Actual':
                    cols.push({
                        key: 'actual',
                        name: 'Actual',
                        minWidth: 100,
                        onRender: (measure: ReportMeasure) => (
                            <DetailsListCellItemContainer>
                                {
                                    periodData.find(
                                        (p) =>
                                            p.measureId === measure.id ||
                                            (measure.isLinked &&
                                                measure.linkedFromMeasureId ===
                                                    measure.id)
                                    )?.actualFormatted
                                }
                            </DetailsListCellItemContainer>
                        ),
                    });
                    break;
                case 'LinkedMeasure_NextTarget':
                    cols.push({
                        key: 'next target',
                        name: 'Next Target',
                        minWidth: 100,
                        onRender: (measure: ReportMeasure) => {
                            const measurePeriodData = periodData.find(
                                (p) =>
                                    p.measureId === measure.id ||
                                    measure.linkedFromMeasureId ===
                                        (measure.isLinked && measure.id)
                            );

                            const display =
                                measurePeriodData?.nextTargetFormatted &&
                                measurePeriodData?.nextPeriod
                                    ? `${measurePeriodData.nextTargetFormatted} (${measurePeriodData.nextPeriod})`
                                    : '-';

                            return (
                                <DetailsListCellItemContainer>
                                    {display}
                                </DetailsListCellItemContainer>
                            );
                        },
                    });
                    break;
                case 'LinkedMeasure_Status':
                    cols.push({
                        key: 'status',
                        name: 'Status',
                        minWidth: 100,
                        onRender: (measure: ReportMeasure) => {
                            const statusValue =
                                periodData.find(
                                    (p) =>
                                        p.measureId === measure.id ||
                                        (measure.isLinked &&
                                            measure.linkedFromMeasureId ===
                                                measure.id)
                                )?.statusValue || 0;

                            return (
                                <MeasureStatusField
                                    statusValue={statusValue}
                                    isStatusLimited={measure.isStatusLimited}
                                />
                            );
                        },
                    });
                    break;
            }
        });

        return cols;
    }, [columnNames, periodData]);

    return (
        <ShimmeredDetailsList
            enableShimmer={props.isLoading}
            shimmerLines={4}
            items={props.linkedMeasures}
            columns={columns}
            layoutMode={DetailsListLayoutMode.justified}
            constrainMode={ConstrainMode.unconstrained}
            selectionMode={SelectionMode.none}
            onShouldVirtualize={(): boolean => false}
            compact
        />
    );
}

type ReportImpliedTask = ExtractQueryArrayType<
    GetTasksForStatusReportQuery,
    ['tasks']
>;

function TemplateReportTaskTableElementTaskLinkedImpliedTasks(props: {
    impliedTasks: GetTasksForStatusReportQuery['tasks'];
    onTaskNavigate: ((taskId: string) => void) | null;
    isLoading: boolean;
    columnNames: AvailableColumnsNames[];
}): JSX.Element {
    const { columnNames, onTaskNavigate } = props;

    const columns = useMemo(() => {
        const cols: IColumn[] = [];

        columnNames.forEach((columnName) => {
            switch (columnName) {
                case 'ImpliedTask_Name':
                    cols.push({
                        key: 'name',
                        fieldName: 'name',
                        name: 'Implied Tasks',
                        minWidth: 0,
                        isRowHeader: true,
                        onRender: (impliedTask: ReportImpliedTask) => {
                            return (
                                <DetailsListCellItemContainer>
                                    <Link
                                        onClick={() =>
                                            onTaskNavigate && impliedTask.id
                                                ? onTaskNavigate(impliedTask.id)
                                                : undefined
                                        }
                                        style={{ whiteSpace: 'pre-wrap' }}
                                    >
                                        {impliedTask.name}
                                    </Link>
                                </DetailsListCellItemContainer>
                            );
                        },
                    });
                    break;
                case 'ImpliedTask_Start':
                    cols.push({
                        key: 'start',
                        fieldName: 'start',
                        name: 'Start',
                        minWidth: 100,
                        onRender: (impliedTask: ReportImpliedTask) => (
                            <DateField date={impliedTask.start} />
                        ),
                    });
                    break;
                case 'ImpliedTask_Due':
                    cols.push({
                        key: 'due',
                        fieldName: 'due',
                        name: 'Due',
                        minWidth: 100,
                        onRender: (impliedTask: ReportImpliedTask) => (
                            <DateField date={impliedTask.due} />
                        ),
                    });
                    break;
                case 'ImpliedTask_Done':
                    cols.push({
                        key: 'done',
                        fieldName: 'done',
                        name: 'Done',
                        minWidth: 100,
                        onRender: (impliedTask: ReportImpliedTask) => (
                            <DateField date={impliedTask.done} />
                        ),
                    });
                    break;
                case 'ImpliedTask_PercentComplete':
                    cols.push({
                        key: 'percentComplete',
                        fieldName: 'percentComplete',
                        name: '% Complete',
                        minWidth: 100,
                        onRender: (impliedTask: ReportImpliedTask) => (
                            <PercentCompleteField
                                percentComplete={impliedTask.percentComplete}
                            />
                        ),
                    });
                    break;
            }
        });
        return cols;
    }, [columnNames, onTaskNavigate]);

    return (
        <ShimmeredDetailsList
            enableShimmer={props.isLoading}
            shimmerLines={4}
            items={props.impliedTasks}
            columns={columns}
            layoutMode={DetailsListLayoutMode.justified}
            constrainMode={ConstrainMode.unconstrained}
            selectionMode={SelectionMode.none}
            onShouldVirtualize={(): boolean => false}
            compact
        />
    );
}

function DateField(p: { date: string | null }) {
    return (
        <DetailsListCellItemContainer>
            {p.date ? dayjs.utc(p.date).format('DD MMM YYYY') : ' - '}
        </DetailsListCellItemContainer>
    );
}

function PercentCompleteField(p: { percentComplete: number }) {
    const formatters = useFormatters();
    return (
        <DetailsListCellItemContainer>
            {formatters.formatTaskPercentage(p.percentComplete)}
        </DetailsListCellItemContainer>
    );
}

function MeasureStatusField(p: {
    statusValue: number;
    isStatusLimited: boolean;
}) {
    const formatters = useFormatters();
    return (
        <DetailsListCellItemContainer>
            {formatters.formatMeasureStatus(p.statusValue, p.isStatusLimited)}
        </DetailsListCellItemContainer>
    );
}
