import * as React from 'react';
import { useCallback, useRef, useState } from 'react';
import {
    Text,
    PrimaryButton,
    Separator,
    DetailsList,
    SelectionMode,
    DetailsListLayoutMode,
    ConstrainMode,
    DefaultButton,
    IconButton,
    IDragDropEvents,
    mergeStyles,
    IButtonStyles,
} from '@fluentui/react';

import { useStateContext } from '../../../services/contextProvider';
import { sorters } from '../../../data/sorters';
import { useThemes } from '../../../hooks/useThemes';
import { multipleExport } from '../../../services/exporter';
import { ReportExportTypePicker } from './ReportExportTypePicker';

export function ReportBuilder(): JSX.Element {
    const { currentTenantId, currentFinancialYearCode, configuration } =
        useStateContext();

    const { currentTheme } = useThemes();

    type ExportBuilderReportItem = {
        reportType:
            | 'TemplateReport'
            | 'MissionAnalysis'
            | 'StatusReport'
            | undefined
            | null;
        templateReportId?: string | undefined | null;
        templateReportViewId?: string | undefined | null;
        missionId: string;
        missionName: string;
        reportName: string;
        sequence: number;
    };

    const [selectedReport, setSelectedReport] =
        useState<ExportBuilderReportItem | null>(null);

    const [selectedReports, setSelectedReports] = useState<
        ExportBuilderReportItem[]
    >([]);

    const handleReportChanged = useCallback(
        (
            report: {
                reportType:
                    | 'TemplateReport'
                    | 'MissionAnalysis'
                    | 'StatusReport'
                    | undefined
                    | null;
                templateReportId: string | null;
                templateReportViewId: string | null;
                statusReportId: string | null;
                missionId: string;
                missionName: string;
                reportName: string;
            } | null
        ) => {
            setSelectedReport(report ? { ...report, sequence: 999 } : null);
        },
        [setSelectedReport]
    );

    const handleAddReportClick = () => {
        const lastSequence = Math.max(
            -1,
            ...selectedReports.map((r) => r.sequence)
        );
        if (selectedReport) {
            setSelectedReports((curr) => [
                ...curr,
                { ...selectedReport, sequence: lastSequence + 1 },
            ]);
        }
    };

    const onExportAllClick = async () => {
        await multipleExport(
            configuration,
            currentTenantId,
            selectedReports.sort(sorters.sequenceSorter).map((r) => ({
                ...r,
                exportType: r.reportType || '',
            }))
        );
    };

    const buttonStyles: IButtonStyles = {
        root: {
            color: currentTheme.palette.themeLighter,
            selectors: {
                '&:hover': {
                    color: currentTheme.palette.themePrimary,
                },
            },
        },
        icon: {
            margin: 0,
        },
    };

    const renderActionButtons = (
        item: ExportBuilderReportItem
    ): JSX.Element => {
        const onDeleteClick = (): void => {
            setSelectedReports((sr) =>
                sr.filter((r) => r.sequence !== item.sequence)
            );
        };

        return (
            <div style={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                <IconButton
                    iconProps={{
                        iconName: 'Delete',
                    }}
                    title="Delete"
                    ariaLabel="Delete"
                    styles={{
                        ...buttonStyles,
                        root: {
                            color: currentTheme.semanticColors.errorIcon,
                        },
                    }}
                    onClick={onDeleteClick}
                />
                <IconButton
                    iconProps={{ iconName: 'GripperDotsVertical' }}
                    title="Move"
                    ariaLabel="Move"
                    styles={{
                        ...buttonStyles,
                        root: {
                            cursor: 'grab',
                        },
                    }}
                />
            </div>
        );
    };

    const move = (
        draggedItem: ExportBuilderReportItem,
        droppedOnItem: ExportBuilderReportItem
    ) => {
        setSelectedReports((sr) => {
            const draggedToIndex = sr.findIndex(
                (r) => droppedOnItem.sequence === r.sequence
            );

            const reordered = sr.filter(
                (cell) => cell.sequence !== draggedItem?.sequence
            );

            reordered.splice(draggedToIndex, 0, draggedItem);

            let sequence = 0;

            const modified = reordered.map((r) => {
                return {
                    ...r,
                    sequence: sequence++,
                };
            });

            return modified;
        });
    };

    const dragEnterClass = mergeStyles({
        backgroundColor: currentTheme.palette.neutralLight,
    });

    const draggedItem = useRef<ExportBuilderReportItem | undefined>(undefined);

    const getDragDropEvents = (): IDragDropEvents => {
        return {
            onDrop: (droppedOnItem?: ExportBuilderReportItem): void => {
                if (draggedItem.current && droppedOnItem) {
                    move(draggedItem.current, droppedOnItem);
                }
            },
            canDrop: () => {
                return true;
            },
            canDrag: () => true,
            onDragEnter: (): string => {
                return dragEnterClass;
            },
            onDragLeave: (): void => {
                return;
            },
            onDragEnd: (): void => {
                draggedItem.current = undefined;
            },
            onDragStart: (item?: ExportBuilderReportItem): void => {
                if (item) {
                    draggedItem.current = item;
                }
            },
        };
    };

    return (
        <div
            style={{
                display: 'flex',
                gap: 4,
                marginTop: 8,
                flexDirection: 'column',
            }}
        >
            {currentTenantId && currentFinancialYearCode && (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 8,
                    }}
                >
                    <div>
                        <ReportExportTypePicker
                            tenantId={currentTenantId}
                            financialYearCode={currentFinancialYearCode}
                            onReportChange={handleReportChanged}
                        />
                    </div>
                    <div>
                        <DefaultButton
                            text="Add Report"
                            onClick={handleAddReportClick}
                            disabled={!selectedReport}
                        />
                    </div>
                </div>
            )}

            <Separator />

            <div>
                {selectedReports.length > 0 ? (
                    <DetailsList
                        dragDropEvents={getDragDropEvents()}
                        columns={[
                            {
                                key: 'missionName',
                                name: 'Mission',
                                fieldName: 'missionName',
                                minWidth: 180,
                                maxWidth: 300,
                            },
                            {
                                key: 'reportName',
                                name: 'Report Name',
                                fieldName: 'reportName',
                                minWidth: 250,
                            },
                            {
                                key: 'actions',
                                name: '',
                                minWidth: 48,
                                isIconOnly: true,
                                isPadded: false,
                                className: 'iconCell',
                                onRender: renderActionButtons,
                            },
                        ]}
                        layoutMode={DetailsListLayoutMode.justified}
                        constrainMode={ConstrainMode.unconstrained}
                        selectionMode={SelectionMode.none}
                        onShouldVirtualize={(): boolean => false}
                        items={selectedReports.sort(sorters.sequenceSorter)}
                    />
                ) : (
                    <Text variant="small">No reports selected</Text>
                )}
            </div>

            <div>
                <PrimaryButton
                    text="Export all reports"
                    disabled={selectedReports.length === 0}
                    onClick={onExportAllClick}
                />
            </div>
        </div>
    );
}
