import { Label } from '@fluentui/react';
import styles from './ApiHelp.module.css';
import { useStateContext } from '../../../../services/contextProvider';

export function Rest(): JSX.Element {
    const { configuration } = useStateContext();
    const apiEndpoint = `${configuration.apiUrl}odata`;

    return (
        <div>
            <h1>Rest Interface</h1>
            <p>We provide an OData Rest Interface.</p>

            <p>
                <Label>Endpoint:</Label>
                <a href={apiEndpoint} target="_blank" rel="noreferrer">
                    {apiEndpoint}
                </a>
            </p>

            <p>
                <Label>HTTP Methods</Label>
                <code className={styles.method}>GET</code>
                <code className={styles.method}>PATCH</code>
            </p>

            <Label>OData Query Parameters</Label>
            <table className={styles.table}>
                <tbody>
                    <tr>
                        <td>$count</td>
                        <td>
                            Example:
                            <br />
                            <code className={styles.code}>$count=true</code>
                            <br />
                            Example Response:
                            <br />
                            <code className={styles.code}>
                                "@odata.count": 10
                            </code>
                            <br />
                            Add to query string to include a row count in the
                            response.
                        </td>
                    </tr>
                    <tr>
                        <td>$select</td>
                        <td>
                            Example:
                            <br />
                            <code className={styles.code}>
                                $select=Id,Title,Owner
                            </code>
                            <br />
                            Use the select keyword to only return specific
                            fields.
                        </td>
                    </tr>
                    <tr>
                        <td>$orderBy</td>
                        <td>
                            Example:
                            <br />
                            <code className={styles.code}>
                                $orderBy=MissionDate desc
                            </code>
                            <br />
                            Use the orderBy keyword to sort the rows.
                        </td>
                    </tr>
                    <tr>
                        <td>$filter</td>
                        <td>
                            Example:
                            <br />
                            <code className={styles.code}>
                                $filter=MissionDate ge 2024-01-01
                            </code>
                            <br />
                            Use the filter keyword to apply a 'where' clause and
                            filter the response.
                        </td>
                    </tr>
                    <tr>
                        <td>$expand</td>
                        <td>
                            Example (on Mission):
                            <br />
                            <code className={styles.code}>
                                $expand=Freedoms
                            </code>
                            <br />
                            If a field is complex, it can be expanded to display
                            sub-properties on the field.
                            <br />
                            (For example, on Mission the Freedoms field can be
                            expanded. On FinancialYear, you can expand
                            Divisions).
                        </td>
                    </tr>
                    <tr>
                        <td>$top</td>
                        <td>
                            Example:
                            <br />
                            <code className={styles.code}>$top=1</code>
                            <br />
                            Add to query string to only return the first n
                            records
                        </td>
                    </tr>
                    <tr>
                        <td>$skip</td>
                        <td>
                            Example:
                            <br />
                            <code className={styles.code}>$skip=10</code>
                            <br />
                            Add to query string to skip the first n records. Use
                            this together with $top to do paging.
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}
