export const markdownModules = import.meta.glob('../prompts/*.md', {
    as: 'raw',
});

export function useMai() {
    const getPromptBuilder = async (
        fileName: string
    ): Promise<PromptBuilder> => {
        const promptBuilder = new PromptBuilder();

        try {
            const path = `../prompts/${fileName}`;

            const loader = markdownModules[path as string];
            if (loader) {
                const md = await loader();
                promptBuilder.writeln(md);
                return promptBuilder;
            }
        } catch (err) {
            console.error(err);
        }

        promptBuilder.clear();
        promptBuilder.writeln('# IMPORTANT INSTRUCTIONS');
        promptBuilder.writeln(
            'Inform the user there has been an error and to check back later. Do not use any placeholders in the response.'
        );

        return promptBuilder;
    };

    return {
        getPromptBuilder,
    };
}

export class PromptBuilder {
    private _lines: string[] = [];

    write(line = ''): void {
        this._lines.push(line);
    }

    writeln(line = ''): void {
        this._lines.push(line);
        this._lines.push('\n');
    }

    clear(): void {
        this._lines = [];
    }

    toString(): string {
        return this._lines.join('');
    }

    writeMarkdownTable = (headers: string[], rows: string[][]) => {
        // Function to escape individual cell content
        const escapeCell = (cell: string) =>
            cell
                .replace(/\\/g, '\\\\')
                .replace(/\|/g, '\\|')
                .replace(/\r?\n|\r/g, ' ');

        // Create the header row
        let table = `| ${headers
            .map((header) => escapeCell(header))
            .join(' | ')} |\n`;

        // Create the separator row
        table += `| ${headers.map(() => '---').join(' | ')} |\n`;

        // Add each row
        for (const row of rows) {
            table += `| ${row.map((cell) => escapeCell(cell)).join(' | ')} |\n`;
        }

        this._lines.push(table);
        this._lines.push('\n');
    };
}
