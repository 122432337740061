import { mergeStyleSets, Text, DayOfWeek } from '@fluentui/react';
import dayjs from 'dayjs';
import { InlineDatePicker } from '../../../components/inputs/InlineDatePicker';
import { DatePickerStrings } from '../../../services/i18n';

export function ActionDatePicker(props: {
    onDateChanged?: (date: string | null) => void;
    dateValue: string | null;
    isReadOnly: boolean;
    isLoading: boolean;
    placeholder?: string;
}): JSX.Element {
    const { onDateChanged, dateValue } = props;

    const formatDate = (date?: Date | string | null): string => {
        return dayjs
            .utc(date || undefined)
            .tz(dayjs.tz.guess())
            .format('DD MMM YYYY');
    };

    const classNames = mergeStyleSets({
        container: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
            gap: 8,
            whiteSpace: 'nowrap',
        },
    });

    if (props.isReadOnly) {
        return (
            <Text>
                {dateValue
                    ? formatDate(dateValue)
                    : props.placeholder || 'Enter Date'}
            </Text>
        );
    }

    return (
        <div className={classNames.container}>
            <InlineDatePicker
                formatDate={formatDate}
                strings={DatePickerStrings}
                ariaLabel="Select a date"
                firstDayOfWeek={DayOfWeek.Monday}
                value={dateValue ? dayjs(dateValue).toDate() : undefined}
                placeholder={props.placeholder || 'Enter Date'}
                styles={{
                    statusMessage: {
                        display: 'none', // This adds white space at the bottom
                    },
                }}
                onSelectDate={(date: Date | null | undefined): void => {
                    const dateString = date
                        ? dayjs(date).format('YYYY-MM-DD')
                        : null;
                    if (onDateChanged) {
                        onDateChanged(dateString);
                    }
                }}
            />
        </div>
    );
}
