import * as React from 'react';
import { NavLink, generatePath, matchPath, useMatch } from 'react-router';
import {
    Nav,
    Icon,
    IButtonProps,
    INavLinkGroup,
    Text,
    Stack,
    INavStyles,
    useTheme,
    INavLink,
    INavButtonProps,
} from '@fluentui/react';
import { PathParams, paths } from '../../services/navigation';
import {
    useGetIsImporterQuery,
    useGetReportsSummaryQuery,
} from '../../data/types';
import { Chip } from '../Chip';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

function MenuNav(props: {
    tenantId: string;
    tenantCode: string;
    financialYearCode?: string;
    teamCode?: string;
    missionId?: string | null;
    contributorId?: string | null;
    roles: string[];
    hideText: boolean;
}): JSX.Element {
    const match = useMatch('*');

    const currentTheme = useTheme();

    const { t } = useTranslation();

    const pathParams: PathParams = {
        tenantCode: props.tenantCode?.toLowerCase(),
        financialYearCode: props.financialYearCode?.toLowerCase(),
        teamCode: props.teamCode?.toLowerCase(),
        missionId: props.missionId || undefined,
        contributorId: props.contributorId || undefined,
    };

    const { data: { isImporter } = {} } = useGetIsImporterQuery({
        variables: {
            tenantId: props.tenantId,
        },
    });

    const { data: { statusReports, reports } = {} } = useGetReportsSummaryQuery(
        {
            skip: !props.tenantId || !props.missionId,
            variables: {
                tenantId: props.tenantId,
                missionId: props.missionId || '',
            },
        }
    );

    const statusReportCount =
        (statusReports?.length || 0) + (reports?.templateReports?.length || 0);

    const statusReportDraftCount =
        (statusReports?.filter((r) => r.utcCompletedDate === null)?.length ||
            0) +
        (reports?.templateReports?.filter((r) => r.utcCompletedDate === null)
            ?.length || 0);

    const links = {
        team: {
            key: 'team',
            path:
                pathParams.financialYearCode && pathParams.teamCode
                    ? generatePath(paths.team, pathParams)
                    : '',
        },
        statusReports: {
            key: 'status-reports',
            path:
                pathParams.financialYearCode && pathParams.missionId
                    ? generatePath(paths.statusReports, pathParams)
                    : '',
        },
        setupHome: {
            key: 'setup',
            path:
                pathParams.financialYearCode && pathParams.tenantCode
                    ? generatePath(paths.setupHome, pathParams)
                    : '',
        },
        reports: {
            key: 'reporting',
            path:
                pathParams.financialYearCode && pathParams.tenantCode
                    ? generatePath(paths.reports, pathParams)
                    : '',
        },
        import: {
            key: 'imports',
            path:
                pathParams.financialYearCode && pathParams.tenantCode
                    ? generatePath(paths.importer, pathParams)
                    : '',
        },
        missionBuilder: {
            key: 'mission-builder',
            path:
                pathParams.financialYearCode && pathParams.missionId
                    ? generatePath(paths.missionBuilder, pathParams)
                    : '',
        },
        contributor: {
            key: 'contributor',
            path:
                pathParams.financialYearCode &&
                pathParams.tenantCode &&
                pathParams.contributorId
                    ? pathParams.teamCode
                        ? generatePath(paths.contributorWithTeam, pathParams)
                        : generatePath(paths.contributor, pathParams)
                    : '',
        },
    };

    type MenuLinkType = INavLink & {
        chip?: { text: string; iconName: string; backgroundColor: string };
    };
    const menuLinks: MenuLinkType[] = [];

    const isOnContributorPage =
        !!matchPath(
            pathParams.teamCode ? paths.contributorWithTeam : paths.contributor,
            match?.pathname || ''
        ) ||
        !!matchPath(
            pathParams.teamCode
                ? paths.contributorWithTeamTask
                : paths.contributorTask,
            match?.pathname || ''
        );

    if (props.contributorId && isOnContributorPage) {
        menuLinks.push({
            key: links.contributor.key,
            name: !props.hideText ? 'My Tasks' : '',
            url: links.contributor.path,
            icon: 'CustomList',
        });
    }

    if (links.missionBuilder.path) {
        menuLinks.push({
            key: links.missionBuilder.key,
            name: !props.hideText ? 'Mission' : '',
            url: links.missionBuilder.path,
            icon: 'Trending12',
            iconOnly: true,
        });
    }

    if (links.team.path) {
        menuLinks.push({
            key: links.team.key,
            name: !props.hideText ? 'Team' : '',
            url: links.team.path,
            icon: 'Group',
        });
    }

    if (links.statusReports.path) {
        menuLinks.push({
            key: links.statusReports.key,
            name: !props.hideText
                ? t('status-report.status-report', {
                      count: statusReportCount,
                  })
                : '',
            url: links.statusReports.path,
            icon: 'CRMReport',
            chip: statusReportDraftCount
                ? {
                      text: `${statusReportDraftCount} ${t(
                          'status-report.draft',
                          {
                              count: statusReportDraftCount,
                          }
                      )}`,
                      iconName: 'PageEdit',
                      backgroundColor:
                          currentTheme.semanticColors.severeWarningIcon,
                  }
                : undefined,
        });
    }

    if (isImporter) {
        menuLinks.push({
            key: links.import.key,
            name: !props.hideText ? 'Import' : '',
            url: links.import.path,
            icon: 'UnstackSelected',
        });
    }

    if (props.roles?.some((r) => ['GlobalAdmin', 'ClientAdmin'].includes(r))) {
        menuLinks.push({
            key: links.reports.key,
            name: !props.hideText ? 'Admin Reports' : '',
            url: links.reports.path,
            icon: 'ReportLibrary',
        });

        menuLinks.push({
            key: links.setupHome.key,
            name: !props.hideText ? 'Setup' : '',
            url: links.setupHome.path,
            icon: 'Settings',
        });
    }

    const navGroup: INavLinkGroup = {
        links: menuLinks,
    };

    const navGroups: INavLinkGroup[] = [navGroup];

    const onRenderLink = (buttonProps: IButtonProps): JSX.Element => {
        const menuLink = (buttonProps as INavButtonProps).link as MenuLinkType;

        return (
            <NavLink
                className={buttonProps.className}
                style={{
                    color: 'inherit',
                    boxSizing: 'border-box',
                    height: props.hideText ? 46 : menuLink.chip ? 90 : 60,
                    lineHeight: 1.2,
                }}
                to={buttonProps.href || ''}
            >
                <Stack horizontalAlign="center" style={{ marginTop: 11 }}>
                    {!!buttonProps.iconProps && (
                        <Icon
                            style={{ fontSize: 20 }}
                            {...buttonProps.iconProps}
                        />
                    )}

                    <Text block variant="small">
                        {buttonProps.title}
                    </Text>

                    {!props.hideText && !!menuLink.chip && (
                        <div style={{ paddingTop: 6 }}>
                            <Chip {...menuLink.chip} />
                        </div>
                    )}
                </Stack>
            </NavLink>
        );
    };

    const linkStyles: Partial<INavStyles> = {
        link: {
            paddingLeft: 0,
            paddingRight: 0,
            borderRadius: 8,
            '&:after': {
                borderLeft: '0 none !important',
            },
        },
    };

    const linkArray = Object.values(links);

    const selectedKey = useMemo(() => {
        return (
            linkArray.find((l) => match?.pathname === l.path)?.key ||
            linkArray
                // Try to match the current path to the longer paths first to find the closest fit.
                .sort((a, b) => b.path.length - a.path.length)
                .find((l) => match?.pathname.startsWith(l.path))?.key
        );
    }, [linkArray, match?.pathname]);

    return (
        <Nav
            styles={linkStyles}
            selectedKey={selectedKey}
            groups={navGroups}
            linkAs={onRenderLink}
        />
    );
}

export default MenuNav;
