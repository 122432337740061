import * as React from 'react';

import {
    useGetResourcesQuery,
    ResourceQl,
    GetResourcesQuery,
} from '../../../data/types';
import { useStateContext } from '../../../services/contextProvider';
import {
    ShimmeredDetailsList,
    IconButton,
    SelectionMode,
    DetailsListLayoutMode,
    IColumn,
    Toggle,
    Icon,
    Persona,
    PersonaSize,
} from '@fluentui/react';

import { useSorter } from '../../../hooks/useSorter';
import { FilterBar } from '../../../components/FilterBar';
import { useEffect, useState } from 'react';
import Fuse from 'fuse.js';
import { photoService } from '../../../services/photo.service';
import { DetailsListCellItemContainer } from '../../../components/shared/DetailsListCellItemContainer';

type ResourceListProps = {
    onItemEdit: (resource: ResourceQl) => void;
    fyCodeFilter: string | null;
};

export function ResourceList(props: ResourceListProps): JSX.Element {
    const { currentTenantId } = useStateContext();

    const [showUserResources, setShowUserResources] = useState<boolean>();

    const [searchText, setSearchText] = useState('');

    const { data, loading } = useGetResourcesQuery({
        skip: !currentTenantId,
        variables: {
            tenantId: currentTenantId || '',
            financialYearCode: props.fyCodeFilter,
        },
    });

    const [filteredResources, setFilteredResources] = useState<
        GetResourcesQuery['resources']
    >([]);

    useEffect(() => {
        let filtered = data?.resources || [];

        if (!showUserResources) {
            filtered = filtered.filter((r) => !r.userId);
        }

        if (searchText) {
            const fuse = new Fuse((filtered || []).slice(), {
                keys: ['displayName'],
                includeScore: false,
                threshold: 0.3,
            });
            filtered = fuse.search(searchText).map((r) => r.item);
        }

        setFilteredResources(filtered);
    }, [data?.resources, showUserResources, searchText]);

    const { sortColumn, sortIsDesc, sortedItems, setSortColumnName } =
        useSorter(filteredResources, 'displayName', false, true);

    const renderActions = (item: ResourceQl): JSX.Element => {
        return (
            <IconButton
                key={item.id}
                iconProps={{ iconName: 'Edit' }}
                onClick={(): void => {
                    props.onItemEdit(item);
                }}
            />
        );
    };

    const columns: IColumn[] = [
        {
            key: 'type',
            name: 'Type',
            fieldName: 'userId',
            minWidth: 32,
            maxWidth: 32,
            isRowHeader: true,
            isResizable: true,
            isIconOnly: true,
            onRender: function onRenderTypeIcon(item: ResourceQl): JSX.Element {
                return item.userId ? (
                    <Persona
                        text={item.displayName || item.id || 'Unknown'}
                        hidePersonaDetails
                        imageUrl={photoService.getImageUrl(item.userId)}
                        size={PersonaSize.size24}
                    />
                ) : (
                    <Icon
                        iconName="WorkforceManagement"
                        title="Resource"
                        styles={{
                            root: {
                                fontSize: 20,
                                width: 24,
                                height: 24,
                            },
                        }}
                    />
                );
            },
            isSorted: sortColumn === 'userId',
            isSortedDescending: sortColumn === 'userId' && sortIsDesc,
            onColumnClick: (): void => setSortColumnName('userId', true),
        },
        {
            key: 'displayName',
            name: 'Name',
            fieldName: 'displayName',
            minWidth: 200,
            isResizable: true,
            isRowHeader: true,
            isSorted: sortColumn === 'displayName',
            isSortedDescending: sortColumn === 'displayName' && sortIsDesc,
            onRender: function onRenderDisplayName(
                item: ResourceQl
            ): JSX.Element {
                return item.userId &&
                    item.name &&
                    item.displayName !== item.name ? (
                    <DetailsListCellItemContainer>
                        {item.displayName} ({item.name})
                    </DetailsListCellItemContainer>
                ) : (
                    <DetailsListCellItemContainer>
                        {item.displayName}
                    </DetailsListCellItemContainer>
                );
            },
            onColumnClick: (): void => setSortColumnName('displayName', true),
        },
        {
            key: 'taskCount',
            name: props.fyCodeFilter
                ? `${props.fyCodeFilter} Tasks`
                : 'Total Tasks',
            fieldName: 'taskCount',
            minWidth: 100,
            isResizable: true,
            isSorted: sortColumn === 'taskCount',
            isSortedDescending: sortColumn === 'taskCount' && sortIsDesc,
            onRender: function onRenderDisplayName(
                item: ResourceQl
            ): JSX.Element {
                return (
                    <DetailsListCellItemContainer>
                        {item.taskCount}
                    </DetailsListCellItemContainer>
                );
            },
            onColumnClick: (): void => setSortColumnName('taskCount'),
        },
        {
            key: 'actions',
            name: '',
            minWidth: 32,
            isIconOnly: true,
            isPadded: false,
            className: 'iconCell',
            onRender: renderActions,
        },
    ];

    const onShowUserResourcesToggleChange = (
        __ev?: React.MouseEvent<HTMLElement>,
        checked?: boolean
    ) => {
        setShowUserResources(checked);
    };

    const handleClearAndDismissClick = () => {
        // NO ACTION
    };

    const handleSearchTextChanged = (keyword: string | null) => {
        setSearchText(keyword || '');
    };

    return (
        <div>
            <FilterBar
                onSearchTextChanged={handleSearchTextChanged}
                onClearAndDismissClick={handleClearAndDismissClick}
                searchPlaceholder="Filter by resource name"
            >
                <Toggle
                    label="Show user resources"
                    checked={showUserResources}
                    onText="On"
                    offText="Off"
                    inlineLabel
                    onChange={onShowUserResourcesToggleChange}
                    styles={{
                        root: {
                            marginBottom: 0,
                        },
                    }}
                />
            </FilterBar>
            <div>
                <ShimmeredDetailsList
                    setKey="items"
                    items={sortedItems || []}
                    columns={columns}
                    selectionMode={SelectionMode.none}
                    layoutMode={DetailsListLayoutMode.justified}
                    enableShimmer={loading}
                    ariaLabelForShimmer="Content is being fetched"
                    ariaLabelForGrid="Item details"
                    listProps={{
                        renderedWindowsAhead: 0,
                        renderedWindowsBehind: 0,
                    }}
                    onItemInvoked={(item?: ResourceQl): void => {
                        if (item) {
                            props.onItemEdit(item);
                        }
                    }}
                />
            </div>
        </div>
    );
}
