import React, { useState } from 'react';
import {
    Panel,
    PanelType,
    Text,
    Stack,
    MessageBarType,
    MessageBar,
    IContextualMenuProps,
    ActionButton,
    IPanelProps,
    IPanelHeaderRenderer,
    IRawStyle,
    mergeStyleSets,
    Link,
} from '@fluentui/react';
import { generatePath, useParams } from 'react-router';
import HistoryChart, { HistoryChartV1 } from './HistoryChart';
import MeasureArrow from './MeasureArrow';
import { useGetMeasureQuery, useGetMissionTasksQuery } from '../data/types';
import { useStateContext } from '../services/contextProvider';
import { useClipboard } from 'use-clipboard-copy';
import MeasureHistoryFeed from './MeasureHistoryFeed';
import { MeasureSeriesNames } from '../data/extendedTypes';
import dayjs from 'dayjs';
import { MeasureCopyDialog } from './MeasureCopyDialog';
import { sorters } from '../data/sorters';
import { TaskSummaryBar } from '../scenes/Team/components/TaskSummaryBar';
import ExpandCollapse from './ExpandCollapse';
import { MeasureCommandBar } from './EditMeasureCommandBar';
import { useActiveView } from '../hooks/useActiveView';
import compact from 'lodash/compact';
import { useThemes } from '../hooks/useThemes';
import { MeasureLinkFromDialog } from './MeasureLinkFromDialog';
import { LegacyMeasureBar } from './LegacyMeasureBar';
import { MeasureLinkInformation } from './MeasureLinkInformation';
import { useFeatures } from '../hooks/useFeatures';
import { paths } from '../services/navigation';
import { Chip } from './Chip';
import { useFormatters } from '../hooks/useFormatters';

export default function MeasurePanel(props: {
    showPanel: boolean;
    measureId: string | null | undefined;
    onDismiss: () => void;
    onAddValuesButtonClick: () => void;
    onEditMeasureButtonClick: () => void;
    onMeasureLockToggleClick?: () => void;
    onCommentsButtonClick?: () => void;
    hideDelete?: boolean;
}): JSX.Element {
    const { currentTheme } = useThemes();

    const params = useParams();

    const { currentTenantId, currentFinancialYearCode } = useStateContext();

    const features = useFeatures();

    const formatters = useFormatters();

    useActiveView(props.showPanel ? 'MeasureOverview' : null);

    const clipboard = useClipboard({
        copiedTimeout: 3000, // show copy message in milliseconds
    });

    const [isMeasureCopyModalOpen, setIsMeasureCopyModalOpen] =
        React.useState(false);

    const [isMeasureLinkFromModalOpen, setIsMeasureLinkFromModalOpen] =
        React.useState(false);

    const [chartOption, setChartOption] = React.useState<'bar' | 'line'>(
        'line'
    );

    const { data, loading } = useGetMeasureQuery({
        skip: !props.showPanel || !props.measureId || !currentTenantId,
        variables: {
            tenantId: currentTenantId || '',
            id: props.measureId || '',
        },
    });

    const { data: taskData } = useGetMissionTasksQuery({
        skip: !props.showPanel || !data?.measure?.missionId || !currentTenantId,
        variables: {
            tenantId: currentTenantId || '',
            missionId: data?.measure?.missionId || '',
        },
    });

    const isLocked = (data?.measure?.tags || []).reduce(
        (value, tag) => value || tag.name === 'Locked',
        false
    );

    const [isLinkedTasksExpanded, setIsLinkedTasksExpanded] = useState(true);
    const handleLinkedTasksExpand = () => setIsLinkedTasksExpanded(true);
    const handleLinkedTasksCollapse = () => setIsLinkedTasksExpanded(false);

    const [isHistoryExpanded, setIsHistoryExpanded] = useState(true);
    const handleHistoryExpand = () => setIsHistoryExpanded(true);
    const handleHistoryCollapse = () => setIsHistoryExpanded(false);

    const linkedTasks = taskData?.tasks
        ?.filter((t) => t.parentTaskId === null && !t.isDuplicate)
        .filter((t) =>
            t.linkedMeasures?.some((lm) => lm.measureId === props.measureId)
        )
        .sort(sorters.sequenceSorter);

    const measure = !loading ? data?.measure : null;

    const status = measure?.lastAsOf?.statusValue || 0;

    const targetFormatted = measure?.lastAsOf?.values?.find(
        (v) => v?.seriesType?.name === MeasureSeriesNames.Target
    )?.formatStr;

    const actualFormatted = measure?.lastAsOf?.values?.find(
        (v) => v?.seriesType?.name === MeasureSeriesNames.Actual
    )?.formatStr;

    const formattedStatus =
        targetFormatted && actualFormatted
            ? formatters.formatMeasureStatus(
                  measure?.lastAsOf?.statusValue,
                  measure.isStatusLimited
              )
            : null;

    const valueHistory = (measure?.valueHistory || [])
        .slice()
        .sort((m1, m2) =>
            dayjs(m1.asOfDate || '').isAfter(dayjs(m2.asOfDate || '')) ? 1 : -1
        );

    const handleLinkButtonClick = () => setIsMeasureCopyModalOpen(true);

    const handleCopyMeasureIdButtonClick = () => {
        clipboard.copy(measure?.id || '');
    };

    const handleChartOptionChanged = (newChartOption: 'bar' | 'line') => {
        setChartOption(newChartOption);
    };

    // Limit if this measure can be linked to another measure.
    const allowLinkFrom =
        measure && !measure.isLinked && measure.linkedMeasures.length === 0;

    const handleLinkFromMeasureClick = allowLinkFrom
        ? () => {
              setIsMeasureLinkFromModalOpen(true);
          }
        : undefined;

    const onRenderNavigationContent = (): JSX.Element => (
        <div style={{ width: '100%', marginTop: 6 }}>
            {!!measure && (
                <MeasureCommandBar
                    measureId={props.measureId}
                    activeButton="Overview"
                    missionAccess={measure?.mission?.rights}
                    isLinked={measure.isLinked}
                    isCustom={measure.isCustom}
                    onAddValuesButtonClick={props.onAddValuesButtonClick}
                    onEditMeasureButtonClick={props.onEditMeasureButtonClick}
                    onCloseButtonClick={props.onDismiss}
                    onSendLinkedButtonClick={handleLinkButtonClick}
                    onLinkFromMeasureClick={handleLinkFromMeasureClick}
                    onCopyMeasureIdButtonClick={handleCopyMeasureIdButtonClick}
                    onMeasureLockToggleClick={props.onMeasureLockToggleClick}
                    isLocked={isLocked}
                    hideDelete={props.hideDelete}
                />
            )}
        </div>
    );

    const hasValues = measure?.lastAsOf || valueHistory.length > 0;

    const formatter = new Intl.NumberFormat(undefined, {
        style: 'percent',
        maximumFractionDigits: 1,
    });

    const linkedToMissions = compact(
        measure?.linkedMeasures?.filter((m) => m.isLinked).map((m) => m.mission)
    ).filter(
        (value, index, self) =>
            self.findIndex((s) => value.id === s.id) === index
    );

    const handleRenderPanelHeader = (
        panelProps?: IPanelProps,
        defaultRender?: IPanelHeaderRenderer
    ): JSX.Element | null => {
        const href =
            measure && !measure.isCustom && params.teamCode
                ? generatePath(paths.missionBuilderMeasure, {
                      ...params,
                      measureId: measure.id,
                      missionId: measure.missionId,
                  })
                : undefined;

        return (
            <Stack tokens={{ childrenGap: 4 }}>
                <Stack.Item
                    styles={{
                        root: {
                            paddingLeft: 24,
                            paddingRight: 24,
                        },
                    }}
                >
                    <MeasureLinkInformation
                        measure={measure}
                        isLoading={loading}
                        showOwner
                    />
                </Stack.Item>
                <Stack.Item>
                    {href ? (
                        <Link href={href}>
                            {defaultRender && defaultRender(panelProps)}
                        </Link>
                    ) : (
                        <div>{defaultRender && defaultRender(panelProps)}</div>
                    )}
                </Stack.Item>
            </Stack>
        );
    };

    const sectionContainerStyle: IRawStyle = {
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
        border: `solid 1px ${currentTheme.palette.neutralLight}`,
        backgroundColor: currentTheme.palette.neutralLighterAlt,
        padding: 8,
    };

    const classNames = mergeStyleSets({
        sectionContainer: sectionContainerStyle,
        historySectionContainer: {
            ...sectionContainerStyle,
            backgroundColor: currentTheme.palette.white,
            padding: 0,
        },
    });

    return (
        <Panel
            isOpen={props.showPanel}
            type={PanelType.medium}
            onDismiss={props.onDismiss}
            headerText={measure ? measure.name || '' : 'Loading...'}
            onRenderHeader={handleRenderPanelHeader}
            closeButtonAriaLabel="Close"
            onRenderNavigationContent={onRenderNavigationContent}
            styles={{
                commands: {
                    zIndex: 1,
                },
                scrollableContent: {
                    // Always show the scrollbar
                    height: '100%',
                    overflowY: 'scroll',
                },
            }}
        >
            <MeasureCopyDialog
                tenantId={currentTenantId || ''}
                measureId={props.measureId}
                hidden={!isMeasureCopyModalOpen}
                onDismiss={() => setIsMeasureCopyModalOpen(false)}
                onMeasuresCreated={() => {
                    setIsMeasureCopyModalOpen(false);
                }}
                financialYearCode={currentFinancialYearCode || null}
                alreadyLinkedToMissions={linkedToMissions}
            />

            <MeasureLinkFromDialog
                tenantId={currentTenantId || ''}
                measureId={props.measureId}
                hidden={!isMeasureLinkFromModalOpen}
                onDismiss={() => setIsMeasureLinkFromModalOpen(false)}
                onLinked={() => {
                    setIsMeasureLinkFromModalOpen(false);

                    // Close the panel to avoid having to reload history, comments, attachments or whatever.
                    props.onDismiss();
                }}
                financialYearCode={currentFinancialYearCode || null}
            />

            {clipboard.copied && (
                <div style={{ marginTop: '12px' }}>
                    <MessageBar messageBarType={MessageBarType.success}>
                        <span>{measure?.id ?? ''} copied to clipboard!</span>
                    </MessageBar>
                </div>
            )}

            {measure?.isCustom &&
                !measure.isLinked &&
                features.Measures2023Upgrade && (
                    <div style={{ marginTop: 12 }}>
                        <LegacyMeasureBar
                            measure={measure}
                            onUpgradeCompleted={props.onDismiss}
                        />
                    </div>
                )}

            {props.measureId && measure && hasValues && (
                <Stack
                    horizontal
                    tokens={{ childrenGap: 16 }}
                    styles={{ root: { paddingTop: 8 } }}
                >
                    <Stack.Item align="start">
                        <Stack horizontal wrap>
                            <Text variant="mediumPlus">Target:&nbsp;</Text>
                            <Text variant="mediumPlus" nowrap>
                                {targetFormatted}
                            </Text>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item align="start">
                        <Stack horizontal wrap>
                            <Text variant="mediumPlus">Actual:&nbsp;</Text>
                            <Text variant="mediumPlus" nowrap>
                                {actualFormatted}
                            </Text>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item grow align="start">
                        <Stack horizontal wrap>
                            <Text variant="mediumPlus">Status:&nbsp;</Text>
                            <Text variant="mediumPlus">{formattedStatus}</Text>
                            <MeasureArrow
                                isStatusLimited={measure.isStatusLimited}
                                arrowColour={measure.lastAsOf?.arrowColour}
                                arrowDirection={
                                    measure.lastAsOf?.arrowDirection || 'SAME'
                                }
                                values={measure.lastAsOf?.values}
                                iconFontSize={14}
                                percentagePosition="Left"
                                statusValue={status}
                            />
                        </Stack>
                    </Stack.Item>
                </Stack>
            )}

            {props.measureId && measure && (
                <Stack
                    tokens={{ childrenGap: 8 }}
                    styles={{ root: { paddingTop: 16 } }}
                >
                    {valueHistory.length > 1 && (
                        <div className={classNames.sectionContainer}>
                            {measure.isCustom && (
                                <div style={{ alignSelf: 'end' }}>
                                    <ChartToggle
                                        chartOption={chartOption}
                                        onChartOptionChanged={
                                            handleChartOptionChanged
                                        }
                                    />
                                </div>
                            )}
                            <div>
                                {measure.isCustom ? (
                                    <HistoryChartV1
                                        measureId={props.measureId}
                                        chartType={chartOption}
                                        status={status}
                                        isStatusLimited={
                                            measure.isStatusLimited
                                        }
                                        recentHistory={valueHistory}
                                        measureType={measure.measureType}
                                        multiplier={measure.multiplier}
                                        decimalPlaces={measure.decimalPlaces}
                                        currencySymbol={
                                            measure.currency?.symbol
                                        }
                                        arrowColour={
                                            targetFormatted &&
                                            measure.lastAsOf?.arrowColour
                                                ? measure.lastAsOf?.arrowColour
                                                : 'NONE'
                                        }
                                        isCustom={measure.isCustom}
                                        frequencyPeriod={
                                            measure.frequencyPeriod
                                        }
                                        width="100%"
                                        height={200}
                                        showTarget={true}
                                        showTargetPhasing={true}
                                        showForecast={measure.showForecast}
                                        showAxis={true}
                                        showLegend={true}
                                    />
                                ) : (
                                    <div style={{ paddingTop: 16 }}>
                                        <HistoryChart
                                            measure={measure}
                                            recentHistory={valueHistory}
                                            width="100%"
                                            height={200}
                                            showAxis={true}
                                            showLegend={true}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                    {linkedTasks && linkedTasks?.length > 0 && (
                        <React.Fragment>
                            <Stack horizontal>
                                <ExpandCollapse
                                    isExpanded={isLinkedTasksExpanded}
                                    onExpand={handleLinkedTasksExpand}
                                    onCollapse={handleLinkedTasksCollapse}
                                />
                                <Stack.Item grow align="center">
                                    <Text variant="mediumPlus">
                                        Linked Tasks
                                    </Text>
                                </Stack.Item>
                            </Stack>

                            <div className={classNames.sectionContainer}>
                                {isLinkedTasksExpanded &&
                                    linkedTasks.map((t) => {
                                        const formattedPercentage =
                                            formatter.format(
                                                t?.percentComplete ?? 0
                                            );

                                        const impliedTasks =
                                            taskData?.tasks?.filter(
                                                (it) =>
                                                    it.parentTaskId === t.id &&
                                                    !it.isDuplicate
                                            );

                                        return (
                                            <div
                                                key={t.id}
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    gap: 2,
                                                    alignItems: 'start',
                                                }}
                                            >
                                                <Text variant="small">
                                                    {t.name}
                                                </Text>
                                                {!!t.taskCategory && (
                                                    <div>
                                                        <Chip
                                                            text={
                                                                t.taskCategory
                                                                    .name
                                                            }
                                                            iconName="TagSolid"
                                                            backgroundColor={`#${t.taskCategory.colourHex}`}
                                                        />
                                                    </div>
                                                )}
                                                {!!impliedTasks?.length && (
                                                    <TaskSummaryBar
                                                        impliedTasks={
                                                            impliedTasks || []
                                                        }
                                                        percentageComplete={
                                                            t.percentComplete ||
                                                            0
                                                        }
                                                    />
                                                )}
                                                <Text variant="small">
                                                    {formattedPercentage}
                                                </Text>
                                            </div>
                                        );
                                    })}
                            </div>
                        </React.Fragment>
                    )}

                    <Stack.Item>
                        <Stack horizontal verticalAlign="center">
                            <ExpandCollapse
                                isExpanded={isHistoryExpanded}
                                onExpand={handleHistoryExpand}
                                onCollapse={handleHistoryCollapse}
                            />
                            <Stack.Item grow align="center">
                                <Text variant="mediumPlus" block>
                                    History
                                </Text>
                            </Stack.Item>
                            <Stack.Item align="center">
                                <ActionButton
                                    onClick={props.onCommentsButtonClick}
                                    iconProps={{ iconName: 'Message' }}
                                    text="Go to Comments"
                                />
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>

                    {isHistoryExpanded && (
                        <Stack.Item
                            className={classNames.historySectionContainer}
                        >
                            <MeasureHistoryFeed measureId={props.measureId} />
                        </Stack.Item>
                    )}
                </Stack>
            )}
        </Panel>
    );
}

function ChartToggle(props: {
    chartOption: 'bar' | 'line';
    onChartOptionChanged: (newChartOption: 'bar' | 'line') => void;
}): JSX.Element {
    const { chartOption, onChartOptionChanged } = props;

    const menuProps: IContextualMenuProps = {
        items: [
            {
                key: 'line',
                text: 'Line Chart',
                iconProps: {
                    iconName: 'StackedLineChart',
                },
                onClick: () => onChartOptionChanged('line'),
            },
            {
                key: 'bar',
                text: 'Bar Chart',
                iconProps: {
                    iconName: 'BarChartVerticalFill',
                },
                onClick: () => onChartOptionChanged('bar'),
            },
        ],
    };

    return (
        <ActionButton
            text={menuProps.items.find((i) => i.key === chartOption)?.text}
            iconProps={
                menuProps.items.find((i) => i.key === chartOption)?.iconProps
            }
            menuProps={menuProps}
        />
    );
}
