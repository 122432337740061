import React from 'react';
import {
    Dropdown,
    DropdownMenuItemType,
    IDropdownOption,
} from '@fluentui/react';
import { InputShimmer } from '.';
import { sorters } from '../../data/sorters';

export type TaskDropDownProps = {
    label?: string;
    required?: boolean;
    disabled?: boolean;
    placeholder?: string;
    errorMessage?: string;
    selectedKey?: string | null;
    isDataLoaded: boolean;
    canSelectSpecifiedTasks: boolean;
    canSelectImpliedTasks: boolean;
    disableResourcedFromTasks?: boolean;
    missionTasks:
        | {
              id: string | null;
              parentTaskId: string | null;
              name: string | null;
              sequence: number | null;
              isDuplicate: boolean;
              resourcedFromTaskId: string | null;
          }[]
        | null
        | undefined;
    onTaskChange: (
        selectedTaskId: string | null,
        taskType: 'specified-task' | 'implied-task'
    ) => void;
};

export function TaskDropDown(props: TaskDropDownProps): JSX.Element {
    const specifiedTaskOptions: IDropdownOption[] = props.missionTasks
        ? props.missionTasks
              .filter((t) => !t?.parentTaskId)
              .filter((t) => !t?.isDuplicate)
              .sort(sorters.sequenceSorter)
              .map(
                  (t) =>
                      ({
                          key: t?.id,
                          text: t?.name || '',
                          data: { type: 'specified-task' },
                          disabled:
                              props.disableResourcedFromTasks &&
                              !!t.resourcedFromTaskId,
                      }) as IDropdownOption
              )
        : [];

    let options: IDropdownOption[];

    const handleRenderOption = (option?: IDropdownOption): JSX.Element => {
        return (
            <div>
                <span
                    style={{
                        fontWeight:
                            option?.data.type === 'specified-task' &&
                            props.canSelectImpliedTasks
                                ? 'bold'
                                : undefined,
                    }}
                >
                    {option?.text}
                </span>
            </div>
        );
    };

    if (props.canSelectImpliedTasks) {
        options = [];
        specifiedTaskOptions.forEach((st) => {
            options.push({
                ...st,
                itemType: props.canSelectSpecifiedTasks
                    ? DropdownMenuItemType.Normal
                    : DropdownMenuItemType.Header,
            });
            (props.missionTasks || [])
                .filter((t) => t?.parentTaskId === st.key)
                .filter((t) => !t?.isDuplicate)
                .sort(sorters.sequenceSorter)
                .forEach((it) => {
                    options.push({
                        key: it.id || '',
                        text: it.name || it.id || '',
                        disabled:
                            props.disableResourcedFromTasks &&
                            !!it.resourcedFromTaskId,
                        data: { type: 'implied-task' },
                    });
                });
            options.push({
                key: `${st.id}_divider`,
                text: '',
                itemType: DropdownMenuItemType.Divider,
            });
        });
    } else {
        options = specifiedTaskOptions;
    }

    return (
        <InputShimmer isDataLoaded={props.isDataLoaded}>
            <Dropdown
                {...props}
                options={options}
                onRenderOption={handleRenderOption}
                styles={{
                    dropdownOptionText: {
                        overflow: 'visible',
                        whiteSpace: 'normal',
                    },
                    dropdownItem: { height: 'auto' },
                }}
                onChange={(
                    _event: React.FormEvent,
                    option?: IDropdownOption | undefined
                ): void => {
                    const selectedTaskId = (option?.key as string) || null;
                    const type = option?.data.type as
                        | 'specified-task'
                        | 'implied-task';
                    props.onTaskChange(selectedTaskId, type);
                }}
            />
        </InputShimmer>
    );
}
