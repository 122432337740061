import React, { useEffect, useState } from 'react';
import { Dropdown, IDropdownOption, mergeStyleSets } from '@fluentui/react';
import { ReportPeriodTypes } from '../../data/types';
import { useReportPeriods } from '../../hooks/useReportPeriods';
import { ReportType } from '../../scenes/StatusReports/StatusReports';

export function ReportPeriodSelection(props: {
    fyStartDate: string | null | undefined;
    fyEndDate: string | null | undefined;
    reportPeriodType: ReportPeriodTypes | null | undefined;
    reportPeriod: number | null | undefined;
    onPeriodChanged: (
        reportPeriodType: ReportPeriodTypes,
        reportPeriod: number
    ) => void;
    reportType: ReportType;
    templateOrReport?: {
        reportPeriodType: ReportPeriodTypes | null;
    };
}) {
    const {
        reportPeriodType,
        reportPeriod,
        fyStartDate,
        fyEndDate,
        onPeriodChanged,
    } = props;

    const { getPeriods, formatPeriod, getAllowedPeriodTypes } =
        useReportPeriods(fyStartDate, fyEndDate);

    const periods = getPeriods(reportPeriodType);

    if (!periods.some((p) => p.period === reportPeriod) && reportPeriod) {
        periods.push({
            text: `Selected - ${formatPeriod(reportPeriodType, reportPeriod)}`,
            period: reportPeriod,
        });
    }

    const classNames = mergeStyleSets({
        container: {
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
        },
    });

    const [periodTypeOptions, setPeriodTypeOptions] =
        useState<IDropdownOption[]>();

    useEffect(() => {
        const allowedPeriods = getAllowedPeriodTypes(
            props.reportType,
            props.templateOrReport
        );

        setPeriodTypeOptions(
            allowedPeriods.map((p) => {
                switch (p) {
                    case ReportPeriodTypes.None: {
                        return {
                            text: 'Current',
                            key: p,
                        };
                    }
                    case ReportPeriodTypes.Month: {
                        return {
                            text: 'Month',
                            key: p,
                        };
                    }
                    case ReportPeriodTypes.Quarter: {
                        return {
                            text: 'Quarter',
                            key: p,
                        };
                    }
                    case ReportPeriodTypes.Half: {
                        return {
                            text: 'Half',
                            key: p,
                        };
                    }
                    case ReportPeriodTypes.Ytd: {
                        return {
                            text: 'YTD',
                            key: p,
                        };
                    }
                    default:
                        throw `Unrecognised period type: ${p}`;
                }
            })
        );
    }, [getAllowedPeriodTypes, props.reportType, props.templateOrReport]);

    return (
        <div className={classNames.container}>
            {!!reportPeriodType && !!periodTypeOptions && (
                <Dropdown
                    label="Report Period Type"
                    selectedKey={reportPeriodType.toString()}
                    options={periodTypeOptions}
                    disabled={periodTypeOptions.length < 2}
                    onChange={(_ev, option) => {
                        if (option) {
                            const newPeriods = getPeriods(
                                option.key as ReportPeriodTypes
                            );
                            onPeriodChanged(
                                option.key as ReportPeriodTypes,
                                newPeriods.length ? newPeriods[0].period : 1
                            );
                        }
                    }}
                />
            )}

            {reportPeriodType &&
                reportPeriodType !== ReportPeriodTypes.None &&
                reportPeriodType !== ReportPeriodTypes.Ytd && (
                    <Dropdown
                        label="Reporting Period"
                        selectedKey={reportPeriod}
                        options={periods.map((p) => ({
                            text: p.text,
                            key: p.period || 0,
                        }))}
                        onChange={(_ev, option) => {
                            if (option) {
                                onPeriodChanged(
                                    reportPeriodType || ReportPeriodTypes.None,
                                    option.key as number
                                );
                            }
                        }}
                    />
                )}
        </div>
    );
}
