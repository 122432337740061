import React from 'react';
import {
    ActivityItem,
    IColumn,
    SelectionMode,
    ShimmeredDetailsList,
} from '@fluentui/react';

import {
    GetTaskHistoryQuery,
    useGetCommentsQuery,
    useGetTaskAttachmentsQuery,
    useGetTaskHistoryQuery,
} from '../data/types';
import { useStateContext } from '../services/contextProvider';

import dayjs from 'dayjs';

import { ExtractQueryArrayType } from '../data/extendedTypes';
import { useAttachmentIcons } from '../hooks/useAttachmentIcons';
import AttachmentLink from './AttachmentLink';
import orderBy from 'lodash/orderBy';
import { useFormatters } from '../hooks/useFormatters';
import { HistoryCoin } from './HistoryCoin';
import { useThemes } from '../hooks/useThemes';
import { useComments } from '../hooks/useComments';

export default function TaskHistoryFeed(props: {
    taskId: string;
}): JSX.Element {
    const { currentTenantId } = useStateContext();

    const { currentTheme } = useThemes();

    const formatter = useFormatters();
    const attachmentIcons = useAttachmentIcons();

    const { renderCommentText } = useComments();

    interface FeedItem {
        key: string;
        type: string;
        person: string | undefined;
        actionText: string;
        text: string | React.ReactNode;
        timeStamp: string | null;
        userId: string | undefined;
        iconName: string | undefined;
    }

    type HistoryItem = ExtractQueryArrayType<
        GetTaskHistoryQuery,
        ['taskHistory']
    >;

    const feedColumns: IColumn[] = [
        {
            key: 'text',
            name: 'text',
            fieldName: 'text',
            minWidth: 100,
            isMultiline: true,
        },
    ];

    const { data: taskHistoryData, loading } = useGetTaskHistoryQuery({
        fetchPolicy: 'no-cache',
        skip: !props.taskId,
        variables: {
            tenantId: currentTenantId || '',
            id: props.taskId || '',
        },
    });

    const { data: commentsData, loading: commentsLoading } =
        useGetCommentsQuery({
            skip: !props.taskId,
            variables: {
                tenantId: currentTenantId || '',
                measureId: null,
                taskId: props.taskId || null,
            },
        });

    const { data: attachmentsData, loading: attachmentsLoading } =
        useGetTaskAttachmentsQuery({
            skip: !props.taskId,
            variables: {
                tenantId: currentTenantId || '',
                taskId: props.taskId || '',
            },
        });

    const feedItems: FeedItem[] = [];

    commentsData?.comments?.forEach((c) => {
        feedItems.push({
            key: `comment_${c.id}`,
            type: 'comment',
            person: c.username || unknownUserDisplayName,
            userId: c.userId || '',
            actionText: 'commented',
            iconName: 'Message',
            timeStamp: c.utcCreated,
            text: renderCommentText(c.text),
        });
    });

    attachmentsData?.task?.attachments?.forEach((attachment) => {
        feedItems.push({
            key: `attachment_${attachment.id}`,
            type: 'attachment',
            person: attachment.username ?? unknownUserDisplayName,
            userId: attachment.userId || '',
            actionText: attachment.isFile
                ? 'attached a file'
                : 'attached a link',
            text: <AttachmentLink attachment={attachment} />,
            iconName: attachmentIcons.getIconName(attachment),
            timeStamp: attachment.utcCreated,
        });
    });

    const unknownUserDisplayName = 'A system process';

    const isSpecifiedTask = (item: HistoryItem): boolean => {
        // This check doesn't check if it's a duplicate of a specified task.
        // We will just check if its on a mission without a parent.
        return !item.isDuplicate && !!item.missionId && !item.parentTaskId;
    };

    const checkCreated = (
        before: HistoryItem | undefined,
        after: HistoryItem
    ) => {
        // Check this update roughly tallies with the created date (the task may be older than the temporal table history)
        if (
            !before &&
            dayjs(after.sysStartTime).diff(after.utcCreated, 'hour', true) < 1
        ) {
            const initialDates: string[] = [];

            if (after.start) {
                initialDates.push(
                    'with the start date of ' +
                        dayjs(after.start).format('DD MMM YYYY')
                );
            }

            if (after.due) {
                initialDates.push(
                    'with the due date of ' +
                        dayjs(after.start).format('DD MMM YYYY')
                );
            }

            const initialDatesJoined =
                initialDates.length > 0 ? ` ${initialDates.join(' and ')}` : '';

            const text = `The task "${after?.name}" was created${initialDatesJoined}.`;

            feedItems.push({
                key: `created_${after.utcCreated?.toString()}`,
                type: 'created',
                person: after?.updatedByUserName || unknownUserDisplayName,
                userId: after?.updatedByUserId || undefined,
                iconName: 'Starburst',
                actionText: 'created',
                text: text,
                timeStamp: after?.utcCreated,
            });
        }
    };

    const checkRename = (before: HistoryItem, after: HistoryItem) => {
        if (before.name !== after.name) {
            feedItems.push({
                key: `rename_${after.utcUpdated?.toString()}`,
                type: 'rename',
                person: after?.updatedByUserName || unknownUserDisplayName,
                userId: after?.updatedByUserId || undefined,
                iconName: 'Rename',
                actionText: 'renamed',
                text: `The task was renamed to "${after?.name?.trim()}".`,
                timeStamp: after?.utcUpdated,
            });
        }
    };

    const checkPercentComplete = (before: HistoryItem, after: HistoryItem) => {
        if (before.percentComplete !== after.percentComplete) {
            feedItems.push({
                key: `percentComplete_${after.sysStartTime?.toString()}`,
                type: 'percentComplete',
                person: after?.updatedByUserName || unknownUserDisplayName,
                userId: after?.updatedByUserId || undefined,
                iconName: 'CalculatorPercentage',
                actionText: 'updated',
                text: `The percentage complete was changed to ${formatter.formatTaskPercentage(
                    after?.percentComplete
                )}.`,
                timeStamp: after?.sysStartTime,
            });
        }
    };

    const checkAccepted = (before: HistoryItem, after: HistoryItem) => {
        if (!before.utcAccepted && !!after.utcAccepted) {
            let text = '';

            if (after.isDuplicate) {
                text = `The task was accepted and marked as a duplicate.`;
            } else if (after.missionId && after.parentTaskId) {
                text = `The task was accepted and added to the mission as an implied task.`;
            } else if (after.missionId && !after.parentTaskId) {
                text = `The task was accepted and added to the mission as a specified task.`;
            } else {
                text = `The task was accepted.`;
            }

            feedItems.push({
                key: `accepted_${after.utcUpdated?.toString()}`,
                type: 'accepted',
                person: after?.updatedByUserName || unknownUserDisplayName,
                userId: after?.updatedByUserId || undefined,
                iconName: 'Accept',
                actionText: 'accepted',
                text: text,
                timeStamp: after?.utcUpdated,
            });
        }
    };

    const checkRemoved = (before: HistoryItem, after: HistoryItem) => {
        if (!!before.missionId && !after.missionId) {
            feedItems.push({
                key: `removed_${after.utcUpdated?.toString()}`,
                type: 'unaccepted the task',
                person: after?.updatedByUserName || unknownUserDisplayName,
                userId: after?.updatedByUserId || undefined,
                iconName: 'DependencyRemove',
                actionText: 'removed',
                text: 'The task was removed from the mission.',
                timeStamp: after?.utcUpdated,
            });
        }
    };

    const checkRejected = (before: HistoryItem, after: HistoryItem) => {
        if (!before.utcRejected && !!after.utcRejected) {
            feedItems.push({
                key: `rejected_${after.utcUpdated?.toString()}`,
                type: 'rejected',
                person: after?.updatedByUserName || unknownUserDisplayName,
                userId: after?.updatedByUserId || undefined,
                iconName: 'Reply',
                actionText: 'rejected the task',
                text: after.rejectedReason,
                timeStamp: after?.utcUpdated,
            });
        }
    };

    const checkStatus = (before: HistoryItem, after: HistoryItem) => {
        if (!before.utcCancelled && !!after.utcCancelled) {
            feedItems.push({
                key: `cancelled_${after.utcUpdated?.toString()}`,
                type: 'cancelled',
                person: after?.updatedByUserName || unknownUserDisplayName,
                userId: after?.updatedByUserId || undefined,
                iconName: 'Blocked',
                actionText: 'changed the status of the task',
                text: 'This task was marked as cancelled',
                timeStamp: after?.utcUpdated,
            });
        } else if (!before.utcPostponed && !!after.utcPostponed) {
            feedItems.push({
                key: `postponed_${after.utcUpdated?.toString()}`,
                type: 'postponed',
                person: after?.updatedByUserName || unknownUserDisplayName,
                userId: after?.updatedByUserId || undefined,
                iconName: 'Recent',
                actionText: 'changed the status of the task',
                text: 'This task was marked as postponed',
                timeStamp: after?.utcUpdated,
            });
        } else if (!before.utcAtRisk && !!after.utcAtRisk) {
            feedItems.push({
                key: `atrisk_${after.utcUpdated?.toString()}`,
                type: 'atrisk',
                person: after?.updatedByUserName || unknownUserDisplayName,
                userId: after?.updatedByUserId || undefined,
                iconName: 'ReportWarning',
                actionText: 'changed the status of the task',
                text: 'This task was marked as at risk',
                timeStamp: after?.utcUpdated,
            });
        } else if (
            (!!before.utcCancelled && !after.utcCancelled) ||
            (!!before.utcPostponed && !after.utcPostponed) ||
            (!!before.utcAtRisk && !after.utcAtRisk)
        ) {
            feedItems.push({
                key: `activated_${after.utcUpdated?.toString()}`,
                type: 'activated',
                person: after?.updatedByUserName || unknownUserDisplayName,
                userId: after?.updatedByUserId || undefined,
                iconName: 'Recent',
                actionText: 'changed the status of the task',
                text: `This task was marked as not ${before.utcCancelled ? 'cancelled' : before.utcPostponed ? 'postponed' : 'at risk'}`,
                timeStamp: after?.utcUpdated,
            });
        }
    };

    const checkDates = (before: HistoryItem, after: HistoryItem) => {
        const dateTypes = ['start', 'due', 'done'];

        const dateChangeTexts: string[] = [];

        dateTypes.forEach((dateType) => {
            const beforeDate = before[dateType as 'start' | 'due' | 'done'];
            const afterDate = after[dateType as 'start' | 'due' | 'done'];

            if (beforeDate !== afterDate) {
                const beforeFormatted = beforeDate
                    ? dayjs(beforeDate).format('DD MMM YYYY')
                    : null;

                const afterFormatted = afterDate
                    ? dayjs(afterDate).format('DD MMM YYYY')
                    : null;

                let text = '';
                if (!beforeFormatted) {
                    text = `The ${dateType} date was set to ${afterFormatted}.`;
                } else if (!afterFormatted) {
                    text = `The ${dateType} date of ${beforeFormatted} was cleared.`;
                } else {
                    text = `The ${dateType} date was changed to ${afterFormatted}.`;
                }
                dateChangeTexts.push(text);
            }
        });

        if (dateChangeTexts.length > 0) {
            feedItems.push({
                key: `dates${after.sysStartTime?.toString()}`,
                type: 'dates',
                person: after.updatedByUserName || unknownUserDisplayName,
                userId: after.updatedByUserId || undefined,
                iconName:
                    !before.done && after.done ? 'EventAccepted' : 'Event',
                actionText:
                    dateChangeTexts.length > 1
                        ? 'changed the dates'
                        : 'changed a date',
                text: dateChangeTexts.join(' '),
                timeStamp: after.sysStartTime,
            });
        }
    };

    if (taskHistoryData?.taskHistory !== null) {
        const history = taskHistoryData?.taskHistory || [];
        history.forEach((h, index) => {
            const previous = history[index + 1];
            if (h !== null) {
                checkCreated(previous, h);
                if (previous) {
                    checkAccepted(previous, h);
                    checkRemoved(previous, h);
                    checkRejected(previous, h);
                    checkRename(previous, h);
                    checkStatus(previous, h);
                    if (!isSpecifiedTask(h)) {
                        checkDates(previous, h);
                    }
                    checkPercentComplete(previous, h);
                }
            }
        });
    }

    function renderItemColumn(
        item?: FeedItem,
        _index?: number,
        column?: IColumn
    ): JSX.Element {
        if (!column || !item) {
            return <span />;
        }

        const fieldContent = item[column.fieldName as keyof FeedItem];

        switch (column.key) {
            case 'text':
                return (
                    <ActivityItem
                        styles={{
                            // workaround for ActivityItem not using the theme
                            root: {
                                color: currentTheme.palette.neutralSecondary,
                            },
                            commentText: {
                                color: currentTheme.palette.neutralPrimary,
                                whiteSpace: 'pre-line',
                            },
                            timeStamp: {
                                color: currentTheme.palette.neutralSecondary,
                            },
                        }}
                        activityDescription={`${item.person} ${item.actionText}`}
                        activityIcon={
                            item.iconName && (
                                <HistoryCoin
                                    iconName={item.iconName}
                                    userDisplayName={
                                        item.person || unknownUserDisplayName
                                    }
                                    userId={item.userId}
                                />
                            )
                        }
                        comments={item.text}
                        timeStamp={dayjs.utc(item.timeStamp).fromNow()}
                    />
                );
            default:
                return <span>{fieldContent}</span>;
        }
    }

    const orderedItems = orderBy(feedItems, ['timeStamp'], 'desc');

    return (
        <ShimmeredDetailsList
            selectionMode={SelectionMode.none}
            isHeaderVisible={false}
            items={orderedItems}
            enableShimmer={loading || commentsLoading || attachmentsLoading}
            columns={feedColumns}
            onRenderItemColumn={renderItemColumn}
        />
    );
}
