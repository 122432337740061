import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import {
    ChoiceGroup,
    DefaultButton,
    IChoiceGroupOption,
    PrimaryButton,
    TextField,
} from '@fluentui/react';
import { EditPanel } from '../../../components/shared/EditPanel';
import {
    refetchGetStatusReportsQuery,
    useGetLatestStatusReportQuery,
    useGetMissionQuery,
    useGetReportTemplatesQuery,
    useUpdateStatusReportMutation,
    ReportPeriodTypes,
} from '../../../data/types';
import { navigation } from '../../../services/navigation';

import Loading from '../../../components/Loading';

import { useReportPeriods } from '../../../hooks/useReportPeriods';
import { ReportPeriodSelection } from '../../../components/reports/ReportPeriodSelection';
import { useCreateTemplateReport } from '../hooks/useCreateTemplateReport';
import { ReportType } from '../StatusReports';

export function StatusReportCreatePanel(props: {
    tenantId: string;
    missionId: string;
    showPanel: boolean;
    onDismiss: () => void;
}): JSX.Element {
    const navigate = useNavigate();
    const params = useParams();

    const StatusReportTypeKey = 'status-report';

    const [selectedReportTypeKey, setSelectedReportTypeKey] =
        useState(StatusReportTypeKey);

    const [isReportTypeSet, setIsReportTypeSet] = useState(false);

    const [hasSaved, setHasSaved] = useState(false);

    const [title, setTitle] = useState('Status Report');
    const [reportingPeriod, setReportingPeriod] = useState<{
        periodType: ReportPeriodTypes;
        period: number | null;
    } | null>(null);

    const [additionalProperties, setAdditionalProperties] = useState<{
        hasCustomTemplate?: boolean;
    }>({
        hasCustomTemplate: false,
    });

    useEffect(() => {
        if (props.showPanel) {
            setSelectedReportTypeKey(StatusReportTypeKey);
            setIsReportTypeSet(false);
            setTitle('Status Report');
            setReportingPeriod(null);
        }
    }, [props.showPanel]);

    const { loading: isMissionLoading, data: missionData } = useGetMissionQuery(
        {
            skip: !props.showPanel,
            variables: {
                tenantId: props.tenantId,
                missionId: props.missionId,
            },
        }
    );

    const { data: latestStatusReportData } = useGetLatestStatusReportQuery({
        fetchPolicy: 'network-only',
        skip: !props.showPanel,
        variables: {
            tenantId: props.tenantId,
            missionId: props.missionId,
        },
    });

    const { data: reportTemplateData, loading } = useGetReportTemplatesQuery({
        skip: !props.showPanel,
        variables: {
            tenantId: props.tenantId,
        },
    });

    const mission = missionData?.mission;
    const fyStartDate = mission?.team?.division?.financialYear?.startDate;
    const fyEndDate = mission?.team?.division?.financialYear?.endDate;

    const { getPeriods } = useReportPeriods(fyStartDate, fyEndDate);

    useEffect(() => {
        if (reportTemplateData?.reports?.templateReports) {
            setAdditionalProperties((p) => ({
                ...p,
                hasCustomTemplate:
                    reportTemplateData?.reports?.templateReports &&
                    reportTemplateData?.reports?.templateReports.length > 0,
            }));
        }
    }, [reportTemplateData]);

    const { createTemplateReport } = useCreateTemplateReport(props.tenantId);

    const [updateStatusReport, { loading: isCreating, error: saveError }] =
        useUpdateStatusReportMutation();

    const handleCreateReportClick = async () => {
        if (selectedReportTypeKey === StatusReportTypeKey) {
            const updateStatusReportResult = await updateStatusReport({
                variables: {
                    tenantId: props.tenantId,
                    input: {
                        id: null,
                        reportDate: null,
                        reportPeriod: reportingPeriod?.period || null,
                        reportPeriodType: reportingPeriod?.periodType || null,
                        utcCompletedDate: null,
                        utcDataDate: null,
                        facts: [], //facts,
                        missionId: props.missionId,
                        title: title,
                        summaryText: '',
                        lastPeriodText:
                            latestStatusReportData?.latestStatusReport
                                ?.nextPeriodText || '',
                        nextPeriodText: '',
                        risksAndOpportunitiesText: '',
                        supportText: '',
                        version: '',
                    },
                    notifyUserIds: [],
                },
                refetchQueries: [
                    refetchGetStatusReportsQuery({
                        tenantId: props.tenantId,
                        missionId: props.missionId,
                    }),
                ],
            });

            setHasSaved(true);

            await new Promise((res) => setTimeout(res, 1000));

            const href = navigation.getPathForParams({
                ...params,
                statusReportId:
                    updateStatusReportResult.data?.statusReportUpdate.id ||
                    undefined,
            });

            navigate(href);
        } else if (selectedReportTypeKey) {
            const newId = await createTemplateReport({
                missionId: props.missionId,
                templateId: selectedReportTypeKey,
                title: title,
                reportingPeriod: reportingPeriod,
            });

            if (newId) {
                const href = navigation.getPathForParams({
                    ...params,
                    templateReportId: newId,
                });
                navigate(href);
            }
        }
    };

    const [selectedTemplateReport, setSeletedTemplateReport] = useState<{
        reportPeriodType: ReportPeriodTypes | null;
    }>();

    const handleNextClick = () => {
        let period: number | null = null;
        let periodType: ReportPeriodTypes = ReportPeriodTypes.None;

        if (selectedReportTypeKey === StatusReportTypeKey) {
            setTitle('Status Report');
            setSeletedTemplateReport(undefined);
            periodType = ReportPeriodTypes.Month;
        } else {
            const templateReport =
                reportTemplateData?.reports?.templateReports.find(
                    (tr) => tr.id === selectedReportTypeKey
                );

            setSeletedTemplateReport(templateReport);
            setTitle(templateReport?.title || 'New Report');
            periodType =
                templateReport?.reportPeriodType ?? ReportPeriodTypes.None;
            period = templateReport?.reportPeriod || null;
        }

        if (!period) {
            const periods = getPeriods(periodType);
            period = periods.length ? periods[0].period : null;
        }

        setReportingPeriod({
            periodType: periodType,
            period: period,
        });

        setIsReportTypeSet(true);
    };

    const typeOptions: IChoiceGroupOption[] = [
        {
            key: StatusReportTypeKey,
            text: 'Mission Status Report',
        },
    ];

    const templates = reportTemplateData?.reports?.templateReports;

    templates?.forEach((t) => {
        if (t.id && t.title) {
            typeOptions.push({
                key: t.id,
                text: t.title,
            });
        }
    });

    const showCreateButton =
        isReportTypeSet ||
        (typeOptions.length === 1 && !!reportingPeriod?.period);

    const showNextButton = !showCreateButton;

    const showReportTitleInput = isReportTypeSet || typeOptions.length === 1;

    return (
        <EditPanel
            activeViewName="StatusReportCreate"
            showPanel={props.showPanel}
            onDismiss={props.onDismiss}
            headerText="Create Report"
            isSaving={isCreating}
            isValid={missionData?.mission?.rights.write || false}
            hasSaved={hasSaved}
            saveErrorMessage={saveError?.message}
            activeViewAdditionalProperties={additionalProperties}
        >
            {loading && <Loading />}

            {!loading && (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 16,
                        paddingTop: 16,
                    }}
                >
                    {typeOptions.length > 1 && (
                        <ChoiceGroup
                            selectedKey={selectedReportTypeKey}
                            disabled={isReportTypeSet}
                            options={typeOptions}
                            onChange={(_ev, option) => {
                                if (option?.key) {
                                    setSelectedReportTypeKey(
                                        option?.key as string
                                    );
                                }
                            }}
                        />
                    )}

                    {showReportTitleInput && (
                        <TextField
                            label="Report Title"
                            value={title}
                            onChange={(_ev, newValue) => {
                                setTitle(newValue || '');
                            }}
                        />
                    )}

                    <ReportPeriodSelection
                        fyStartDate={fyStartDate}
                        fyEndDate={fyEndDate}
                        reportPeriodType={reportingPeriod?.periodType}
                        reportPeriod={reportingPeriod?.period}
                        onPeriodChanged={function (
                            reportPeriodType: ReportPeriodTypes,
                            reportPeriod: number
                        ): void {
                            setReportingPeriod({
                                periodType: reportPeriodType,
                                period: reportPeriod,
                            });
                        }}
                        reportType={
                            selectedReportTypeKey == StatusReportTypeKey
                                ? ReportType.StatusReport
                                : ReportType.TemplateReport
                        }
                        templateOrReport={
                            selectedReportTypeKey != StatusReportTypeKey
                                ? selectedTemplateReport
                                : undefined
                        }
                    />

                    {showNextButton && (
                        <DefaultButton
                            text="Next >"
                            onClick={handleNextClick}
                        />
                    )}

                    {showCreateButton && (
                        <PrimaryButton
                            text="Create Report"
                            disabled={
                                isMissionLoading ||
                                isCreating ||
                                !missionData?.mission?.rights.write
                            }
                            onClick={handleCreateReportClick}
                        />
                    )}
                </div>
            )}
        </EditPanel>
    );
}
