import * as React from 'react';

import {
    useGetMissionSearchQuery,
    MissionQl,
    useGetMissionQuery,
    useUpdateMissionMutation,
} from '../../../data/types';
import { useStateContext } from '../../../services/contextProvider';
import {
    ShimmeredDetailsList,
    IconButton,
    SelectionMode,
    DetailsListLayoutMode,
    IColumn,
    Stack,
    Persona,
    PersonaSize,
    Toggle,
    mergeStyleSets,
} from '@fluentui/react';
import { useSorter } from '../../../hooks/useSorter';
import { photoService } from '../../../services/photo.service';
import { navigation } from '../../../services/navigation';
import { useNavigate } from 'react-router';
import { DivisionPicker } from './DivisionPicker';
import { DetailsListCellItemContainer } from '../../../components/shared/DetailsListCellItemContainer';

import DeleteModal from '../../../components/shared/DeleteModal';
import dayjs from 'dayjs';

import { useInputMappers } from '../../../hooks/useInputMappers';
import { FilterBar } from '../../../components/FilterBar';
import { useCallback } from 'react';

type MissionListProps = {
    fyCodeFilter: string | null;
    onItemEdit: (Mission: MissionQl) => void;
};

export function MissionList(props: MissionListProps): JSX.Element {
    const { currentTenantId, currentTenantCode } = useStateContext();

    const [textFilter, setTextFilter] = React.useState<string | null>();
    const [isConfirmingActive, setIsConfirmingActive] = React.useState<{
        missionId: string;
        isActive: boolean;
    } | null>(null);

    const [selectedDivisionId, setSelectedDivisionId] = React.useState<
        string | null
    >(null);

    const navigate = useNavigate();

    const { data, loading } = useGetMissionSearchQuery({
        skip: !currentTenantId,
        variables: {
            tenantId: currentTenantId || '',
            searchText: textFilter || '',
            isWritable: false,
            isImportable: false,
            includeInactive: true,
            includeDeleted: false,
            financialYearCode: props.fyCodeFilter,
            divisionId: selectedDivisionId,
        },
    });

    const onEditIconClick = (mission: MissionQl) => {
        props.onItemEdit(mission);
    };

    const { sortColumn, sortIsDesc, sortedItems, setSortColumnName } =
        useSorter(data?.missions, 'owner', false, true);

    const handleSelectedDivisionIdsChange = (
        divisionId: string,
        isSelected: boolean
    ) => {
        if (divisionId && isSelected) {
            setSelectedDivisionId(divisionId);
        }
    };

    const handleToggleActive = (missionId: string, isActive: boolean) =>
        setIsConfirmingActive({ missionId: missionId, isActive: isActive });

    const columns: IColumn[] = [
        {
            key: 'financialYear',
            name: 'FY',
            fieldName: 'financialYear.code',
            minWidth: 50,
            maxWidth: 100,
            isResizable: true,
            onRender: function renderFinancialYear(
                item: MissionQl
            ): JSX.Element {
                return (
                    <DetailsListCellItemContainer>
                        {item?.team?.division?.financialYear?.code}
                    </DetailsListCellItemContainer>
                );
            },
            isSorted: sortColumn === 'team.division.financialYear.code',
            isSortedDescending:
                sortColumn === 'team.division.financialYear.code' && sortIsDesc,
            onColumnClick: (): void =>
                setSortColumnName('team.division.financialYear.code', false),
        },
        {
            key: 'team',
            name: 'Team Code',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            isSorted: sortColumn === 'team.code',
            isSortedDescending: sortColumn === 'team.code' && sortIsDesc,
            onColumnClick: (): void => setSortColumnName('team.code', false),
            onRender: function renderDisplayName(item: {
                team: {
                    name: string | null;
                    code: string | null;
                } | null;
            }): JSX.Element {
                return (
                    <DetailsListCellItemContainer>
                        {item.team?.code}
                    </DetailsListCellItemContainer>
                );
            },
        },
        {
            key: 'username',
            name: 'User',
            fieldName: 'username',
            minWidth: 150,
            maxWidth: 200,
            isResizable: true,
            isSorted: sortColumn === 'username',
            isSortedDescending: sortColumn === 'username' && sortIsDesc,
            onColumnClick: (): void => setSortColumnName('username', true),
            onRender: function renderDisplayName(item: {
                userId: string | null;
                username: string | null;
            }): JSX.Element {
                if (item.userId) {
                    return (
                        <Persona
                            text={item.username || item.userId}
                            imageUrl={photoService.getImageUrl(item.userId)}
                            size={PersonaSize.size24}
                        />
                    );
                } else {
                    return <span />;
                }
            },
        },
        {
            key: 'owner',
            name: 'Owner',
            fieldName: 'owner',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            isSorted: sortColumn === 'owner',
            isSortedDescending: sortColumn === 'owner' && sortIsDesc,
            onColumnClick: (): void => setSortColumnName('owner', true),
        },
        {
            key: 'title',
            name: 'Title',
            fieldName: 'title',
            minWidth: 50,
            isResizable: true,
            isSorted: sortColumn === 'title',
            isSortedDescending: sortColumn === 'title' && sortIsDesc,
            onColumnClick: (): void => setSortColumnName('title', true),
        },
        {
            key: 'utcInactive',
            name: 'Active?',
            fieldName: 'utcInactive',
            minWidth: 100,
            isResizable: true,
            isSorted: sortColumn === 'utcInactive',
            isSortedDescending: sortColumn === 'utcInactive' && sortIsDesc,
            onColumnClick: (): void => setSortColumnName('active'),
            onRender: function renderEnabled(item: {
                id: string;
                utcInactive: string | null;
            }): JSX.Element {
                return (
                    <DetailsListCellItemContainer>
                        <Toggle
                            styles={{
                                root: {
                                    marginBottom: 0,
                                },
                            }}
                            onText="Yes"
                            offText="No"
                            checked={!item.utcInactive}
                            onChange={(
                                _ev: React.MouseEvent<HTMLElement>,
                                checked?: boolean
                            ): void =>
                                handleToggleActive(item.id, checked || false)
                            }
                        />
                    </DetailsListCellItemContainer>
                );
            },
        },
        {
            key: 'actions',
            name: '',
            minWidth: 64,
            maxWidth: 64,
            isPadded: false,
            className: 'iconCell',
            onRender: function renderActions(item: MissionQl): JSX.Element {
                return (
                    <Stack horizontal>
                        <IconButton
                            iconProps={{ iconName: 'Edit' }}
                            onClick={(): void => {
                                onEditIconClick(item);
                            }}
                        />
                        <IconButton
                            iconProps={{ iconName: 'NavigateForward' }}
                            onClick={(): void => {
                                const path = navigation.getPathForParams({
                                    tenantCode: currentTenantCode || '',
                                    financialYearCode:
                                        item.team?.division?.financialYear
                                            ?.code || '',
                                    teamCode: item.team?.code || undefined,
                                    missionId: item.id || undefined,
                                });
                                navigate(path);
                            }}
                        />
                    </Stack>
                );
            },
        },
    ];

    function renderItemColumn(
        item?: MissionQl,
        _index?: number,
        column?: IColumn
    ): JSX.Element {
        if (!column || !item) {
            return <span />;
        }
        return (
            <DetailsListCellItemContainer>
                {
                    item[column.fieldName as keyof MissionQl] as
                        | React.ReactNode
                        | string
                }
            </DetailsListCellItemContainer>
        );
    }

    const classNames = mergeStyleSets({
        container: {
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
        },
        filterBar: {
            marginLeft: 8,
            marginRight: 8,
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            gap: 16,
        },
        filterInput: {
            minWidth: 300,
            maxWidth: 300,
        },
    });

    const handleSearchTextChanged = useCallback((keyword?: string | null) => {
        setTextFilter(keyword);
    }, []);

    const handleClearAndDismissClick = () => {
        setSelectedDivisionId(null);
    };
    return (
        <React.Fragment>
            <div className={classNames.container}>
                <FilterBar
                    onSearchTextChanged={handleSearchTextChanged}
                    searchPlaceholder="Filter by keyword"
                    onClearAndDismissClick={handleClearAndDismissClick}
                >
                    <div style={{ minWidth: 250 }}>
                        <DivisionPicker
                            placeholder="Filter by division"
                            selectedDivisionId={selectedDivisionId}
                            onChange={handleSelectedDivisionIdsChange}
                            fyCodeFilter={props.fyCodeFilter}
                        />
                    </div>
                </FilterBar>
                <div>
                    <ShimmeredDetailsList
                        setKey="items"
                        items={sortedItems || []}
                        columns={columns}
                        selectionMode={SelectionMode.none}
                        layoutMode={DetailsListLayoutMode.justified}
                        enableShimmer={loading}
                        ariaLabelForShimmer="Content is being fetched"
                        ariaLabelForGrid="Item details"
                        listProps={{
                            renderedWindowsAhead: 0,
                            renderedWindowsBehind: 0,
                        }}
                        onItemInvoked={(item?: MissionQl): void => {
                            if (item) {
                                props.onItemEdit(item);
                            }
                        }}
                        onRenderItemColumn={renderItemColumn}
                    />
                </div>
            </div>

            <ToggleMissionActiveModal
                tenantId={currentTenantId}
                isOpen={!!isConfirmingActive}
                onDismiss={(): void => setIsConfirmingActive(null)}
                missionId={isConfirmingActive?.missionId || null}
                isToggleActive={isConfirmingActive?.isActive || null}
            />
        </React.Fragment>
    );
}

function ToggleMissionActiveModal(props: {
    tenantId: string | null | undefined;
    isOpen: boolean;
    onDismiss: () => void;
    missionId: string | null;
    isToggleActive: boolean | null;
}) {
    const { getMissionInput } = useInputMappers();

    const { data: missionData, error: loadError } = useGetMissionQuery({
        skip: !props.tenantId || !props.missionId || !props.isOpen,
        fetchPolicy: 'network-only',
        variables: {
            tenantId: props.tenantId || '',
            missionId: props.missionId || '',
        },
    });

    const mission = missionData?.mission;

    const [updateMission, { loading: isSaving, error: saveError }] =
        useUpdateMissionMutation();

    const activeChangeAction = async () => {
        if (mission) {
            const utcInactive = props.isToggleActive
                ? null
                : dayjs().utc().toISOString();

            await updateMission({
                variables: {
                    tenantId: props.tenantId || '',
                    input: {
                        ...getMissionInput(mission),
                        utcInactive: utcInactive,
                    },
                },
            });

            props.onDismiss();
        }
    };

    const message = props.isToggleActive
        ? 'Are you sure you want to activate this mission?'
        : 'Are you sure you want to deactivate this mission? Any users with task resources or linked measures will receive warnings.';

    return (
        <DeleteModal
            {...props}
            primaryButtonText="Confirm"
            message={message}
            deleteAction={activeChangeAction}
            isDeleting={isSaving}
            error={loadError || saveError}
            activeViewName={null}
        />
    );
}
