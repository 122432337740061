import React, {
    CSSProperties,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useParams } from 'react-router';

import dayjs from 'dayjs';

import {
    GetMissionQuery,
    GetMissionTasksQuery,
    useGetMissionQuery,
    useGetMissionTasksQuery,
} from '../../data/types';
import { useStateContext } from '../../services/contextProvider';

import { sorters } from '../../data/sorters';
import { useTaskStatus } from '../../hooks/useTaskStatus';
import { useStrategicDirection } from '../../hooks/useStrategicDirection';

import { StepContainer } from './components/StepContainer';
import {
    ExtractQueryArrayType,
    TaskWithStatus,
} from '../../data/extendedTypes';

import { Icon } from '@fluentui/react';
import { useLanguage } from '../../services/i18n';

import {
    CheckMarkIcon,
    LightningBoltIcon,
    LightningBoltSolidIcon,
    OfflineStorageIcon,
    OfflineStorageSolidIcon,
    RadioBulletIcon,
    Trending12Icon,
} from '@fluentui/react-icons-mdl2';

import { PresentationTitleSlide } from './components/PresentationTitleSlide';
import { PresentationSlide } from './components/PresentationSlide';
import { PresentationBlockquote } from './components/PresentationBlockquote';

import { PresentationUserImage } from './components/PresentationUserImage';
import { usePresentationColours } from './hooks/usePresentationColours';
import Reveal from 'reveal.js';
import { PresentationTooltipHost } from './components/PresentationTooltipHost';

export function MissionAnalysisPresentation(props: {
    isSummary?: boolean;
}): JSX.Element {
    const { currentTenantId } = useStateContext();

    const { missionId } = useParams();

    const handleMessage = () => {
        parent.document.dispatchEvent(new Event('presentation:action'));
    };

    useEffect(() => {
        window.addEventListener('keydown', handleMessage, false);
        window.addEventListener('mousedown', handleMessage, false);
        window.addEventListener('touchstart', handleMessage, false);
    }, []);

    const [revealApi, setRevealApi] = useState<Reveal.Api>();
    const handleInitialized = setRevealApi;

    const handleSectionChanged = useCallback(() => {
        if (revealApi?.sync) {
            revealApi.sync();
        }
    }, [revealApi]);

    const { data: missionData, loading: missionLoading } = useGetMissionQuery({
        skip: !missionId || !currentTenantId,
        variables: {
            tenantId: currentTenantId || '',
            missionId: missionId || '',
        },
        pollInterval: 10000,
    });

    const { data: tasksData, loading: tasksLoading } = useGetMissionTasksQuery({
        skip: !missionId || !currentTenantId,
        variables: {
            tenantId: currentTenantId || '',
            missionId: missionId || '',
        },
        pollInterval: 10000,
    });

    const isReady = !!missionData && !missionLoading && !tasksLoading;

    const getSteps = useCallback((): JSX.Element[] => {
        const mission = missionData?.mission;

        const steps = !props.isSummary
            ? [
                  <PresentationTitleSlide
                      key="title"
                      title="Mission Presentation"
                      mission={mission}
                      onSectionChanged={handleSectionChanged}
                  />,
              ]
            : [];

        if (isReady && mission) {
            steps.push(
                <StrategicDirectionSlide
                    key="strategic-direction"
                    mission={mission}
                />
            );

            if (missionData.measureSummary.length) {
                steps.push(
                    <MeasuresSlide key="measures" missionData={missionData} />
                );
            }

            if (tasksData?.tasks.length) {
                steps.push(
                    <SpecifiedTasksSlide
                        key="specified-tasks"
                        missionData={missionData}
                        tasksData={tasksData}
                        truncateNames={props.isSummary}
                        hideImpliedTasks={!props.isSummary}
                    />
                );
            }

            if (!props.isSummary) {
                if (missionData.tasksSummary.length && tasksData) {
                    const showEffort =
                        tasksData?.tasks?.some((it) => it.effortWeight > 1) ||
                        false;

                    const showCost =
                        tasksData?.tasks?.some((it) => it.costWeight > 1) ||
                        false;

                    const specifiedTasks = tasksData?.tasks
                        ?.slice()
                        ?.filter((t) => t.parentTaskId === null)
                        ?.filter((t) => !t.isDuplicate)
                        ?.sort(sorters.sequenceSorter);

                    (specifiedTasks || []).forEach((st) => {
                        if (st.id) {
                            steps.push(
                                <ImpliedTasksStep
                                    key={`implied-tasks-${st.id}`}
                                    specifiedTaskId={st.id}
                                    tasksData={tasksData}
                                    showCost={showCost}
                                    showEffort={showEffort}
                                />
                            );
                        }
                    });
                }

                if (mission.behaviours.length) {
                    steps.push(
                        <BehavioursSlide
                            key="behaviours"
                            missionData={missionData}
                        />
                    );
                }

                if (mission.freedoms.length || mission.constraints.length) {
                    steps.push(
                        <BoundariesSlide
                            key="boundaries"
                            missionData={missionData}
                        />
                    );
                }

                steps.push(
                    <StrategicDirectionSlide
                        key="strategic-direction-reaffirm"
                        mission={mission}
                        isReaffirm
                    />
                );
            }
        }

        return steps;
    }, [
        handleSectionChanged,
        isReady,
        missionData,
        tasksData,
        props.isSummary,
    ]);

    return (
        <StepContainer
            isReady={isReady}
            steps={getSteps()}
            onInitialized={handleInitialized}
        />
    );
}

function PresentationTaskStatus(props: {
    impliedTask: TaskWithStatus & {
        percentComplete: number | null | undefined;
    };
}): JSX.Element {
    const taskStatus = useTaskStatus(props.impliedTask);
    let percentageText: string | null = null;

    if (
        (props.impliedTask.percentComplete ?? 0) > 0 &&
        (props.impliedTask.percentComplete ?? 0) < 1
    ) {
        const formatter = new Intl.NumberFormat(undefined, {
            style: 'percent',
            maximumFractionDigits: 1,
        });
        const formattedPercentage = formatter.format(
            props.impliedTask.percentComplete ?? 0
        );
        percentageText = ` (${formattedPercentage})`;
    }

    return (
        <span style={{ whiteSpace: 'nowrap' }}>
            {taskStatus?.text} {percentageText}
        </span>
    );
}

function StrategicDirectionSlide(props: {
    isReaffirm?: boolean;
    mission: GetMissionQuery['mission'];
}) {
    const { isReaffirm, mission } = props;

    const { currentTeamCode } = useStateContext();

    const { onLevelUpStatement, twoLevelsUpStatement, vision, purpose } =
        useStrategicDirection(mission, currentTeamCode);

    const key = isReaffirm
        ? 'strategic-direction-reaffirm'
        : 'strategic-direction';

    return (
        <PresentationSlide key={key} sectionKey={key} leftSize="large">
            <PresentationSlide.LeftSection>
                <div
                    style={{
                        textAlign: 'left',
                    }}
                >
                    <div style={{ padding: 16, textAlign: 'center' }}>
                        <PresentationUserImage
                            userId={mission?.userId}
                            style={{
                                width: '10vw',
                            }}
                        />

                        <h3>{mission?.owner}</h3>

                        {isReaffirm && <h5>REAFFIRM</h5>}

                        <h6>{mission?.missionStatement}</h6>
                    </div>
                </div>
            </PresentationSlide.LeftSection>
            <PresentationSlide.RightSection>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '1.2vw',
                        paddingRight: 16,
                    }}
                >
                    {!!purpose && (
                        <div>
                            <h6>Purpose</h6>
                            <PresentationBlockquote>
                                {purpose}
                            </PresentationBlockquote>
                        </div>
                    )}

                    {!!vision && (
                        <div>
                            <h6>Vision</h6>
                            <PresentationBlockquote>
                                {vision}
                            </PresentationBlockquote>
                        </div>
                    )}

                    {!!twoLevelsUpStatement && (
                        <div>
                            <h6>Two Levels Up</h6>
                            <PresentationBlockquote>
                                {twoLevelsUpStatement}
                            </PresentationBlockquote>
                        </div>
                    )}

                    {!!onLevelUpStatement && (
                        <div>
                            <h6>One Level Up</h6>
                            <PresentationBlockquote>
                                {onLevelUpStatement}
                            </PresentationBlockquote>
                        </div>
                    )}
                </div>
            </PresentationSlide.RightSection>
        </PresentationSlide>
    );
}

function MeasuresSlide(props: { missionData: GetMissionQuery }) {
    const { t } = useLanguage();

    const { missionData } = props;

    const measureGroups = useMemo(
        () => missionData?.measureGroups?.slice()?.sort(sorters.sequenceSorter),
        [missionData]
    );

    const measures = useMemo(
        () =>
            missionData?.measureSummary?.slice()?.sort(sorters.sequenceSorter),
        [missionData]
    );

    const measureGroupDisplayList = useMemo(() => {
        const groups: {
            id: string | null;
            name: string | null;
        }[] = (measureGroups || [])
            .filter((mg) => measures?.some((m) => m.measureGroupId === mg.id))
            .map((mg) => ({
                id: mg.id,
                name: mg.name,
            }));

        if (measures?.some((m) => !m.measureGroupId)) {
            groups?.push({
                id: null,
                name: groups.length ? 'Ungrouped' : null,
            });
        }
        return groups;
    }, [measures, measureGroups]);

    return (
        <PresentationSlide sectionKey="measures">
            <PresentationSlide.ListContainer>
                <div className="mintListHeader">
                    <h6>
                        {t('measure-of-success.measure-of-success', {
                            count: measures?.length,
                        })}
                    </h6>
                </div>
                {measures?.length && (
                    <div className="mintListBody" style={{ textAlign: 'left' }}>
                        <div
                            style={{
                                width: '100%',
                            }}
                        >
                            <div>
                                {measureGroupDisplayList?.map((g) => (
                                    <React.Fragment key={g.id}>
                                        {g.name ? (
                                            <strong>{g.name}</strong>
                                        ) : null}
                                        <br />
                                        <ul>
                                            {measures
                                                ?.filter(
                                                    (m) =>
                                                        m.measureGroupId ===
                                                        g.id
                                                )
                                                .map((measure) => (
                                                    <li key={measure?.id}>
                                                        {measure?.name}
                                                    </li>
                                                ))}
                                        </ul>
                                        <br />
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>
                    </div>
                )}
            </PresentationSlide.ListContainer>
        </PresentationSlide>
    );
}

function SpecifiedTaskCategory(props: {
    truncateNames?: boolean;
    specifiedTask: ExtractQueryArrayType<GetMissionTasksQuery, ['tasks']>;
}) {
    const { specifiedTask } = props;

    return (
        <div
            style={{
                fontSize: '0.6em',
                verticalAlign: 'bottom',
                paddingLeft: '0.4vw',
                marginLeft: 14,
                borderLeft: `solid 4px #${specifiedTask.taskCategory?.colourHex || '818182'}`,
            }}
            className={props?.truncateNames ? `line-clamp1` : undefined}
        >
            <PresentationTooltipHost
                content={specifiedTask.taskCategory?.name || undefined}
                hideTooltip={!props.truncateNames}
            >
                {specifiedTask.taskCategory
                    ? specifiedTask.taskCategory.name
                    : 'No Category'}
            </PresentationTooltipHost>
        </div>
    );
}

function SpecifiedTaskSlideNameBox(props: {
    truncateNames?: boolean;
    specifiedTask: ExtractQueryArrayType<GetMissionTasksQuery, ['tasks']>;
}) {
    const { specifiedTask } = props;

    const { highlightColourTransparent, highlightColour } =
        usePresentationColours();

    const classificationTag =
        specifiedTask.tags.find((t) => t.name === 'Main Effort') ||
        specifiedTask.tags.find((t) => t.name === 'Flanking Activity') ||
        specifiedTask.tags.find((t) => t.name === 'Enabling Activity');

    const dotSize = 28;
    const dotSizeStyle = `min(2vw, ${dotSize - 4}px)`;
    const dotIconStyle = `min(1.2vw, ${dotSize - 8}px)`;

    return (
        <div
            style={{
                paddingTop: 8,
                paddingBottom: 8,
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'stretch',
            }}
        >
            <div
                style={{
                    background: `linear-gradient(to right, transparent 0%, transparent 50%, ${highlightColourTransparent} 50%, ${highlightColourTransparent} 100%)`,
                    borderImage: `linear-gradient(to right, transparent 0%, transparent 50%, ${highlightColour} 50%, ${highlightColour} 100%) 9`,
                    // minHeight:
                    //     '10vh',
                    borderColor: 'red',
                    borderWidth: 1,
                    borderStyle: 'solid',
                    borderLeftStyle: 'none',
                    borderRightStyle: 'none',

                    width: dotSize,
                    minWidth: dotSize,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <div
                    style={{
                        width: dotSize,
                        height: dotSize,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <div
                        style={{
                            position: 'relative',
                            width: dotSizeStyle,
                            minWidth: dotSizeStyle,
                            height: dotSizeStyle,
                        }}
                    >
                        <div
                            className="mintLeftLabelDot"
                            style={{
                                position: 'absolute',
                                left: 0,
                                top: 0,
                                width: dotSizeStyle,
                                minWidth: dotSizeStyle,
                                height: dotSizeStyle,
                                borderRadius: dotSizeStyle,
                                backgroundColor: '#fff',
                            }}
                        ></div>

                        <div
                            className="mintLeftLabelDot"
                            style={{
                                position: 'absolute',
                                left: 0,
                                top: 0,
                                width: dotSizeStyle,
                                minWidth: dotSizeStyle,
                                height: dotSizeStyle,
                                borderRadius: dotSizeStyle,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Icon
                                styles={{
                                    root: {
                                        fontSize: dotIconStyle,
                                    },
                                }}
                                iconName={
                                    classificationTag?.icon || 'RadioBullet'
                                }
                                title={classificationTag?.name || undefined}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div
                style={{
                    flexGrow: 1,
                    backgroundColor: highlightColourTransparent,
                    borderTop: `solid 1px ${highlightColour}`,
                    borderBottom: `solid 1px ${highlightColour}`,
                    padding: '0.6vw',
                    fontSize: '0.9em',
                    overflow: 'hidden',
                    lineHeight: '1.4em',
                    alignContent: 'center',
                }}
            >
                <PresentationTooltipHost
                    content={specifiedTask?.name || undefined}
                    hideTooltip={!props.truncateNames}
                >
                    <span
                        className={
                            props?.truncateNames ? `line-clamp3` : undefined
                        }
                    >
                        {specifiedTask?.name}
                    </span>
                </PresentationTooltipHost>
            </div>
        </div>
    );
}

function SpecifiedTaskSlideLinkedMeasures(props: {
    truncateNames?: boolean;
    specifiedTask: ExtractQueryArrayType<GetMissionTasksQuery, ['tasks']>;
    measureSummary: GetMissionQuery['measureSummary'];
}) {
    const { specifiedTask, measureSummary } = props;

    const linkedMeasuresToDisplay = measureSummary
        ?.slice()
        ?.filter((m) =>
            specifiedTask.linkedMeasures.some((lm) => lm.measureId === m.id)
        )
        .sort(sorters.sequenceSorter)
        .slice(0, 3);

    if (!linkedMeasuresToDisplay.length) {
        return <div />;
    }

    return (
        <div
            style={{
                paddingLeft: 24,
            }}
        >
            <ul
                className="iconList"
                style={{
                    fontSize: '0.7em',
                }}
            >
                {linkedMeasuresToDisplay?.map((m) => (
                    <li className="listItem" key={m?.id}>
                        <Trending12Icon className="icon" />
                        <PresentationTooltipHost
                            content={m?.name || undefined}
                            hideTooltip={!props.truncateNames}
                        >
                            <span
                                className={
                                    props?.truncateNames
                                        ? `line-clamp1`
                                        : undefined
                                }
                            >
                                {m?.shortName ? m?.shortName : m?.name}
                            </span>
                        </PresentationTooltipHost>
                    </li>
                ))}
            </ul>
        </div>
    );
}

function SpecifiedTaskSlideImpliedTasks(props: {
    truncateNames?: boolean;
    specifiedTask: ExtractQueryArrayType<GetMissionTasksQuery, ['tasks']>;
    impliedTasks: GetMissionTasksQuery['tasks'];
}) {
    const { specifiedTask, impliedTasks } = props;

    const { highlightColourTransparent } = usePresentationColours();

    const impliedTasksToDisplay = impliedTasks.filter(
        (it) => it.parentTaskId === specifiedTask.id
    );

    return (
        <div
            style={{
                marginTop: '0.4vw',
                marginLeft: 14,
                background: `${highlightColourTransparent} 50%`,
                flexGrow: 1,
            }}
        >
            <ul className="iconList">
                {impliedTasksToDisplay
                    ?.filter((it) => it.parentTaskId === specifiedTask.id)
                    ?.map((it) => (
                        <li
                            className="listItem"
                            key={it?.id}
                            style={{
                                fontSize: '0.7em',
                                padding: '0.4vw',
                                lineHeight: '1.4em',
                            }}
                        >
                            {it.done ? (
                                <CheckMarkIcon className="icon" />
                            ) : (
                                <RadioBulletIcon className="icon" />
                            )}
                            <PresentationTooltipHost
                                content={it?.name || undefined}
                                hideTooltip={!props.truncateNames}
                            >
                                <span
                                    className={
                                        props?.truncateNames
                                            ? `line-clamp3`
                                            : undefined
                                    }
                                    style={{
                                        minHeight: 'auto',
                                    }}
                                >
                                    {it?.name}
                                </span>
                            </PresentationTooltipHost>
                        </li>
                    ))}
            </ul>
        </div>
    );
}

function chunkArray<T>(arr: T[], size: number): T[][] {
    return arr.reduce((acc: T[][], _, index) => {
        if (index % size === 0) {
            acc.push(arr.slice(index, index + size));
        }
        return acc;
    }, []);
}

function SpecifiedTasksSlide(props: {
    missionData: GetMissionQuery;
    tasksData: GetMissionTasksQuery;
    hideImpliedTasks?: boolean;
    truncateNames?: boolean;
}) {
    const { missionData, tasksData } = props;

    const { mission } = missionData;

    const specifiedTasks = useMemo(
        () =>
            tasksData?.tasks
                ?.slice()
                ?.filter((t) => t.parentTaskId === null)
                ?.filter((t) => !t.isDuplicate)
                ?.sort(sorters.sequenceSorter) || [],
        [tasksData]
    );

    const impliedTasks =
        tasksData?.tasks
            ?.slice()
            ?.filter((t) => t.parentTaskId !== null)
            ?.filter((t) => !t.isDuplicate)
            ?.sort(sorters.sequenceSorter) || [];

    const itemsPerRow =
        (specifiedTasks?.length || 0) > 1
            ? Math.min(specifiedTasks?.length || 0, 5)
            : specifiedTasks?.length || 1;

    const chunkedSpecifiedTasks = chunkArray(specifiedTasks, itemsPerRow);

    const rowStyle: CSSProperties = {
        display: 'grid',
        gridTemplateColumns: `repeat(${itemsPerRow}, 1fr)`,
        alignItems: 'stretch',
    };

    const itemStyle: CSSProperties = {
        textAlign: 'left',
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box',
        alignContent: 'stretch',
    };

    return (
        <PresentationSlide
            key="specified-tasks"
            sectionKey="specified-tasks"
            leftSize="none"
        >
            <PresentationSlide.ContentContainer>
                {!!mission?.missionStatement && (
                    <div
                        style={{
                            padding: '2vh',
                            paddingTop: 28,
                        }}
                    >
                        <PresentationBlockquote hideIndicator>
                            MISSION: {mission?.missionStatement}
                        </PresentationBlockquote>
                    </div>
                )}

                <div
                    style={{
                        paddingLeft: '2vh',
                        paddingRight: '2vh',
                    }}
                >
                    <div
                        style={{
                            overflowY: 'auto',
                            overflowX: 'hidden',
                            height: 'calc(82vh - 64px)',
                            paddingRight: 16,
                        }}
                    >
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '4vh',
                            }}
                        >
                            {chunkedSpecifiedTasks.map(
                                (specifiedTasks, stIndex) => {
                                    return (
                                        <div key={stIndex}>
                                            <div style={rowStyle}>
                                                {specifiedTasks.map(
                                                    (specifiedTask) => {
                                                        return (
                                                            <div
                                                                key={
                                                                    specifiedTask?.id
                                                                }
                                                                style={{
                                                                    ...itemStyle,
                                                                    justifyContent:
                                                                        'end',
                                                                }}
                                                            >
                                                                <SpecifiedTaskCategory
                                                                    {...props}
                                                                    specifiedTask={
                                                                        specifiedTask
                                                                    }
                                                                />
                                                            </div>
                                                        );
                                                    }
                                                )}
                                            </div>

                                            <div style={rowStyle}>
                                                {specifiedTasks.map(
                                                    (specifiedTask) => {
                                                        return (
                                                            <div
                                                                key={
                                                                    specifiedTask?.id
                                                                }
                                                                style={
                                                                    itemStyle
                                                                }
                                                            >
                                                                <SpecifiedTaskSlideNameBox
                                                                    {...props}
                                                                    specifiedTask={
                                                                        specifiedTask
                                                                    }
                                                                />
                                                            </div>
                                                        );
                                                    }
                                                )}
                                            </div>
                                            <div style={rowStyle}>
                                                {specifiedTasks.map(
                                                    (specifiedTask) => {
                                                        return (
                                                            <div
                                                                key={
                                                                    specifiedTask?.id
                                                                }
                                                                style={
                                                                    itemStyle
                                                                }
                                                            >
                                                                <SpecifiedTaskSlideLinkedMeasures
                                                                    {...props}
                                                                    specifiedTask={
                                                                        specifiedTask
                                                                    }
                                                                    measureSummary={
                                                                        missionData.measureSummary
                                                                    }
                                                                />
                                                            </div>
                                                        );
                                                    }
                                                )}
                                            </div>
                                            <div style={rowStyle}>
                                                {!props?.hideImpliedTasks &&
                                                    !!impliedTasks?.length &&
                                                    specifiedTasks.map(
                                                        (specifiedTask) => {
                                                            return (
                                                                <div
                                                                    key={
                                                                        specifiedTask.id
                                                                    }
                                                                    style={
                                                                        itemStyle
                                                                    }
                                                                >
                                                                    <SpecifiedTaskSlideImpliedTasks
                                                                        {...props}
                                                                        specifiedTask={
                                                                            specifiedTask
                                                                        }
                                                                        impliedTasks={
                                                                            impliedTasks
                                                                        }
                                                                    />
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                            </div>
                                        </div>
                                    );
                                }
                            )}
                        </div>
                    </div>
                </div>
            </PresentationSlide.ContentContainer>
        </PresentationSlide>
    );
}

function ImpliedTasksStep(props: {
    specifiedTaskId: string;
    showEffort: boolean;
    showCost: boolean;
    tasksData: GetMissionTasksQuery;
}): JSX.Element {
    const { specifiedTaskId, tasksData } = props;

    const areRatingsCombined = props.showEffort && props.showCost;

    const specifiedTask = tasksData?.tasks.find(
        (st) => st.id === specifiedTaskId
    );

    const impliedTasks = tasksData?.tasks
        ?.slice()
        ?.filter((t) => !t.isDuplicate)
        ?.filter((it) => it?.parentTaskId === specifiedTaskId)
        ?.sort(sorters.sequenceSorter);

    return (
        <PresentationSlide sectionKey={`task-${specifiedTaskId}`}>
            <PresentationSlide.ListContainer>
                <div className="mintListHeader">
                    <h6>{specifiedTask?.name}</h6>
                </div>
                <div className="mintListBody">
                    <table>
                        <thead>
                            <tr>
                                <th>Implied Task</th>
                                <th>Resources</th>
                                {(props.showEffort || props.showCost) && (
                                    <th>
                                        {areRatingsCombined
                                            ? 'Ratings'
                                            : props.showEffort
                                              ? 'Effort'
                                              : 'Cost'}
                                    </th>
                                )}

                                <th>Start</th>
                                <th>Due</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(impliedTasks || []).map((it) => (
                                <tr key={it?.id}>
                                    <td>{it?.name}</td>
                                    <td>
                                        {it.resourcedTasks?.length === 0 && (
                                            <span>-</span>
                                        )}
                                        {(it.resourcedTasks?.slice() || [])
                                            .sort((r1, r2) =>
                                                r1.resourceIsPrimary ===
                                                r2.resourceIsPrimary
                                                    ? 0
                                                    : r1.resourceIsPrimary
                                                      ? -1
                                                      : 1
                                            )
                                            .map((rt, rtIndex) => [
                                                rtIndex > 0 && '\n',
                                                <div
                                                    key={rtIndex}
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        fontWeight:
                                                            rt.resourceIsPrimary
                                                                ? 'bold'
                                                                : undefined,
                                                    }}
                                                >
                                                    {rt.resource?.displayName}
                                                </div>,
                                            ])}
                                    </td>

                                    {(props.showEffort || props.showCost) && (
                                        <td
                                            style={{
                                                whiteSpace: 'nowrap',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    gap: 16,
                                                }}
                                            >
                                                {props.showEffort && (
                                                    <div>
                                                        {areRatingsCombined && (
                                                            <div
                                                                style={{
                                                                    fontSize:
                                                                        '0.6em',
                                                                    textTransform:
                                                                        'uppercase',
                                                                }}
                                                            >
                                                                Effort
                                                            </div>
                                                        )}
                                                        {[
                                                            ...Array(
                                                                it.effortWeight
                                                            ),
                                                        ].map((_ew, index) => (
                                                            <LightningBoltSolidIcon
                                                                key={index}
                                                            />
                                                        ))}

                                                        {[
                                                            ...Array(
                                                                5 -
                                                                    it.effortWeight
                                                            ),
                                                        ].map((_ew, index) => (
                                                            <LightningBoltIcon
                                                                key={index}
                                                                style={{
                                                                    color: '#d0d0d0',
                                                                }}
                                                            />
                                                        ))}
                                                    </div>
                                                )}

                                                {props.showCost && (
                                                    <div>
                                                        {areRatingsCombined && (
                                                            <div
                                                                style={{
                                                                    fontSize:
                                                                        '0.6em',
                                                                    textTransform:
                                                                        'uppercase',
                                                                }}
                                                            >
                                                                Cost
                                                            </div>
                                                        )}
                                                        {[
                                                            ...Array(
                                                                it.costWeight
                                                            ),
                                                        ].map((_ew, index) => (
                                                            <OfflineStorageSolidIcon
                                                                key={index}
                                                            />
                                                        ))}

                                                        {[
                                                            ...Array(
                                                                5 -
                                                                    it.costWeight
                                                            ),
                                                        ].map((_ew, index) => (
                                                            <OfflineStorageIcon
                                                                key={index}
                                                                style={{
                                                                    color: '#d0d0d0',
                                                                }}
                                                            />
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        </td>
                                    )}

                                    <td
                                        style={{
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        {it?.start
                                            ? dayjs
                                                  .utc(it?.start)
                                                  .format('DD MMM YYYY')
                                            : '-'}
                                    </td>
                                    <td
                                        style={{
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        {it?.due
                                            ? dayjs
                                                  .utc(it?.due)
                                                  .format('DD MMM YYYY')
                                            : '-'}
                                    </td>
                                    <td>
                                        <PresentationTaskStatus
                                            impliedTask={it}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </PresentationSlide.ListContainer>
        </PresentationSlide>
    );
}

function BehavioursSlide(props: { missionData: GetMissionQuery }) {
    const {
        missionData: { mission },
    } = props;

    const { t } = useLanguage();

    return (
        <PresentationSlide
            key="behaviours"
            sectionKey="behaviours"
            leftSize="large"
        >
            <PresentationSlide.LeftSection>
                <div style={{ padding: 16 }}>
                    <h3>{mission?.owner}</h3>
                    <h6>{t('mission.behaviour', { count: 10 })}</h6>
                </div>
            </PresentationSlide.LeftSection>
            <PresentationSlide.RightSection>
                <div style={{ paddingRight: 16 }}>
                    <PresentationBlockquote>
                        <ul>
                            {mission?.behaviours
                                .slice()
                                .sort(sorters.sequenceSorter)
                                .map((b) => (
                                    <li
                                        key={b?.id}
                                        style={{ whiteSpace: 'pre-line' }}
                                    >
                                        {b?.text}
                                    </li>
                                ))}
                        </ul>
                    </PresentationBlockquote>
                </div>
            </PresentationSlide.RightSection>
        </PresentationSlide>
    );
}

function BoundariesSlide(props: { missionData: GetMissionQuery }) {
    const {
        missionData: { mission },
    } = props;

    const { t } = useLanguage();

    return (
        <PresentationSlide
            key="boundaries"
            sectionKey="boundaries"
            leftSize="large"
        >
            <PresentationSlide.LeftSection>
                <div style={{ padding: 16 }}>
                    <h3>{mission?.owner}</h3>
                    <h6>{t('mission.boundaries')}</h6>
                </div>
            </PresentationSlide.LeftSection>
            <PresentationSlide.RightSection>
                <div style={{ paddingRight: 16 }}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 48,
                        }}
                    >
                        <div>
                            <h6>
                                {t('mission.freedom', {
                                    count: mission?.freedoms?.length,
                                })}
                            </h6>
                            <PresentationBlockquote>
                                <ul>
                                    {mission?.freedoms
                                        .slice()
                                        .sort(sorters.sequenceSorter)
                                        .map((b) => (
                                            <li
                                                key={b?.id}
                                                style={{
                                                    whiteSpace: 'pre-line',
                                                }}
                                            >
                                                {b?.text}
                                            </li>
                                        ))}
                                </ul>
                            </PresentationBlockquote>
                        </div>
                        <div>
                            <h6>
                                {t('mission.constraint', {
                                    count: mission?.constraints?.length,
                                })}
                            </h6>
                            <PresentationBlockquote>
                                <ul>
                                    {mission?.constraints
                                        .slice()
                                        .sort(sorters.sequenceSorter)
                                        .map((b) => (
                                            <li
                                                key={b?.id}
                                                style={{
                                                    whiteSpace: 'pre-line',
                                                }}
                                            >
                                                {b?.text}
                                            </li>
                                        ))}
                                </ul>
                            </PresentationBlockquote>
                        </div>
                    </div>
                </div>
            </PresentationSlide.RightSection>
        </PresentationSlide>
    );
}
