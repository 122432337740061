import React, { useState } from 'react';
import {
    IColumn,
    IconButton,
    MessageBar,
    MessageBarType,
    ShimmeredDetailsList,
    Stack,
    TextField,
    Text,
} from '@fluentui/react';
import { DetailsListCellItemContainer } from '../../../components/shared/DetailsListCellItemContainer';
import { useClipboard } from 'use-clipboard-copy';
import { useNavigate } from 'react-router';
import { useStateContext } from '../../../services/contextProvider';
import { navigation } from '../../../services/navigation';
import { Variable } from './formulaUtils';

export type RowData = {
    variableName: string;
    measureId: string | null;
    measureName: string | null;
    missionOwner: string | null | undefined;
    missionId: string | null | undefined;
    frequencyPeriod: string | null | undefined;
};

type VariableListProps = {
    measures: {
        __typename: 'MeasureQL';
        id: string | null;
        name: string | null;
        frequencyPeriod: string | null;
        mission: {
            __typename: 'MissionQL';
            id: string | null;
            title: string | null;
            owner: string | null;
        } | null;
    }[];
    variables: Variable[];
    loading?: boolean;
    shimmerLines?: number;
    addVariableDeclaration?: (item?: RowData) => void;
    handleVariableRename?: (oldName: string, newName: string) => void;
};

function VariableList(props: VariableListProps): JSX.Element {
    const { currentTenantCode, currentFinancialYearCode, currentTeamCode } =
        useStateContext();

    const clipboard = useClipboard({
        copiedTimeout: 3000, // show copy message in milliseconds
    });

    const navigate = useNavigate();

    const [currentItem, setCurrentItem] = useState<RowData>();
    const [edittingError, setEdittingError] = useState<string>();

    const sortedItems: RowData[] =
        props.measures?.map((m) => {
            return {
                variableName: '',
                measureId: m.id,
                measureName: m.name,
                missionId: m.mission?.id,
                missionOwner: m.mission?.owner,
                frequencyPeriod: m.frequencyPeriod,
            };
        }) ?? [];

    for (const argument of sortedItems) {
        const measureId = argument.measureId;

        const variable = props.variables.find(
            (v) => v.value.measureId === measureId
        );

        if (variable) {
            argument.variableName = variable.name ?? '';
        }
    }

    const handleOnItemEdit = (item: RowData) => {
        setEdittingError(undefined);
        setCurrentItem(item);
    };

    const handleVariableNameChange = (
        _event: React.FormEvent,
        newValue?: string | undefined
    ) => {
        if (currentItem) {
            if (!newValue) {
                // Allow deleting variable name
                setEdittingError(undefined);
            } else if (!newValue?.startsWith('@')) {
                setEdittingError(`${newValue} Must start with "@"`);
                // setEdittingError('Must start with "@"');
            } else if (newValue?.indexOf(' ') > 0) {
                setEdittingError('No spaces are allowed');
            } else {
                setEdittingError(undefined);
            }

            const existingVars = props.variables.filter(
                (v) => v.name == newValue
            );
            if (existingVars.length > 0) {
                setEdittingError('This variable name is already taken');
            }

            setCurrentItem({ ...currentItem, variableName: newValue || '' });
        }
    };

    const handleOnItemSave = () => {
        if (currentItem) {
            const existingVariableForMeasureId = props.variables.filter(
                (v) => v.value.measureId === currentItem.measureId
            );
            if (existingVariableForMeasureId.length === 0) {
                if (props.addVariableDeclaration && !edittingError) {
                    props.addVariableDeclaration(currentItem);
                }
            } else {
                existingVariableForMeasureId.forEach((v) => {
                    if (props.handleVariableRename) {
                        props.handleVariableRename(
                            v.name || '',
                            currentItem.variableName
                        );
                    }
                });
            }
        }
        setCurrentItem(undefined);
    };

    const handleOnItemCancel = () => {
        setCurrentItem(undefined);
    };

    const columns: IColumn[] = [
        {
            key: 'measureId',
            fieldName: 'measureId',
            name: 'Measure Id',
            minWidth: 100,
            maxWidth: 300,
            onRender: function renderActions(item: RowData): JSX.Element {
                return (
                    <DetailsListCellItemContainer>
                        {item.measureId}
                        <IconButton
                            iconProps={{ iconName: 'ClipboardSolid' }}
                            title={'Copy Measure Id'}
                            onClick={(): void => {
                                clipboard.copy(`'${item.measureId}'`);
                            }}
                        />
                    </DetailsListCellItemContainer>
                );
            },
        },
        {
            key: 'measureName',
            fieldName: 'measureName',
            name: 'Measure Name',
            minWidth: 100,
            maxWidth: 300,
        },
        {
            key: 'measureNavigate',
            name: '',
            isIconOnly: true,
            isPadded: false,
            className: 'iconCell',
            minWidth: 64,
            maxWidth: 64,
            onRender: function renderActions(item: RowData): JSX.Element {
                return (
                    <Stack horizontal>
                        <IconButton
                            iconProps={{ iconName: 'NavigateForward' }}
                            onClick={(): void => {
                                const path = navigation.getPathForParams({
                                    tenantCode: currentTenantCode || '',
                                    financialYearCode:
                                        currentFinancialYearCode || '',
                                    teamCode: currentTeamCode || undefined,
                                    missionId: item.missionId || undefined,
                                    measureId: item.measureId || undefined,
                                    formulaEdit: false,
                                });
                                navigate(path);
                            }}
                        />
                    </Stack>
                );
            },
        },
        {
            key: 'owner',
            fieldName: 'missionOwner',
            name: 'Mission Owner',
            minWidth: 100,
            maxWidth: 300,
        },
        {
            key: 'updateFrequency',
            fieldName: 'frequencyPeriod',
            name: 'Update Frequency',
            minWidth: 100,
            maxWidth: 300,
        },
        {
            key: 'variableName',
            fieldName: 'variableName',
            name: 'Variable Name',
            minWidth: 100,
            maxWidth: 300,
            onRender: function renderActions(item: RowData): JSX.Element {
                return currentItem?.measureId === item.measureId ? (
                    <DetailsListCellItemContainer>
                        <TextField
                            placeholder="@VariableName"
                            value={currentItem.variableName}
                            onChange={handleVariableNameChange}
                        />
                        {edittingError && (
                            <MessageBar messageBarType={MessageBarType.error}>
                                <Text>{edittingError}</Text>
                            </MessageBar>
                        )}
                    </DetailsListCellItemContainer>
                ) : (
                    <DetailsListCellItemContainer>
                        {item.variableName && (
                            <>
                                {item.variableName}
                                <IconButton
                                    iconProps={{ iconName: 'ClipboardSolid' }}
                                    title={'Copy Variable Name'}
                                    onClick={(): void => {
                                        clipboard.copy(item.variableName);
                                    }}
                                />
                            </>
                        )}
                    </DetailsListCellItemContainer>
                );
            },
        },
        {
            key: 'edit',
            name: 'Edit',
            isIconOnly: true,
            minWidth: 64,
            maxWidth: 64,
            isPadded: false,
            className: 'iconCell',
            onRender: function renderActions(item: RowData): JSX.Element {
                return currentItem?.measureId === item.measureId ? (
                    <>
                        <IconButton
                            key={`save-${item.measureId}`}
                            iconProps={{ iconName: 'Save' }}
                            onClick={(): void => handleOnItemSave()}
                        />
                        <IconButton
                            key={`cancel-${item.measureId}`}
                            iconProps={{ iconName: 'Cancel' }}
                            onClick={(): void => handleOnItemCancel()}
                        />
                    </>
                ) : (
                    <IconButton
                        key={item.measureId}
                        iconProps={{ iconName: 'Edit' }}
                        onClick={(): void => handleOnItemEdit(item)}
                    />
                );
            },
        },
    ];

    return (
        <>
            <ShimmeredDetailsList
                // onRenderItemColumn={props.onRenderItemColumn}
                // onRenderField={handleRenderField}
                // onRenderDetailsHeader={handleRenderDetailsHeader}
                // onRenderRow={props.onRenderRow}
                columns={columns}
                shimmerLines={props.shimmerLines}
                enableShimmer={props.loading}
                items={sortedItems}
                // layoutMode={DetailsListLayoutMode.justified}
                // selectionMode={SelectionMode.none}
                // constrainMode={ConstrainMode.unconstrained}
                // selection={selection}
                // compact={true}
            />
        </>
    );
}

export default VariableList;
