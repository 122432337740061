import React from 'react';
import { Text } from '@fluentui/react';
import {
    DonutChart,
    IChartDataPoint,
    IChartProps,
} from '@fluentui/react-charting';
import { useTaskSummary } from '../hooks/useTaskSummary';
import { TaskWithStatus } from '../data/extendedTypes';

function MeasureLinkedTasksDonut(props: {
    impliedTasks: TaskWithStatus[];
}): JSX.Element {
    const { impliedTasks } = props;

    const taskSummary = useTaskSummary(impliedTasks);

    const chartData: IChartDataPoint[] = taskSummary
        .filter((ts) => ts.count)
        .map((ts) => {
            return {
                legend: ts.name,
                data: ts.count,
                color: ts.colour,
            };
        });

    const data: IChartProps = {
        chartTitle: 'Linked Tasks',
        chartData: chartData,
    };

    if (!data.chartData?.length) {
        return (
            <div
                style={{
                    width: '100%',
                    height: 210,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Text
                    variant="small"
                    styles={{ root: { fontStyle: 'italic' } }}
                    block
                >
                    No linked tasks selected
                </Text>
            </div>
        );
    }

    return (
        <div>
            <DonutChart
                data={data}
                innerRadius={40}
                height={210}
                hideLegend
                // legendProps={{
                //     styles: {
                //         root: {
                //             paddingTop: 30,
                //         },
                //     },
                //     allowFocusOnLegends: true,
                // }}
            />
            <Text
                variant="medium"
                styles={{
                    root: {
                        textAlign: 'center',
                        paddingTop: 16,
                        fontWeight: 600,
                    },
                }}
                block
            >
                Implied Task Status
            </Text>
        </div>
    );
}

export default React.memo(MeasureLinkedTasksDonut);
