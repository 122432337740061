// markdownItFluentEmoji.ts

import MarkdownIt from 'markdown-it';
import Token from 'markdown-it/lib/token.mjs';
import { defaultTheme } from '../../hooks/useThemes';

const EMOJI_REGEX = /✅|⚠️|⭐/g;

/**
 * Replaces certain emojis with Fluent UI Icon HTML.
 */
export function ReplaceEmojisPlugin(md: MarkdownIt): void {
    md.core.ruler.push('fluent_emoji_replace', (state) => {
        // Iterate over all tokens
        for (const blockToken of state.tokens) {
            // We only care about inline tokens (where text/emoji might appear)
            if (blockToken.type !== 'inline' || !blockToken.children) {
                continue;
            }

            const inlineTokens = blockToken.children;
            for (let i = 0; i < inlineTokens.length; i++) {
                const token = inlineTokens[i as number];

                // We only want to transform text tokens
                if (token.type === 'text' && token.content.match(EMOJI_REGEX)) {
                    // Split this text token into multiple tokens (text or html_inline)
                    const newTokens: Token[] = [];

                    // Use a regex to walk through all parts of the string
                    let lastIndex = 0;
                    const textContent = token.content;
                    textContent.replace(EMOJI_REGEX, (match, offset) => {
                        // Push any text before this emoji as a text token
                        if (offset > lastIndex) {
                            const textToken = new Token('text', '', 0);
                            textToken.content = textContent.slice(
                                lastIndex,
                                offset
                            );
                            newTokens.push(textToken);
                        }

                        // Push the replaced HTML token
                        const htmlToken = new Token('html_inline', '', 0);
                        htmlToken.content = getFluentIconHTML(match);
                        newTokens.push(htmlToken);

                        lastIndex = offset + match.length;
                        return match; // required by `replace`, but not used
                    });

                    // If there's text remaining after the last match, push it
                    if (lastIndex < textContent.length) {
                        const textToken = new Token('text', '', 0);
                        textToken.content = textContent.slice(lastIndex);
                        newTokens.push(textToken);
                    }

                    // Replace the original token with the new sequence
                    inlineTokens.splice(i, 1, ...newTokens);

                    // Adjust index so we skip over newly added tokens
                    // (newTokens.length - 1) because we removed 1 token and added newTokens.length
                    i += newTokens.length - 1;
                }
            }
        }
    });
}

function getFluentIconHTML(emoji: string): string {
    switch (emoji) {
        case '✅':
            return `<i data-icon-name="Completed" aria-hidden="true" style="font-family: 'FabricMDL2Icons-2'; font-style: normal; color: ${defaultTheme.semanticColors.successIcon};"></i>`;
        case '⚠️':
            return `<i data-icon-name="Warning" aria-hidden="true" style="font-family: 'FabricMDL2Icons-1'; font-style: normal; color: ${defaultTheme.semanticColors.severeWarningIcon};"></i>`;
        case '⭐':
            return `<i data-icon-name="FavoriteStarFill" aria-hidden="true" style="font-family: 'FabricMDL2Icons'; font-style: normal; color: ${defaultTheme.palette.yellowDark};"></i>`;
        default:
            return emoji;
    }
}
