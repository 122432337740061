import React, { Component, Fragment, PropsWithChildren } from 'react';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ai, getAppInsights } from '../services/telemetry.service';
import { ITelemetryItem } from '@microsoft/applicationinsights-web';
import Context from '../services/context';
import { StorageThemeKey, ThemeNames } from '../hooks/useThemes';
import { useCookieConsent } from '../hooks/useCookieConsent';

type TelemetryProviderProps = PropsWithChildren<{
    disableCookiesUsage: boolean;
}>;

/**
 * This Component provides telemetry with Azure App Insights
 *
 * NOTE: the package '@microsoft/applicationinsights-react-js' has a HOC withAITracking that requires this to be a Class Component rather than a Functional Component
 */
class TelemetryProvider extends Component<TelemetryProviderProps> {
    state = {
        initialized: false,
    };

    static contextType = Context;
    declare context: React.ContextType<typeof Context>;

    componentDidMount(): void {
        const { initialized } = this.state;

        const appInsightsConnectionString =
            this.context?.configuration.appInsightsConnectionString;

        if (
            !initialized &&
            appInsightsConnectionString &&
            Boolean(appInsightsConnectionString) &&
            Boolean(history)
        ) {
            ai.initialize(
                appInsightsConnectionString,
                this.props.disableCookiesUsage
            );
            this.setState({ initialized: true });
        }

        const appInsights = getAppInsights();

        if (appInsights) {
            const telemetryInitializer = (envelope: ITelemetryItem): void => {
                const telemetryItem = envelope.baseData;

                const themeName =
                    localStorage.getItem(StorageThemeKey) || ThemeNames.default;

                const userEmail = this.context?.currentUserEmail;
                const userDisplayName = this.context?.currentUserDisplayName;
                const tenantCode = this.context?.currentTenantCode;
                const financialYearCode =
                    this.context?.currentFinancialYearCode;

                if (telemetryItem) {
                    telemetryItem.properties = telemetryItem.properties || {};

                    telemetryItem.properties['theme'] = themeName;

                    if (userEmail) {
                        telemetryItem.properties['userEmail'] = userEmail;
                    }
                    if (userDisplayName) {
                        telemetryItem.properties['userDisplayName'] =
                            userDisplayName;
                    }
                    if (tenantCode) {
                        telemetryItem.properties['tenantCode'] = tenantCode;
                    }
                    if (financialYearCode) {
                        telemetryItem.properties['financialYearCode'] =
                            financialYearCode;
                    }
                }
            };

            appInsights.addTelemetryInitializer(telemetryInitializer);

            const userId = this.context?.currentUserId;

            if (userId) {
                appInsights.setAuthenticatedUserContext(userId);
            } else {
                appInsights.clearAuthenticatedUserContext();
            }
        }
    }

    render(): React.ReactNode {
        const { children } = this.props;
        return <Fragment>{children}</Fragment>;
    }
}

const TelemetryProviderHoC = withAITracking(
    ai.reactPlugin,
    TelemetryProvider,
    'TelemetryProvider',
    'telemetryContainer'
);

export default function WrappedTelemetryProvider(
    props: PropsWithChildren
): JSX.Element {
    const { hasAccepted } = useCookieConsent();

    const disablePerformanceAnalyticsCookiesUsage = !hasAccepted(
        'PERFORMANCE_AND_ANALYTICS'
    );

    return (
        <TelemetryProviderHoC
            {...props}
            disableCookiesUsage={disablePerformanceAnalyticsCookiesUsage}
        />
    );
}
