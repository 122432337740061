import MarkdownIt from 'markdown-it';
import StateInline from 'markdown-it/lib/rules_inline/state_inline.mjs';

export function BoldUserMentionsPlugin(md: MarkdownIt) {
    const mentionRegex = /^@\[(.*?)\]\((.*?)\)/;

    function boldMentions(state: StateInline, silent: boolean): boolean {
        const start = state.pos;
        const src = state.src;
        const maxPos = state.posMax;

        // Must start with '@['
        if (src.charCodeAt(start) !== 0x40 /* '@' */) {
            return false;
        }
        if (
            start + 1 >= maxPos ||
            src.charCodeAt(start + 1) !== 0x5b /* '[' */
        ) {
            return false;
        }

        // Attempt to match the full pattern: @[...](...)
        const match = mentionRegex.exec(src.slice(start));
        if (!match) {
            return false;
        }

        // match[1] is the display name, match[2] is the user ID (unused here)
        const displayName = match[1];

        // If we are not in silent mode, create tokens for bold text
        if (!silent) {
            // Push opening <strong> token
            let token = state.push('strong_open', 'strong', 1);
            token.markup = '**';

            // Push the text token (the display name only)
            token = state.push('text', '', 0);
            token.content = displayName; // e.g., "John Doe"

            // Push closing </strong> token
            token = state.push('strong_close', 'strong', -1);
            token.markup = '**';
        }

        // Move the parser position past the entire matched mention
        state.pos += match[0].length;

        // We handled it, so return true
        return true;
    }

    // Insert our rule before the built-in link rule
    md.inline.ruler.before('link', 'bold_user_mentions', boldMentions);
}
