import {
    ContextualMenu,
    PersonaCoin,
    PersonaSize,
    Target,
} from '@fluentui/react';
import { forwardRef, useState, useImperativeHandle } from 'react';
import { photoService } from '../../services/photo.service';
import { SuggestionKeyDownProps, SuggestionProps } from '@tiptap/suggestion';

export type MarkdownEditorMentionUser = {
    id: string | null;
    displayName: string | null;
};

export type MarkdownEditorMentionListProps =
    SuggestionProps<MarkdownEditorMentionUser> & {
        target: Target | undefined;
    };
export type MarkdownEditorMentionListRef = {
    onKeyDown: (props: SuggestionKeyDownProps) => void;
};

export const MarkdownEditorMentionList = forwardRef(
    (props: MarkdownEditorMentionListProps, ref) => {
        const [shouldFocusOnMount, setShouldFocusOnMount] = useState(false);

        const selectItem = (index: number) => {
            const item = props.items[index as number];

            if (item) {
                props.command(item);
            }
        };

        useImperativeHandle(ref, () => ({
            onKeyDown: ({ event }: { event: KeyboardEvent }) => {
                if (event.key === 'ArrowDown') {
                    setShouldFocusOnMount(true);
                    return true;
                }
                if (event.key === 'Enter') {
                    if (props.items.length) {
                        selectItem(0);
                        return true;
                    }
                }
            },
        }));

        return (
            <>
                <ContextualMenu
                    items={
                        props.items.map((u) => ({
                            key: u.id || '',
                            text: u.displayName || '',
                            iconProps: {},
                            onRenderIcon: () => (
                                <PersonaCoin
                                    imageUrl={photoService.getImageUrl(u.id)}
                                    text={u.displayName || u.id || ''}
                                    size={PersonaSize.size24}
                                />
                            ),
                        })) || []
                    }
                    onDismiss={() => {
                        setShouldFocusOnMount(false);
                    }}
                    hidden={!props.target}
                    target={props.target}
                    onItemClick={(_ev, item) => {
                        const index = props.items.findIndex(
                            (u) => u.id === item?.key
                        );
                        selectItem(index);
                    }}
                    shouldFocusOnMount={shouldFocusOnMount}
                />
            </>
        );
    }
);

MarkdownEditorMentionList.displayName = 'MentionList';
