import React, { useMemo } from 'react';
import {
    IPersonaProps,
    ValidationState,
    Label,
    PersonaPresence,
    CompactPeoplePicker,
} from '@fluentui/react';
import {
    GetUsersSearchQuery,
    GetUsersSearchQueryVariables,
    GetUsersSearchDocument,
} from '../data/types';
import { useStateContext } from '../services/contextProvider';
import { useApolloClient } from '@apollo/client';
import { photoService } from '../services/photo.service';

export type UserPickerProps = {
    defaultSelectedUsers?: {
        userId: string | null | undefined;
        displayName: string | null | undefined;
        presence?: PersonaPresence;
    }[];
    selectedUsers?: {
        userId: string | null | undefined;
        displayName: string | null | undefined;
        presence?: PersonaPresence;
    }[];
    onChange: (users: { userId: string; name: string }[]) => void;
    itemLimit?: number | undefined;
    label?: string | null | undefined;
    disabled?: boolean;
};

export function UserPicker(props: UserPickerProps): JSX.Element {
    const { currentTenantId } = useStateContext();

    const client = useApolloClient();

    const updateSelectedUsers = (
        personas?: IPersonaProps[] | undefined
    ): void => {
        let users: { userId: string; name: string }[];
        if (personas) {
            users = personas.map((persona) => {
                return {
                    userId: persona.id || '',
                    name: persona.text || '',
                    presence: persona.presence,
                };
            });
        } else {
            users = [];
        }
        if (props.onChange) {
            props.onChange(users);
        }
    };

    const getDefaultSelectedItems = (): IPersonaProps[] | undefined => {
        if (!props.defaultSelectedUsers) {
            return undefined;
        }

        const personas = props?.defaultSelectedUsers.map((r) => {
            return {
                id: r?.userId || '',
                text: r?.displayName || '',
                imageUrl: photoService.getImageUrl(r?.userId),
            };
        });

        return personas;
    };

    const selectedItems = useMemo((): IPersonaProps[] | undefined => {
        if (!props.selectedUsers) {
            return undefined;
        }

        const personas = props.selectedUsers.map((r) => {
            return {
                id: r.userId || '',
                text: r.displayName || '',
                imageUrl: photoService.getImageUrl(r.userId),
                presence: r.presence,
            } as IPersonaProps;
        });

        return personas;
    }, [props.selectedUsers]);

    const listContainsPersona = (
        persona: IPersonaProps,
        personas: IPersonaProps[]
    ): boolean => {
        if (!personas || !personas.length || personas.length === 0) {
            return false;
        }
        return personas.filter((item) => item.id === persona.id).length > 0;
    };

    const removeDuplicates = (
        personas: IPersonaProps[],
        possibleDupes: IPersonaProps[]
    ): IPersonaProps[] => {
        return personas.filter(
            (persona) => !listContainsPersona(persona, possibleDupes)
        );
    };

    const onResolveSuggestions = (
        filterText: string,
        currentPersonas: IPersonaProps[] | undefined,
        limitResults?: number
    ): IPersonaProps[] | Promise<IPersonaProps[]> => {
        if (filterText) {
            const query = client.query<
                GetUsersSearchQuery,
                GetUsersSearchQueryVariables
            >({
                query: GetUsersSearchDocument,
                variables: {
                    tenantId: currentTenantId || '',
                    searchText: filterText,
                    useCache: false,
                },
            });

            return query.then((queryResult) => {
                if (queryResult.data && queryResult.data.userSearch) {
                    let filteredPersonas: IPersonaProps[] =
                        queryResult.data.userSearch.map((u) => {
                            return {
                                id: u?.id || '',
                                text: u?.displayName || '',
                                imageUrl: photoService.getImageUrl(u?.id),
                            };
                        });

                    filteredPersonas = removeDuplicates(
                        filteredPersonas,
                        currentPersonas || []
                    );

                    filteredPersonas = limitResults
                        ? filteredPersonas.splice(0, limitResults)
                        : filteredPersonas;

                    return filteredPersonas;
                } else {
                    return [];
                }
            });
        } else {
            return [];
        }
    };

    const validateInput = (input: string): ValidationState => {
        if (input.indexOf('@') !== -1) {
            return ValidationState.valid;
        } else if (input.length > 1) {
            return ValidationState.warning;
        } else {
            return ValidationState.invalid;
        }
    };

    const getTextFromItem = (persona: IPersonaProps): string => {
        return persona.text as string;
    };

    const onChange = (items?: IPersonaProps[] | undefined): void => {
        updateSelectedUsers(items);
    };

    return (
        <div>
            <Label>{props.label ?? 'Select a user'}</Label>
            <CompactPeoplePicker
                selectedItems={selectedItems}
                defaultSelectedItems={getDefaultSelectedItems()}
                itemLimit={props.itemLimit}
                onResolveSuggestions={onResolveSuggestions}
                getTextFromItem={getTextFromItem}
                className={'ms-PeoplePicker'}
                key={'userPicker'}
                onChange={onChange}
                onValidateInput={validateInput}
                inputProps={{
                    'aria-label': 'User Picker',
                }}
                disabled={props.disabled}
            />
        </div>
    );
}
