import React, { useEffect, useRef } from 'react';
import { useThemes } from '../../../hooks/useThemes';
import { PresentationUserImage } from './PresentationUserImage';
import { usePresentationColours } from '../hooks/usePresentationColours';

export function PresentationTitleSlide(props: {
    title: string;
    mission:
        | {
              owner: string | null;
              title: string | null;
              userId: string | null;
          }
        | undefined
        | null;
    onSectionChanged: () => void;
}) {
    const { onSectionChanged } = props;

    const { lightTheme } = useThemes();

    const { titleBackgroundColour, backgroundImage, darkLogoSrc } =
        usePresentationColours();

    useEffect(() => {
        if (sectionRef.current) {
            sectionRef.current.setAttribute(
                'data-background-image',
                backgroundImage || ''
            );
            sectionRef.current.setAttribute(
                'data-background-color',
                titleBackgroundColour || ''
            );
            onSectionChanged();
        }
    }, [titleBackgroundColour, backgroundImage, onSectionChanged]);

    const sectionRef = useRef<HTMLElement>(null);

    return (
        <section
            key="title"
            ref={sectionRef}
            data-background-image={backgroundImage}
            data-background-color={titleBackgroundColour}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100vh',
                    justifyContent: 'center',
                }}
            >
                <div
                    style={{
                        backgroundColor: backgroundImage
                            ? `${lightTheme.palette.white}CC` // add a little transparency if there's a background image
                            : lightTheme.palette.white,
                        width: '100%',
                        paddingLeft: '4vw',
                        paddingTop: '6vh',
                        paddingBottom: '8vh',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '4vw',
                    }}
                >
                    <PresentationUserImage
                        userId={props.mission?.userId}
                        style={{
                            width: '16vw',
                        }}
                    />

                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'start',
                            flexDirection: 'column',
                            gap: '1vh',
                        }}
                    >
                        <h1
                            style={{
                                color: lightTheme.palette.neutralDark,
                                textAlign: 'left',
                            }}
                            data-id="mission-owner"
                        >
                            {props.mission?.owner}
                        </h1>
                        <h4
                            style={{
                                color: lightTheme.palette.neutralDark,
                                textAlign: 'left',
                            }}
                            data-id="mission-title"
                        >
                            {props.mission?.title}
                        </h4>
                    </div>
                </div>
                <h5 className="mintTitleBar">{props.title}</h5>
            </div>

            {!!darkLogoSrc && (
                <div
                    style={{
                        position: 'absolute',
                        bottom: 8,
                        left: 8,
                        height: 60,
                    }}
                >
                    <img
                        src={darkLogoSrc}
                        style={{
                            border: 0,
                            maxHeight: '100%',
                            maxWidth: '100%',
                            width: 'auto',
                            margin: 0,
                        }}
                    />
                </div>
            )}
        </section>
    );
}
