import React, { PropsWithChildren } from 'react';
import { PresentationSectionIndicator } from './PresentationSectionIndicator';

export function PresentationBlockquote(
    props: PropsWithChildren<{
        label?: string | JSX.Element;
        align?: 'start' | 'center';
        hideIndicator?: boolean;
    }>
) {
    return (
        <div
            style={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'row',
                alignItems: props.align || 'center',
                width: '100%',
            }}
        >
            {!props.hideIndicator && (
                <PresentationSectionIndicator label={props.label} />
            )}

            <blockquote
                style={{
                    position: 'relative',
                    top: 0,
                    left: 0,
                    width: '100%',
                    margin: 0,
                    display: 'flex',
                    borderLeft: 'none',
                    borderRight: 'none',
                    padding: 0,
                }}
            >
                <div style={{ padding: 8, paddingLeft: 16, width: '100%' }}>
                    {props.children}
                </div>
            </blockquote>
        </div>
    );
}
