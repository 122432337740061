import { CSSProperties } from 'react';
import MarkdownIt from 'markdown-it';
import { BoldUserMentionsPlugin } from '../../components/plugins/BoldUserMentionsPlugin';
import { ReplaceEmojisPlugin } from '../../components/plugins/ReplaceEmojisPlugin';

export function Markdown(props: {
    source?: string | null;
    className?: string;
    style?: CSSProperties | undefined;
}): JSX.Element {
    const md = new MarkdownIt({ xhtmlOut: true, linkify: false, breaks: true });
    md.use(BoldUserMentionsPlugin);
    md.use(ReplaceEmojisPlugin);

    const html = md.render(props.source || '');

    return (
        <div
            className={props.className}
            style={props.style}
            dangerouslySetInnerHTML={{ __html: html }}
        />
    );
}
